import React, { useContext } from 'react';
import { UserContext } from '../providers/user-context';
import { Redirect } from 'react-router-dom';

export const PermissionsMiddleware = ({ component, permission }) => {
	const { user } = useContext(UserContext);
	const { permissions: userPermissions } = user;

	const next = userPermissions.find(item => item === permission);
	console.log(next);

	return (next || !permission) ? component : <Redirect to="/error"/>;
};