import React from 'react';
import './_style.scss';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import AdminPriceLists from '../../../organism/AdminPriceLists/AdminPriceLists';

const PriceList = () => (
  <div>
    <TitlePage text="PARAMETRÍA LISTA DE PRECIOS" />
    <AdminPriceLists />
  </div>
);

export default PriceList;
