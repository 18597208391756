import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

const PaginatedTable = ({ columns, pageSize, onFetch, onFormat, filters, emptyText, ...rest }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [dataSource, setDataSource] = useState([]);

  const onResult = data => {
    if (onFormat) {
      setDataSource(onFormat(data));
    } else {
      setDataSource(data.content);
    }
    setTotalElements(data.totalElements);
  };

  const onPageChange = page => {
    setPageNumber(page - 1);
  };

  const localeTableProperties = { emptyText: emptyText || 'No existen resultados' };

  useEffect(() => {
    onFetch(pageNumber, pageSize, onResult);
  }, [pageNumber, pageSize]);

  useEffect(() => {
    setPageNumber(0);
    onFetch(pageNumber, pageSize, onResult);
  }, filters);

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      locale={localeTableProperties}
      pagination={{
        total: totalElements,
        pageSize,
        current: pageNumber + 1,
        onChange: onPageChange
      }}
      {...rest}
    />
  );
};

PaginatedTable.propTypes = {
  filters: PropTypes.array
};

export default PaginatedTable;
