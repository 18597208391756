/* eslint-disable max-len */

const loginRequest = () => ({
	data: {
		"id": 1,
		"username": "admin@atixlabs.com",
		"email": "admin@atixlabs.com",
		"name": "Admin",
		"lastname": "Admin",
		"accessToken": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbkBhdGl4bGFicy5jb20iLCJpYXQiOjE2MDAzNTcwMzUsImV4cCI6MTYwMDk2MTgzNX0.fD7ouVG2KBtISBf1Z2wQG5yHkhn-HBlAM15pBKDN_KDFlgwnlHT7ZhMlVOLLcaEQxS9EAT0Ka5K3yy0x9PKs1g",
		"primaryNavbar": {
			"menus": [{
				"key": "USERS",
				"description": "My account",
				"items": [{
					"key": "LOGOUT",
					"description": "Logout",
					"items": [],
					"url": "/login",
					"icon": 'logout',
					"order": 1,
					"type": "GET"
				}, {
					"key": "VIEW_PROFILE",
					"description": "View Profile",
					"items": [],
					"url": "/user-information",
					"icon": null,
					"order": 0,
					"type": "GET"
				}],
				"url": null,
				"icon": 'ShoppingCartOutlined',
				"order": 3
			}, {
				"key": "MANAGE_USERS",
				"description": "Manage Users",
				"items": [],
				"url": "/administration",
				"icon": null,
				"order": 2
			}]
		},
		"secondaryNavbar": {
			"menus": [{
				"key": "PRODUCTIVITY",
				"description": "Productividad",
				"items": [],
				"url": "/productividad",
				"icon": "productividad-icon",
				"order": 1
			}, {
				"key": "CATALOG",
				"description": "Catálogo",
				"items": [],
				"url": "/catalogo",
				"icon": "catalogo-icon",
				"order": 6
			}, {
				"key": "PRICE_LIST",
				"description": "Lista de Precios",
				"items": [],
				"url": "/lista-precios",
				"icon": "lista-precios-icon",
				"order": 3
			}, {
				"key": "PROMOTIONS",
				"description": "Promociones",
				"items": [],
				"url": "/promociones",
				"icon": "promociones-icon",
				"order": 5
			}, {
				"key": "ACCOUNTABILITIES",
				"description": "Rendiciones",
				"items": [],
				"url": "/rendiciones",
				"icon": "rendiciones-icon",
				"order": 4
			}, {
				"key": "PARAMETERS",
				"description": "Parametría",
				"items": [{
					"key": "CATALOG_PARAMETERS",
					"description": "Catálogo",
					"items": [],
					"url": "/parametria-catalogo",
					"icon": null,
					"order": 0,
					"type": null
				}, {
					"key": "PRICE_LIST_PARAMETERS",
					"description": "Lista de Precios",
					"items": [],
					"url": "/parametria-lista-precios",
					"icon": null,
					"order": 2,
					"type": null
				}, {
					"key": "PRODUCTIVITY_PARAMETERS",
					"description": "Productividad",
					"items": [],
					"url": "/parametria-productividad",
					"icon": null,
					"order": 4,
					"type": null
				}, {
					"key": "PROMOTIONS_PARAMETERS",
					"description": "Promociones",
					"items": [],
					"url": "/parametria-promociones",
					"icon": null,
					"order": 5,
					"type": null
				}, {
					"key": "NEWS_PARAMETERS",
					"description": "Novedades",
					"items": [],
					"url": "/parametria-novedades",
					"icon": null,
					"order": 1,
					"type": null
				}, {
					"key": "ACCOUNTABILITIES_PARAMETERS",
					"description": "Rendiciones",
					"items": [],
					"url": "/parametria-rendiciones",
					"icon": null,
					"order": 3,
					"type": null
				}],
				"url": null,
				"icon": null,
				"order": 7
			}, {
				"key": "NEWS",
				"description": "Novedades",
				"items": [],
				"url": "/novedades",
				"icon": "novedades-icon",
				"order": 0
			}, {
				"key": "SURVEYS",
				"description": "Relevamientos",
				"items": [],
				"url": "/relevamientos",
				"icon": "relevamientos-icon",
				"order": 2
			}]
		},
		"role": "Administrator",
		"permissions": [
			"VIEW_PROFILE",
			"CREATE_USER",
			"MODIFY_USERS",
			"LIST_USERS",
			"HOME",
			"NEWS",
			"ADMIN_NEWS",
			"PRICE_LIST",
			"ADMIN_PRICE_LIST",
			"ADMIN_PRICE_LIST_CREATE",
			"ADMIN_PRICE_LIST_EDIT",
		],
		"actions": ["DELETE", "CREATE", "UPDATE"]
	}
});

const logoutRequest = () => ({});

export default () => ({
	loginRequest,
	logoutRequest
});
