import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import iconPlus from '../../../img/icon-plus.svg';

const Cards = ({ id, title, fecha, img, handleShow }) => (
    <div className="card-container">
      <div
        className="card-img-container"
        style={{
          background: `url(${img}) no-repeat center center`,
          backgroundSize: 'cover',
          height: '250px',
          borderRadius: '4px 4px 0 0'
        }}
      />
      <div className="card-content">
        <p className="label-date">{fecha}</p>
        <h3 className="card-title">{title}</h3>
        <div className="icon-plus">
          <button style={{ padding: '0px', cursor: 'pointer' }} onClick={() => handleShow(id)}>
            <img src={iconPlus} />
          </button>
        </div>
      </div>
    </div>
  );

Cards.propTypes = {
  handleShow: PropTypes.func.isRequired
};

export default Cards;
