import React from 'react';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import AdminUserList from "../../../organism/AdminUserList/AdminUserList";

const PriceList = () => (
	<div>
		<TitlePage text="PARAMETRÍA USUARIOS" />
		<AdminUserList />
	</div>
);

export default PriceList;
