import React from 'react';
import './style.scss';
import star from '../../../img/star.svg';

const PersonalData = ({ name, mail, points }) => (
  <div className="personal-data-container">

      <span>{name}</span>
      <p>{mail}</p>
      <div className="btn-points">
        <img src={star} alt="" />
        <span>{points} PUNTOS</span>
      </div>
    </div>

);

export default PersonalData;
