import helpers from './helpers';

const confirmPurchase = makePostRequest => () => makePostRequest('cart/purchase');

const confirmPurchaseWithPoints = makePostRequest => () => makePostRequest('cart/purchase-points');

export default client => {
  const { makePostRequest } = helpers(client);
  return {
    confirmPurchase: confirmPurchase(makePostRequest),
    confirmPurchaseWithPoints: confirmPurchaseWithPoints(makePostRequest)
  };
};
