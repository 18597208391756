import helpers from './helpers';

const getBranchOffices  = makeGetRequest => (data) => makeGetRequest('branch-offices', data);

export default client => {
	const { makeGetRequest } = helpers(client);
	return {
		getBranchOffices: getBranchOffices(makeGetRequest),
	};
};
