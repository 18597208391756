import helpers from './helpers';

const saveExpenseReport = makePostFileRequest => data =>
  makePostFileRequest('/expense-report', data);

const confirmExpenseReport = makePutRequest => (id, data) =>
  makePutRequest(`/expense-report/${id}/confirm`, data);

const rejectExpenseReport = makePutRequest => (id, data) =>
  makePutRequest(`/expense-report/${id}/reject`, data);

const sendExpenseReport = makePutRequest => id => makePutRequest(`/expense-report/${id}/send`);

const deleteExpenseReport = makeDeleteRequest => id =>
  makeDeleteRequest(`/expense-report/draft/${id}`);

const getExpenseReport = makeGetRequest => data => makeGetRequest('/expense-report', data);

const getStatesExpenseReport = makeGetRequest => () => makeGetRequest('/expense-report/states');

// Admin endpoints
const getExpenseReportAdmin = makeGetRequest => data =>
  makeGetRequest('/expense-report/parameters', data);

const getStatesExpenseReportAdmin = makeGetRequest => () =>
  makeGetRequest('/expense-report/parameters/states');

const deleteExpenseReportAdmin = makeDeleteRequest => id =>
  makeDeleteRequest(`/expense-report/${id}`);

const getExpenseTopAro = makeGetRequest => data => makeGetRequest('/expense-report/top-aro', data);

// Dashboard
const getExpenseReportAdminDashboard = makeGetRequest => data =>
  makeGetRequest('/expense-report/dashboard', data);

const getExpenseReportDashboardLines = makeGetRequest => data =>
  makeGetRequest('/expense-report/dashboard/lines', data);

const getExpenseReportDashboardMarkets = makeGetRequest => data =>
  makeGetRequest('/expense-report/dashboard/markets', data);

const getExpenseReportDashboardAros = makeGetRequest => data =>
  makeGetRequest('/expense-report/dashboard/aros', data);

export default client => {
  const { makePostFileRequest, makePutRequest, makeDeleteRequest, makeGetRequest } = helpers(
    client
  );
  return {
    saveExpenseReport: saveExpenseReport(makePostFileRequest),
    sendExpenseReport: sendExpenseReport(makePutRequest),
    deleteExpenseReport: deleteExpenseReport(makeDeleteRequest),
    getExpenseReport: getExpenseReport(makeGetRequest),
    getStatesExpenseReport: getStatesExpenseReport(makeGetRequest),
    confirmExpenseReport: confirmExpenseReport(makePutRequest),
    rejectExpenseReport: rejectExpenseReport(makePutRequest),
    getExpenseReportAdmin: getExpenseReportAdmin(makeGetRequest),
    getStatesExpenseReportAdmin: getStatesExpenseReportAdmin(makeGetRequest),
    deleteExpenseReportAdmin: deleteExpenseReportAdmin(makeDeleteRequest),
    getExpenseReportAdminDashboard: getExpenseReportAdminDashboard(makeGetRequest),
    getExpenseTopAro: getExpenseTopAro(makeGetRequest),
    getExpenseReportDashboardLines: getExpenseReportDashboardLines(makeGetRequest),
    getExpenseReportDashboardMarkets: getExpenseReportDashboardMarkets(makeGetRequest),
    getExpenseReportDashboardAros: getExpenseReportDashboardAros(makeGetRequest)
  };
};
