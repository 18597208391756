import React, { useState, useEffect } from 'react';
import './_style.scss';
import TitlePage from '../../atoms/TitlePage/TitlePage';
import { message } from 'antd';
import History from '../../atoms/Breadcrumb/history';
import apiCall from '../../../services/api-calls/all';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import PreApprovalForm from '../../organism/PreApprovalForm/PreApprovalForm';
import { useRedirect } from '../../Router/redirect';
import { PREAPPROVAL } from '../../../utils/constants';
import moment from 'moment';


const breadcrumbs = [
  {
    title: 'Pre-Aprobación',
    href: PREAPPROVAL
  },
  {
    title: 'Editar pre-aprobación',
    href: ''
  }
];
const { getPreApprovalById, updatePreApproval } = apiCall();
const PreApprovalEdit = ({ match, location }) => {
  const { params } = match;
  const [preapproval, setPreapproval] = useState({});
  const { redirect, setUrlToRedirect } = useRedirect();
  const preApprovalState = location.state;

  const _getPreApprovalById = async () => {
    try {
      const response = await getPreApprovalById(params.id);
      setPreapproval(response);
    } catch (error) {
      const { data } = error.response;
      const errorMessage = processedErrorMessage(error, data);
      message.error(errorMessage);
    }
  };

  const handleUpdate = async values => {
    try {
      const { document, fromDate, toDate, ...rest } = values;
      const formData = new FormData();
      if (document.name) {
        formData.set('file', document);
      } else {
        formData.set('file', null);
      }

      formData.set(
        'preApprovalProperties',
        JSON.stringify(
          Object.assign(rest, {
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD')
          })
        )
      );

      await updatePreApproval(params.id, formData);
      message.success('¡Tu Pre-aprobación ha sido actualizado!');
      setUrlToRedirect(PREAPPROVAL);
    } catch (error) {
      const { data } = error.response;
      const errorMessage = processedErrorMessage(error, data);
      message.error(errorMessage);
    }
  };
  useEffect(() => {
    _getPreApprovalById();
  }, []);

  return (
    <div className="order-edit-container">
      {redirect()}
      <div className="title">
        <History breadcrumbs={breadcrumbs} />
        <TitlePage text="EDITAR PRE-APROBACIÓN" />
      </div>
      <PreApprovalForm handleSubmit={handleUpdate} preapproval={preapproval} preApprovalState={preApprovalState} />
    </div>
  );
};

export default PreApprovalEdit;
