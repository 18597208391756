import React, { useState, useEffect } from 'react';
import './_style.scss';
import { message, Select } from 'antd';
import TitlePage from '../../atoms/TitlePage/TitlePage';
import History from '../../atoms/Breadcrumb/history';
import RefundsForm from '../../organism/RefundsForm/RefundsForm';
import apiCall from '../../../services/api-calls/all';

import { REFUND } from '../../../utils/constants';
import { useRedirect } from '../../Router/redirect';
import { processedErrorMessage } from '../../../services/api-calls/helpers';

const { getRefundById, updateRefund } = apiCall();

const breadcrumbs = [
  {
    title: 'Reembolso',
    href: REFUND
  },
  {
    title: 'Editar reembolso',
    href: ''
  }
];

const RefundsEdit = ({ match, location }) => {
  const {params} = match;
  const [refund, setRefund] = useState({});
  const { redirect, setUrlToRedirect } = useRedirect();
  const refundState = location.state;

  const _getRefundById = async () => {
    try {
      const response = await getRefundById(params.id);
      setRefund(response);
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  const handleUpdate = async values => {
    try {
      const { invoice, receipt, ...rest } = values;
      const formData = new FormData();
      if (invoice.name) {
        formData.set('invoice', invoice);
      } else {
        formData.set('invoice', null);
      }
      if (receipt.name) {
        formData.set('receipt', receipt);
      } else {
        formData.set('receipt', null);
      }
      formData.set(
        'refundProperties',
        JSON.stringify(rest)
      );

      await updateRefund(params.id, formData);
      message.success('Reembolso ha sido actualizado con exito!');
      setUrlToRedirect(REFUND);
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  useEffect(() => {
    _getRefundById();
  }, [])

  return (
    <div>
      {redirect()}
      <div className="title">
        <History breadcrumbs={breadcrumbs} />
        <TitlePage text="EDITAR REEMBOLSO" />
      </div>
      <RefundsForm handleSubmit={handleUpdate} refund={refund} refundState={refundState} />
    </div>
  )
};

export default RefundsEdit;
