import helpers from './helpers';

const getMonths = makeGetRequest => () => makeGetRequest('/calendar/months');
const getYears = makeGetRequest => (data) => makeGetRequest('/calendar/years', data);

export default client => {
  const { makeGetRequest } = helpers(client);
  return {
    getMonths: getMonths(makeGetRequest),
    getYears: getYears(makeGetRequest)
  };
};
