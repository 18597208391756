import React from 'react';
import { Form, Input, Tooltip } from 'antd';
import { PASSWORD_RULE, CONFIRM_PASSWORD_RULE } from '../../../utils/validation-rules';

const UserFormEditPassword = () => {
  return (
    <>
      <div className="_input">
        <label className="input-label">Contraseña </label>
        <Tooltip title="La password debe contener un minimo de 6 caracteres, al menos un numero y al menos una letra.">
          <Form.Item rules={[PASSWORD_RULE]} name="password">
            <Input.Password className="input" placeholder="******" />
          </Form.Item>
        </Tooltip>
      </div>

      <div className="_input">
        <label className="input-label">Confirmar contraseña </label>
        <Form.Item
          rules={[PASSWORD_RULE, CONFIRM_PASSWORD_RULE]}
          dependencies={['password']}
          name="confirmPassword"
        >
          <Input.Password className="input" placeholder="******" />
        </Form.Item>
      </div>
    </>
  );
};

export default UserFormEditPassword;
