import { FILENAME_CONTENT_DISPOSITION } from './validation-regular-exp';
import moment from 'moment';

export const scrollWindowToTop = ({ smooth }) =>
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: smooth ? 'smooth' : undefined
  });

export const compare = (element1, element2, key) => {
  if (!element1[key] && element1[key] !== 0) return 1;
  if (!element2[key] && element2[key] !== 0) return -1;
  return JSON.stringify(element1[key]).localeCompare(JSON.stringify(element2[key]));
};

const getUrlVars = () => {
  const vars = {};
  const parts = window.location.href.replace(/[&?]+([^&=]+)=([^&]*)/gi, (m, key, value) => {
    vars[key] = value;
  });
  return vars;
};

export const getUrlParam = (parameter, defaultvalue) => {
  let urlparameter = defaultvalue;
  if (window.location.href.includes(parameter)) {
    urlparameter = getUrlVars()[parameter];
  }
  return urlparameter;
};

export const isEmpty = object => Object.entries(object).length === 0;

export const downloadPdf = (file, fileName) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.setAttribute('download', fileName);
  document.body.append(link);
  link.click();
  document.body.removeChild(link);
};

export const currencyFormat = value => `$${parseFloat(value).toFixed(2)}`;

export const dateFormat = date => {
  const format = new Date(date);
  return `${format.getUTCDate()}/${format.getUTCMonth() + 1}/${format.getUTCFullYear()}`;
};

export const cleanObjectValues = object =>
  Object.entries(object).reduce(
    (accumulator, item) =>
      item[1] ? Object.assign({}, accumulator, { [item[0]]: item[1] }) : accumulator,
    {}
  );

export const arrayBufferToString = buffer => {
  const byteArray = new Uint8Array(buffer);
  let string = '';
  let cc = 0;
  let numberBytes = 0;
  for (let i = 0, length_ = byteArray.length; i < length_; ++i) {
    const v = byteArray[i];
    if (numberBytes > 0) {
      // 2 bit determining that this is a tailing byte + 6 bit of payload
      if ((cc & 192) === 192) {
        // processing tailing-bytes
        cc = (cc << 6) | (v & 63);
      } else {
        throw new Error('this is no tailing-byte');
      }
    } else if (v < 128) {
      // single-byte
      numberBytes = 1;
      cc = v;
    } else if (v < 192) {
      // these are tailing-bytes
      throw new Error('invalid byte, this is a tailing-byte');
    } else if (v < 224) {
      // 3 bits of header + 5bits of payload
      numberBytes = 2;
      cc = v & 31;
    } else if (v < 240) {
      // 4 bits of header + 4bit of payload
      numberBytes = 3;
      cc = v & 15;
    } else {
      // UTF-8 theoretically supports up to 8 bytes containing up to 42bit of payload
      // but JS can only handle 16bit.
      throw new Error('invalid encoding, value out of range');
    }

    if (--numberBytes === 0) {
      string += String.fromCharCode(cc);
    }
  }
  if (numberBytes) {
    throw new Error('the bytes don\'t sum up');
  }
  return string;
};

export const extractContentDispositionFilename = (response, filename) => {
  const r = new RegExp(FILENAME_CONTENT_DISPOSITION);
  return response.headers['content-disposition']
    ? r.exec(response.headers['content-disposition'])[0]
    : filename;
};

export const getMonthDescriptionByNumber = month => {
  const m = moment(new Date(null, month - 1)).format('MMMM');
  return m.toUpperCase();
};
