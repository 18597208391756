import React, { useEffect, useRef, useState } from 'react';
import { Input, message, Select, Space } from 'antd';
import './_style.scss';
import ButtonPrimary from '../../../components/atoms/ButtonPrimary/button-primary';
import PaginatedTable from '../../molecules/PaginatedTable/paginated-table.jsx';
import { columns } from '../../../utils/tables/table-expense-admin';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCalls from '../../../services/api-calls/all';
import { DownloadOutlined, InfoCircleFilled } from '@ant-design/icons';

import {
  ADMIN_USER_LIST_URL,
  EXPENSE_APPROVED,
  PAGE_SIZE,
  RENDITIONS
} from '../../../utils/constants';
import { useRedirect } from '../../Router/redirect';
import { UserHelpers } from '../../../utils/user-helpers';
import { downloadPdf } from '../../../utils/func-helpers';
import PopUpExpenseEdit from '../../molecules/PopUp/popUp';
import PopUpImport from '../../molecules/PopUp/popUp';
import ExpensePopUpForm from '../ExpensePopUpForm/ExpensePopUpForm';
import { get } from 'lodash';
import ExpenseImportTemplateForm from '../ExpenseImportTemplateForm/ExpenseImportTemplateForm';
import SkuImportForm from '../SkuImportForm/SkuImportForm';
import Spinner from '../../atoms/Spinner/Spinner';

const {
  getExpenseReportAdmin,
  getStatesExpenseReportAdmin,
  deleteExpenseReportAdmin,
  confirmExpenseReport,
  rejectExpenseReport,
  getMonths,
  getYears,
  downloadFile,
  importTemplateExpense,
  importSku,
  downloadSku,
  downloadExpenseReportTemplate
} = apiCalls();
const { Search } = Input;

const AdminExpenseList = () => {
  const [search, setSearch] = useState('');
  const [months, setMonths] = useState([]);
  const [monthsLoading, setMonthsLoading] = useState(false);
  const [years, setYears] = useState([]);
  const [yearsLoading, setYearsLoading] = useState(false);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [states, setStates] = useState([]);
  const [statesSelected, setStatesSelected] = useState([-1]);
  const [expenseEdit, setExpenseEdit] = useState(undefined);
  const [reset, setReset] = useState(false);
  const filters = [search, month, year, statesSelected, reset];
  const { isViewer } = UserHelpers();
  const { redirect, setUrlToRedirect } = useRedirect();
  const { Option } = Select;
  const modal = useRef();
  const modalImportTemplate = useRef();
  const modalImportSku = useRef();
  const form = useRef();
  const formImportTemplate = useRef();
  const formImportSku = useRef();
  const [loading, setLoading] = useState(false);
  const [errorRows, setErrorRows] = useState([]);

  const handleOnEdit = id => {
    setUrlToRedirect(`${ADMIN_USER_LIST_URL}/${id}`);
  };

  const expenseFiltered = async (page, pageSize, onResult) => {
    try {
      setLoading(true);
      const filter = {
        page,
        size: pageSize,
        search,
        month,
        year,
        states: statesSelected && !statesSelected.includes(-1) ? statesSelected.join(',') : null
      };
      const response = await getExpenseReportAdmin(filter);
      setLoading(false);
      onResult(response);
    } catch (error) {
      setLoading(false);
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  const _getMonths = async () => {
    try {
      setMonthsLoading(true);
      const response = await getMonths();
      setMonths(response);
      setMonthsLoading(false);
    } catch (error) {
      setMonthsLoading(false);
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };
  const _getYear = async () => {
    try {
      setYearsLoading(true);
      const response = await getYears({ next: 5, last: 2 });
      setYears(response);
      setYearsLoading(false);
    } catch (error) {
      setYearsLoading(false);
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  const _getStates = async () => {
    try {
      setLoading(true);
      const response = await getStatesExpenseReportAdmin();
      setStates(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const handleStates = categoryId => {
    // reset other pills
    if (categoryId === -1) setStatesSelected([-1]);
    else {
      const selected = statesSelected.includes(-1) ? [] : statesSelected;
      const exist = selected.indexOf(categoryId);
      exist > -1 ? selected.splice(exist, 1) : selected.push(categoryId);
      setStatesSelected([...new Set(selected)]);
    }
  };

  const handleUpdateExpense = async values => {
    const { state, comment } = values;
    const parameters = {
      comment
    };
    const endpoint = state === EXPENSE_APPROVED ? confirmExpenseReport : rejectExpenseReport;
    try {
      setLoading(true);
      await endpoint(expenseEdit, parameters);
      setReset(!reset);
      setLoading(false);
      message.success(`Rendición ${state.toLowerCase()} con éxito!`);
      modal.current.hiddenModal();
      form.current.clearForm();
    } catch (error) {
      setLoading(false);
      form.current.clearForm();
      message.error(processedErrorMessage(error));
    }
  };

  const handleDeleteExpense = async id => {
    try {
      setLoading(true);
      await deleteExpenseReportAdmin(id);
      setReset(!reset);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const m = get(error, 'response.data');
      message.error(processedErrorMessage(error, m));
    }
  };

  const prepareDownload = async (id, fileName) => {
    try {
      setLoading(true);
      const fileData = await downloadFile(id);
      setLoading(false);
      downloadPdf(fileData, fileName);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const handleImportExpense = async values => {
    try {
      setLoading(true);
      const { template } = values;
      const formData = new FormData();
      formData.set('template', template);

      await importTemplateExpense(formData);
      setLoading(false);
      message.success('Acción realizada con éxito');
      modalImportTemplate.current.hiddenModal();
      formImportTemplate.current.clearForm();
    } catch (error) {
      setLoading(false);
      const m = get(error, 'reponse.data');
      message.error(processedErrorMessage(error, m));
    }
  };

  const handleImportSku = async values => {
    try {
      setLoading(true);
      const { skusfile } = values;
      const formData = new FormData();
      formData.set('skusfile', skusfile);

      await importSku(formData);
      message.success('Acción realizada con éxito');
      setLoading(false);
      setErrorRows([]);
      modalImportSku.current.hiddenModal();
      formImportSku.current.clearForm();
    } catch (error) {
      setLoading(false);
      const status = get(error, 'response.status');
      if (status === 412) {
        const response = get(error, 'response.data');
        const {
          processExcelFileResult: { errorRows }
        } = response;
        setErrorRows(errorRows);
      } else {
        const errorMessage = processedErrorMessage(error);
        message.error(errorMessage);
      }
    }
  };

  useEffect(() => {
    if (statesSelected.length === 0) setStatesSelected([-1]);
  }, [statesSelected]);

  useEffect(() => {
    _getMonths();
    _getYear();
    _getStates();
  }, []);

  const downloadLastSku = async () => {
    try {
      setLoading(true);
      const fileData = await downloadSku();
      downloadPdf(fileData.data, 'Maestro de Productos.xlsx');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const handleDownloadExpenseReportTemplate = async () => {
    try {
      setLoading(true);
      const fileData = await downloadExpenseReportTemplate();
      downloadPdf(fileData.data, 'Template de rendiciones.xlsx');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  return (
    <Spinner loading={loading}>
      {redirect()}
      <div>
        <div className="inputs-container-expense-list" style={{ justifyContent: 'space-between' }}>
          <div className="inputs_filter_container">
            <div className="search-container-renditions">
              <Search
                className="search_btn"
                placeholder="Buscar"
                enterButton
                onSearch={value => setSearch(value)}
              />
            </div>
            <div className="select_container">
              <Select
                loading={monthsLoading}
                className="select_input"
                placeholder="Mes"
                onChange={value => setMonth(value)}
              >
                <Option key={0} value={''}>
                  TODOS
                </Option>
                {months.map(month => (
                  <Option key={month.id} value={month.id}>
                    {month.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="select_container">
              <Select
                loading={yearsLoading}
                className="select_input"
                placeholder="Año"
                onChange={value => setYear(value)}
              >
                <Option key={0} value={''}>
                  TODOS
                </Option>
                {years.map(year => (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="btn_filter_container">
            <div className="btn-container-param">
              <Space>
                <ButtonPrimary
                  theme="btn-blue"
                  text="IMPORTAR TEMPLATE DE RENDICIONES"
                  onClick={() => modalImportTemplate.current.showModal()}
                />
                <ButtonPrimary
                  theme="btn-blue"
                  text="MAESTRO DE PRODUCTOS"
                  onClick={() => modalImportSku.current.showModal()}
                />
              </Space>
            </div>
          </div>
        </div>
      </div>
      <div className="renditions-filters-container">
        <ButtonPrimary
          text="TODOS"
          onClick={() => handleStates(-1)}
          theme={statesSelected.includes(-1) ? 'filters-style active' : 'filters-style'}
        />
        {states.map(state => (
          <ButtonPrimary
            key={state.id}
            text={state.description}
            onClick={() => handleStates(state.id)}
            theme={statesSelected.includes(state.id) ? 'filters-style active' : 'filters-style'}
          />
        ))}
      </div>
      <div className="page-content">
        <PaginatedTable
          className="table-users"
          columns={columns(
            expenseToEdit => {
              setExpenseEdit(expenseToEdit);
              modal.current.showModal();
            },
            handleDeleteExpense,
            isViewer,
            prepareDownload
          )}
          onFetch={expenseFiltered}
          pageSize={PAGE_SIZE}
          filters={filters}
          scroll={{ x: 420, y: 300 }}
        />
      </div>
      <PopUpExpenseEdit title={'Editar Rendición'} ref={modal} footer={null}>
        <ExpensePopUpForm
          ref={form}
          handleSubmit={handleUpdateExpense}
          handleCancel={() => {
            modal.current.hiddenModal();
            form.current.clearForm();
          }}
        />
      </PopUpExpenseEdit>

      <PopUpImport
        title={'IMPORTAR TEMPLATE DE RENDICIONES'}
        ref={modalImportTemplate}
        footer={null}
      >
        <div className="template-download-container">
          <div className="template-download-text">
            <h2>Modelo de Prueba</h2>
            <p>Descargá el ultimo Template de Rendiciones disponible.</p>
          </div>
          <div className="template-download-btn">
            <ButtonPrimary
              iconLeft={<DownloadOutlined />}
              text="DESCARGAR"
              onClick={handleDownloadExpenseReportTemplate}
            />
          </div>
        </div>
        <ExpenseImportTemplateForm
          ref={formImportTemplate}
          handleSubmit={handleImportExpense}
          handleCancel={() => {
            modalImportTemplate.current.hiddenModal();
            formImportTemplate.current.clearForm();
          }}
        />
      </PopUpImport>

      <PopUpImport title={'IMPORTAR MAESTRO DE PRODUCTOS'} ref={modalImportSku} footer={null}>
        <div className="template-download-container">
          <div className="template-download-text">
            <h2>Maestro de Productos</h2>
            <p>Descargá el Maestro de Productos actualizado.</p>
          </div>
          <div className="template-download-btn">
            <ButtonPrimary
              iconLeft={<DownloadOutlined />}
              text="DESCARGAR"
              onClick={downloadLastSku}
            />
          </div>
        </div>
        <SkuImportForm
          ref={formImportSku}
          errorRows={errorRows}
          loading={loading}
          handleSubmit={handleImportSku}
          handleCancel={() => {
            setErrorRows([]);
            modalImportSku.current.hiddenModal();
            formImportSku.current.clearForm();
          }}
        />
      </PopUpImport>
    </Spinner>
  );
};

export default AdminExpenseList;
