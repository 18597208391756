import React from 'react';
import './_style.scss';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import AdminClientLists from '../../../organism/AdminClientLists/AdminClientLists';

const Clients = () => (
  <div className="main-clients-container">
    <TitlePage text="PARAMETRÍA CLIENTES" />
    <AdminClientLists />
  </div>
);

export default Clients;
