import React from 'react';
import './_style.scss';

import TitlePage from '../../atoms/TitlePage/TitlePage';
import NewLists from '../../organism/NewLists/NewLists';

const News = () => (
  <div>
    <TitlePage text="NOVEDADES" />
    <NewLists/>
  </div>
);

export default News;
