import React, {useState} from 'react';
import './style.scss';
import TitlePage from '../../atoms/TitlePage/TitlePage';
import RenditionsForm from '../../organism/RenditionsForm/RenditionsForm';
import History from '../../atoms/Breadcrumb/history';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCall from '../../../services/api-calls/all';
import {message, Table} from 'antd';
import { useRedirect } from '../../Router/redirect';
import { RENDITIONS } from '../../../utils/constants';
import { get } from 'lodash';
import {InfoCircleFilled} from "@ant-design/icons";
import Spinner from '../../atoms/Spinner/Spinner';

const breadcrumbs = [
  {
    title: 'Rendiciones',
    href: RENDITIONS
  },
  {
    title: 'Crear Nueva Rendición',
    href: ''
  }
];

const { saveExpenseReport } = apiCall();
const columns = [
  {
    title: 'Fila',
    dataIndex: 'rowNumber'
  },
  {
    title: 'Descripción del error',
    dataIndex: 'error'
  },
];

const RenditionsCreate = () => {
  const { redirect, setUrlToRedirect } = useRedirect();
  const [ disabledButton, setDisabledButton ] = useState();
  const [ errorRows, setErrorRows ] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCreate = async values => {
    try {
      setDisabledButton(true)
      setLoading(true);
      const { invoice, expenseReport, ...rest } = values;
      const formData = new FormData();
      formData.set('expenseReport', expenseReport);
      formData.set('invoice', invoice);
      formData.set('expenseReportProperties', JSON.stringify(rest));

      await saveExpenseReport(formData);
      message.success('Acción realizada con éxito');
      setUrlToRedirect(RENDITIONS);
    } catch (error) {
      const status = get(error, 'response.status');
      if (status === 412) {
        const response = get(error, 'response.data');
        const {processExcelFileResult: {errorRows}} = response

        message.error(response.message);
        setErrorRows(errorRows);
      } else {
        const errorMessage = processedErrorMessage(error);
        message.error(errorMessage);
      }
    } finally {
      setLoading(false);
      setDisabledButton(false)
    }
  };

  return (
    <Spinner loading={loading}>
      {redirect()}
      <History breadcrumbs={breadcrumbs} />
      <div className="title">
        <TitlePage text="CREAR NUEVA RENDICIÓN" />
      </div>
      <RenditionsForm handleSubmit={handleCreate} disabledButtons={disabledButton} >
        {errorRows.length > 0 && <Table
            columns={columns}
            dataSource={errorRows}
            bordered
            rowKey={(obj, index) => index}
            className='table-errors'
            title={() =>
                <>
                  <InfoCircleFilled />
                  <span>Por favor, corregí los errores que se muestran a continuación y volvé a subir el archivo</span>
                </>
            }
        />}
      </RenditionsForm>

    </Spinner>
  );
};

export default RenditionsCreate;
