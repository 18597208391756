import helpers from './helpers';

const importSku = makePostFileRequest => data => makePostFileRequest('/skus/import', data);

export default client => {
	const { makePostFileRequest } = helpers(client);
	return {
		importSku: importSku(makePostFileRequest),
	};
};
