import React, { useEffect, useState } from 'react';
import './_styles.scss';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Form, Input, message, Select, Space, Table } from 'antd';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import { RENDITIONS, SURVEYS } from '../../../utils/constants';
import { useRedirect } from '../../Router/redirect';
import apiCall from '../../../services/api-calls/all';
import { REQUIRED_FIELD_RULE } from '../../../utils/validation-rules';
import {
  downloadPdf,
  extractContentDispositionFilename,
  isEmpty
} from '../../../utils/func-helpers';
import { UserHelpers } from '../../../utils/user-helpers';
import spreadsheet from '../../../img/spreadsheet 1.png';
import { DownloadOutlined, InfoCircleFilled } from '@ant-design/icons';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import ImageLabel from '../../atoms/ImageLabel/ImageLabel';
import Spinner from '../../atoms/Spinner/Spinner';

const { getPaymentMethods, getResaleMethods, getBrands, getMeasures } = apiCall();

const { downloadExpenseReport, getYears, getMonths } = apiCall();

const RenditionsForm = ({ children, survey, submitText, handleSubmit, disabledButtons }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [expenseReport, setExpenseReport] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [expenseReportList, setExpenseReportList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [selectValues, setSelectValues] = useState({});
  const [months, setMonths] = useState([]);
  const [monthsLoading, setMonthsLoading] = useState(false);
  const [years, setYears] = useState([]);
  const [yearsLoading, setYearsLoading] = useState(false);
  const [month, setMonth] = useState(undefined);
  const [year, setYear] = useState(undefined);
  const { redirect, setUrlToRedirect } = useRedirect();
  const { isViewer } = UserHelpers();
  const [loading, setLoading] = useState(false);

  const initialState = async () => {
    try {
      const response = await Promise.all([
        getPaymentMethods(),
        getResaleMethods(),
        getBrands(),
        getMeasures()
      ]);
      const [paymentMethods, resaleMethods, brands, measures] = response;
      setSelectValues({
        paymentMethods,
        resaleMethods,
        brands,
        measures
      });
    } catch (error) {}
  };

  const downloadFile = async () => {
    try {
      setLoading(true);
      const response = await downloadExpenseReport();
      const filename = extractContentDispositionFilename(response, 'rendiciones.xlsx');
      setLoading(false);
      downloadPdf(response.data, filename);
    } catch (error) {
      setLoading(false);
      processedErrorMessage(error);
    }
  };

  const _getYears = async () => {
    try {
      setYearsLoading(true);
      setYears(await getYears({ next: 0, last: 2 }));
      setYearsLoading(false);
    } catch (error) {
      setYearsLoading(false);
      processedErrorMessage(error);
    }
  };

  const _getMonth = async () => {
    try {
      setMonthsLoading(true);
      setMonths(await getMonths());
      setMonthsLoading(false);
    } catch (error) {
      setMonthsLoading(false);
      processedErrorMessage(error);
    }
  };

  useEffect(() => {
    initialState();
    _getYears();
    _getMonth();
  }, []);

  useEffect(() => {
    if (survey && !isEmpty(survey)) {
      const {
        brand,
        counterPaymentMethod,
        measure,
        resalePaymentMethod,
        surveyDate,
        ...rest
      } = survey;
      form.setFieldsValue({
        idMeasure: measure.id,
        idBrand: brand.id,
        idCounterPaymentMethod: counterPaymentMethod ? counterPaymentMethod.id : undefined,
        idResalePaymentMethod: resalePaymentMethod ? resalePaymentMethod.id : undefined,
        surveyDate: moment(surveyDate),
        ...rest
      });
    }
  }, [survey]);

  const updateFileData = (set, data, setObject) => {
    form.setFieldsValue(set);
    if (data) {
      setObject([
        {
          uid: '1',
          name: data.name ? data.name : 'rendiciones'
        }
      ]);
    } else {
      setObject([]);
    }
  };

  useEffect(() => {
    updateFileData({ expenseReport }, expenseReport, setExpenseReportList);
  }, [expenseReport]);

  useEffect(() => {
    updateFileData({ invoice }, invoice, setInvoiceList);
  }, [invoice]);

  const availableMonths = index => {
    const date = new Date();
    return year >= date.getFullYear() ? date.getMonth() < index : false;
  };

  const monthOptions = () =>
    months.map((month, index) => (
      <Option disabled={availableMonths(index)} key={month.id} value={month.id}>
        {month.name}
      </Option>
    ));

  useEffect(() => {
    if (year >= new Date().getFullYear()) {
      form.setFieldsValue({ month: undefined });
    }
  }, [year]);

  return (
    <Spinner loading={loading}>
      <Form form={form} onFinish={handleSubmit}>
        {redirect()}
        <div className="page-content renditions-form">
          <p className="required-fields">* Campos requeridos</p>
          <div className="inputs-survey-container">
            <div className="text-area-survey">
              <div className="download-container inputs_container">
                <div className="message">
                  <img src={spreadsheet} alt="" />
                  <div className="message-text">
                    <h3>Modelo de Rendiciones</h3>
                    <h4>Si aún no tenes tu template de rendiciones, podés descargarlo desde acá</h4>
                  </div>
                </div>
                <div className="btn_crear_form">
                  <ButtonPrimary
                    onClick={() => downloadFile()}
                    iconLeft={<DownloadOutlined />}
                    text="DESCARGAR"
                  />
                </div>
              </div>
            </div>
            <div className="inputs-survey">
              <div className="_input">
                <label className="input-label">
                  Alias <span>*</span>
                </label>
                <Form.Item rules={[REQUIRED_FIELD_RULE]} name="alias" className="input-box">
                  <Input maxLength={50} className="input" placeholder="Alias" />
                </Form.Item>
              </div>
              <div className="_input">
                <label className="input-label">
                  Mes de rendimiento <span>*</span>
                </label>
                <Form.Item rules={[REQUIRED_FIELD_RULE]} name={'month'} className="input-box">
                  <Select
                    placeholder="Mes de rendimiento"
                    loading={monthsLoading}
                    onChange={value => setMonth(value)}
                  >
                    {monthOptions()}
                  </Select>
                </Form.Item>
              </div>
              <div className="_input">
                <label className="input-label">
                  Año rendimiento <span>*</span>
                </label>
                <Form.Item rules={[REQUIRED_FIELD_RULE]} name="year" className="input-box">
                  <Select
                    placeholder="Mes de rendimiento"
                    loading={yearsLoading}
                    onChange={value => setYear(value)}
                  >
                    {years.map(year => (
                      <Option key={year} value={year}>
                        {year}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="inputs-survey">
              <div className="_input">
                <Form.Item rules={[REQUIRED_FIELD_RULE]} name="expenseReport" className="input-box">
                  <ImageLabel
                    label={
                      <label className="text-area-label">
                        Subir rendiciones <span>*</span>
                      </label>
                    }
                    setFile={setExpenseReport}
                    formats={['.xlsx']}
                    description="El formato aceptado es .xlsx"
                    fileList={expenseReportList}
                    beforeUpload={file => {
                      const isXlsx =
                        file.type ===
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                      const isLt5M = file.size / 1024 / 1024 < 5;

                      if (!isLt5M)
                        message.error('El documento cargado no respeta el limite de 5MB');

                      if (!isXlsx)
                        message.error('El archivo cargado no respeta las condiciones de formato');
                      return !isXlsx || !isLt5M;
                    }}
                  />
                </Form.Item>
              </div>
              <div className="_input">
                <Form.Item rules={[REQUIRED_FIELD_RULE]} name="invoice" className="input-box">
                  <ImageLabel
                    label={
                      <label className="text-area-label">
                        Subir comprobantes <span>*</span>
                      </label>
                    }
                    setFile={setInvoice}
                    fileList={invoiceList}
                    beforeUpload={() => false}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          {children}
        </div>
        <div className="buttons-container">
          <ButtonSecondary
            text="CANCELAR"
            onClick={() => setUrlToRedirect(RENDITIONS)}
            disabled={disabledButtons}
            theme={disabledButtons ? 'disabled' : 'btn-cancel'}
          />
          <ButtonPrimary
            disabled={isViewer() || disabledButtons}
            theme={isViewer() || disabledButtons ? 'disabled' : 'btn-save'}
            text={submitText}
            type="submit"
          />
        </div>
      </Form>
    </Spinner>
  );
};

RenditionsForm.propTypes = {
  survey: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  disabledButtons: PropTypes.bool,
  submitText: PropTypes.string
};

RenditionsForm.defaultProps = {
  survey: {},
  submitText: 'GUARDAR'
};

export default RenditionsForm;
