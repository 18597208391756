import React, { useState, useEffect } from 'react';
import './_style.scss';
import { REFUND } from '../../../utils/constants';
import { message } from 'antd';
import get from 'lodash';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCall from '../../../services/api-calls/all';

import TitlePage from '../../atoms/TitlePage/TitlePage';
import History from '../../atoms/Breadcrumb/history';
import RefundsForm from '../../organism/RefundsForm/RefundsForm';
import useRedirect from '../../Router/redirect';

const breadcrumbs = [
  {
    title: 'Reembolso',
    href: REFUND
  },
  {
    title: 'Crear nuevo reembolso',
    href: ''
  }
];

const { getPreApprovalById, createRefund } = apiCall();

const RefundsCreate = ({ match }) => {
  const { params } = match;
  const [preapproval, setPreapproval] = useState({});
  const { redirect, setUrlToRedirect } = useRedirect();
  const [ disabledButton, setDisabledButton ] = useState();

  const _getPreApprovalById = async () => {
    try {
      const response = await getPreApprovalById(params.id);
      setPreapproval(response);
    } catch (error) {
      const { data } = error.response;
      const errorMessage = processedErrorMessage(error, data);
      message.error(errorMessage);
    }
  };

  const handleCreate = async values => {
    try {
      setDisabledButton(true);
      const { invoice, receipt, ...rest } = values;
      const formData = new FormData();
      formData.set('invoice', invoice);
      formData.set('receipt', receipt);
      formData.set(
        'refundProperties',
        JSON.stringify(rest)
      );

      await createRefund(formData);
      message.success('Reembolso creado con éxito!');
      setUrlToRedirect(REFUND);
    } catch (error) {
      const m = get(error, 'response.data');
      message.error(processedErrorMessage(error, m));
    }
    finally {
      setDisabledButton(false);
    }
  };

  useEffect(() => {
    _getPreApprovalById();
  }, [])

  return (
    <>
      {redirect()}
      <div className="title">
        <History breadcrumbs={breadcrumbs} />
        <TitlePage text="CREAR REEMBOLSO" />
      </div>
      <RefundsForm handleSubmit={handleCreate} preapproval={preapproval}  disabledButtons={disabledButton} />
    </>
  );
};

export default RefundsCreate;
