import { Button, Col, Row } from 'antd';
import React, { useContext } from 'react';
import { CloseOutlined, CloseCircleFilled } from '@ant-design/icons';
import './style.scss';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';

const Notification = ({ icon, title, description, date, onRead }) => (
  <Row className="notification-container">
    <Col className="icon-container" span={2}>
      {icon}
    </Col>
    <Col span={18}>
      <span>{title}</span>
      <p>{description}</p>
      <p>{date}</p>
    </Col>
    <Col span={2}>
      <button
        type="button"
        className="closeButton"
        onClick={onRead}
      >
        <CloseOutlined />
      </button>
    </Col>
  </Row>
);

export default Notification;
