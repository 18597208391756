import React from 'react';
import './_style.scss';
import ButtonPrimary from '../ButtonPrimary/button-primary';

const ButtonLogin = ({ text, theme, onClick, iconLeft, iconRight, disabled, type, htmlType }) => (
  <div className="button-login-container">
    <ButtonPrimary
      type={type}
      htmltype={htmlType}
      onClick={onClick}
      disabled={disabled}
      text="INGRESAR"
    />
  </div>
);
/* eslint-enable react/button-has-type */
export default ButtonLogin;
