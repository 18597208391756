import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Space, Table } from 'antd';
import ButtonPrimary from '../../../components/atoms/ButtonPrimary/button-primary';
import { InfoCircleFilled } from '@ant-design/icons';
import { REQUIRED_FIELD_RULE } from '../../../utils/validation-rules';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import ImageLabel from '../../atoms/ImageLabel/ImageLabel';
import Spinner from '../../atoms/Spinner/Spinner';
import { UserHelpers } from '../../../utils/user-helpers';
import { PERMISSION_ADMIN_EXPENSE_IMPORT } from '../../../utils/constants';

const SkuImportForm = forwardRef(
  ({ handleSubmit, handleCancel, errorRows, loading }, reference) => {
    const [skusfile, setSkusfile] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [form] = Form.useForm();
    const { hasPermissionFor } = UserHelpers();

    useImperativeHandle(reference, () => ({
      clearForm() {
        setSkusfile(null);
        setFileList([]);
        form.setFieldsValue({
          skusfile: undefined
        });
      }
    }));

    const updateFileData = (set, data, setObject) => {
      form.setFieldsValue(set);
      if (data) {
        setObject([
          {
            uid: '1',
            name: data.name ? data.name : 'skusfile'
          }
        ]);
      } else {
        setObject([]);
      }
    };

    useEffect(() => {
      updateFileData({ skusfile }, skusfile, setFileList);
    }, [skusfile]);

    const columns = [
      {
        title: 'Fila',
        dataIndex: 'rowNumber',
        key: 'rowNumber'
      },
      {
        title: 'Descripcion del error',
        dataIndex: 'error',
        key: 'error'
      }
    ];

    return (
      <Spinner loading={loading}>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item rules={[REQUIRED_FIELD_RULE]} name="skusfile">
            <ImageLabel
              label={
                <label className="text-area-label">
                  Subir maestro de productos <span>*</span>
                </label>
              }
              setFile={setSkusfile}
              formats={['.xlsx']}
              description="El formato aceptado es .xlsx"
              fileList={fileList}
            />
          </Form.Item>
          <div className="table-error-container">
            {errorRows.length > 0 && (
              <Table
                pagination={false}
                columns={columns}
                dataSource={errorRows}
                bordered
                className="table-errors"
                title={() => (
                  <>
                    <InfoCircleFilled />
                    <span>
                      Por favor, corregí los errores que se muestran a continuación y volvé a subir
                      el archivo.
                    </span>
                  </>
                )}
              />
            )}
          </div>
          <Space>
            <ButtonPrimary
              disabled={!hasPermissionFor(PERMISSION_ADMIN_EXPENSE_IMPORT)}
              text="GUARDAR"
              theme={
                hasPermissionFor(PERMISSION_ADMIN_EXPENSE_IMPORT)
                  ? 'btn-guardar'
                  : 'disabled btn-guardar'
              }
              type="submit"
            />
            <ButtonSecondary text="CANCELAR" theme="btn-cancel" onClick={handleCancel} />
          </Space>
        </Form>
      </Spinner>
    );
  }
);

SkuImportForm.propTypes = {
  expense: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorRows: PropTypes.array,
  loading: PropTypes.bool
};

export default SkuImportForm;
