import React from 'react';
import { Popconfirm, Space, Tooltip } from 'antd';
import ButtonPrimary from '../../components/atoms/ButtonPrimary/button-primary';
import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined
} from '@ant-design/icons';
import { EXPENSE_APPROVED, EXPENSE_DECLINED, EXPENSE_DRAFT, EXPENSE_PENDING } from '../constants';

export const columns = (handleSendExpense, handleDeleteExpense, isViewer, prepareDownload) => [
  {
    title: 'RAZÓN SOCIAL',
    dataIndex: 'registeredName',
    key: 'nombre',
    width: 200
  },
  {
    title: 'AÑO',
    dataIndex: 'year',
    key: 'año',
    width: 100
  },
  {
    title: 'MES',
    dataIndex: 'month',
    key: 'mes',
    width: 150,
    render: ({ name }) => <p className="table-month">{name}</p>
  },
  {
    title: 'ARCHIVOS',
    key: 'archivos',
    dataIndex: 'expenseReport',
    width: 150,
    render: ({ id, fileName }) => (
      <ButtonPrimary
        theme="files-btn"
        text="Descargar"
        iconLeft={<DownloadOutlined />}
        onClick={() => prepareDownload(id, fileName)}
      />
    )
  },
  {
    title: 'COMPROBANTES',
    key: 'comprobantes',
    dataIndex: 'invoice',
    width: 150,
    render: ({ id, fileName }) => (
      <ButtonPrimary
        theme="files-btn"
        text="Descargar"
        iconLeft={<DownloadOutlined />}
        onClick={() => prepareDownload(id, fileName)}
      />
    )
  },
  {
    title: 'ESTADO',
    dataIndex: 'state',
    key: 'estado',
    width: 150,
    render: ({ description }) => {
      if (description === EXPENSE_PENDING)
        return (
          <Tooltip title="Pendiente">
            <ClockCircleFilled style={{ color: '#FED102' }} />
          </Tooltip>
        );

      if (description === EXPENSE_APPROVED)
        return (
          <Tooltip title="Confirmado">
            <CheckCircleFilled style={{ color: '#5BC535' }} />
          </Tooltip>
        );

      if (description === EXPENSE_DECLINED)
        return (
          <Tooltip title="Rechazado">
            <CloseCircleFilled style={{ color: '#FF2E2E' }} />
          </Tooltip>
        );

      if (description === EXPENSE_DRAFT)
        return (
          <Tooltip title="Pendiente">
            <ClockCircleFilled style={{ color: '#FED102' }} />
          </Tooltip>
        );
    }
  },
  {
    title: 'USUARIO ',
    dataIndex: 'user',
    key: 'usuario',
    width: 250
  },
  {
    title: 'ALIAS',
    dataIndex: 'alias',
    key: 'alias',
    width: 150
  },

  {
    title: 'ACCIONES',
    key: 'acciones',
    width: 200,
    render: ({ id, state }) => (
      <Space>
        <ButtonPrimary
          disabled={isViewer() || state.description !== EXPENSE_PENDING}
          theme={isViewer() || state.description !== EXPENSE_PENDING ? 'disabled' : 'edit_btn'}
          iconLeft={<EditOutlined />}
          onClick={() => handleSendExpense(id)}
        />
        <Popconfirm
          title="¿Desea eliminar la rendición seleccionada?"
          onConfirm={() => handleDeleteExpense(id)}
        >
          <ButtonPrimary
            disabled={isViewer()}
            theme={isViewer() ? 'disabled' : 'eliminar_btn'}
            iconLeft={<DeleteOutlined />}
          />
        </Popconfirm>
      </Space>
    )
  }
];
