export const firstName = { name: 'name', placeholder: 'First Name', label: 'First Name' };
export const lastName = { name: 'lastName', placeholder: 'Last Name', label: 'Last Name' };
export const username = { name: 'username', placeholder: 'Usuario', label: 'Usuario' };
export const phone = { name: 'phone', placeholder: 'Phone', label: 'Phone' };
export const password = { name: 'password', placeholder: 'Contraseña', label: 'Contraseña' };
export const confirmPassword = {
  name: 'confirmPassword',
  placeholder: 'Confirm password',
  label: 'Confirm password'
};
export const newPassword = {
  name: 'newPassword',
  placeholder: 'New password',
  label: 'New password'
};
export const confirmNewPassword = {
  name: 'confirmNewPassword',
  placeholder: 'Confirm new password',
  label: 'Confirm new password'
};
export const role = { name: 'role' };
export const type = { name: 'type' };
