import React from 'react';
import './style.scss';
import { Breadcrumb } from 'antd';
import PropTypes from 'prop-types';


const History = ({ breadcrumbs}) => (
  <Breadcrumb className="breadcrumb-container">
    {breadcrumbs.map((breadcrumb, index) => (
      <Breadcrumb.Item key={index}>
        <a href={breadcrumb.href}>{breadcrumb.title}</a>
      </Breadcrumb.Item>
    ))}
  </Breadcrumb>
);

History.propTypes = {
  breadcrumbs: PropTypes.array.isRequired
};

export default History;
