import React from 'react';
import { Card } from 'antd';
import './style.scss';
import { divide } from 'lodash';

const { Meta } = Card;
const MainCard = ({ title, fecha, img, description }) => (
  <div className="main-card-container">
    <div
      className="main-card-img-container"
      style={{
        background: `url(${img}) no-repeat center center`,
        backgroundSize: 'cover',
        width: '32%',
        borderRadius: '4px 0 0 4px'
      }}
    />
    <div className="main-card-text-content">
      <p className="label-date">{fecha}</p>
      <h3 className="card-title">{title}</h3>
      <p className="card-description">{description}</p>
    </div>
  </div>
);

export default MainCard;
