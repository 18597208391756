import React, { useState } from 'react';
import './_style.scss';
import TitlePage from '../../atoms/TitlePage/TitlePage';
import History from '../../atoms/Breadcrumb/history';
import { message } from 'antd';
import SurveyProductForm from '../../organism/SurveyProductForm/SurveyProductForm';
import { SURVEYS } from '../../../utils/constants';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCall from '../../../services/api-calls/all';
import { useRedirect } from '../../Router/redirect';
import Spinner from '../../atoms/Spinner/Spinner';

const { saveSurveyProduct } = apiCall();

const breadcrumbs = [
  {
    title: 'Relevamientos',
    href: SURVEYS
  },
  {
    title: 'Crear Relevamiento',
    href: '#'
  }
];

const SurveyProductCreate = () => {
  const { redirect, setUrlToRedirect } = useRedirect();
  const [saving, setSaving] = useState(false);

  const handleCreate = async values => {
    try {
      setSaving(true);
      await saveSurveyProduct(values);
      setSaving(false);
      message.success('¡Tu relevamiento ha sido creado con éxito!');
      setUrlToRedirect(SURVEYS);
    } catch (error) {
      const { data } = error.response;
      const errorMessage = processedErrorMessage(error, data);
      setSaving(false);
      message.error(errorMessage);
    }
  };

  return (
    <Spinner loading={saving} text="Guardando...">
      <div className="create-survey-container">
        {redirect()}
        <History breadcrumbs={breadcrumbs} />
        <div className="title">
          <TitlePage text="CREAR NUEVO RELEVAMIENTO" />
        </div>
        <SurveyProductForm handleSubmit={handleCreate} survey={null} />
      </div>
    </Spinner>
  );
};

export default SurveyProductCreate;
