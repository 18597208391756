import React from 'react';
import './style.scss';
import TitlePage from '../../atoms/TitlePage/TitlePage';
import ExpenseList from '../../organism/ExpenseList/ExpenseList';

const RenditionsClient = () => (
  <div className="main-renditions-container">
    <TitlePage text="RENDICIONES" />
    <ExpenseList />
  </div>
);

export default RenditionsClient;
