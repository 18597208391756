import React, { useState, useEffect } from 'react';
import './_style.scss';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import History from '../../../atoms/Breadcrumb/history';
import ClientForm from '../../../organism/ClientForm/ClientForm';
import apiCall from '../../../../services/api-calls/all';
import { message } from 'antd';
import { processedErrorMessage } from '../../../../services/api-calls/helpers';
import { ADMIN_CLIENTS_URL } from '../../../../utils/constants';
import { isEmpty } from "../../../../utils/func-helpers";

const { showClient } = apiCall();

const ClientEdit = ({ match }) => {
  const { params } = match;
  const [client, setClient] = useState({});

  const handleShowClient = async () => {
    try {
      const response = await showClient(params.id);
      setClient(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const breadcrumbs = [
    {
      title: 'Parametría Clientes',
      href: ADMIN_CLIENTS_URL,
    },
    {
      title: 'Editar Cliente',
      href: '',
    }
  ];

  useEffect(() => {
    handleShowClient();
  }, []);

  return (
    <div className="client-edit-container">
      <History breadcrumbs={breadcrumbs} />
      <TitlePage text="EDITAR CLIENTE" />
      {!isEmpty(client) && <ClientForm client={client} /> }
    </div>
  );
};

export default ClientEdit;
