import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, message, Select, Space } from 'antd';
import apiCalls from '../../../services/api-calls/all';
import { EXPENSE_PENDING } from '../../../utils/constants';
import { UserHelpers } from '../../../utils/user-helpers';
import TextArea from 'antd/es/input/TextArea';
import { REQUIRED_FIELD_RULE } from '../../../utils/validation-rules';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import ImageLabel from '../../atoms/ImageLabel/ImageLabel';
import { PERMISSION_ADMIN_EXPENSE_IMPORT } from '../../../utils/constants';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';

const { getStatesExpenseReportAdmin } = apiCalls();
const { Search } = Input;

const ExpenseImportTemplateForm = forwardRef(({ handleSubmit, handleCancel }, reference) => {
  const [states, setStates] = useState([]);
  const [template, setTemplate] = useState(null);
  const [templateList, setTemplateList] = useState([]);
  const { isViewer } = UserHelpers();
  const { Option } = Select;
  const [form] = Form.useForm();
  const { hasPermissionFor } = UserHelpers();

  useImperativeHandle(reference, () => ({
    clearForm() {
      setTemplate(null);
      setTemplateList([]);
      form.setFieldsValue({
        template: undefined
      });
    }
  }));

  const _getStates = async () => {
    try {
      const response = await getStatesExpenseReportAdmin();
      setStates(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  useEffect(() => {
    _getStates();
  }, []);

  const updateFileData = (set, data, setObject) => {
    form.setFieldsValue(set);
    if (data) {
      setObject([
        {
          uid: '1',
          name: data.name ? data.name : 'template'
        }
      ]);
    } else {
      setObject([]);
    }
  };

  useEffect(() => {
    updateFileData({ template }, template, setTemplateList);
  }, [template]);

  return (
    <>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name="template">
          <ImageLabel
            label={
              <label className="text-area-label">
                Subir template <span>*</span>
              </label>
            }
            setFile={setTemplate}
            formats={['.xlsx']}
            description="El formato aceptado es .xlsx"
            fileList={templateList}
            beforeUpload={() => false}
          />
        </Form.Item>
        <Space>
          <ButtonPrimary
            disabled={!hasPermissionFor(PERMISSION_ADMIN_EXPENSE_IMPORT)}
            text="GUARDAR"
            theme={
              hasPermissionFor(PERMISSION_ADMIN_EXPENSE_IMPORT)
                ? 'btn-guardar'
                : 'disabled btn-guardar'
            }
            type="submit"
          />
          <ButtonSecondary text="CANCELAR" theme="btn-cancel" onClick={handleCancel} />
        </Space>
      </Form>
    </>
  );
});

ExpenseImportTemplateForm.propTypes = {
  expense: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string
};

ExpenseImportTemplateForm.defaultProps = {
  submitText: 'GUARDAR'
};

export default ExpenseImportTemplateForm;
