import React, { useState, useEffect } from 'react';
import './style.scss';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import { Modal, Space, Table, message, Form } from 'antd';
import { DownloadOutlined, InfoCircleFilled } from '@ant-design/icons';
import ImageLabel from '../../atoms/ImageLabel/ImageLabel';
import apiCall from '../../../services/api-calls/all';
import { downloadPdf } from '../../../utils/func-helpers';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import Spinner from '../../atoms/Spinner/Spinner';
import { REQUIRED_FIELD_RULE } from '../../../utils/validation-rules';
import { get } from 'lodash';
import { UserHelpers } from '../../../utils/user-helpers';

const { downloadProductsTemplate, importProducts } = apiCall();

const ModalImportProducts = ({ getProducts }) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorRows, setErrorRows] = useState([]);
  const [products, setProducts] = useState(null);
  const [productsList, setProductsList] = useState([]);
  const { isViewer } = UserHelpers();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleImport = async values => {
    try {
      setLoading(true);
      const { products } = values;
      const formData = new FormData();
      formData.set('products', products);

      const response = await importProducts(formData);
      const {
        processExcelFileResult: { totalValidRows }
      } = response.data;
      message.success(`Acción realizada con éxito. Se procesaron ${totalValidRows} filas.`);
      setIsModalVisible(false);
      setErrorRows([]);
      setProductsList([]);
      setProducts(null);
    } catch (error) {
      const status = get(error, 'response.status');
      if (status === 412) {
        const response = get(error, 'response.data');
        const {
          processExcelFileResult: { errorRows }
        } = response;
        setErrorRows(errorRows);
      } else {
        const errorMessage = processedErrorMessage(error);
        message.error(errorMessage);
      }
    } finally {
      setLoading(false);
      getProducts(0);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setErrorRows([]);
    setProductsList([]);
    setProducts(null);
  };

  const columns = [
    {
      title: 'Fila',
      dataIndex: 'rowNumber',
      key: 'rowNumber'
    },
    {
      title: 'Descripcion del error',
      dataIndex: 'error',
      key: 'error'
    }
  ];

  const downloadFile = async () => {
    try {
      setLoading(true);
      const response = await downloadProductsTemplate();
      setLoading(false);
      downloadPdf(response.data, 'templateCatalogo.xlsx');
    } catch (error) {
      setLoading(false);
      processedErrorMessage(error);
    }
  };

  const updateFileData = (set, data, setObject) => {
    form.setFieldsValue(set);
    if (data) {
      setObject([
        {
          uid: '1',
          name: data ? (data.name ? data.name : data) : 'products'
        }
      ]);
    } else {
      setObject([]);
    }
  };

  useEffect(() => {
    updateFileData({ products }, products, setProductsList);
  }, [products]);

  return (
    <>
      {!isViewer() ? <ButtonPrimary text="IMPORTAR PRODUCTOS" theme="btn-blue" onClick={showModal} /> : undefined}
      <Modal
        title="IMPORTAR PRODUCTOS"
        visible={isModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={null}
      >
        <Spinner loading={loading}>
          <Form form={form} onFinish={handleImport}>
            <div className="modal-import-products-container">
              <div className="template-download-container">
                <div className="template-download-text">
                  <h2>Modelo de Prueba</h2>
                  <p>Podés descargar el Modelo de Importación de Productos desde acá</p>
                </div>
                <div className="template-download-btn">
                  <ButtonPrimary
                    iconLeft={<DownloadOutlined />}
                    text="DESCARGAR"
                    onClick={() => downloadFile()}
                  />
                </div>
              </div>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="products">
                <ImageLabel
                  fileList={productsList}
                  setFile={setProducts}
                  formats={['.xlsx']}
                  label={
                    <label className="input-label">
                      Subir Archivo<span>*</span>
                    </label>
                  }
                  beforeUpload={() => false}
                  description="El formato aceptado es .xlsx"
                />
              </Form.Item>
              <div className="table-error-container">
                {errorRows.length > 0 && (
                  <Table
                    pagination={false}
                    columns={columns}
                    dataSource={errorRows}
                    bordered
                    className="table-errors"
                    title={() => (
                      <>
                        <InfoCircleFilled />
                        <span>
                          Por favor, corregí los errores que se muestran a continuación y volvé a
                          subir el archivo.
                        </span>
                      </>
                    )}
                  />
                )}
              </div>
            </div>
            <Space>
              <ButtonPrimary text="GUARDAR" type="submit" />
              <ButtonSecondary text="CANCELAR" onClick={handleCancel} />
            </Space>
          </Form>
        </Spinner>
      </Modal>
    </>
  );
};

export default ModalImportProducts;
