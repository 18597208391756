import React from 'react';
import './style.scss';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import ProductivityList from '../../../organism/ProductivityList/ProductivityList';

const Productivity = () => (
  <div className="productivity-container">
    <TitlePage text="PRODUCTIVIDAD" />
    <ProductivityList />
  </div>
);
export default Productivity;
