import React from 'react';
import './_style.scss';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import History from '../../../atoms/Breadcrumb/history';
import ClientForm from '../../../organism/ClientForm/ClientForm';
import { ADMIN_CLIENTS_URL } from "../../../../utils/constants";

const ClientCreate = () => {

  const breadcrumbs = [
    {
      title: 'Parametría Clientes',
      href: ADMIN_CLIENTS_URL,
    },
    {
      title: 'Crear Cliente',
      href: '',
    }
  ];

  return (
    <div className="create-client-container">
      <History breadcrumbs={breadcrumbs} />
      <div className="title">
        <TitlePage text="CREAR CLIENTE" />
      </div>
      <ClientForm />
    </div>
  );
}

export default ClientCreate;
