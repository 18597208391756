import React, { useState, useEffect, useRef } from 'react';
import './_style.scss';
import PropTypes from 'prop-types';
import starWhite from '../../../img/startwhite.svg';
import price from '../../../img/price.svg';
import { Form, message, Table } from 'antd';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import PopUpOrder from '../../molecules/PopUp/popUp';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import { useRedirect } from '../../Router/redirect';
import { currencyFormat } from '../../../utils/func-helpers';
import { columns } from '../../../utils/tables/table-order-admin';
import UserHelpers from '../../../utils/user-helpers';
import apiCalls from '../../../services/api-calls/all';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import {ADMIN_ORDER_LIST, PAYMENT_METHOD_POINTS} from "../../../utils/constants";
const { updateQuantityProduct, confirmProduct, rejectOrder } = apiCalls();

const OrderForm = ({ order, handleSubmit, declineOrder, submitText }) => {
  const { canUpdate, canView, isViewer } = UserHelpers();
  const { redirect, setUrlToRedirect } = useRedirect();
  const [validQuantity, setValidQuantity] = useState({});
  const [formValues, setFormValues] = useState({});
  const [productsConfirmed, setProductsConfirmed] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [form] = Form.useForm();
  const modal = useRef();

  const getIdProductFromValidQuantity = () =>
    Object.keys(validQuantity)
      .filter(key => key.includes('Price'))
      .map(key => key.replace('productPrice', ''));

  const _confirmProduct = async id => {
    try {
      const parameters = {
        quantity: validQuantity[`productQty${id}`]
      };
      await updateQuantityProduct(order.id, id, parameters);
      await confirmProduct(order.id, id);
      const products = productsConfirmed;
      products.push(id);
      setProductsConfirmed([...new Set(products)]);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const handleProductTextButton = id => (productsConfirmed.includes(id) ? 'OK' : 'CONFIRMAR');

  const onChangeQty = async ({ product: { id }, quantity }, value) => {
    setValidQuantity(
      Object.assign({}, validQuantity, {
        [`product${id}`]: value > quantity,
        [`productQty${id}`]: value
      })
    );
  };

  const calculatePrice = () => {
    const total = getIdProductFromValidQuantity().reduce(
      (accumulator, key) =>
        accumulator + validQuantity[`productPrice${key}`] * validQuantity[`productQty${key}`],
      0
    );
    setTotalPrice(total);
  };

  const calculatePoints = () => {
    const total = getIdProductFromValidQuantity().reduce(
      (accumulator, key) =>
        accumulator + validQuantity[`productPoints${key}`] * validQuantity[`productQty${key}`],
      0
    );
    setTotalPoints(total);
  };

  const renderPaymentMethod = () => {
    const { paymentMethod } = order;

    return paymentMethod.code === PAYMENT_METHOD_POINTS ? (
      <div className="data-points-container">
        <p>TOTAL PUNTOS</p>
        <img src={starWhite} alt="" />
        <span>{totalPoints}</span>
      </div>
    ) : (
      <div className="data-price-container">
        <p>TOTAL PESOS</p>
        <img src={price} alt="" />
        <span>{currencyFormat(totalPrice)}</span>
      </div>
    );
  };

  useEffect(() => {
    const qtyNames = order.items.reduce((accumulator, { quantity, product }) => {
      accumulator[`qty${product.id}`] = quantity;
      return accumulator;
    }, {});
    setFormValues(qtyNames);
  }, [order]);

  useEffect(() => {
    form.setFieldsValue(formValues);
  }, [formValues]);

  useEffect(() => {
    const qtyValidation = order.items.reduce((accumulator, { quantity, product }) => {
      accumulator[`product${product.id}`] = false;
      accumulator[`productQty${product.id}`] = quantity;
      accumulator[`productPrice${product.id}`] = product.price;
      accumulator[`productPoints${product.id}`] = product.points;
      return accumulator;
    }, {});
    setValidQuantity(qtyValidation);
    setProductsConfirmed(order.items.map(o => o.id));
  }, [order]);

  useEffect(() => {
    calculatePrice();
    calculatePoints();
  }, [validQuantity]);

  const modalActionButtons = () => (
    <div className="buttons-container">
      <ButtonPrimary
        text="CONFIRMAR"
        onClick={() => {
          modal.current.hiddenModal();
          declineOrder();
        }}
        theme="confirm-btn"
      />
      <ButtonPrimary text="CANCELAR" onClick={() => modal.current.hiddenModal()} theme="cancel" />
    </div>
  );

  return (
    <div className={'order-form-container'}>
      <Form form={form} onFinish={handleSubmit}>
        {redirect()}
        <div className="page-content">
          <div className="resume-container">
            <div className="resume-container-title">RESUMEN</div>
            <div className="order-detail-data-container">{renderPaymentMethod()}</div>
          </div>
          <Table
            className="order-detail-table"
            columns={columns(
              canUpdate() ? _confirmProduct : null,
              isViewer(),
              onChangeQty,
              validQuantity,
              handleProductTextButton,
              order.state.description !== 'Pendiente',
              productsConfirmed
            )}
            dataSource={order.items}
            scroll={{ x: 500 }}
          />
        </div>
        <div className="buttons-edit-order-container">
          <ButtonSecondary
            text="CANCELAR"
            theme="btn-cancel"
            onClick={() => setUrlToRedirect(ADMIN_ORDER_LIST)}
          />
          <ButtonSecondary
            text="RECHAZAR PEDIDO"
            disabled={!canUpdate() || order.state.description !== 'Pendiente'}
            theme={
              canUpdate() && order.state.description === 'Pendiente' ? 'btn-cancel' : 'disabled'
            }
            onClick={() => modal.current.showModal()}
          />
          <ButtonPrimary
            disabled={!canUpdate() || order.state.description !== 'Pendiente'}
            theme={
              canUpdate() && order.state.description === 'Pendiente' ? 'btn-cancel' : 'disabled'
            }
            text={submitText}
            onClick={() => {
              handleSubmit();
            }}
          />
        </div>
      </Form>
      <PopUpOrder title={'Rechazar Pedido'} ref={modal} footer={modalActionButtons()}>
        ¿Esta seguro de Rechazar el pedido?
      </PopUpOrder>
    </div>
  );
};

OrderForm.propTypes = {
  order: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string
};

OrderForm.defaultProps = {
  submitText: 'GUARDAR'
};

export default OrderForm;
