import React from 'react';
import './_style.scss';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import History from '../../../atoms/Breadcrumb/history';
import PromotionForm from '../../../organism/PromotionForm/PromotionForm';
import { ADMIN_PROMOTIONS_URL } from "../../../../utils/constants";

const PromotionsCreate = () => {

  const breadcrumbs = [
    {
      title: 'Parametría Promociones',
      href: ADMIN_PROMOTIONS_URL,
    },
    {
      title: 'Crear Promociones',
      href: '',
    }
  ];

  return (
    <div>
      <History breadcrumbs={breadcrumbs} />
      <div className="title">
        <TitlePage text="CREAR PROMOCIÓN" />
      </div>
      <PromotionForm />
    </div>
  );
}

export default PromotionsCreate;
