import helpers from './helpers';

const getProductsFiltered  = makeGetRequest => data => makeGetRequest('products', data);

const getProductsCatalog  = makeGetRequest => data => makeGetRequest('products/catalog', data);

const showProduct = makeGetRequest => id => makeGetRequest(`products/${id}`);

const createProduct = makePostFileRequest => (data) => makePostFileRequest(`products`, data);

const editProduct = makePutFileRequest => (id, data) => makePutFileRequest(`products/${id}`, data);

const destroyProduct = makeDeleteRequest => (id) => makeDeleteRequest(`products/${id}`);

const getProductsCategories  = makeGetRequest => data => makeGetRequest('products/categories', data);

const enableProduct  = makePutRequest => (id, enable) => makePutRequest(`products/${id}/enable/${enable}`);

const importProducts = makePutFileRequest => (data) => makePutFileRequest('products/import', data);

export default client => {
	const { makeGetRequest, makePostFileRequest, makePutRequest, makePutFileRequest, makeDeleteRequest } = helpers(client);
	return {
		getProductsFiltered: getProductsFiltered(makeGetRequest),
		getProductsCatalog: getProductsCatalog(makeGetRequest),
		showProduct: showProduct(makeGetRequest),
		createProduct: createProduct(makePostFileRequest),
		editProduct: editProduct(makePutFileRequest),
		destroyProduct: destroyProduct(makeDeleteRequest),
		getProductsCategories: getProductsCategories(makeGetRequest),
		enableProduct: enableProduct(makePutRequest),
		importProducts: importProducts(makePutFileRequest)
	};
};
