import React, {useState} from 'react';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import History from '../../../atoms/Breadcrumb/history';
import ModalGuardar from '../../../molecules/ModalSave/modal-save';
import {Menu, Dropdown, Modal, Button, message} from 'antd';
import CatalogueForm from "../../../organism/CatalogueForm/CatalogueForm";
import {useRedirect} from "../../../Router/redirect";
import {ADMIN_CATALOGUE_LIST_URL, ADMIN_PRICE_LIST_URL, ADMIN_USER_LIST_URL} from "../../../../utils/constants";
import {processedErrorMessage} from "../../../../services/api-calls/helpers";
import apiCall from "../../../../services/api-calls/all";
import Spinner from '../../../atoms/Spinner/Spinner';


const breadcrumbs = [
	{
		title: 'Parametría Catalogo',
		href: ADMIN_CATALOGUE_LIST_URL
	},
	{
		title: 'Crear Producto',
		href: ''
	}
];
const { createProduct } = apiCall();

const CatalogueCreate = () => {
	const [loading, setLoading] = useState(false);
	const { redirect, setUrlToRedirect } = useRedirect();

	const handleCreate = async ({image, ...rest}) => {
		if (loading) return;
		setLoading(true);
		try {
			const formData = new FormData();
			formData.set('productProperties', JSON.stringify(rest))
			if (image && image.name) {
				formData.set('image', image);
			} else {
				formData.set('image', null);
			}

			await createProduct(formData);
			message.success('Acción realizada con éxito');
			setLoading(false);
			setUrlToRedirect(ADMIN_CATALOGUE_LIST_URL);
		} catch (error) {
			const {data} = error.response
			const errorMessage = processedErrorMessage(error, data);
			message.error(errorMessage);
			setLoading(false);
		}
	};

	return (
		<Spinner loading={loading}>
			{redirect()}
			<History breadcrumbs={breadcrumbs} />
			<div className="title">
				<TitlePage text="CREAR PRODUCTO" />
			</div>
			<CatalogueForm handleSubmit={handleCreate}/>
		</Spinner>
	)
};

export default CatalogueCreate;
