import React, { useState, useEffect } from 'react';
import './style.scss';
import TitlePage from '../../atoms/TitlePage/TitlePage';
import { Divider, message } from 'antd';
import PersonalData from '../../molecules/PersonalDataProfile/personal-data';
import OrderTable from '../../organism/OrdersTable/order-table';
import apiCalls from '../../../services/api-calls/all';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import { UserHelpers } from '../../../utils/user-helpers';

const { getProfile, getOrderProfile } = apiCalls();

const Profile = () => {
  const [profile, setProfile] = useState({});
  const [company, setCompany] = useState({});
  const { isViewer } = UserHelpers();
  const _getProfile = async () => {
    try {
      const response = await getProfile();
      setProfile(response);
      setCompany(response.company);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const ordersFiltered = async (page, pageSize, onResult) => {
    try {
      const filter = {
        page,
        size: pageSize,
        currentUser: {}
      };
      const response = await getOrderProfile(filter);
      onResult(response);
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  useEffect(() => {
    _getProfile();
  }, []);

  return (
    <div className="main-profile-container">
      <TitlePage text="PERFIL" />
      <div className="page-content">
        <div className="panel-data-container">
          <PersonalData
            name={`${profile.name} ${profile.lastname}`}
            mail={profile.email}
            points={profile.points}
          />
        </div>
        <div className="tables-container">
          <div className="table-responsable">
            <div className="title-container">
              <span>RESPONSABLE</span>
            </div>
            <div className="text-responsable-container">
              <div className="text-company-column">
                <p>RESPONSABLE</p>
                <span>{`${profile.name} ${profile.lastname}`}</span>
              </div>
              <div className="text-company-column">
                <p>TELÉFONO</p>
                <span>{profile.phone}</span>
              </div>
            </div>
          </div>
          <div className="table-company">
            <div className="title-container">
              <span>EMPRESA</span>
            </div>

            <div className="text-company-container">
              <div className="text-company-column">
                <div>
                  <p>RAZÓN SOCIAL</p>
                  <span>{company.registeredName}</span>
                </div>
                <Divider />
                <div>
                  <p>CUIT</p>
                  <span>{company.cuit}</span>
                </div>
                <Divider />
                <div>
                  <p>DIRECCIÓN FISCAL</p>
                  <span>{company.taxAddress}</span>
                </div>
                <Divider />
                <div>
                  <p>LOCALIDAD</p>
                  <span>{company.branchOfficeCity && company.branchOfficeCity.name}</span>
                </div>
              </div>
              <div className="text-company-column">
                <div>
                  <p>NOMBRE COMERCIAL</p>
                  <span>{company.commercialName}</span>
                </div>
                <Divider />
                <div>
                  <p>NOMBRE SUCURSAL</p>
                  <span>{company.branchOfficeName}</span>
                </div>
                <Divider />
                <div>
                  <p>PROVINCIA</p>
                  <span>{company.branchOfficeState && company.branchOfficeState.name}</span>
                </div>
                <Divider />
              </div>
            </div>
          </div>
          {!isViewer() && (
            <div>
              <div className="title-container">
                <span>MIS PEDIDOS</span>
              </div>
              <OrderTable orders={ordersFiltered} filters={[]} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
