import helpers from './helpers';

const getChannels = makeGetRequest => () => makeGetRequest('/channels');

export default client => {
  const { makeGetRequest } = helpers(client);
  return {
    getChannels: getChannels(makeGetRequest)
  };
};
