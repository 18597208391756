import React, { useState, useEffect } from 'react';
import './_style.scss';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import History from '../../../atoms/Breadcrumb/history';
import PriceListForm from '../../../organism/PriceListForm/PriceListForm';
import { ADMIN_PRICE_LIST_URL } from "../../../../utils/constants";
import {message} from "antd";
import {processedErrorMessage} from "../../../../services/api-calls/helpers";
import apiCall from "../../../../services/api-calls/all";
import { isEmpty } from "../../../../utils/func-helpers";

const { showPriceList } = apiCall();

const PriceListCreate = ({ match }) => {
  const { params } = match;
  const [priceList, setPriceList] = useState({});

  const handleShowPriceList = async () => {
    try {
      const response = await showPriceList(params.id);
      setPriceList(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const breadcrumbs = [
    {
      title: 'Parametría Lista de precios',
      href: ADMIN_PRICE_LIST_URL,
    },
    {
      title: 'Editar Lista de precios',
      href: '',
    }
  ];

  useEffect(() => {
    handleShowPriceList();
  }, []);

  return (
    <div>
      <History breadcrumbs={breadcrumbs} />
      <div className="title">
        <TitlePage text="EDITAR LISTA DE PRECIOS" />
      </div>
      {!isEmpty(priceList) ? <PriceListForm priceList={priceList} /> : ''}
    </div>
  );
};

export default PriceListCreate;
