import React from 'react';
import './_style.scss';

import TitlePage from '../../../atoms/TitlePage/TitlePage';
import AdminNewLists from '../../../organism/AdminNewLists/AdminNewLists';

const News = () => (
  <div>
    <TitlePage text="PARAMETRÍA NOVEDADES" />
    <AdminNewLists/>
  </div>
);

export default News;
