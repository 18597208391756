import React from 'react';
import './_style.scss';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import History from '../../../atoms/Breadcrumb/history';
import PriceListForm from '../../../organism/PriceListForm/PriceListForm';
import { ADMIN_PRICE_LIST_URL } from "../../../../utils/constants";

const PriceListCreate = () => {

  const breadcrumbs = [
    {
      title: 'Parametría Lista de precios',
      href: ADMIN_PRICE_LIST_URL,
    },
    {
      title: 'Crear Lista de precios',
      href: '',
    }
  ];

  return (
    <div>
      <History breadcrumbs={breadcrumbs} />
      <div className="title">
        <TitlePage text="CREAR LISTA DE PRECIOS" />
      </div>
      <PriceListForm />
    </div>
  );
};

export default PriceListCreate;
