import React from 'react';
import PropTypes from 'prop-types';
import { Upload, message } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import './_style.scss';

const { Dragger } = Upload;

const ImageLabel = ({
  label,
  description,
  formats,
  setFile,
  fileList,
  beforeUpload,
  download,
  isRequired,
  disabled
}) => {
  const properties = {
    name: 'file',
    accept: formats,
    multiple: false,
    beforeUpload: beforeUpload
      ? beforeUpload
      : fileTemporary => {
          const isLt5M = fileTemporary.size / 1024 / 1024 < 5;
          let validFile = false;
          if (!isLt5M) {
            message.error('El documento cargado no respeta el limite de 5MB');
            validFile = true;
          }

          const extFile = `.${fileTemporary.name.split('.').pop()}`;
          if (!formats.includes(extFile)) {
            message.error('El documento cargado no respeta las condiciones de formato.');
            validFile = true;
          }
          return !isLt5M || !formats.includes(extFile);
        },
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: <CloseCircleFilled />
    }
  };

  const onFileChanged = data => {
    const { status } = data.file;

    if (
      status === 'done' ||
      (status !== 'uploading' && status !== 'error' && status !== 'removed')
    ) {
      setFile(data.file);
    } else if (status === 'error') {
      message.error(`El documento cargado no respeta las condiciones de formato.`);
    } else if (status === 'removed') {
      setFile(null);
    }
  };

  return (
    <>
      <span className="input-label">
        {label} {isRequired ? <span>*</span> : ''}
      </span>
      <div className="button-container">
        {download ? <div className="margin-right">{download}</div> : <div></div>}
        <Dragger {...properties} onChange={onFileChanged} fileList={fileList}>
          <ButtonPrimary
            type="button"
            text="ELEGIR ARCHIVO"
            theme={disabled ? 'btn-elegir-archivo disabled' : 'btn-elegir-archivo'}
            disabled={disabled}
          />
        </Dragger>
      </div>
      {description && (
        <div>
          <p className="load-image-description">{description}</p>
        </div>
      )}
    </>
  );
};

ImageLabel.propTypes = {
  description: PropTypes.string,
  formats: PropTypes.array,
  setFile: PropTypes.func.isRequired,
  fileList: PropTypes.array.isRequired,
  beforeUpload: PropTypes.func,
  isRequired: PropTypes.bool,
  download: PropTypes.element,
  disabled: PropTypes.bool
};

ImageLabel.defaultProps = {
  beforeUpload: () => false,
  isRequired: false,
  download: undefined,
  disabled: false,
  description: undefined,
  formats: []
};

export default ImageLabel;
