import helpers from './helpers';

const getAppVersion = makeGetRequest => () => makeGetRequest('/appversion');

export default client => {
  const { makeGetRequest } = helpers(client);
  return {
    getAppVersion: getAppVersion(makeGetRequest)
  };
};
