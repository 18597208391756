import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import OrderForm from '../OrderForm/OrderForm';
import { columns } from '../../../utils/tables/table-order-list-profile';
import columnsDetails from '../../../utils/tables/table-order-list-profile-details';
import { ADMIN_ORDER_LIST, PAGE_SIZE, PAYMENT_METHOD_POINTS } from '../../../utils/constants';
import PaginatedTable from '../../molecules/PaginatedTable/paginated-table';
import { useRedirect } from '../../Router/redirect';
import PopUpOrder from '../../molecules/PopUp/popUp';
import img from '../../../img/gorra.jpg';
import starWhite from '../../../img/white-star.svg';
import price from '../../../img/price-white.svg';
import { message, Modal, Table } from 'antd';
import apiCalls from '../../../services/api-calls/all';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import { currencyFormat } from '../../../utils/func-helpers';
import { downloadPdf } from '../../../utils/func-helpers';
import Spinner from '../../atoms/Spinner/Spinner';

const { getOrderDetails, exportOrder } = apiCalls();

const OrderTable = ({ orders, filters }) => {
  const modal = useRef();
  const [order, setOrder] = useState({ paymentMethod: {} });
  const [loading, setLoading] = useState(false);

  const handleOnEdit = async id => {
    try {
      setLoading(true);
      const response = await getOrderDetails(id);
      setOrder(response);
      setLoading(false);
      modal.current.showModal();
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    };
  };

  const handleOnDownload = async id => {
    try {
      setLoading(true);
      const fileData = await exportOrder(id);
      setLoading(false);
      downloadPdf(fileData.data, `resumenPedido${id}.pdf`);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const renderPaymentMethod = () => {
    const {
      paymentMethod: { code }
    } = order;
    return code === PAYMENT_METHOD_POINTS ? (
      <div className="data-points-container">
        <p>TOTAL PUNTOS</p>
        <img src={starWhite} alt="" />
        <span>{order.totalPoints}</span>
      </div>
    ) : (
      <div className="data-price-container">
        <p>TOTAL PESOS</p>
        <img src={price} alt="" />
        <span>{currencyFormat(order.totalPrice)}</span>
      </div>
    );
  };

  return (
    <Spinner loading={loading} >
      <PaginatedTable
        className="table-users"
        columns={columns(handleOnEdit, handleOnDownload)}
        onFetch={orders}
        pageSize={PAGE_SIZE}
        filters={filters}
        scroll={{ x: 420, y:300 }}
      />
      <PopUpOrder title={`PEDIDO #${order.id}`} ref={modal} footer={null} width={1000}>
        <div className="resume-container">
          <div>RESUMEN</div>
          <div className="order-detail-data-container">{renderPaymentMethod()}</div>
        </div>

        <Table
          className="order-detail-table"
          columns={columnsDetails.columns(order.paymentMethod.code)}
          dataSource={order.items}
          scroll={{ x: 800 }}
        />
      </PopUpOrder>
    </Spinner>
  );
};

OrderTable.propTypes = {
  orders: PropTypes.array.isRequired
};

export default OrderTable;
