const development = {
  endpoints: {
    backend: 'http://localhost:8080/'
  }
};

const staging = {
  endpoints: {
    backend: 'https://api.staging.goodyear.atixlabs.xyz/'
  }
};

const uat = {
  endpoints: {
    backend: 'https://api.uat.goodyear.atixlabs.xyz/'
  }
};

const production = {
  endpoints: {
    backend: 'https://api.clubgoodyear.com.ar/'
  }
};

const test = {
  endpoints: {
    backend: 'http://1:3011' // FIXME: Set this
  }
};

const configs = {
  development,
  staging,
  uat,
  production,
  test
};

let environment = window._env_ || 'development';

export const setEnvironment = customEnvironment => (environment = customEnvironment);

export const config = configs[environment];
