import React, { useState, useEffect } from 'react';
import './_style.scss';

import Cards from '../../molecules/AdminCards/cards';
import apiCalls from '../../../services/api-calls/all';
import { message } from 'antd';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import AdminMainCard from '../../molecules/AdminMainCard/AdminMainCard';
import Spinner from '../../atoms/Spinner/Spinner';

const { getNews } = apiCalls();

const NewLists = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearchNews = async () => {
    try {
      setLoading(true);
      const response = await getNews();
      setNews(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  useEffect(() => {
    handleSearchNews();
  }, []);

  return (
    <Spinner loading={loading}>
      <div className="novedades-container">
        {news
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map(newItem =>
            newItem.order === 1 ? (
              <AdminMainCard
                key={newItem.id}
                id={newItem.id}
                description={newItem.description}
                fecha={newItem.date}
                title={newItem.title}
                img={newItem.image}
              />
            ) : (
              <Cards
                key={newItem.id}
                id={newItem.id}
                fecha={newItem.date}
                title={newItem.title}
                img={newItem.image}
              />
            )
          )}
      </div>
    </Spinner>
  );
};

export default NewLists;
