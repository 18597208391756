import React, { useContext } from 'react';
import { Layout, Menu } from 'antd';
import './_style.scss';
import { compare } from '../../../utils/func-helpers';
import { useRedirect } from '../../Router/redirect';
import history from '../../Router/history';
import PropTypes from 'prop-types';
import imgCollapse from '../../../img/collapse-icon.png';
import Sider from 'antd/es/layout/Sider';
import { NavbarContext } from '../../../services/providers/navbar-context';

const { SubMenu } = Menu;

const getIcon = type => {
  if (type) {
    const images = require.context('../../../img', true);
    const img = images('./' + `${type}.png`);
    return <img style={{ marginRight: '20px' }} src={img} alt="" />;
  }
};

const GetIconComponent = ({ icon }) => getIcon(icon);

const NavBar = ({ navbarEntries, isSmall }) => {
  const { redirect, setUrlToRedirect } = useRedirect();
  const { collapsed, setCollapsed } = useContext(NavbarContext);

  const itemClick = path => {
    if (path !== null) {
      setUrlToRedirect(path);
    }
  };

  const renderNavItem = (path, name, img) => (
    <Menu.Item key={path}>
      {img}
      <button
        onClick={() => {
          history.push(path);
        }}
      >
        {name}
      </button>
    </Menu.Item>
  );

  const menuItemsRenderer = (items, withTitle = false) =>
    items
      .sort((a, b) => compare(a, b, 'order'))
      .map(item => {
        if (item.items.length > 0) {
          return (
            <SubMenu
              className={isSmall ? 'submenu' : 'small submenu'}
              key={item.key}
              title={
                <span>
                  <GetIconComponent icon={item.icon} />
                  <span>{!collapsed || withTitle ? item.description : ''}</span>
                </span>
              }
            >
              {menuItemsRenderer(item.items, true)}
            </SubMenu>
          );
        }
        return (
          <Menu.Item key={item.key} onClick={() => itemClick(item.url)} title={item.description}>
            {item.icon && <GetIconComponent icon={item.icon} />}
            {!collapsed || withTitle ? item.description : ''}
          </Menu.Item>
        );
      });

  const menuItems = navbarEntries
    .sort((a, b) => compare(a, b, 'order'))
    .map(entry => menuItemsRenderer([entry]));

  return (
    <Layout style={{ height: '100vh', background: '#222c64;' }}>
      <Sider
        trigger={<img src={imgCollapse} alt="" />}
        collapsible
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
      >
        {redirect()}
        <div className="logo" />
        <div className="sidebar">
            <Menu
                selectedKeys={[history.location.pathname]}
                mode="inline"
                theme="dark"
                className={'ulMain'}
            >
                {menuItems}
            </Menu>
        </div>

      </Sider>
    </Layout>
  );
};

NavBar.propTypes = {
  navbarEntries: PropTypes.any
};

export default NavBar;
