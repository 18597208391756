import React, { useState } from 'react';
import './_style.scss';
import TitlePage from '../../atoms/TitlePage/TitlePage';
import History from '../../atoms/Breadcrumb/history';
import apiCall from '../../../services/api-calls/all';
import PreApprovalForm from '../../organism/PreApprovalForm/PreApprovalForm';
import { useRedirect } from '../../Router/redirect';
import { ADMIN_ORDER_LIST, PREAPPROVAL, SURVEYS } from '../../../utils/constants';
import { message } from 'antd';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import get from 'lodash';
import moment from 'moment';
import Spinner from '../../atoms/Spinner/Spinner';

const { createPreApproval } = apiCall();

const breadcrumbs = [
  {
    title: 'Pre-Aprobación',
    href: PREAPPROVAL
  },
  {
    title: 'Crear nueva pre-aprobación',
    href: ''
  }
];

const PreApprovalCreate = () => {
  const { redirect, setUrlToRedirect } = useRedirect();
  const [saving, setSaving] = useState(false);

  const handleCreate = async values => {
    try {
      setSaving(true);
      const { document, fromDate, toDate, ...rest } = values;
      const formData = new FormData();

      formData.set('file', document);
      formData.set(
        'preApprovalProperties',
        JSON.stringify(
          Object.assign(rest, {
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD')
          })
        )
      );

      await createPreApproval(formData);
      setSaving(false);
      message.success('Pre-aprobacion creada con éxito!');
      setUrlToRedirect(PREAPPROVAL);
    } catch (error) {
      const m = get(error, 'response.data');
      setSaving(false);
      message.error(processedErrorMessage(error, m));
    }
  };

  return (
    <Spinner loading={saving} text="Guardando...">
      <div className="order-edit-container">
        {redirect()}
        <div className="title">
          <History breadcrumbs={breadcrumbs} />
          <TitlePage text="CREAR PRE-APROBACIÓN" />
        </div>
        <PreApprovalForm handleSubmit={handleCreate} />
      </div>
    </Spinner>
  );
};

export default PreApprovalCreate;
