import { Space, Tooltip } from 'antd';
import ButtonPrimary from '../../components/atoms/ButtonPrimary/button-primary';
import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  DownloadOutlined,
  EyeFilled
} from '@ant-design/icons';

import React from 'react';
import { APPROVED, DECLINED, PENDING } from '../constants';

export const columns = ({ onEdit, onConfirm, onReject, isDisabled }) => [
  {
    title: 'ID',
    dataIndex: 'idPreApproval',
    key: 'idPreApproval'
  },
  {
    title: 'NOMBRE',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'RAZÓN SOCIAL',
    dataIndex: 'registeredName',
    key: 'commercialName'
  },
  {
    title: 'TIPO',
    dataIndex: 'type',
    key: 'type',
    render: ({ description }) => description
  },
  {
    title: 'ESTADO',
    dataIndex: 'state',
    key: 'state',
    align: 'center',
    render: ({ description }) => {
      if (description === PENDING)
        return (
          <Tooltip title="Pendiente">
            <ClockCircleFilled style={{ size: '20px', color: '#FED102' }} />
          </Tooltip>
        );

      if (description === APPROVED)
        return (
          <Tooltip title="Confirmado">
            <CheckCircleFilled style={{ size: '20px', color: '#5BC535' }} />
          </Tooltip>
        );

      if (description === DECLINED)
        return (
          <Tooltip title="Rechazado">
            <CloseCircleFilled style={{ Size: '20px', color: '#FF2E2E' }} />
          </Tooltip>
        );
    }
  },
  {
    title: 'INVERSIÓN',
    dataIndex: 'investment',
    key: 'investment'
  },
  {
    title: 'REEMBOLSO',
    dataIndex: 'refund',
    key: 'refund'
  },
  {
    title: 'ACCIONES',
    key: 'acciones',
    render: ({ id, state }) => (
      <Space>
        <ButtonPrimary
          theme="actions-refunds-btn"
          iconLeft={<EyeFilled />}
          onClick={() => onEdit(id, state)}
        />
        <ButtonPrimary
          disabled={isDisabled(state)}
          theme={isDisabled(state) ? 'actions-refunds-btn disabled' : 'actions-refunds-btn'}
          iconLeft={<CheckCircleFilled />}
          onClick={() => onConfirm(id)}
        />
        <ButtonPrimary
          disabled={isDisabled(state)}
          theme={isDisabled(state) ? 'actions-refunds-btn disabled' : 'actions-refunds-btn'}
          iconLeft={<CloseCircleFilled />}
          onClick={() => onReject(id)}
        />
      </Space>
    )
  }
];
