import React, { useContext, useState } from 'react';
import NavBar from '../NavBar/navbar';
import './_style.scss';
import logo from '../../../img/logo-large.png';
import logoSmall from '../../../img/logo-small.svg';
import '../../../css/app.scss';
import { UserContext } from '../../../services/providers/user-context.jsx';
import { NavbarContext } from '../../../services/providers/navbar-context';

const Header = () => {
  const { user } = useContext(UserContext);
  const { collapsed } = useContext(NavbarContext);
  const { menus } = user.secondaryNavbar;

  return (
    <div className="header">
      <div className="TopNav">
        <div className="img-container">
          <img src={collapsed ? logoSmall : logo} alt="logo" />
        </div>
      </div>
      <NavBar navbarEntries={menus} />
    </div>
  );
};
export default Header;
