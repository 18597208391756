import React from 'react';
import {Space} from 'antd';
import ButtonPrimary from '../../components/atoms/ButtonPrimary/button-primary';
import {
  EyeFilled,
  DeleteOutlined,
  DownloadOutlined
} from '@ant-design/icons';

export const columns = (onEdit, onDestroy, onDownload) => {
  let columns = [
    {
      title: 'NOMBRE',
      dataIndex: 'name',
      key: 'nombre'
    },
    {
      title: 'VIGENCIA',
      key: 'vigencia',
      render: (text, record) => `${record.fromDate} - ${record.toDate}`,
      width: 250
    },
    {
      title: 'PBU',
      dataIndex: 'producerBusinessUnit',
      key: 'pbu',
      render: (producerBusinessUnit) => producerBusinessUnit.description
    },
    {
      title: 'LINK',
      dataIndex: 'id',
      key: 'protocolo',
      render: (id) => (
          <ButtonPrimary theme="protocolo_btn" text="Descargar" iconLeft={<DownloadOutlined />} onClick={() => onDownload(id)} />
      )
    }
  ]

  if(onEdit || onDestroy)
    columns.push({
      dataIndex: 'id',
      title: 'ACCIONES',
      key: 'acciones',
      render: (id, record) => (
          <Space>
            {onEdit && <ButtonPrimary theme="btn-promo" iconLeft={<EyeFilled />} onClick={() => onEdit(id)} />}
            {onDestroy && <ButtonPrimary theme="eliminar_btn"  iconLeft={<DeleteOutlined />} onClick={() => onDestroy(id)} />}
          </Space>
      )
    })

  return columns
}
