import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, message, Select, Space } from 'antd';
import ButtonPrimary from '../../../components/atoms/ButtonPrimary/button-primary';
import apiCalls from '../../../services/api-calls/all';

import { EXPENSE_PENDING } from '../../../utils/constants';
import { UserHelpers } from '../../../utils/user-helpers';
import TextArea from 'antd/es/input/TextArea';
import { REQUIRED_FIELD_RULE } from '../../../utils/validation-rules';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import { processedErrorMessage } from '../../../services/api-calls/helpers';

const { getStatesExpenseReportAdmin } = apiCalls();
const { Search } = Input;

const ExpensePopUpForm = forwardRef(({ handleSubmit, handleCancel }, reference) => {
  const [states, setStates] = useState([]);
  const { isViewer } = UserHelpers();
  const { Option } = Select;
  const [form] = Form.useForm();

  useImperativeHandle(reference, () => ({
    clearForm() {
      form.setFieldsValue({
        state: undefined,
        comment: undefined
      });
    }
  }));

  const _getStates = async () => {
    try {
      const response = await getStatesExpenseReportAdmin();
      setStates(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  useEffect(() => {
    _getStates();
  }, []);

  return (
    <>
      <Form form={form} onFinish={handleSubmit} className="expense-pop-up-form-container">
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name="state">
          <Select
            style={{
              width: '150px'
            }}
            placeholder="Estado"
          >
            {states.map(
              ({ id, description }) =>
                description !== EXPENSE_PENDING && (
                  <Option key={id} value={description}>
                    {description}
                  </Option>
                )
            )}
          </Select>
        </Form.Item>
        <Form.Item rules={[]} name="comment">
          <TextArea maxLength={50} rows={4} />
        </Form.Item>
        <div className="expense-popup-form-buttons">
          <ButtonPrimary text="GUARDAR" theme="btn-guardar" type="submit" />
          <ButtonSecondary text="CANCELAR" theme="btn-cancel" onClick={handleCancel} />
        </div>
      </Form>
    </>
  );
});

ExpensePopUpForm.propTypes = {
  expense: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string
};

ExpensePopUpForm.defaultProps = {
  submitText: 'GUARDAR'
};

export default ExpensePopUpForm;
