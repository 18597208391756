import React from 'react';
import './style.scss';

import TitlePage from '../../atoms/TitlePage/TitlePage';
import {PriceList} from "../../organism/PriceList/PriceList";

const Prices = () => (
  <div className="main-price-lists-container">
    <TitlePage text="LISTA DE PRECIOS" />
    <PriceList />
  </div>
);

export default Prices;
