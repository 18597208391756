import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const ButtonSecondary = ({
  text,
  theme,
  onClick,
  iconLeft,
  iconRight,
  disabled,
  type,
  htmlType
}) => {
  const classname = `ButtonSecondary ${theme}`;
  /* eslint-disable react/button-has-type */
  return (
    <button
      type={type}
      htmltype={htmlType}
      className={classname}
      onClick={onClick}
      disabled={disabled}
    >
      <p>
        {iconLeft} {text} {iconRight}
      </p>
    </button>
  );
  /* eslint-enable react/button-has-type */
};

ButtonSecondary.propTypes = {
  disabled: PropTypes.bool,
  htmlType: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  theme: PropTypes.string,
  type: PropTypes.string
};

ButtonSecondary.defaultProps = {
  disabled: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  type: 'button',
  htmlType: undefined,
  onClick: () => {}
};

export default ButtonSecondary;
