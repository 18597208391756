import { Modal, Table } from 'antd';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary'
import React from 'react';
import './style.scss';
import { EyeFilled } from '@ant-design/icons';
import starWhite from '../../../img/white-star.svg';
import star from '../../../img/star.svg';
import price from '../../../img/price-white.svg';
import img from '../../../img/gorra.jpg';

const dataSource = [
    {
      key: '1',
      description: 'Gorra GY- Talle M. Lorem Ipsum.',
      points: '300',
      price: '$600',
      quantity: '2',
      realQuantity: '1',
      total: '$600'
    },
    {
      key: '2',
      description: 'Gorra GY- Talle M. Lorem Ipsum.',
      points: '300',
      price: '$600',
      quantity: '2',
      realQuantity: '1',
      total: '$600'
    },
    {
      key: '3',
      description: 'Gorra GY- Talle M. Lorem Ipsum.',
      points: '300',
      price: '$600',
      quantity: '2',
      realQuantity: '1',
      total: '$600'
    },
  ];

  const columns = [
    {
      title: 'PRODUCTO',
      key: 'product',
      render: () => (
          <div
          style={{
            background: `url(${img}) no-repeat center center`,
            backgroundSize: 'cover',
            width: '80px',
            borderRadius: '4px',
            height: '80px'
          }}>
        </div>
        ),
    },
    {
      title: 'DESCRIPCIÓN',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'PUNTOS',
      key: 'points',
      render: () => (
      <div className='points-container'><img src={star} alt="" /><p>300</p></div>),
    },
    {
        title: 'PRECIO',
        dataIndex: 'price',
        key: 'price',
      },
    {
        title: 'CANTIDAD',
        dataIndex: 'quantity',
        key: 'quantity',
      },
    {
        title: 'CANTIDAD REAL',
        dataIndex: 'realQuantity',
        key: 'realQuantity',
      },
    {
        title: 'TOTAL',
        dataIndex: 'total',
        key: 'total',
      },
  ];
class OrderDetail extends React.Component {
  state = {
    loading: false,
    visible: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible, loading } = this.state;
    return (
      <>
        <ButtonPrimary theme='btn-detail' iconLeft={<EyeFilled />} onClick={this.showModal} />
        <Modal
          visible={visible}
          title="PEDIDO #39988473"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            null
          ]}
        >
         <div className='resume-container'> 
             <div>RESUMEN</div>
             <div className='order-detail-data-container'>
                <div className='data-points-container'>
                    <p>TOTAL PUNTOS</p>
                    <img src={starWhite} alt=""/>
                    <span>300</span>
                </div>
                <div className='data-price-container'>
                    <p>TOTAL PESOS</p>
                    <img src={price} alt=""/>
                    <span>1500</span>
                </div>
             </div>
         </div>

          <Table
              className='order-detail-table'
              columns={columns}
              dataSource={dataSource}
              scroll={{x:500}}
              />
        </Modal>
      </>
    );
  }
}

export default OrderDetail;