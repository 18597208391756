import React, { useState } from 'react';
import './style.scss';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import AdminSurveyMeasureTable from '../../../organism/AdminSurveyMeasureTable/AdminSurveyMeasureTable';
import AdminSurveyBrandsTable from '../../../organism/AdminSurveyBrandsTable/AdminSurveyBrandsTable';
import Spinner from '../../../atoms/Spinner/Spinner';

const AdminSurvey = () => {
  const [loading, setLoading] = useState(false);
  
  return (
    <Spinner loading={loading}>
      <TitlePage text="PARAMETRÍA RELEVAMIENTOS" />
      <div className="page-content">
        <div className="title">
          <TitlePage text="MEDIDAS" />
        </div>
        <AdminSurveyMeasureTable setLoading={setLoading} />
        <div className="title">
          <TitlePage text="MARCA" />
        </div>
        <AdminSurveyBrandsTable setLoading={setLoading} />
      </div>
    </Spinner>
  );
};

export default AdminSurvey;
