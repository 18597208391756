import React, { useState, useEffect } from 'react';
import './_style.scss';
import { Input, DatePicker, Menu, Modal, Dropdown, message, Button, Space } from 'antd';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import PaginatedTable from '../../molecules/PaginatedTable/paginated-table.jsx';
import { columns } from '../../../utils/tables/table-promotions-admin';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCalls from '../../../services/api-calls/all';
import { UserHelpers } from '../../../utils/user-helpers';
import { downloadPdf } from '../../../utils/func-helpers';
import {
  ADMIN_PRICE_LIST_CREATE_URL,
  ADMIN_PROMOTIONS_CREATE_URL,
  ADMIN_PROMOTIONS_URL,
  PAGE_SIZE
} from '../../../utils/constants';
import { useRedirect } from '../../Router/redirect';

import { DownOutlined, UserOutlined } from '@ant-design/icons';
import Spinner from '../../atoms/Spinner/Spinner';

const { getPromotionsFiltered, getPromotionStates, destroyPromotion, downloadFile } = apiCalls();
const { Search } = Input;
const { confirm } = Modal;
const { RangePicker } = DatePicker;

const Promotions = () => {
  const [title, setTitle] = useState('');
  const [state, setState] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [promotions, setPromotions] = useState([]);
  const [states, setStates] = useState([]);
  const [reset, setReset] = useState(false);
  const filters = [dateFilter, state, title, reset];
  const { redirect, setUrlToRedirect } = useRedirect();
  const { canCreate, canUpdate, canDelete } = UserHelpers();
  const [loading, setLoading] = useState(false);

  const formatTableData = data =>
    data.content.map((promotion, i) => ({ key: (i + 1).toString(), ...promotion }));

  const handleOnEdit = id => {
    setUrlToRedirect(`${ADMIN_PROMOTIONS_URL}/${id}`);
  };

  const handleOnDestroy = id => {
    const promotion = promotions.find(promotion => promotion.id === id);
    confirm({
      title: '¿Desea eliminar esta promoción?',
      content: promotion.title,
      async onOk() {
        try {
          setLoading(true);
          await destroyPromotion(id);
          setReset(!reset);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          const errorMessage = processedErrorMessage(error);
          message.error(errorMessage);
        }
      },
      onCancel() {
        console.log('Cancel');
      }
    });
  };

  const prepareDownloadPdf = async id => {
    const promotion = promotions.find(promotion => promotion.id === id);
    const { file } = promotion;
    if (file) {
      try {
        setLoading(true);
        const fileData = await downloadFile(file.id);
        setLoading(false);
        downloadPdf(fileData, file.fileName);
      } catch (error) {
        setLoading(false);
        message.error(processedErrorMessage(error));
      }
    }
  };

  const searchStates = async () => {
    try {
      setLoading(true);
      const response = await getPromotionStates();
      response.push({ id: 0, description: 'Todos' });
      setStates(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const populatePromotions = async (page, pageSize, onResult) => {
    try {
      setLoading(true);
      const filter = {
        page,
        size: pageSize,
        fromDate: dateFilter ? dateFilter[0] : null,
        toDate: dateFilter ? dateFilter[1] : null,
        title,
        state: state ? state : null
      };
      const response = await getPromotionsFiltered(filter);
      setLoading(false);
      onResult(response);
      setPromotions(response.content);
    } catch (error) {
      setLoading(false);
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  const _showState = () => {
    if (state) {
      const selectState = states.filter(stateItem => stateItem.id === state)[0];
      return selectState.description;
    }
    return 'Estado';
  };

  useEffect(() => {
    searchStates();
  }, []);

  return (
    <Spinner loading={loading}>
      {redirect()}
      <div className="inputs_container">
        <div className="search_btn">
          <Search
            className="search_btn"
            placeholder="Título"
            onSearch={value => setTitle(value)}
            enterButton
          />
        </div>
        <Space className="admin-promotions">
          <div>
            <RangePicker
              format="YYYY-MM-DD"
              onChange={(date, dateString) => setDateFilter(dateString)}
            />
          </div>
          <div>
            <Dropdown
              overlay={
                <Menu
                  onClick={e => setState(parseInt(e.key))}
                  selectedKeys={[state ? state.toString() : null]}
                >
                  {states.map(state => (
                    <Menu.Item key={state.id} icon={<UserOutlined />}>
                      {state.description}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              className="dropdown_estados"
            >
              <Button>
                {_showState()} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          {canCreate() && (
            <div className="btn_crear">
              <ButtonPrimary
                theme="btn_blue"
                text="CREAR NUEVA PROMOCIÓN"
                onClick={() => setUrlToRedirect(ADMIN_PROMOTIONS_CREATE_URL)}
              />
            </div>
          )}
        </Space>
      </div>
      <div className="page-content">
        <PaginatedTable
          className="table_promociones"
          columns={columns(
            canUpdate() ? handleOnEdit : null,
            canDelete() ? handleOnDestroy : null,
            prepareDownloadPdf
          )}
          onFetch={populatePromotions}
          onFormat={formatTableData}
          pageSize={PAGE_SIZE}
          filters={filters}
          scroll={{ x: 800, y: '40vh' }}
        />
      </div>
    </Spinner>
  );
};

export default Promotions;
