import { Input, InputNumber } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { REQUIRED_FIELD_RULE } from '../../../utils/validation-rules';
import { Form } from 'antd';

const FormItemInput = ({
  label,
  placeholder,
  isRequired,
  name,
  inputContentClassName,
  disabled,
  rules,
  number,
  onChange,
  min,
  max,
  step
}) => (
  <div className={inputContentClassName}>
    <label className="input-label">
      {label} {isRequired ? <span>*</span> : ''}
    </label>
    <Form.Item
      rules={isRequired ? rules.concat([REQUIRED_FIELD_RULE]) : rules}
      name={name}
      onChange={onChange}
    >
      {number ? (
        <InputNumber
          min={min}
          max={max}
          step={step}
          className="input"
          placeholder={placeholder}
          disabled={disabled}
        />
      ) : (
        <Input className="input" placeholder={placeholder} disabled={disabled} />
      )}
    </Form.Item>
  </div>
);

export default FormItemInput;

FormItemInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
  inputContentClassName: PropTypes.string,
  disabled: PropTypes.bool,
  rules: PropTypes.array,
  number: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number
};

FormItemInput.defaultProps = {
  placeholder: '',
  isRequired: false,
  inputContentClassName: 'content-100',
  disabled: false,
  rules: [],
  number: false,
  min: undefined,
  max: undefined,
  step: 1
};
