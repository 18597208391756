import React, { useEffect, useContext, useRef, useState } from 'react';
import './style.scss';
import TitlePage from '../../atoms/TitlePage/TitlePage';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import PopUpPurchase from '../../molecules/PopUp/popUp';
import CartList from '../../organism/CartList/CartList';
import { CartContext } from '../../../services/providers/cart-provider';
import { currencyFormat } from '../../../utils/func-helpers';
import apiCall from '../../../services/api-calls/all';
import confirmPurchaseSVG from '../../../img/confirmPurchase.svg';
import { message } from 'antd';
import { processedErrorMessage } from '../../../services/api-calls/helpers';

const { confirmPurchase, confirmPurchaseWithPoints } = apiCall();
const Cart = () => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const { cart, loadCart, clearCart } = useContext(CartContext);
  const modal = useRef();
  const modalConfirmation = useRef();

  useEffect(() => {
    loadCart();
  }, []);

  const confirmPurchaseModal = method => {
    try {
      loadCart();
      setPaymentMethod(method);
      modal.current.showModal();
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const submitPurchase = async () => {
    try {
      const sendPurchase = paymentMethod === 'money' ? confirmPurchase : confirmPurchaseWithPoints;
      await sendPurchase();
      modal.current.hiddenModal();
      modalConfirmation.current.showModal();
      clearCart();
    } catch (error) {
      const {
        response: { data }
      } = error;
      if (data.hasOwnProperty('errors')) {
        const m = data.errors.map(e => <p style={{ textAlign: 'left' }}>{e}</p>);
        m.push(
          <p>Por favor, modifique la cantidad requerida o elimine el producto del carrito.</p>
        );
        message.error(m);
      } else message.error(data);

      modal.current.hiddenModal();
    }
  };

  const modalActionButtons = () => (
    <div className="buttons-container">
      <ButtonPrimary text="ACEPTAR" onClick={submitPurchase} theme="confirm-btn" />
      <ButtonPrimary text="CANCELAR" onClick={() => modal.current.hiddenModal()} theme="cancel" />
    </div>
  );

  return (
    <div className="cart-container">
      <TitlePage text="CARRITO" />
      <div className="data-container">
        <div className="page-content">
          <CartList />
        </div>
        <div className="receipt">
          <div className="title-container">
            <span>RESUMEN</span>
          </div>
          <div className="subtitle-container">
            <div className="receipt-subtitle">
              <span>TOTAL PUNTOS</span>
            </div>
            <div className="data">
              <span>{cart.subtotalPoints}</span>
            </div>

            <div className="receipt-subtitle">
              <span>TOTAL PESOS</span>
            </div>
            <div className="data">
              <span>{currencyFormat(cart.subtotalPrice)}</span>
            </div>
            <div className="buttons-container">
              <ButtonPrimary
                text="CONFIRMAR/PAGAR"
                onClick={() => confirmPurchaseModal('money')}
                disabled={!cart.items.length > 0}
                theme={!cart.items.length ? 'disabled' : 'confirm-btn'}
              />
              <ButtonPrimary
                text="CANJEAR PUNTOS"
                onClick={() => confirmPurchaseModal('points')}
                disabled={!cart.items.length > 0}
                theme={!cart.items.length ? 'disabled' : 'cancel'}
              />
            </div>
          </div>
          <div />
        </div>
      </div>
      <PopUpPurchase title={'Confirmación Pedido'} ref={modal} footer={modalActionButtons()}>
        <p>
          {paymentMethod === 'money'
            ? `¿Desea confirmar la compra en pesos: "${currencyFormat(
                cart.subtotalPrice
              )}", de los productos presentes en el carrito?`
            : `¿Desea confirmar la compra en puntos: "${cart.subtotalPoints}", de los productos presentes en el carrito?`}
        </p>
      </PopUpPurchase>
      <PopUpPurchase title={null} ref={modalConfirmation} footer={null}>
        <div className={'cart-modal-confirmation'}>
          <img className="modal-img" src={confirmPurchaseSVG} alt="" />
          <p>TU PEDIDO HA SIDO CONFIRMADO!</p>
          <ButtonPrimary text="VOLVER" onClick={() => modalConfirmation.current.hiddenModal()} />
        </div>
      </PopUpPurchase>
    </div>
  );
};

export default Cart;
