import React, { useState, useEffect } from 'react';
import './_style.scss';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import { message } from 'antd';
import History from '../../../atoms/Breadcrumb/history';
import apiCall from '../../../../services/api-calls/all';
import { processedErrorMessage } from '../../../../services/api-calls/helpers';
import OrderForm from '../../../organism/OrderForm/OrderForm';
import { useRedirect } from '../../../Router/redirect';
import { ADMIN_ORDER_LIST } from '../../../../utils/constants';

const { showOrder, confirmOrder, rejectOrder } = apiCall();

const breadcrumbs = [
  {
    title: 'Parametría Pedidos',
    href: ADMIN_ORDER_LIST
  },
  {
    title: 'Editar Pedido',
    href: ''
  }
];

const EditOrder = ({ match }) => {
  const {
    params: { id: userId }
  } = match;
  const { redirect, setUrlToRedirect } = useRedirect();

  const [order, setOrder] = useState({ items: [], state: {}, paymentMethod: {} });

  const searchOrderEdit = async () => {
    try {
      const response = await showOrder(userId);
      setOrder(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  useEffect(() => {
    searchOrderEdit();
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await confirmOrder(order.id);
      message.success(response.data);
      setUrlToRedirect(ADMIN_ORDER_LIST);
    } catch (error) {
      const {
        response: { data }
      } = error;
      message.error(processedErrorMessage(error, data));
    }
  };

  const declineOrder = async () => {
    try {
      const response = await rejectOrder(order.id);
      message.success(response.data);
      setUrlToRedirect(ADMIN_ORDER_LIST);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  return (
    <div className="order-edit-container">
      {redirect()}
      <div className="title">
        <History breadcrumbs={breadcrumbs} />
        <TitlePage text="EDITAR PEDIDO" />
      </div>
      <OrderForm
        handleSubmit={handleSubmit}
        declineOrder={declineOrder}
        order={order}
        submitText={'CONFIRMAR PEDIDO'}
      />
    </div>
  );
};

export default EditOrder;
