import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import { Form, Select, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import ImageLabel from '../../atoms/ImageLabel/ImageLabel';
import FormItemInput from '../../atoms/FormItemInput/FormItemInput';
import get from 'lodash';
import {
  NUMBER_FIELD_RULE_WITH_SPECIAL_CHARS,
  POSITIVE_NUMBERS_RULE,
  REQUIRED_FIELD_RULE
} from '../../../utils/validation-rules';
import useRedirect from '../../Router/redirect';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCall from '../../../services/api-calls/all';
import { PENDING, REFUND } from '../../../utils/constants';
import { isEmpty } from '../../../utils/func-helpers';
import UserHelpers from '../../../utils/user-helpers';
import { downloadPdf } from '../../../utils/func-helpers';

const { Option } = Select;

const {
  getPreApprovalTypes,
  getPreApprovalCategories,
  getPreApprovalSubCategories,
  downloadFile
} = apiCall();

const RefundsForm = ({ handleSubmit, refund, preapproval, refundState, disabledButtons }) => {
  const [form] = Form.useForm();
  const [types, setTypes] = useState([]);
  const [typesLoading, setTypesLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [category, setCategory] = useState(undefined);
  const [subcategories, setSubcategories] = useState([]);
  const [subcategoriesLoading, setSubcategoriesLoading] = useState(false);
  const [subcategory, setSubcategory] = useState(undefined);
  const [invoice, setInvoice] = useState(null);
  const [receipt, setReceipt] = useState(null);
  const [, setInvestment] = useState('');
  const [invoiceFileList, setInvoiceFileList] = useState([]);
  const [receiptFileList, setReceiptFileList] = useState([]);
  const { redirect, setUrlToRedirect } = useRedirect();
  const { isAdmin, isViewer } = UserHelpers();
  const [loading, setLoading] = useState(false);

  const _getPreApprovalTypes = async () => {
    try {
      setTypesLoading(true);
      const response = await getPreApprovalTypes();
      setTypes(response);
      setTypesLoading(false);
    } catch (error) {
      setTypesLoading(false);
      message.error(processedErrorMessage(error, get(error, 'response.data')));
    }
  };

  const _getPreApprovalCategories = async () => {
    try {
      setCategoriesLoading(true);
      const response = await getPreApprovalCategories();
      setCategories(response);
      setCategoriesLoading(false);
    } catch (error) {
      setCategoriesLoading(false);
      message.error(processedErrorMessage(error, get(error, 'response.data')));
    }
  };

  const _getPreApprovalSubCategories = async idCategory => {
    try {
      setSubcategoriesLoading(true);
      const response = await getPreApprovalSubCategories({
        idCategory
      });
      setSubcategories(response);
      setSubcategoriesLoading(false);
    } catch (error) {
      setSubcategoriesLoading(false);
      message.error(processedErrorMessage(error, get(error, 'response.data')));
    }
  };

  const updateFileData = (set, data, setObject) => {
    form.setFieldsValue(set);
    if (data) {
      setObject([
        {
          uid: '1',
          name: data ? (data.name ? data.name : data) : 'document'
        }
      ]);
    } else {
      setObject([]);
    }
  };

  const prepareDownload = async (id, fileName) => {
    try {
      const fileData = await downloadFile(id);
      downloadPdf(fileData, fileName);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const canEdit = () => refundState === undefined || refundState.description === PENDING;

  useEffect(() => {
    _getPreApprovalTypes();
    _getPreApprovalCategories();
  }, []);

  useEffect(() => {
    if (category) {
      _getPreApprovalSubCategories(category);
    }
  }, [category]);

  useEffect(() => {
    if (subcategories.map(item => item.id).includes(subcategory)) {
      form.setFieldsValue({
        subcategory: subcategory
      });
    } else {
      form.setFieldsValue({
        subcategory: undefined
      });
    }
  }, [subcategories]);

  useEffect(() => {
    updateFileData({ invoice }, invoice, setInvoiceFileList);
  }, [invoice]);

  useEffect(() => {
    updateFileData({ receipt }, receipt, setReceiptFileList);
  }, [receipt]);

  useEffect(() => {
    if ((refund && !isEmpty(refund)) || (preapproval && !isEmpty(preapproval))) setLoading(true);
  }, [refund, preapproval]);

  useEffect(() => {
    if (refund && !isEmpty(refund)) {
      form.setFieldsValue({
        idPreApproval: refund.idPreApproval,
        name: refund.name,
        type: refund.type.id,
        provider: refund.provider,
        invoiceNumber: refund.invoiceNumber,
        investment: refund.investment,
        refund: refund.refund,
        category: refund.category.id,
        subcategory: refund.subcategory.id,
        invoice: refund.invoice.fileName,
        receipt: refund.receipt.fileName,
        observations: refund.observations
      });
      setCategory(refund.category.id);
      setSubcategory(refund.subcategory.id);
      setInvoice(refund.invoice.fileName);
      setReceipt(refund.receipt.fileName);
    } else {
      if (preapproval && !isEmpty(preapproval)) {
        form.setFieldsValue({
          idPreApproval: preapproval.id,
          name: preapproval.name,
          type: preapproval.type.id,
          category: preapproval.category.id,
          subcategory: preapproval.subcategory.id
        });
        setCategory(preapproval.category.id);
        setSubcategory(preapproval.subcategory.id);
      }
    }
    setLoading(false);
  }, [refund, preapproval]);

  const notGreaterThanInvestment = () => {
    return [
      ({ getFieldValue }) => ({
        validator(value) {
          if (
            !value ||
            getFieldValue(value.field) <= getFieldValue('investment') &&
            getFieldValue(value.field) > 0 && getFieldValue(value.field) <= 999999.99
          ) {
            return Promise.resolve();
          } else {
            return Promise.reject(
              'Campo numerico mayor a 0 (cero) y no mayor al monto ingresado en Inversión'
            );
          }
        }
      })
    ];
  };

  const notGreaterThanZeroAndMax = () => {
    return [
      ({ getFieldValue }) => ({
        validator(value) {
          if (
            !value ||
            getFieldValue(value.field) <= 999999.99 &&
            getFieldValue(value.field) > 0
          ) {
            return Promise.resolve();
          } else {
            return Promise.reject(
              'Campo numerico mayor a 0 (cero) y menor a 999999.99'
            );
          }
        }
      })
    ];
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      {redirect()}
      <div className="page-content Refunds-create-form">
        <p className="required-fields">* Campos requeridos</p>
        <div className="content-100">
          <FormItemInput
            label="ID"
            placeholder="ID"
            isRequired
            name="idPreApproval"
            inputContentClassName="content-50"
            disabled
          />
        </div>
        <FormItemInput
          label="Nombre"
          placeholder="Nombre"
          isRequired
          name="name"
          inputContentClassName="content-50"
          disabled
        />
        <div className="content-50">
          <label className="input-label">
            Tipo <span>*</span>
          </label>
          <Form.Item rules={[REQUIRED_FIELD_RULE]} className="content-100" name="type">
            <Select loading={typesLoading} placeholder="Tipo" style={{ width: '100%' }} disabled>
              {types.map(({ id, description }) => (
                <Option key={id} value={id}>
                  {description}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <FormItemInput
          label="Proveedores"
          placeholder="Proveedores"
          name="provider"
          isRequired
          inputContentClassName="content-50"
          disabled={isAdmin()}
        />
        <FormItemInput
          name="invoiceNumber"
          label="Num. Facturas"
          placeholder="Num. Facturas"
          isRequired
          inputContentClassName="content-50"
          disabled={isAdmin()}
        />
        <FormItemInput
          number
          step={0.01}
          rules={notGreaterThanZeroAndMax()}
          label="Inversión"
          placeholder="Inversión"
          name="investment"
          isRequired
          inputContentClassName="content-50"
          onChange={event => setInvestment(event.target.value)}
          disabled={isAdmin()}
        />
        <FormItemInput
          number
          step={0.01}
          rules={notGreaterThanInvestment()}
          label="Reembolso"
          placeholder="Reembolso"
          name="refund"
          isRequired
          inputContentClassName="content-50"
        />
        <div className="content-50">
          <label className="input-label">
            Categoría <span>*</span>
          </label>
          <Form.Item rules={[REQUIRED_FIELD_RULE]} name="category">
            <Select
              loading={categoriesLoading}
              placeholder="CATEGORÍA"
              style={{ width: '100%' }}
              onChange={value => setCategory(value)}
              disabled
            >
              {categories.map(({ id, description }) => (
                <Option key={id} value={id}>
                  {description}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="content-50">
          <label className="input-label">
            Subcategoría <span>*</span>
          </label>
          <Form.Item rules={[REQUIRED_FIELD_RULE]} name="subcategory">
            <Select
              disabled={category === undefined}
              value={subcategory}
              loading={subcategoriesLoading}
              placeholder="SUBCATEGORÍA"
              style={{ width: '100%' }}
              onChange={value => setSubcategory(value)}
              disabled
            >
              {subcategories.map(({ id, description }) => (
                <Option key={id} value={id}>
                  {description}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          rules={[REQUIRED_FIELD_RULE]}
          className="content-50 margin-bottom-25"
          name="invoice"
        >
          <ImageLabel
            setFile={setInvoice}
            fileList={invoiceFileList}
            label="Comprobantes"
            isRequired
            beforeUpload={() => false}
            download={
              refund && refund.invoice ? (
                <ButtonPrimary
                  theme="actions-preapproval-btn"
                  iconLeft={<DownloadOutlined />}
                  onClick={() => prepareDownload(refund.invoice.id, refund.invoice.fileName)}
                />
              ) : (
                <></>
              )
            }
            disabled={isAdmin()}
          />
        </Form.Item>
        <Form.Item
          rules={[REQUIRED_FIELD_RULE]}
          className="content-50 margin-bottom-25"
          name="receipt"
        >
          <ImageLabel
            setFile={setReceipt}
            fileList={receiptFileList}
            label="Pieza"
            isRequired
            beforeUpload={() => false}
            download={
              refund && refund.receipt ? (
                <ButtonPrimary
                  theme="actions-preapproval-btn"
                  iconLeft={<DownloadOutlined />}
                  onClick={() => prepareDownload(refund.receipt.id, refund.receipt.fileName)}
                />
              ) : (
                <></>
              )
            }
            disabled={isAdmin()}
          />
        </Form.Item>
        <div className="content-100">
          <label className="text-area-label">Comentarios</label>
          <Form.Item name="observations">
            <TextArea className="content-100" />
          </Form.Item>
        </div>
      </div>
      <div className="buttons-container">
        <ButtonSecondary
          text="CANCELAR"
          theme="btn-cancelar"
          onClick={() => setUrlToRedirect(REFUND)}
        />
        <ButtonPrimary
          text="GUARDAR"
          disabled={!canEdit() || isViewer() || disabledButtons}
          theme={
            !canEdit() || isViewer() || disabledButtons ? 'btn-guardar disabled' : 'btn-guardar'
          }
          type="submit"
        />
      </div>
    </Form>
  );
};

RefundsForm.propTypes = {
  refund: PropTypes.object,
  preapproval: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  refundState: PropTypes.object
};

RefundsForm.defaultProps = {
  refund: {},
  preapproval: {},
  refundState: undefined
};

export default RefundsForm;
