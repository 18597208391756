import {
  REGEX_PASSWORD,
  REGEX_EMAIL,
  REGEX_PHONE,
  REGEX_NUMBERS_AND_SPECIAL_CHARS,
  REGEX_POSITIVE_NUMBERS
} from './validation-regular-exp';
import {
  REQUIRED_FIELD,
  PASSWORD,
  EMAIL,
  PHONE,
  NUMERIC_FIELD,
  NUMERIC_FIELD_WITH_SPECIAL_CHARS,
  POSITIVE_NUMERIC_FIELD,
  PASSWORD_NOT_MATCH,
  EMAIL_NOT_MATCH,
  LIMIT_FOR_MAX_VALUE
} from './validation-messages';

export const REQUIRED_FIELD_RULE = { required: true, message: REQUIRED_FIELD };
export const PASSWORD_RULE = { pattern: REGEX_PASSWORD, message: PASSWORD, type: 'string' };
export const EMAIL_RULE = { pattern: REGEX_EMAIL, message: EMAIL, type: 'string' };
export const PHONE_RULE = { pattern: REGEX_PHONE, message: PHONE };
export const NUMBER_FIELD_RULE = { type: 'integer', message: NUMERIC_FIELD };
export const NUMBER_FIELD_RULE_WITH_SPECIAL_CHARS = {
  pattern: REGEX_NUMBERS_AND_SPECIAL_CHARS,
  message: NUMERIC_FIELD_WITH_SPECIAL_CHARS,
  type: 'string'
};
export const POSITIVE_NUMBERS_RULE = {
  type: 'integer',
  message: POSITIVE_NUMERIC_FIELD,
  pattern: REGEX_POSITIVE_NUMBERS
};

export const MAX_VALUE = max => () => ({
  validator(rule, value) {
    if (!value || value <= max) {
      return Promise.resolve();
    }
    return Promise.reject(LIMIT_FOR_MAX_VALUE.replace(/:max/g, max));
  }
});
export const CONFIRM_PASSWORD_RULE = ({ getFieldValue }) => ({
  validator(rule, value) {
    if (!value || getFieldValue('password') === value) {
      return Promise.resolve();
    }
    return Promise.reject(PASSWORD_NOT_MATCH);
  }
});

export const CONFIRM_USERNAME_RULE = ({ getFieldValue }) => ({
  validator(rule, value) {
    if (!value || getFieldValue('username') === value) {
      return Promise.resolve();
    }
    return Promise.reject(EMAIL_NOT_MATCH);
  }
});
