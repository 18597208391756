import React from 'react';
import PropTypes from 'prop-types';
import './_styles.scss';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Spinner = ({ children, text, loading, icon, size}) => {

  return (
    <Spin
      className="loading-spinner"
      tip={text}
      indicator={icon}
      spinning={loading}
      size={size}
      delay={100}
    >
      {children}
    </Spin>
  );
};

Spinner.propTypes = {
    text: PropTypes.string,
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool.isRequired,
    icon: PropTypes.node,
    size: PropTypes.string
};

Spinner.defaultProps = {
    text: 'Cargando...',
    icon: <LoadingOutlined spin />,
    size: "large"
};

export default Spinner;