import React, { useState, useEffect, useRef } from 'react';
import './_style.scss';

import Cards from '../../molecules/Cards/cards';
import PopUpNovedades from '../../molecules/PopUp/popUp';
import apiCalls from '../../../services/api-calls/all';
import { message} from 'antd';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import MainCard from '../../molecules/MainCard/mainCard';
import Spinner from '../../atoms/Spinner/Spinner'

const { getNews, showNew } = apiCalls();

const NewLists = () => {
  const [newItem, setNewItem] = useState({});
  const [news, setNews] = useState([]);
  const modal = useRef();
  const [loading, setLoading] = useState(false);

  const handleSearchNews = async () => {
    try {
      setLoading(true);
      const response = await getNews();
      setNews(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const handleShowNew = async id => {
    try {
      setLoading(true);
      const response = await showNew(id);
      setNewItem(response);
      setLoading(false);
      modal.current.showModal();
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  useEffect(() => {
    handleSearchNews();
  }, []);

  return (
    <Spinner loading={loading} >
      <div className="novedades-container">
        {news
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map(newItem =>
            newItem.order === 1 ? (
              <MainCard
                key={newItem.id}
                id={newItem.id}
                fecha={newItem.date}
                title={newItem.title}
                img={newItem.image}
                description={newItem.description}
                handleShow={handleShowNew}
              />
            ) : (
              <Cards
                key={newItem.id}
                id={newItem.id}
                fecha={newItem.date}
                title={newItem.title}
                img={newItem.image}
                handleShow={handleShowNew}
              />
            )
          )}
        <PopUpNovedades title={newItem.title} ref={modal} footer={null}>
          <img className="modal-img" src={newItem.image} alt="" />
          <p>{newItem.description}</p>
        </PopUpNovedades>
      </div>
    </Spinner>
  );
};

export default NewLists;
