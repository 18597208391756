import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './_style.scss';
import UploadImage from '../../molecules/UploadImage/UploadImage';
import ImageLabel from '../../atoms/ImageLabel/ImageLabel';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import { DatePicker, Form, Input, message, Space, Select } from 'antd';
import { useRedirect } from '../../Router/redirect';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import { ADMIN_PRICE_LIST_URL } from '../../../utils/constants';
import { REQUIRED_FIELD_RULE } from '../../../utils/validation-rules';
import apiCall from '../../../services/api-calls/all';
import { isEmpty } from '../../../utils/func-helpers';
import moment from 'moment';
import Spinner from '../../atoms/Spinner/Spinner';

const { getPriceListPbus, createPriceList, editPriceList } = apiCall();
const { RangePicker } = DatePicker;
const { Option } = Select;

const PriceListForm = ({ priceList = null }) => {
  const [form] = Form.useForm();
  const [states, setStates] = useState([]);
  const [statesLoading, setStatesLoading] = useState(false);
  const [image, setImage] = useState(
    priceList && priceList.hasOwnProperty('image') ? priceList.image : null
  );
  const [file, setFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { redirect, setUrlToRedirect } = useRedirect();

  const searchPbus = async () => {
    try {
      setStatesLoading(true);
      const response = await getPriceListPbus();
      setStates(response);
      setStatesLoading(false);
    } catch (error) {
      setStatesLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const handleSubmit = async values => {
    if (loading) return;
    setLoading(true);
    try {
      const formData = new FormData();
      Object.keys(values).map(key => {
        if (key !== 'image' && key !== 'file' && values[key]) formData.set(key, values[key]);
      });
      if (image.name) {
        formData.set('image', image);
      } else {
        formData.set('image', null);
      }
      if (file.name) {
        formData.set('file', file);
      } else {
        formData.set('file', null);
      }
      if (values.hasOwnProperty('date') && values.date) {
        formData.set('fromDate', moment(values.date[0]).format('YYYY-MM-DD'));
        formData.set('toDate', moment(values.date[1]).format('YYYY-MM-DD'));
      }

      if (priceList) {
        await editPriceList(priceList.id, formData);
      } else {
        await createPriceList(formData);
      }
      message.success('Acción realizada con éxito');
      setLoading(false);
      setUrlToRedirect(ADMIN_PRICE_LIST_URL);
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
      setLoading(false);
    }
  };

  const updateFileData = (set, data, setObject) => {
    form.setFieldsValue(set);
    if (data) {
      setObject([
        {
          uid: '1',
          name: data.name ? data.name : priceList.name
        }
      ]);
    } else {
      setObject([]);
    }
  };

  useEffect(() => {
    searchPbus();
  }, []);

  useEffect(() => {
    updateFileData({ image }, image, setImageList);
  }, [image]);

  useEffect(() => {
    updateFileData({ file }, file, setFileList);
  }, [file]);

  useEffect(() => {
    if (priceList && !isEmpty(priceList)) setLoading(true);
  }, [priceList]);

  useEffect(() => {
    if (priceList && !isEmpty(priceList)) {
      form.setFieldsValue({
        name: priceList.name,
        date: [moment(priceList.fromDate), moment(priceList.toDate)],
        file: priceList.file.fileName,
        image: priceList.image,
        idPBU: priceList.producerBusinessUnit.id
      });
      setImage(priceList.image);
      setFile(priceList.file.fileName);
    }
    setLoading(false);
  }, [priceList]);

  return (
    <Spinner loading={loading}>
      <Form form={form} onFinish={handleSubmit} className="pricelist-form-container">
        {redirect()}
        <div className="page-content">
          <div className="load-image">
            <Form.Item rules={[REQUIRED_FIELD_RULE]} name="image">
              <UploadImage
                defaultImage={image ? { fileUrl: image } : {}}
                setFile={setImage}
                formats={['.png', '.jpg']}
                description="Los formatos aceptados son .jpg y .png. El tamaño máximo permitido para los archivos es 5MB."
                fileList={imageList}
              />
            </Form.Item>
          </div>
          <div className="inputs-container">
            <div className="input-pricelist">
              <label className="input-label">
                Nombre <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="name">
                <Input className="input" placeholder="Nombre" />
              </Form.Item>
            </div>
            <div className="select-pricelist">
              <label className="text-area-label">
                PBU <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="idPBU">
                <Select loading={statesLoading} placeholder="PBU">
                  {states.map((state, index) => (
                    <Option key={index} value={state.id}>
                      {state.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="input-pricelist input-100">
              <label className="input-label">
                Vigencia <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="date">
                <Space direction="vertical" size={12}>
                  <RangePicker
                    defaultValue={
                      priceList && !isEmpty(priceList)
                        ? [moment(priceList.fromDate), moment(priceList.toDate)]
                        : null
                    }
                    format="YYYY-MM-DD"
                    onChange={(value, date) => {
                      date[0] === '' || date[1] === ''
                        ? form.setFieldsValue({ date: null })
                        : form.setFieldsValue({ date: date });
                    }}
                  />
                </Space>
              </Form.Item>
            </div>

            <div className="elegir-archivo-container-pricelist">
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="file">
                <ImageLabel
                  label={
                    <label className="text-area-label">
                      Documento <span>*</span>
                    </label>
                  }
                  setFile={setFile}
                  formats={['.xls', '.xlsx']}
                  description="El formato aceptado es .xls, .xlsx"
                  fileList={fileList}
                  beforeUpload={() => {
                    return false;
                  }}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="buttons-container">
          <ButtonSecondary
            text="CANCELAR"
            theme="btn-cancelar"
            onClick={() => setUrlToRedirect(ADMIN_PRICE_LIST_URL)}
          />
          <ButtonPrimary text="GUARDAR" theme="btn-guardar" type="submit" />
        </div>
      </Form>
    </Spinner>
  );
};

PriceListForm.propTypes = {
  priceList: PropTypes.object
};

export default PriceListForm;
