import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './_style.scss';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import { DatePicker, Form, Input, InputNumber, message, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import ImageLabel from '../../atoms/ImageLabel/ImageLabel';

import { REQUIRED_FIELD_RULE } from '../../../utils/validation-rules';
import apiCall from '../../../services/api-calls/all';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import get from 'lodash';
import { PREAPPROVAL, PREAPPROVAL_PENDING } from '../../../utils/constants';
import { useRedirect } from '../../Router/redirect';
import { isEmpty } from '../../../utils/func-helpers';
import moment from 'moment';
import UserHelpers from '../../../utils/user-helpers';
import Spinner from '../../atoms/Spinner/Spinner';

const { Option } = Select;

const { getPreApprovalTypes, getPreApprovalCategories, getPreApprovalSubCategories } = apiCall();

const PreApprovalForm = ({ handleSubmit, preapproval, preApprovalState }) => {
  const [form] = Form.useForm();
  const [types, setTypes] = useState([]);
  const [typesLoading, setTypesLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [category, setCategory] = useState(undefined);
  const [subcategories, setSubcategories] = useState([]);
  const [subcategoriesLoading, setSubcategoriesLoading] = useState(false);
  const [subcategory, setSubcategory] = useState(undefined);
  const [document, setDocument] = useState(null);
  const [documentList, setDocumentList] = useState([]);
  const { redirect, setUrlToRedirect } = useRedirect();
  const { isViewer, isAdmin } = UserHelpers();
  const [loading, setLoading] = useState(false);

  const _getPreApprovalTypes = async () => {
    try {
      setTypesLoading(true);
      const response = await getPreApprovalTypes();
      setTypes(response);
      setTypesLoading(false);
    } catch (error) {
      setTypesLoading(false);
      message.error(processedErrorMessage(error, get(error, 'response.data')));
    }
  };

  const _getPreApprovalCategories = async () => {
    try {
      setCategoriesLoading(true);
      const response = await getPreApprovalCategories();
      setCategories(response);
      setCategoriesLoading(false);
    } catch (error) {
      setCategoriesLoading(false);
      message.error(processedErrorMessage(error, get(error, 'response.data')));
    }
  };

  const _getPreApprovalSubCategories = async idCategory => {
    try {
      setSubcategoriesLoading(true);
      const response = await getPreApprovalSubCategories({
        idCategory
      });
      setSubcategories(response);
      setSubcategoriesLoading(false);
    } catch (error) {
      setSubcategoriesLoading(false);
      message.error(processedErrorMessage(error, get(error, 'response.data')));
    }
  };

  const updateFileData = (set, data, setObject) => {
    form.setFieldsValue(set);
    if (data) {
      setObject([
        {
          uid: '1',
          name: data ? (data.name ? data.name : data) : 'document'
        }
      ]);
    } else {
      setObject([]);
    }
  };
  const disabledDate = current =>
    current && current < moment(form.getFieldValue('fromDate')).endOf('day');

  const canEdit = () =>
    (preApprovalState === undefined || preApprovalState.description === PREAPPROVAL_PENDING) &&
    !isAdmin();

  useEffect(() => {
    _getPreApprovalTypes();
    _getPreApprovalCategories();
  }, []);

  useEffect(() => {
    if (category) {
      _getPreApprovalSubCategories(category);
    }
  }, [category]);

  useEffect(() => {
    if (subcategories.map(item => item.id).includes(subcategory)) {
      form.setFieldsValue({
        idSubcategory: subcategory
      });
    } else {
      form.setFieldsValue({
        idSubcategory: undefined
      });
    }
  }, [subcategories]);

  useEffect(() => {
    updateFileData({ document }, document, setDocumentList);
  }, [document]);

  useEffect(() => {
    if (preapproval && !isEmpty(preapproval))
      setLoading(true);
  }, [preapproval])

  useEffect(() => {
    if (preapproval && !isEmpty(preapproval)) {
      form.setFieldsValue({
        name: preapproval.name,
        fromDate: moment(preapproval.fromDate),
        toDate: moment(preapproval.toDate),
        idCategory: preapproval.category.id,
        idSubcategory: preapproval.subcategory.id,
        idType: preapproval.type.id,
        estimatedInvestment: preapproval.estimatedInvestment,
        observations: preapproval.observations,
        document: preapproval.document.fileName,
        image: preapproval.image
      });
      setCategory(preapproval.category.id);
      setSubcategory(preapproval.subcategory.id);
      setDocument(preapproval.document.fileName);
      setLoading(false);
    }
  }, [preapproval]);

  return (
    <Spinner loading={loading}>
      <Form form={form} onFinish={handleSubmit}>
        {redirect()}
        <div className="page-content">
          <div className="pre-approval-form">
            <div className="content-50">
              <label className="input-label">
                Nombre <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="name">
                <Input className="input" placeholder="Nombre" maxLength="30" />
              </Form.Item>
            </div>
            <div className="content-50">
              <label className="input-label">
                Tipo <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="idType">
                <Select loading={typesLoading} placeholder="Tipo" style={{ width: '100%' }}>
                  {types.map(({ id, description }) => (
                    <Option key={id} value={id}>
                      {description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="content-50">
              <label className="input-label">
                Categoría <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="idCategory">
                <Select
                  loading={categoriesLoading}
                  placeholder="Categoría"
                  style={{ width: '100%' }}
                  onChange={value => setCategory(value)}
                >
                  {categories.map(({ id, description }) => (
                    <Option key={id} value={id}>
                      {description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="content-50">
              <label className="input-label">
                Subcategoría <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="idSubcategory">
                <Select
                  disabled={category === undefined}
                  value={subcategory}
                  loading={subcategoriesLoading}
                  placeholder="Subcategoría"
                  style={{ width: '100%' }}
                  onChange={value => setSubcategory(value)}
                >
                  {subcategories.map(({ id, description }) => (
                    <Option key={id} value={id}>
                      {description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="content-50">
              <label className="input-label">
                Fecha Desde <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="fromDate">
                <DatePicker placeholder="Fecha" format="YYYY-MM-DD" />
              </Form.Item>
            </div>
            <div className="content-50">
              <label className="input-label">
                Fecha Hasta <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="toDate">
                <DatePicker disabledDate={disabledDate} placeholder="Fecha" format="YYYY-MM-DD" />
              </Form.Item>
            </div>
            <div className="content-50">
              <label className="input-label">
                Inversion Estimada $ <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="estimatedInvestment">
                <InputNumber step={0.01} className="input" placeholder="Ejm: 0.20" />
              </Form.Item>
            </div>
            <div className="content-50">
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="document">
                <ImageLabel
                  setFile={setDocument}
                  fileList={documentList}
                  label={
                    <label className="input-label">
                      Documento <span>*</span>
                    </label>
                  }
                  beforeUpload={() => false}
                />
              </Form.Item>
            </div>
            <div className="content-100">
              <label className="text-area-label">Observaciones</label>
              <Form.Item rules={[]} name="observations">
                <TextArea className="content-100" />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="buttons-container">
          <ButtonSecondary
            text="CANCELAR"
            theme="btn-cancelar"
            onClick={() => setUrlToRedirect(PREAPPROVAL)}
          />
          <ButtonPrimary
            text="GUARDAR"
            theme={isViewer() || !canEdit() ? 'btn-guardar disabled' : 'btn-guardar'}
            disabled={isViewer() || !canEdit()}
            type="submit"
          />
        </div>
      </Form>
    </Spinner>
  );
};

PreApprovalForm.propTypes = {
  preapproval: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  preApprovalState: PropTypes.object
};

PreApprovalForm.defaultProps = {
  submitText: 'GUARDAR',
  preapproval: {},
  preApprovalState: undefined
};

export default PreApprovalForm;
