import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import { PRODUCTIVITY } from '../../../utils/constants';
import { useRedirect } from '../../Router/redirect';
import { Form, InputNumber, message, Select } from 'antd';
import { REQUIRED_FIELD_RULE } from '../../../utils/validation-rules';
import apiCall from '../../../services/api-calls/all';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import { isEmpty } from '../../../utils/func-helpers';
import { UserHelpers } from '../../../utils/user-helpers';
import Spinner from '../../atoms/Spinner/Spinner';

const { getMonths, getYears } = apiCall();

const NewPeriodForm = ({ productivity, handleSubmit, submitText, editMode }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { redirect, setUrlToRedirect } = useRedirect();
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const { isViewer } = UserHelpers();
  const [loadingMonths, setLoadingMonths] = useState(false);
  const [loadingYears, setLoadingYears] = useState(false);

  const _getMonth = async () => {
    try {
      setLoadingMonths(true);
      const response = await getMonths();
      setMonths(response);
      setLoadingMonths(false);
    } catch (error) {
      setLoadingMonths(false);
      message.error(processedErrorMessage(error));
    }
  };

  const _getYears = async () => {
    try {
      setLoadingYears(true);
      const response = await getYears({ next: 5, last: 5 });
      setYears(response);
      setLoadingYears(false);
    } catch (error) {
      setLoadingYears(false);
      message.error(processedErrorMessage(error));
    }
  };

  const setProductivity = async values => {
    if (values && !isEmpty(values)) {
      const { billing, inventory, unitSales, year, month } = values;

      form.setFieldsValue({
        ...billing,
        ...inventory,
        ...unitSales,
        year,
        month
      });
    }
  };

  useEffect(() => {
    setProductivity(productivity);
  }, [productivity]);

  useEffect(() => {
    _getMonth();
    _getYears();
  }, []);

  return (
    <Form form={form} onFinish={handleSubmit}>
      {redirect()}
      <div className="page-content">
        <div className="title-new-period">PERIODO A INFORMAR</div>
        <span className="required">*Campos requeridos</span>
        <div className="productivity-input">
          <label className="input-label">
            Mes <span>*</span>
          </label>
          <Form.Item rules={[REQUIRED_FIELD_RULE]} name="month">
            <Select loading={loadingMonths} disabled={editMode} placeholder="Seleccione un Mes" style={{ width: '100%' }}>
              {months.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="productivity-input">
          <label className="input-label">
            Año <span>*</span>
          </label>
          <Form.Item rules={[REQUIRED_FIELD_RULE]} name="year">
            <Select loading={loadingYears} disabled={editMode} placeholder="Seleccione un Año" style={{ width: '100%' }}>
              {years.map(year => (
                <Option key={year} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="title-monthly-sales">VENTAS MENSUALES</div>
        <div className="monthly-sales-input">
          <label className="input-label">Pasajero Radial ≤ 16</label>
          <Form.Item rules={[]} name="radialPassenger16">
            <InputNumber min={0} className="input" placeholder="Ejm: 12" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Pasajero Radial 17</label>
          <Form.Item rules={[]} name="radialPassenger17">
            <InputNumber min={0} className="input" placeholder="Ejm: 17" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Pasajero Radial ≥18</label>
          <Form.Item rules={[]} name="radialPassenger18">
            <InputNumber min={0} className="input" placeholder="Ejm: 19" />
          </Form.Item>
        </div>
        {/*  Camioneta */}
        <div className="monthly-sales-input">
          <label className="input-label">Camioneta Radial ≤ 16</label>
          <Form.Item rules={[]} name="radialPickup16">
            <InputNumber min={0} className="input" placeholder="Ejm: 15" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Camioneta Radial 17</label>
          <Form.Item rules={[]} name="radialPickup17">
            <InputNumber min={0} className="input" placeholder="Ejm: 17" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Camioneta Radial ≥18</label>
          <Form.Item rules={[]} name="radialPickup18">
            <InputNumber min={0} className="input" placeholder="Ejm: 19" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Vehículos Atendidos</label>
          <Form.Item rules={[]} name="servicedVehicles">
            <InputNumber min={0} className="input" placeholder="Ejm: 1" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Vehículos Atendidos con Montaje</label>
          <Form.Item rules={[]} name="servicedVehiclesTireMounting">
            <InputNumber min={0} className="input" placeholder="Ejm: 3" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Q de alineaciones</label>
          <Form.Item rules={[]} name="alignments">
            <InputNumber min={0} className="input" placeholder="Ejm: 2" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Q de Boxes de Servicio</label>
          <Form.Item rules={[]} name="serviceBoxes">
            <InputNumber min={0} className="input" placeholder="Ejm: 4" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Q de Balanceos</label>
          <Form.Item rules={[]} name="balances">
            <InputNumber min={0} className="input" placeholder="Ejm: 4" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Días trabajados</label>
          <Form.Item rules={[]} name="workedDays">
            <InputNumber min={0} className="input" placeholder="Ejm: 4" />
          </Form.Item>
        </div>

        <div className="title-monthly-sales">FACTURACIÓN</div>
        <div className="monthly-sales-input">
          <label className="input-label">Neumáticos Mostrador</label>
          <Form.Item rules={[]} name="counterTires">
            <InputNumber min={0} step={0.01} className="input" placeholder="Ejm: 1.5" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Neumáticos Reventa</label>
          <Form.Item rules={[]} name="resaleTires">
            <InputNumber min={0} step={0.01} className="input" placeholder="Ejm: 0.2" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Repuestos y Accesorios</label>
          <Form.Item rules={[]} name="spareParts">
            <InputNumber min={0} step={0.01} className="input" placeholder="Ejm: 2.3" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Reconstrucción</label>
          <Form.Item rules={[]} name="rebuilds">
            <InputNumber min={0} step={0.01} className="input" placeholder="Ejm: 1.5" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Servicios Gomería</label>
          <Form.Item rules={[]} name="tireRepairServices">
            <InputNumber min={0} step={0.01} className="input" placeholder="Ejm: 1.3" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Servicios Mecánico</label>
          <Form.Item rules={[]} name="autoRepairServices">
            <InputNumber min={0} step={0.01} className="input" placeholder="Ejm: 1.2" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Varios</label>
          <Form.Item rules={[]} name="various">
            <InputNumber min={0} step={0.01} className="input" placeholder="Ejm: 3.2" />
          </Form.Item>
        </div>

        <div className="title-monthly-sales">INVENTARIO AL CIERRE</div>
        <div className="monthly-sales-input">
          <label className="input-label">Goodyear</label>
          <Form.Item rules={[]} name="goodyear">
            <InputNumber min={0} className="input" placeholder="Ejm: 4" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Kelly</label>
          <Form.Item rules={[]} name="kelly">
            <InputNumber min={0} className="input" placeholder="Ejm: 4" />
          </Form.Item>
        </div>
        <div className="monthly-sales-input">
          <label className="input-label">Otros</label>
          <Form.Item rules={[]} name="others">
            <InputNumber min={0} className="input" placeholder="Ejm: 4" />
          </Form.Item>
        </div>
      </div>

      <div className="new-period-btns-container">
        <ButtonSecondary
          text="CANCELAR"
          theme="btn-cancel"
          onClick={() => setUrlToRedirect(PRODUCTIVITY)}
        />
        <ButtonPrimary
          disabled={isViewer()}
          theme={isViewer() ? 'disabled' : 'btn-save'}
          text={submitText}
          type="submit"
        />
      </div>
    </Form>
  );
};

NewPeriodForm.propTypes = {
  productivity: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  submitText: PropTypes.string
};

NewPeriodForm.defaultProps = {
  submitText: 'GUARDAR',
  productivity: {},
  editMode: false
};

export default NewPeriodForm;
