import React from 'react';
import './style.scss';
import TitlePage from '../../atoms/TitlePage/TitlePage';
import RefundList from '../../organism/RefundList/RefundList';

const Refunds = () => {
  return (
    <div className="main-rendiciones-container">
      <TitlePage text="REEMBOLSOS" />
      <RefundList />
    </div>
  );
}

export default Refunds;
