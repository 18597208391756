import React from 'react';
import { Checkbox, InputNumber, Space } from 'antd';
import ButtonPrimary from '../../components/atoms/ButtonPrimary/button-primary';
import { DeleteOutlined, EyeFilled } from '@ant-design/icons';
import img from '../../img/gorra.jpg';
import star from '../../img/star.svg';
import { currencyFormat } from '../func-helpers';

export const columns = (onChange, removeFromCart) => [
  {
    title: 'PRODUCTO',
    dataIndex: 'product',
    key: 'image',
    render: ({ image }) => (
      <div
        style={{
          background: `url(${image}) no-repeat center center`,
          borderRadius: '4px',
          height: '80px'
        }}
      />
    )
  },
  {
    title: 'DESCRIPCIÓN',
    dataIndex: 'product',
    key: 'description',
    render: ({ title, description, code }) => (
      <div className="table-description">
        <p>{title}</p>
        <p>{description}</p>
        <p>CÓDIGO {code}</p>
      </div>
    )
  },
  {
    title: 'PUNTOS',
    dataIndex: 'product',
    key: 'points',
    render: ({ points }) => (
      <div className="tablecell-points">
        <img src={star} alt="" srcSet="" />
        <span>{points}</span>
      </div>
    )
  },
  {
    title: 'PRECIO',
    dataIndex: 'product',
    key: 'price',
    render: ({ price }) => <div>{currencyFormat(price)}</div>
  },
  {
    title: 'CANTIDAD',
    key: 'qty',
    render: ({ quantity, product }) => (
      <Space>
        <InputNumber min={1} defaultValue={quantity} onChange={value => onChange(product, value)} />
      </Space>
    )
  },
  {
    title: 'SUBTOTAL',
    dataIndex: 'subtotal',
    key: 'total',
    render: subtotal => <div>{currencyFormat(subtotal)}</div>
  },
  {
    title: '',
    dataIndex: 'product',
    key: 'action',
    render: ({ id, title }) => (
      <Space>
        <ButtonPrimary
          theme="cancel"
          iconLeft={<DeleteOutlined />}
          onClick={() => removeFromCart({ idProduct: id, title })}
        />
      </Space>
    )
  }
];
