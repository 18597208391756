import React from 'react';
import { currencyFormat } from '../func-helpers';
import img from '../../img/gorra.jpg';
import star from '../../img/star.svg';
import { PAYMENT_METHOD_POINTS } from '../constants';

export const columns = code => [
  {
    title: 'PRODUCTO',
    key: 'product',
    dataIndex: 'product',
    render: ({ image }) => (
      <div
        style={{
          background: `url(${image}) no-repeat center center`,
          backgroundSize: 'cover',
          width: '80px',
          borderRadius: '4px',
          height: '80px'
        }}
      />
    )
  },
  {
    title: 'DESCRIPCIÓN',
    key: 'description',
    dataIndex: 'product',
    render: ({ title, description, code }) => (
      <div className="table-description">
        <p>{title}</p>
        <p>{description}</p>
        <p>CÓDIGO {code}</p>
      </div>
    )
  },
  {
    title: 'PUNTOS',
    dataIndex: 'points',
    render: points => (
      <div className="points-container">
        <img src={star} alt="" />
        <p>{points}</p>
      </div>
    )
  },

  {
    title: 'PRECIO',
    dataIndex: 'price',
    render: price => <> {currencyFormat(price)}</>
  },
  {
    title: 'CANTIDAD PEDIDA',
    dataIndex: 'orderedQuantity'
  },
  {
    title: 'CANTIDAD REAL',
    dataIndex: 'quantity'
  },
  {
    title: 'TOTAL',
    render: ({ totalPrice, totalPoints }) =>
      code === PAYMENT_METHOD_POINTS ? <p>{totalPoints}</p> : <p>{currencyFormat(totalPrice)}</p>
  }
];

export default {
  columns
};
