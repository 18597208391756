import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OrderForm from '../OrderForm/OrderForm';
import { columns } from '../../../utils/tables/table-order-list-admin';
import { ADMIN_ORDER_LIST, PAGE_SIZE } from '../../../utils/constants';
import PaginatedTable from '../../molecules/PaginatedTable/paginated-table';
import { useRedirect } from '../../Router/redirect';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCalls from '../../../services/api-calls/all';
import { downloadPdf } from '../../../utils/func-helpers';
import { message } from 'antd';
import Spinner from '../../atoms/Spinner/Spinner';

const { exportOrder } = apiCalls();

const OrderList = ({ orders, filters }) => {
  const { redirect, setUrlToRedirect } = useRedirect();
  const [loading, setLoading] = useState(false);

  const handleOnEdit = id => {
    setUrlToRedirect(`${ADMIN_ORDER_LIST}/${id}`);
  };

  const handleOnDownload = async id => {
    try {
      setLoading(true);
      const fileData = await exportOrder(id);
      setLoading(false);
      downloadPdf(fileData.data, `resumenPedido${id}.pdf`);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  return (
    <Spinner loading={loading}>
      {redirect()}
      <PaginatedTable
        className="table-users"
        columns={columns(handleOnEdit, handleOnDownload)}
        onFetch={orders}
        pageSize={PAGE_SIZE}
        filters={filters}
        scroll={{ x: 420, y:300 }}
      />
    </Spinner>
  );
};

OrderForm.propTypes = {
  orders: PropTypes.array.isRequired
};

export default OrderList;
