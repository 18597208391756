import React from 'react';
import { Checkbox, Space } from 'antd';
import ButtonPrimary from '../../components/atoms/ButtonPrimary/button-primary';
import { DeleteOutlined, EyeFilled } from '@ant-design/icons';

export const columns = (onEdit, onDestroy, isViewer) => [
  {
    title: 'RAZÓN SOCIAL',
    dataIndex: 'registeredName',
    key: 'name'
  },
  {
    title: 'SUCURSAL',
    dataIndex: 'branchOfficeName'
  },
  {
    title: 'USUARIO',
    dataIndex: 'user'
  },
  {
    title: 'AÑO CARGADO',
    dataIndex: 'year'
  },
  {
    dataIndex: 'month',
    title: 'MES CARGADO',
    render: month => month.name
  },
  {
    title: 'ACCIONES',
    key: 'acciones',
    render: productivity => (
      <Space>
        {onEdit && (
          <ButtonPrimary
            theme="promo-btn"
            iconLeft={<EyeFilled />}
            onClick={() => onEdit(productivity.id)}
          />
        )}
        <ButtonPrimary
          disabled={isViewer}
          theme={isViewer ? 'disabled' : 'eliminar_btn'}
          iconLeft={<DeleteOutlined />}
          onClick={() => onDestroy(productivity)}
        />
      </Space>
    )
  }
];
