import React, { useEffect, useState } from 'react';
import './_style.scss';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DatePicker, Form, Input, InputNumber, Menu, Select } from 'antd';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import { SURVEYS } from '../../../utils/constants';
import { useRedirect } from '../../Router/redirect';
import TextArea from 'antd/es/input/TextArea';
import apiCall from '../../../services/api-calls/all';
import { REQUIRED_FIELD_RULE } from '../../../utils/validation-rules';
import { isEmpty } from '../../../utils/func-helpers';
import { UserHelpers } from '../../../utils/user-helpers';

const { getPaymentMethods, getResaleMethods, getBrands, getMeasures } = apiCall();
const menu = (
  <Menu>
    <Menu.Item key="0">
      <a href="">1</a>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="1">
      <a href="">2</a>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="3">3</Menu.Item>
  </Menu>
);
const SurveyForm = ({ survey, submitText, handleSubmit }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [selectValues, setSelectValues] = useState({});
  const { redirect, setUrlToRedirect } = useRedirect();
  const { isViewer } = UserHelpers();
  const [loading, setLoading] = useState(false);

  const initialState = async () => {
    try {
      setLoading(true);
      const response = await Promise.all([
        getPaymentMethods(),
        getResaleMethods(),
        getBrands(),
        getMeasures()
      ]);
      const [paymentMethods, resaleMethods, brands, measures] = response;
      setSelectValues({
        paymentMethods,
        resaleMethods,
        brands,
        measures
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    initialState();
  }, []);

  useEffect(() => {
    if (survey && !isEmpty(survey)) {
      const {
        brand,
        counterPaymentMethod,
        measure,
        resalePaymentMethod,
        surveyDate,
        ...rest
      } = survey;
      form.setFieldsValue({
        idMeasure: measure.id,
        idBrand: brand.id,
        idCounterPaymentMethod: counterPaymentMethod ? counterPaymentMethod.id : undefined,
        idResalePaymentMethod: resalePaymentMethod ? resalePaymentMethod.id : undefined,
        surveyDate: moment(surveyDate),
        ...rest
      });
    }
  }, [survey]);

  return (
    <Form form={form} onFinish={handleSubmit}>
      {redirect()}
      <div className="page-content">
        <p className="required-fields">* Campos requeridos</p>
        <div className="inputs-survey-container">
          <div className="inputs-survey">
            <div className="_input">
              <label className="input-label">
                Fecha de relevamiento <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name={'surveyDate'}>
                <DatePicker format="YYYY-MM-DD" className="datepicker" />
              </Form.Item>
            </div>
            <div className="_input">
              <label className="input-label">
                Medidas <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="idMeasure">
                <Select loading={loading} placeholder="Medidas" style={{ width: '100%' }}>
                  {selectValues.measures &&
                    selectValues.measures.map(({ id, description }) => (
                      <Option key={id} value={id}>
                        {description}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="_input">
              <label className="input-label">
                Fuente de información <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="sourceInformation">
                <Input placeholder="Fuente de información" />
              </Form.Item>
            </div>
            <div className="_input">
              <label className="input-label">
                Marca <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="idBrand">
                <Select loading={loading} placeholder="Marca" style={{ width: '100%' }}>
                  {selectValues.brands &&
                    selectValues.brands.map(({ id, description }) => (
                      <Option key={id} value={id}>
                        {description}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="text-area-survey">
            <label className="input-label">
              Diseño
            </label>
            <Form.Item rules={[]} name="design">
              <TextArea maxLength={100} rows={3} placeholder="Diseño" />
            </Form.Item>
          </div>
          <div className="inputs-survey">
            <div className="_input">
              <label className="input-label">Precio Mostrador</label>
              <Form.Item rules={[]} name="counterPrice">
                <InputNumber step={0.01} placeholder="Precio Mostrador" />
              </Form.Item>
            </div>
            <div className="_input">
              <label className="input-label">Forma de pago</label>
              <Form.Item rules={[]} name="idCounterPaymentMethod">
                <Select loading={loading} placeholder="Forma de Pago" style={{ width: '100%' }}>
                  {selectValues.paymentMethods &&
                    selectValues.paymentMethods.map(({ id, description }) => (
                      <Option key={id} value={id}>
                        {description}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="_input">
              <label className="input-label">Precio Reventa</label>
              <Form.Item rules={[]} name="resalePrice">
                <InputNumber step={0.01} placeholder="Precio Reventa" />
              </Form.Item>
            </div>
            <div className="_input">
              <label className="input-label">Forma de pago</label>
              <Form.Item rules={[]} name="idResalePaymentMethod">
                <Select loading={loading} placeholder="Forma de Pago" style={{ width: '100%' }}>
                  {selectValues.resaleMethods &&
                    selectValues.resaleMethods.map(({ id, description }) => (
                      <Option key={id} value={id}>
                        {description}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="text-area-survey">
            <label className="input-label">Observaciones</label>
            <Form.Item rules={[]} name="observations">
              <TextArea maxLength={100} rows={3} placeholder="Observaciones" />
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="buttons-container">
        <ButtonSecondary
          text="CANCELAR"
          theme="btn-cancel"
          onClick={() => setUrlToRedirect(SURVEYS)}
        />
        <ButtonPrimary
          disabled={isViewer()}
          theme={isViewer() ? 'disabled' : 'btn-save'}
          text={submitText}
          type="submit"
        />
      </div>
    </Form>
  );
};

SurveyForm.propTypes = {
  survey: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string
};

SurveyForm.defaultProps = {
  survey: {},
  submitText: 'GUARDAR'
};

export default SurveyForm;
