import React, { useState, useEffect, useRef } from 'react';
import Promotion from '../../molecules/Promotion/Promotion';
import apiCalls from '../../../services/api-calls/all';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import { DownloadOutlined } from '@ant-design/icons';
import PopUpPromotion from '../../molecules/PopUp/popUp';
import { message } from 'antd';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import { PAGE_SIZE } from '../../../utils/constants';
import { downloadPdf } from '../../../utils/func-helpers';

const { getPromotions, downloadFile } = apiCalls();
const PromotionCardList = ({setLoading}) => {
  const [promotionItem, setPromotionItem] = useState({});
  const [promotions, setPromotions] = useState([]);
  const modal = useRef();

  const handleSearchPromotions = async () => {
    try {
      setLoading(true);
      const { content } = await getPromotions({ page: 0, size: PAGE_SIZE });
      setPromotions(content);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const prepareDownloadPdf = async () => {
    const { file } = promotionItem;
    if (file) {
      try {
        setLoading(true);
        const fileData = await downloadFile(file.id);
        setLoading(false);
        downloadPdf(fileData, file.fileName);
      } catch (error) {
        setLoading(false);
        message.error(processedErrorMessage(error));
      }
    }
  };

  useEffect(() => {
    handleSearchPromotions();
  }, []);

  const Button = [
    <ButtonPrimary
      theme="button_descargar"
      text="DESCARGAR PRESENTACIÓN"
      iconLeft={<DownloadOutlined />}
      loading={'loading'}
      onClick={prepareDownloadPdf}
    />
  ];

  const showModal = id => {
    const promotion = promotions.find(promotion => promotion.id === id);
    setPromotionItem(promotion);
    modal.current.showModal();
  };

  const _renderPromotions = () =>
    promotions.map(promotion => (
      <div className="promocion-container" key={promotion.id}>
        <Promotion
          id={promotion.id}
          img={promotion.image}
          title={promotion.title}
          description={promotion.description}
          handleShow={showModal}
        />
      </div>
    ));

  return (
    <>
      {_renderPromotions()}
      <PopUpPromotion ref={modal} title={promotionItem.title} footer={Button}>
        <p>{promotionItem.description}</p>
      </PopUpPromotion>
    </>
  );
};

export default PromotionCardList;
