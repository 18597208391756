import helpers from './helpers';

const getMarkets = makeGetRequest => () => makeGetRequest('/markets');

export default client => {
  const { makeGetRequest } = helpers(client);
  return {
    getMarkets: getMarkets(makeGetRequest)
  };
};
