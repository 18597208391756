import React, { useContext } from 'react';
import { UserContext } from '../services/providers/user-context';

export const UserHelpers = () => {
  const { user } = useContext(UserContext);

  const canUpdate = () => !!user.actions.find(action => action === 'UPDATE');
  const canDelete = () => !!user.actions.find(action => action === 'DELETE');
  const canCreate = () => !!user.actions.find(action => action === 'CREATE');
  const hasPermissionFor = (permission) => user.permissions.includes(permission);
  const canView = () => !!user.actions.find(action => action === 'VIEW');
  const canAddToCart = () => !!user.actions.find(action => action === 'ADD_TO_CART');
  const isViewer = () => user.role === 'Viewer';
  const isCustomer = () => user.role === 'Customer';
  const isAdmin = () => user.role === 'Administrator';

  return { canUpdate, canDelete, canCreate, hasPermissionFor, canView, canAddToCart, isViewer, isCustomer, isAdmin };
};

export default UserHelpers;
