import React from 'react';
import './style.scss';

const Stock = ({ title, icon, quantity }) => (
  <div className="stock">
    <img src={icon} alt="" />
    <span>{`${title}:${quantity}`}</span>
  </div>
);

export default Stock;
