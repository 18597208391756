import React, { createContext, useState, useEffect, useContext } from 'react';
import apiCall from '../../services/api-calls/all';
import { message } from 'antd';
import { processedErrorMessage } from '../api-calls/helpers';
import { UserContext } from './user-context';
import { ACTION_ADD_TO_CART } from '../../utils/constants';

const CartContext = createContext({});
const { getCart, getCartStatus, addToCart, removeItemFromCart } = apiCall();
const CartProvider = ({ children }) => {
  const [cart, setCart] = useState({ subtotalPoints: 0, subtotalPrice: 0, items: [] });
  const { user } = useContext(UserContext);
  const [cartStatus, setCartStatus] = useState({ amountProducts: 0, products: [] });

  const clearCart = () => {
    setCart({ subtotalPoints: 0, subtotalPrice: 0, items: [] });
    setCartStatus({ amountProducts: 0, products: [] });
  };

  const loadCart = async () => {
    try {
      const response = await getCart();
      setCart(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const loadCartStatus = async () => {
    try {
      const response = await getCartStatus();
      setCartStatus(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const addItemToCart = parameters =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await addToCart(parameters);
        loadCartStatus();
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });

  const removeFromCart = parameters =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await removeItemFromCart(parameters);
        loadCartStatus();
        loadCart();
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  useEffect(() => {
    if (user.accessToken) {
      const { actions } = user;
      const hasPrimaryNavbarCart = actions.find(action => action === ACTION_ADD_TO_CART);
      if (hasPrimaryNavbarCart) loadCartStatus();
    }
  }, [user]);

  return (
    <CartContext.Provider
      value={{ cart, cartStatus, clearCart, loadCart, addItemToCart, removeFromCart }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
