import React, { useEffect, useState } from 'react';
import { Input, message, Select } from 'antd';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import PaginatedTable from '../../molecules/PaginatedTable/paginated-table.jsx';
import { columns } from '../../../utils/tables/table-expense-client';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCalls from '../../../services/api-calls/all';

import { ADMIN_USER_LIST_URL, PAGE_SIZE, RENDITIONS_CREATE } from '../../../utils/constants';
import { useRedirect } from '../../Router/redirect';
import { UserHelpers } from '../../../utils/user-helpers';
import { downloadPdf } from '../../../utils/func-helpers';
import Spinner from '../../atoms/Spinner/Spinner';

const {
  getExpenseReport,
  getStatesExpenseReport,
  deleteExpenseReport,
  sendExpenseReport,
  getMonths,
  getYears,
  downloadFile
} = apiCalls();
const { Search } = Input;

const ExpenseList = () => {
  const [search, setSearch] = useState('');
  const [months, setMonths] = useState([]);
  const [monthsLoading, setMonthsLoading] = useState(false);
  const [years, setYears] = useState([]);
  const [yearsLoading, setYearsLoading] = useState(false);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [states, setStates] = useState([]);
  const [statesSelected, setStatesSelected] = useState([-1]);
  const [reset, setReset] = useState(false);
  const filters = [search, month, year, statesSelected, reset];
  const { isViewer } = UserHelpers();
  const { redirect, setUrlToRedirect } = useRedirect();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);

  const handleOnEdit = id => {
    setUrlToRedirect(`${ADMIN_USER_LIST_URL}/${id}`);
  };

  const expenseFiltered = async (page, pageSize, onResult) => {
    try {
      setLoading(true);
      const filter = {
        page,
        size: pageSize,
        search,
        month,
        year,
        states: statesSelected && !statesSelected.includes(-1) ? statesSelected.join(',') : null
      };
      const response = await getExpenseReport(filter);
      setLoading(false);
      onResult(response);
    } catch (error) {
      setLoading(false);
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  const _getMonths = async () => {
    try {
      setMonthsLoading(true);
      const response = await getMonths();
      setMonths(response);
      setMonthsLoading(false);
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      setMonthsLoading(false);
      message.error(errorMessage);
    }
  };
  const _getYear = async () => {
    try {
      setYearsLoading(true);
      const response = await getYears({ next: 5, last: 2 });
      setYears(response);
      setYearsLoading(false);
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      setYearsLoading(false);
      message.error(errorMessage);
    }
  };

  const _getStates = async () => {
    try {
      setLoading(true);
      const response = await getStatesExpenseReport();
      setStates(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const handleStates = categoryId => {
    // reset other pills
    if (categoryId === -1) setStatesSelected([-1]);
    else {
      const selected = statesSelected.includes(-1) ? [] : statesSelected;
      const exist = selected.indexOf(categoryId);
      exist > -1 ? selected.splice(exist, 1) : selected.push(categoryId);
      console.log(selected);
      setStatesSelected([...new Set(selected)]);
    }
  };

  const prepareDownload = async (id, fileName) => {
    try {
      setLoading(true);
      const fileData = await downloadFile(id);
      setLoading(false);
      downloadPdf(fileData, fileName);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const handleSendExpense = async id => {
    try {
      setLoading(true);
      await sendExpenseReport(id);
      setLoading(false);
      setReset(!reset);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const handleDeleteExpense = async id => {
    try {
      setLoading(true);
      await deleteExpenseReport(id);
      setLoading(false);
      setReset(!reset);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  useEffect(() => {
    if (statesSelected.length === 0) setStatesSelected([-1]);
  }, [statesSelected]);

  useEffect(() => {
    _getMonths();
    _getYear();
    _getStates();
  }, []);

  return (
    <Spinner loading={loading}>
      {redirect()}
      <div className="expense-list-form-container">
        <div className="inputs-container">
          <div className="search-container-renditions">
            <Search
              className="search_btn"
              placeholder="Buscar"
              enterButton
              onSearch={value => setSearch(value)}
            />
          </div>
          <div className="input-item">
            <Select placeholder="Mes" onChange={value => setMonth(value)} loading={monthsLoading}>
              <Option key={0} value={''}>
                TODOS
              </Option>
              {months.map(month => (
                <Option key={month.id} value={month.id}>
                  {month.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="input-item">
            <Select placeholder="Año" onChange={value => setYear(value)} loading={yearsLoading}>
              <Option key={0} value={''}>
                TODOS
              </Option>
              {years.map(year => (
                <Option key={year} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </div>
          <div className="btn-create">
            <ButtonPrimary
              disabled={isViewer()}
              theme={isViewer() ? 'disabled' : 'btn-blue'}
              onClick={() => setUrlToRedirect(RENDITIONS_CREATE)}
              text="CREAR NUEVA RENDICIÓN"
            />
          </div>
        </div>
      </div>
      <div className="renditions-filters-container">
        <ButtonPrimary
          text="TODOS"
          onClick={() => handleStates(-1)}
          theme={statesSelected.includes(-1) ? 'filters-style active' : 'filters-style'}
        />
        {states.map(state => (
          <ButtonPrimary
            key={state.id}
            text={state.description}
            onClick={() => handleStates(state.id)}
            theme={statesSelected.includes(state.id) ? 'filters-style active' : 'filters-style'}
          />
        ))}
      </div>
      <div className="page-content">
        <PaginatedTable
          className="table-users"
          columns={columns(handleSendExpense, handleDeleteExpense, isViewer, prepareDownload)}
          onFetch={expenseFiltered}
          pageSize={PAGE_SIZE}
          filters={filters}
          scroll={{ x: 420, y: 300 }}
        />
      </div>
    </Spinner>
  );
};

export default ExpenseList;
