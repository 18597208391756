import React from 'react';
import { Form, InputNumber, Space } from 'antd';
import ButtonPrimary from '../../components/atoms/ButtonPrimary/button-primary';
import { EyeFilled, DeleteOutlined, DownloadOutlined, CheckCircleFilled } from '@ant-design/icons';
import star from '../../img/star.png';
import { currencyFormat } from '../func-helpers';
import { MAX_VALUE, NUMBER_FIELD_RULE, REQUIRED_FIELD_RULE } from '../validation-rules';

export const columns = (
  onEdit,
  canView,
  onChangeQty,
  validQuantity,
  buttonText,
  validStateOrder,
  productsConfirmed
) => [
  {
    title: 'PRODUCTO',
    key: 'product',
    dataIndex: 'product',
    render: ({ image }) => (
      <div
        style={{
          background: `url(${image}) no-repeat center center`,
          backgroundSize: 'cover',
          width: '80px',
          borderRadius: '4px',
          height: '80px'
        }}
      />
    )
  },
  {
    title: 'DESCRIPCIÓN',
    key: 'description',
    dataIndex: 'product',
    render: ({ title, description, code }) => (
      <div className="table-description">
        <p>{title}</p>
        <p>{description}</p>
        <p>CÓDIGO {code}</p>
      </div>
    )
  },
  {
    title: 'PUNTOS',
    key: 'points',
    dataIndex: 'product',
    render: ({ points }) => (
      <div className="points-container">
        <img src={star} alt="" />
        <p>{points}</p>
      </div>
    )
  },
  {
    title: 'PRECIO',
    dataIndex: 'product',
    key: 'price',
    render: ({ price }) => <div>{currencyFormat(price)}</div>
  },
  {
    title: 'CANTIDAD',
    dataIndex: 'orderedQuantity',
    key: 'quantity'
  },
  {
    title: 'CANT. REAL',
    key: 'realQuantity',
    render: ({ quantity, product, confirmed }) => (
      <Form.Item
        rules={[REQUIRED_FIELD_RULE, NUMBER_FIELD_RULE, MAX_VALUE(quantity)]}
        name={`qty${product.id}`}
      >
        <InputNumber
          disabled={
            confirmed || canView || validStateOrder || productsConfirmed.includes(product.id)
          }
          min={0}
          onChange={value => onChangeQty({ product, quantity }, value)}
        />
      </Form.Item>
    )
  },
  {
    title: 'TOTAL',
    dataIndex: 'product',
    key: 'total',
    render: ({ price, id }) => <div>{currencyFormat(price * validQuantity[`productQty${id}`])}</div>
  },
  {
    title: 'ACCIONES',
    key: 'total',
    render: ({ product, confirmed }) => (
      <ButtonPrimary
        disabled={
          confirmed ||
          canView ||
          (onEdit && validQuantity[`product${product.id}`]) ||
          validStateOrder ||
          productsConfirmed.includes(product.id)
        }
        theme={
          confirmed ||
          canView ||
          (onEdit && validQuantity[`product${product.id}`]) ||
          validStateOrder ||
          productsConfirmed.includes(product.id)
            ? 'disabled'
            : ''
        }
        iconLeft={<CheckCircleFilled />}
        text={buttonText(product.id)}
        onClick={async () => {
          await onEdit(product.id);
          buttonText(product.id);
        }}
      />
    )
  }
];
