import React, { useState } from 'react';
import './style.scss';
import TitlePage from '../../atoms/TitlePage/TitlePage';
import PromotionCardList from '../../organism/PromotionCardList/PromotionsCardList';
import Spinner from '../../atoms/Spinner/Spinner';

const Promotions = () => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <TitlePage text="PROMOCIONES" />
      <Spinner loading={loading}>
        <div className="promociones-container">
          <PromotionCardList setLoading={setLoading} />
        </div>
      </Spinner>
    </>
  );
};

export default Promotions;
