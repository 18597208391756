import React from 'react';
import { Checkbox, Space } from 'antd';
import ButtonPrimary from '../../components/atoms/ButtonPrimary/button-primary';
import { EyeFilled } from '@ant-design/icons';

export const columns = (onEdit, onChangeStatus) => [
  {
    title: 'NOMBRE',
    dataIndex: 'name',
    key: 'name',
    width: 200
  },
  {
    title: 'APELLIDO',
    key: 'lastName',
    dataIndex: 'lastName',
    width: 150
  },
  {
    title: 'USUARIO',
    dataIndex: 'username',
    key: 'username',
    width: 250
  },
  {
    title: 'CLIENTE',
    dataIndex: 'customer',
    key: 'customer',
    width: 150,
    render: customer => customer.registeredName
  },
  {
    dataIndex: 'branchOffice',
    title: 'SUCURSAL',
    key: 'branchOffice',
    width: 150,
    render: branchOffice => branchOffice.name
  },
  {
    dataIndex: 'phone',
    title: 'TELÉFONO',
    width: 150,
    key: 'phone'
  },
  {
    dataIndex: 'role',
    title: 'TIPO DE USUARIO',
    key: 'role',
    width: 150,
    render: role => role.description
  },
  {
    title: 'ACCIONES',
    key: 'acciones',
    width: 200,
    render: ({ id, active }) => (
      <Space>
        {onEdit && (
          <ButtonPrimary
            theme="promo-btn"
            iconLeft={<EyeFilled />}
            text="VER USUARIO"
            onClick={() => onEdit(id)}
          />
        )}
        {onEdit && (
          <Checkbox
            key={`checkbox${id}`}
            defaultChecked={active}
            onChange={() => onChangeStatus(id, !active)}
          />
        )}
      </Space>
    )
  }
];
