import React, { useEffect, useState } from 'react';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import History from '../../../atoms/Breadcrumb/history';
import { message } from 'antd';
import CatalogueForm from '../../../organism/CatalogueForm/CatalogueForm';
import { useRedirect } from '../../../Router/redirect';
import { ADMIN_CATALOGUE_LIST_URL } from '../../../../utils/constants';
import { processedErrorMessage } from '../../../../services/api-calls/helpers';
import apiCall from '../../../../services/api-calls/all';

const breadcrumbs = [
  {
    title: 'Parametría Catálogo',
    href: ADMIN_CATALOGUE_LIST_URL
  },
  {
    title: 'Editar Producto',
    href: ''
  }
];
const { showProduct, editProduct } = apiCall();

const CatalogueEdit = ({ match }) => {
  const { params } = match;
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});
  const { redirect, setUrlToRedirect } = useRedirect();

  const handleShowProduct = async () => {
    try {
      const response = await showProduct(params.id);
      setProduct({
        ...response
      });
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  useEffect(() => {
    handleShowProduct();
  }, []);

  const handleEdit = async ({ image, ...rest }) => {
    if (loading) return;
    setLoading(true);
    try {
      const formData = new FormData();
      formData.set('productProperties', JSON.stringify(rest));
      if (image) {
        image.name ? formData.set('image', image) : formData.set('image', new Blob());
      } else {
        formData.set('image', null);
      }

      await editProduct(params.id, formData);
      message.success('Actualización realizada con éxito');
      setLoading(false);
      setUrlToRedirect(ADMIN_CATALOGUE_LIST_URL);
    } catch (error) {
      const { data } = error.response;
      const errorMessage = processedErrorMessage(error, data);
      message.error(errorMessage);
      setLoading(false);
    }
  };

  return (
    <div>
      {redirect()}
      <History breadcrumbs={breadcrumbs} />
      <div className="title">
        <TitlePage text="ACTUALIZAR PRODUCTO" />
      </div>
      <CatalogueForm product={product} submitText={'ACTUALIZAR'} handleSubmit={handleEdit} />
    </div>
  );
};

export default CatalogueEdit;
