import helpers from './helpers';

const createRefund = makePostFileRequest => data => makePostFileRequest('/refund', data);

const getRefund = makeGetRequest => data => makeGetRequest('/refund', data);

const getRefundById = makeGetRequest => id => makeGetRequest(`/refund/${id}`);

const updateRefund = makePutFileRequest => (id, data) => makePutFileRequest(`/refund/${id}`, data);

const confirmRefund = makePutRequest => id => makePutRequest(`/refund/${id}/confirm`);

const rejectRefund = makePutRequest => id => makePutRequest(`/refund/${id}/reject`);

const getRefundStates = makeGetRequest => () => makeGetRequest('/refund/states');

export default client => {
  const { makePostFileRequest, makeGetRequest, makePutFileRequest, makePutRequest } = helpers(client);
  return {
    createRefund: createRefund(makePostFileRequest),
    getRefund: getRefund(makeGetRequest),
    getRefundById: getRefundById(makeGetRequest),
    updateRefund: updateRefund(makePutFileRequest),
    confirmRefund: confirmRefund(makePutRequest),
    rejectRefund: rejectRefund(makePutRequest),
    getRefundStates: getRefundStates(makeGetRequest),
  };
};
