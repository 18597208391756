import React from 'react';
import { Space } from 'antd';
import ButtonPrimary from '../../components/atoms/ButtonPrimary/button-primary';
import { EyeFilled } from '@ant-design/icons';

export const columns = onEdit => [
  {
    title: 'CÓDIGO SAP',
    key: 'sapCode',
    dataIndex: 'sapCode',
    width: 150
  },
  {
    title: 'RAZÓN SOCIAL',
    dataIndex: 'registeredName',
    key: 'registeredName',
    width: 200
  },
  {
    title: 'RESPONSABLE',
    key: 'responsable',
    dataIndex: 'responsibleEmail',
    width: 250
  },
  {
    title: 'PROVINCIA',
    key: 'provincia',
    dataIndex: 'state',
    width: 150,
    render: state => state.name

  },
  {
    title: 'LOCALIDAD',
    key: 'localidad',
    dataIndex: 'city',
    width: 150,
    render: city => city.name
  },
  {
    title: 'DIRECCIÓN',
    dataIndex: 'taxAddress',
    key: 'taxAddress',
    width: 150,
  },
  {
    title: 'TELÉFONO',
    dataIndex: 'branchOffice',
    key: 'branchOffice',
    width: 150,
    render: branchOffice => branchOffice.responsiblePhone
  },
  {
    title: 'SUCURSAL PRINCIPAL',
    key: 'sucursal',
    dataIndex: 'branchOffice',
    width: 200,
    render: branchOffice => branchOffice.name
  },
  {
    dataIndex: 'id',
    title: 'ACCIONES',
    key: 'acciones',
    width: 200,
    render: id => (
      <Space>
        {onEdit && (
          <ButtonPrimary
            theme="promo-btn"
            iconLeft={<EyeFilled />}
            text="CLIENTE"
            onClick={() => onEdit(id)}
          />
        )}
      </Space>
    )
  }
];
