import React, { useState, useEffect } from 'react';
import './_style.scss';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import CatalogueCardsAdmin from '../../../molecules/CatalogueCardsADMIN/catalogue-cards-admin';
import ButtonPrimary from '../../../atoms/ButtonPrimary/button-primary';
import { message, Pagination, Select } from 'antd';
import apiCalls from '../../../../services/api-calls/all';
import { processedErrorMessage } from '../../../../services/api-calls/helpers';
import { ADMIN_CATALOGUE_CREATE } from '../../../../utils/constants';
import { useRedirect } from '../../../Router/redirect';
import { currencyFormat } from '../../../../utils/func-helpers';
import { UserHelpers } from '../../../../utils/user-helpers';
import ModalImportProducts from '../../../molecules/ModalCatalogueImportProducts/modal-catalogue-import-products';
import Spinner from '../../../atoms/Spinner/Spinner';

const { getProductsFiltered, getProductsCategories, enableProduct } = apiCalls();
const { Option } = Select;

const CatalogueAdmin = () => {
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [size, setSize] = useState(0);
  const { redirect, setUrlToRedirect } = useRedirect();
  const { canCreate } = UserHelpers();
  const [loading, setloading] = useState(false);

  const getProducts = async (page, pageSize = 12) => {
    try {
      setloading(true);
      const filter = {
        page,
        size: pageSize,
        idProductCategory: category ? category : null
      };
      const response = await getProductsFiltered(filter);
      setProducts(response.content);
      setTotalProducts(response.totalElements);
      setSize(response.size);
      setloading(false);
    } catch (error) {
      setloading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const getCategories = async () => {
    try {
      setCategoriesLoading(true);
      const response = await getProductsCategories();
      setCategories(response);
      setCategoriesLoading(false);
    } catch (error) {
      setCategoriesLoading(false);
      message.error(processedErrorMessage(error));
    }
  };
  useEffect(() => {
    onChange(1);
  }, [category]);

  useEffect(() => {
    getCategories();
  }, []);

  const enableProductHandle = async (id, enable) => {
    try {
      setloading(true);
      await enableProduct(id, enable);
      setProducts(products.map(p => (p.id === id ? { ...p, enable } : p)));
      setloading(false);
    } catch (error) {
      setloading(false);
      message.error(processedErrorMessage(error));
    }
  };
  const prepareProducts = () =>
    products.map(product => (
      <CatalogueCardsAdmin
        key={product.id}
        id={product.id}
        code={product.code}
        img={product.image}
        title={product.title}
        description={product.description}
        puntos={product.points}
        stock={product.availableStock}
        precio={currencyFormat(product.price)}
        enable={product.enable}
        enableAction={enableProductHandle}
      />
    ));

  const onChange = value => {
    getProducts(value - 1);
    setPageNumber(value);
  };

  const renderProducts = () => <>{prepareProducts()}</>;

  return (
    <Spinner loading={loading}>
      <div className="catalogue-page-container">
        {redirect()}
        <TitlePage text="PARAMETRÍA CATÁLOGO" />
        <div className="filters-container">
          <Select loading={categoriesLoading} placeholder="Filtrar por" onChange={setCategory}>
            {categories.map(category => (
              <Option key={category.id} value={category.id}>
                {category.description}
              </Option>
            ))}
            <Option key={0} value={0}>
              Todas
            </Option>
          </Select>
          <div>
            {canCreate() ? (
              <ButtonPrimary
                text="AGREGAR PRODUCTO"
                theme="btns-product"
                onClick={() => setUrlToRedirect(ADMIN_CATALOGUE_CREATE)}
              />
            ) : (
              ''
            )}
            <ModalImportProducts getProducts={getProducts} />
          </div>
        </div>
        <div className="cards-container">
          {products.length > 0 ? renderProducts() : 'No hay productos disponibles'}
        </div>
        {totalProducts > size ? (
          <Pagination
            total={totalProducts}
            current={pageNumber}
            pageSize={size}
            onChange={onChange}
          />
        ) : (
          ''
        )}
      </div>
    </Spinner>
  );
};

export default CatalogueAdmin;
