import React from 'react';
import './style.scss';
import { DownloadOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import PropTypes from 'prop-types';

const Price = ({ img, description, colorTag, tagText, handleDownload }) => (
  <>
    <div
      style={{
        background: `url(${img}) no-repeat center center`,
        backgroundSize: 'cover',
        borderRadius: '100px',
        height: '60px',
        width: '60px'
      }}
    ></div>
    <div className="description">
      <p>{description}</p>
    </div>
    <div className="tag">
      <Tag color={colorTag}>{tagText}</Tag>
    </div>
    <div className="btn-download">
      <ButtonPrimary onClick={handleDownload} text="DESCARGAR" iconLeft={<DownloadOutlined />} />
    </div>
  </>
);

Price.propTypes = {
  handleDownload: PropTypes.func.isRequired
};

export default Price;
