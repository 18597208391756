import helpers from './helpers';

const getAros = makeGetRequest => () => makeGetRequest('/aros');

export default client => {
  const { makeGetRequest } = helpers(client);
  return {
    getAros: getAros(makeGetRequest)
  };
};
