import helpers from './helpers';

const showOrder = makeGetRequest => id => makeGetRequest(`orders/${id}`);
const getOrders = makeGetRequest => data => makeGetRequest('orders/', data);
const getOrderStates = makeGetRequest => () => makeGetRequest('orders/states');
const updateQuantityProduct = makePutRequest => (id, idProduct, data) =>
  makePutRequest(`orders/${id}/products/${idProduct}/update-quantity`, data);

const confirmProduct = makePutRequest => (id, idProduct) =>
  makePutRequest(`orders/${id}/products/${idProduct}/confirm`);

const rejectOrder = makePutRequest => id => makePutRequest(`orders/${id}/reject`);
const confirmOrder = makePutRequest => id => makePutRequest(`orders/${id}/confirm`);
const getOrderDetails = makeGetRequest => id => makeGetRequest(`orders/${id}/details`);
const getOrderProfile = makeGetRequest => data => makeGetRequest('orders/profile', data);

export default client => {
  const { makeGetRequest, makePutRequest } = helpers(client);
  return {
    getOrders: getOrders(makeGetRequest),
    getOrderStates: getOrderStates(makeGetRequest),
    showOrder: showOrder(makeGetRequest),
    confirmOrder: confirmOrder(makePutRequest),
    confirmProduct: confirmProduct(makePutRequest),
    rejectOrder: rejectOrder(makePutRequest),
    updateQuantityProduct: updateQuantityProduct(makePutRequest),
    getOrderDetails: getOrderDetails(makeGetRequest),
    getOrderProfile: getOrderProfile(makeGetRequest)
  };
};
