import React, {useState, useEffect} from 'react';
import apiCall from '../../../services/api-calls/all'

const { getAppVersion } = apiCall();

const Footer = () => {
  const [appVersion, setAppVersion] = useState('');

  const _getAppVersion = async () => {
    try {
      const response = await getAppVersion();
      setAppVersion(response);
    }
    catch (error) {}
  }

  useEffect(() => {
    _getAppVersion()
  }, []);

  return <div className="AppFooter">{appVersion}</div>;
};

export default Footer;
