import React from 'react';
import './style.scss';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import PropTypes from 'prop-types';

const Promotion = ({ id, img, title, description, handleShow }) => (
  <div className="main-container">
    <div
      style={{
        background: `url(${img}) no-repeat center center`,
        backgroundSize: 'cover',
        height: '150px'
      }}
    />

    <div className="blue-container">
      <p>{title}</p>
    </div>
    <div className="text-container">
      <div className="descripcion-container">
        <p className="descripcion">{description}</p>
      </div>
      <div>
        <ButtonPrimary onClick={() => handleShow(id)} text="VER PROMOCIÓN" theme="btn_promocion" />
      </div>
    </div>
  </div>
);

Promotion.propTypes = {
  handleShow: PropTypes.func.isRequired
};

export default Promotion;
