import React, { useEffect, useState } from 'react';
import './_style.scss';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import History from '../../../atoms/Breadcrumb/history';
import NewForm from '../../../organism/NewForm/NewForm';

import apiCall from '../../../../services/api-calls/all';
import { message } from 'antd';
import { processedErrorMessage } from '../../../../services/api-calls/helpers';
import { ADMIN_NEWS_URL } from '../../../../utils/constants';
import Spinner from '../../../atoms/Spinner/Spinner';

const { showNew } = apiCall();

const NewsEdit = ({ match }) => {
  const { params } = match;
  const [newItem, setNewItem] = useState({});
  const [loading, setLoading] = useState(false);

  const handleShowNew = async () => {
    try {
      setLoading(true);
      const response = await showNew(params.id);
      setNewItem(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  useEffect(() => {
    handleShowNew();
  }, []);

  const breadcrumbs = [
    {
      title: 'Parametría Novedades',
      href: ADMIN_NEWS_URL,
    },
    {
      title: 'Editar Novedad',
      href: '',
    }
  ];

  return (
    <Spinner loading={loading}>
      <History breadcrumbs={breadcrumbs} />
      <TitlePage text="EDITAR NOVEDAD" />
      {newItem.hasOwnProperty('id') ? <NewForm newItem={newItem} /> : ''}
    </Spinner>
  );
};

export default NewsEdit;
