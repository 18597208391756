import helpers from './helpers';

const unreadNotifications = makeGetRequest => () => makeGetRequest('/notifications/unread');

const readNotificationIdentifiedBy = makePutRequest => id => makePutRequest(`/notifications/${id}/read`);

const readAllNotifications = makePutRequest => () => makePutRequest('/notifications/read');

export default client => {
  const { makeGetRequest, makePutRequest } = helpers(client);
  return {
    unreadNotifications: unreadNotifications(makeGetRequest),
    readNotificationIdentifiedBy: readNotificationIdentifiedBy(makePutRequest),
    readAllNotifications: readAllNotifications(makePutRequest)
  };
};
