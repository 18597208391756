import helpers from './helpers';

const getPriceLists = makeGetRequest => data => makeGetRequest('price-lists/current', data);

const getPriceListsFiltered  = makeGetRequest => data => makeGetRequest('price-lists/filtered', data);

const showPriceList = makeGetRequest => id => makeGetRequest(`price-lists/${id}`);

const createPriceList = makePostFileRequest => (data) => makePostFileRequest(`price-lists`, data);

const editPriceList = makePutFileRequest => (id, data) => makePutFileRequest(`price-lists/${id}`, data);

const destroyPriceList = makeDeleteRequest => (id) => makeDeleteRequest(`price-lists/${id}`);

const getPriceListPbus = makeGetRequest => () => makeGetRequest(`price-lists/producer-business-units`);

export default client => {
  const { makeGetRequest, makePostFileRequest, makePutFileRequest, makeDeleteRequest } = helpers(client);
  return {
    getPriceLists: getPriceLists(makeGetRequest),
    getPriceListsFiltered: getPriceListsFiltered(makeGetRequest),
    showPriceList: showPriceList(makeGetRequest),
    createPriceList: createPriceList(makePostFileRequest),
    editPriceList: editPriceList(makePutFileRequest),
    destroyPriceList: destroyPriceList(makeDeleteRequest),
    getPriceListPbus: getPriceListPbus(makeGetRequest)
  };
};