import React from 'react';
import './_style.scss';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import AdminPromotionLists from '../../../organism/AdminPromotionLists/AdminPromotionLists';

const Promotions = () => (
  <div>
    <TitlePage text="PARAMETRÍA PROMOCIONES" />
    <AdminPromotionLists />
  </div>
);

export default Promotions;
