import { Space, Tooltip } from 'antd';
import ButtonPrimary from '../../components/atoms/ButtonPrimary/button-primary';
import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  DownloadOutlined,
  EyeFilled,
  DollarCircleFilled
} from '@ant-design/icons';

import React from 'react';
import {
  PREAPPROVAL_APPROVED,
  PREAPPROVAL_DECLINED,
  EXPENSE_DRAFT,
  PREAPPROVAL_PENDING,
  PENDING,
  APPROVED,
  DECLINED
} from '../constants';
import { dateFormat } from '../func-helpers';

export const columns = ({
  onEdit,
  isDisabled,
  isConfirmed,
  prepareDownload,
  onConfirm,
  onReject,
  onCreateRefund
}) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 100
  },
  {
    title: 'VIGENCIA',
    width: 250,
    render: ({ fromDate, toDate }) => `${dateFormat(fromDate)} - ${dateFormat(toDate)}`
  },
  {
    title: 'NOMBRE',
    dataIndex: 'name',
    key: 'name',
    width: 200
  },
  {
    title: 'RAZÓN SOCIAL',
    dataIndex: 'registeredName',
    key: 'commercialName',
    width: 250
  },
  {
    title: 'TIPO',
    dataIndex: 'type',
    width: 150,
    render: ({ description }) => description
  },
  {
    title: 'REEMBOLSO',
    dataIndex: 'refund',
    width: 150,
    render: refund => {
      if (refund === undefined || refund === null || (refund && refund.id === null))
        return 'Ausente';
      return refund.idPreApproval;
    }
  },
  {
    title: 'ESTADO REEMBOLSO',
    dataIndex: 'refund',
    width: 200,
    align: 'center',
    render: ({ state }) => {
      if (state === undefined || state === null)
        return (
          <Tooltip title="Ausente">
            <img src="/img/absent.svg" />
          </Tooltip>
        );
      if (state.description === PENDING)
        return (
          <Tooltip title="Pendiente">
            <ClockCircleFilled style={{ size: '20px', color: '#FED102' }} />
          </Tooltip>
        );

      if (state.description === APPROVED)
        return (
          <Tooltip title="Confirmado">
            <CheckCircleFilled style={{ size: '20px', color: '#5BC535' }} />
          </Tooltip>
        );

      if (state.description === DECLINED)
        return (
          <Tooltip title="Rechazado">
            <CloseCircleFilled style={{ Size: '20px', color: '#FF2E2E' }} />
          </Tooltip>
        );
    }
  },
  {
    title: 'ESTADO',
    dataIndex: 'state',
    width: 100,
    align: 'center',
    render: ({ description }) => {
      if (description === PREAPPROVAL_PENDING)
        return (
          <Tooltip title="Pendiente">
            <ClockCircleFilled style={{ size: '15px', color: '#FED102' }} />
          </Tooltip>
        );

      if (description === PREAPPROVAL_APPROVED)
        return (
          <Tooltip title="Confirmado">
            <CheckCircleFilled style={{ size: '15px', color: '#5BC535' }} />
          </Tooltip>
        );

      if (description === PREAPPROVAL_DECLINED)
        return (
          <Tooltip title="Rechazado">
            <CloseCircleFilled style={{ Size: '15px', color: '#FF2E2E' }} />
          </Tooltip>
        );
    }
  },
  {
    title: 'ACCIONES',
    key: 'acciones',
    width: 300,
    render: ({ id, document, state, refund }) => {
      return (
        <Space>
          <ButtonPrimary
            theme="actions-preapproval-btn"
            iconLeft={<DownloadOutlined />}
            onClick={() => prepareDownload(document.id, document.fileName)}
          />
          <ButtonPrimary
            onClick={() => onEdit(id, state)}
            theme="promo-btn"
            iconLeft={<EyeFilled />}
          />
          <ButtonPrimary
            disabled={isDisabled(state)}
            theme={
              isDisabled(state) ? 'actions-preapproval-btn disabled' : 'actions-preapproval-btn'
            }
            iconLeft={<CheckCircleFilled />}
            onClick={() => onConfirm(id)}
          />
          <ButtonPrimary
            disabled={isDisabled(state)}
            theme={
              isDisabled(state) ? 'actions-preapproval-btn disabled' : 'actions-preapproval-btn'
            }
            iconLeft={<CloseCircleFilled />}
            onClick={() => onReject(id)}
          />
          <ButtonPrimary
            disabled={!isConfirmed(state) || refund.id !== null}
            theme={
              !isConfirmed(state) || refund.id !== null
                ? 'actions-preapproval-btn disabled'
                : 'actions-preapproval-btn'
            }
            iconLeft={
              <Tooltip title="Crear Reembolso">
                <DollarCircleFilled />
              </Tooltip>
            }
            onClick={() => onCreateRefund(id)}
          />
        </Space>
      );
    }
  }
];
