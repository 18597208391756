import { get } from 'lodash';
import {
  UNEXPECTED_ERROR,
  API_ERROR_401,
  API_ERROR_403,
  API_ERROR_500,
  STATUS_401,
  STATUS_403,
  STATUS_500
} from './messages.constants.json';

export const createQueryString = query =>
  Object.keys(query)
    .map(key => `${key}=${query[key] || ''}`)
    .join('&');

const replaceUrlParameters = (url, parametersData) => {
  let currentParameter = '';
  let isAdding = false;
  const parameters = [];

  for (const element of url) {
    if (element === '}') {
      isAdding = false;
    }
    if (isAdding) {
      currentParameter += element;
    } else if (currentParameter) {
      parameters.push(currentParameter);
      currentParameter = '';
    }
    if (element === '{') {
      isAdding = true;
    }
  }
  parameters.forEach(p => {
    url = url.replace(`{${p}}`, parametersData[p]);
  });

  return `${url}`;
};
const addQueryString = (url, query) => {
  const queryStringParameters = createQueryString(query || {});
  return query ? `${url}?${queryStringParameters}` : url;
};

const makeGetRequest = httpClient => (url, parameters, queryParameters) => {
  const completeUrl = addQueryString(url, queryParameters);
  return httpClient
    .get(completeUrl, {
      params: parameters
    })
    .then(response => response.data);
};

const makeGetFileRequest = httpClient => (url, parameters, queryParameters) => {
  const completeUrl = addQueryString(url, queryParameters);
  return httpClient
    .get(completeUrl, {
      params: parameters
    })
    .then(response => response);
};

const makePostRequest = httpClient => (url, bodyParameters = {}, queryParameters) => {
  const completeUrl = addQueryString(url, queryParameters);
  return httpClient.post(completeUrl, { ...bodyParameters });
};

const makePutRequest = httpClient => (url, bodyParameters = {}, queryParameters) => {
  const completeUrl = addQueryString(url, queryParameters);
  return httpClient.put(completeUrl, { ...bodyParameters });
};

const makePostFileRequest = httpClient => (url, formData, queryParameters) => {
  const completeUrl = addQueryString(url, queryParameters);
  return httpClient.post(completeUrl, formData);
};

const makePutFileRequest = httpClient => (url, formData, queryParameters) => {
  const completeUrl = addQueryString(url, queryParameters);
  return httpClient.put(completeUrl, formData);
};

const makeDeleteRequest = httpClient => (url, bodyParameters = {}, queryParameters) => {
  const completeUrl = addQueryString(url, queryParameters);
  return httpClient.delete(completeUrl, { ...bodyParameters });
};

const makePatchRequest = httpClient => (url, bodyParameters = {}, queryParameters) => {
  url = replaceUrlParameters(url, bodyParameters);

  const completeUrl = addQueryString(url, queryParameters);
  return httpClient.patch(completeUrl, { ...bodyParameters });
};

export default client => ({
  makeGetRequest: makeGetRequest(client),
  makePostRequest: makePostRequest(client),
  makePutRequest: makePutRequest(client),
  makePatchRequest: makePatchRequest(client),
  makeDeleteRequest: makeDeleteRequest(client),
  makePostFileRequest: makePostFileRequest(client),
  makePutFileRequest: makePutFileRequest(client),
  makeGetFileRequest: makeGetFileRequest(client)
});

export const processedErrorMessage = (error, messageText) => {
  const status = get(error, 'response.status');
  if (messageText) return messageText;
  if (!status) return UNEXPECTED_ERROR;
  switch (status) {
    case STATUS_401:
      return API_ERROR_401;
    case STATUS_403:
      return API_ERROR_403;
    case STATUS_500:
      return API_ERROR_500;
    default:
      return messageText || UNEXPECTED_ERROR;
  }
};
