import helpers from "./helpers";

const getStates = makeGetRequest => () => makeGetRequest(`states`);

const getCities = makeGetRequest => (idState) => makeGetRequest(`cities?idState=${idState}`);

export default client => {
  const { makeGetRequest } = helpers(client);
  return {
    getStates: getStates(makeGetRequest),
    getCities: getCities(makeGetRequest)
  };
};