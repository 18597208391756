import React, { useEffect, useState } from 'react';
import './_style.scss';
import { DatePicker, Menu, Dropdown, message, Button, Space, Input, Modal } from 'antd';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import PaginatedTable from '../../molecules/PaginatedTable/paginated-table.jsx';
import { columns } from '../../../utils/tables/table-price-lists-admin';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import { UserHelpers } from '../../../utils/user-helpers';
import apiCalls from '../../../services/api-calls/all';
import {
  ADMIN_PRICE_LIST_CREATE_URL,
  ADMIN_PRICE_LIST_URL,
  PAGE_SIZE
} from '../../../utils/constants';
import { useRedirect } from '../../Router/redirect';

import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { downloadPdf } from '../../../utils/func-helpers';
import Spinner from '../../atoms/Spinner/Spinner';

const { getPriceListsFiltered, getPriceListPbus, destroyPriceList, downloadFile } = apiCalls();
const { Search } = Input;
const { confirm } = Modal;
const { RangePicker } = DatePicker;

const Promotions = () => {
  const [name, setName] = useState('');
  const [state, setState] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [priceLists, setPriceLists] = useState([]);
  const [states, setStates] = useState([]);
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const filters = [dateFilter, state, name, reset];
  const { redirect, setUrlToRedirect } = useRedirect();
  const { canCreate, canUpdate, canDelete } = UserHelpers();

  const formatTableData = data =>
    data.content.map((priceList, i) => ({ key: (i + 1).toString(), ...priceList }));

  const handleOnEdit = id => {
    setUrlToRedirect(`${ADMIN_PRICE_LIST_URL}/${id}`);
  };

  const handleOnDestroy = id => {
    const priceList = priceLists.find(priceList => priceList.id === id);
    confirm({
      title: '¿Desea eliminar esta lista de precio?',
      content: priceList.name,
      async onOk() {
        try {
          setLoading(true);
          await destroyPriceList(id);
          setReset(!reset);
          setLoading(false);
        } catch (error) {
          const errorMessage = processedErrorMessage(error);
          setLoading(false);
          message.error(errorMessage);
        }
      },
      onCancel() {
        console.log('Cancel');
      }
    });
  };

  const prepareDownload = async id => {
    const priceList = priceLists.find(priceList => priceList.id === id);
    const { file } = priceList;
    if (file) {
      try {
        setLoading(true);
        const fileData = await downloadFile(file.id);
        setLoading(false);
        downloadPdf(fileData, file.fileName);
      } catch (error) {
        console.log(error);
        setLoading(false);
        message.error(processedErrorMessage(error));
      }
    }
  };

  const searchStates = async () => {
    try {
      setLoading(true);
      const response = await getPriceListPbus();
      setLoading(false);
      response.push({ id: 0, description: 'Todos' });
      setStates(response);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const populatePriceLists = async (page, pageSize, onResult) => {
    try {
      const filter = {
        page,
        size: pageSize,
        fromDate: dateFilter ? dateFilter[0] : null,
        toDate: dateFilter ? dateFilter[1] : null,
        name,
        idPBU: state ? state : null
      };
      setLoading(true);
      const response = await getPriceListsFiltered(filter);
      setLoading(false);
      onResult(response);
      setPriceLists(response.content);
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      setLoading(false);
      message.error(errorMessage);
    }
  };

  const _showState = () => {
    if (state) {
      const selectState = states.filter(stateItem => stateItem.id === state)[0];
      return selectState.description;
    }
    return 'PBU';
  };

  useEffect(() => {
    searchStates();
  }, []);

  return (
    <Spinner loading={loading}>
      {redirect()}
      <div className="inputs_container">
        <div className="search_btn">
          <Search
            className="search_btn"
            placeholder="Nombre"
            onSearch={value => setName(value)}
            enterButton
          />
        </div>
        <Space className="admin-pricelist">
          <div>
            <RangePicker
              format="YYYY-MM-DD"
              onChange={(date, dateString) => setDateFilter(dateString)}
            />
          </div>
          <div>
            <Dropdown
              overlay={
                <Menu
                  onClick={e => setState(parseInt(e.key))}
                  selectedKeys={[state ? state.toString() : null]}
                >
                  {states.map(state => (
                    <Menu.Item key={state.id} icon={<UserOutlined />}>
                      {state.description}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              className="dropdown_estados"
            >
              <Button>
                {_showState()} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          {canCreate() && (
            <div className="btn_crear">
              <ButtonPrimary
                theme="btn_blue"
                text="CREAR NUEVA LISTA DE PRECIOS"
                onClick={() => setUrlToRedirect(ADMIN_PRICE_LIST_CREATE_URL)}
              />
            </div>
          )}
        </Space>
      </div>
      <div className="page-content">
        <PaginatedTable
          className="table_promociones"
          columns={columns(
            canUpdate() ? handleOnEdit : null,
            canDelete() ? handleOnDestroy : null,
            prepareDownload
          )}
          onFetch={populatePriceLists}
          onFormat={formatTableData}
          pageSize={PAGE_SIZE}
          filters={filters}
          scroll={{ x: 800, y: '40vh' }}
        />
      </div>
    </Spinner>
  );
};

export default Promotions;
