import React, {useState,useEffect} from 'react';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import History from '../../../atoms/Breadcrumb/history';
import { message } from 'antd';
import UserForm from "../../../organism/UserForm/UserForm";
import { useRedirect } from '../../../Router/redirect';
import {ADMIN_USER_LIST_URL} from "../../../../utils/constants";
import {processedErrorMessage} from "../../../../services/api-calls/helpers";
import apiCall from "../../../../services/api-calls/all";

const { editUser } = apiCall();

const breadcrumbs = [
	{
		title: 'Parametría Usuarios',
		href: ADMIN_USER_LIST_URL
	},
	{
		title: 'Actualizar usuario',
		href: ''
	}
];

const { showUser } = apiCall();

const UserCreate = ({match}) => {
	const { params } = match;
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState({});
	const { redirect, setUrlToRedirect } = useRedirect();

	const handleShowUser = async () => {
		try {
			const response = await showUser(params.id);
			setUser({
				id: response.id,
				name: response.name,
				lastName: response.lastName,
				email: response.email,
				phone: response.phone,
				username: response.username,
				role: response.role.code,
				customer: response.customer,
				idBranchOffice: response.branchOffice.id,
				canSeeRestricted: response.canSeeRestricted
			});
		} catch (error) {
			message.error(processedErrorMessage(error));
		}
	};

	useEffect(() => {
		handleShowUser();
	}, []);

	const handleSubmit = async values => {

		if (loading) return;
		setLoading(true);
		Object.keys(values).map(key => {
	  	if(values.hasOwnProperty(key) && !values[key]) delete values[key];});//el bug que saca canSeeRestricted puede estar en este borrado
		
		  try {
			//fix para garantizar canSeeRestricted envie valor. existe un bug que lo saca del objeto si tiene false.
			let valuesFinal = values;
			if(!values.canSeeRestricted)
				valuesFinal = {...values, canSeeRestricted:false};

			await editUser(user.id, valuesFinal);
			message.success('Actualización realizada con éxito');
			setLoading(false);
			setUrlToRedirect(ADMIN_USER_LIST_URL);
		} catch (error) {
			const errorMessage = processedErrorMessage(error);
			message.error(errorMessage);
			setLoading(false);
		}
		
	};

	return (
		<div>
			{redirect()}
			<History breadcrumbs={breadcrumbs} />
			<div className="title">
				<TitlePage text="EDITAR USUARIO" />
			</div>
			<UserForm user={user} handleSubmit={handleSubmit} submitText='ACTUALIZAR'/>
		</div>
	)
}

export default UserCreate;
