import React, {useState} from 'react';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import History from '../../../atoms/Breadcrumb/history';
import { message } from 'antd';
import UserForm from "../../../organism/UserForm/UserForm";
import { useRedirect } from '../../../Router/redirect';
import {ADMIN_USER_LIST_URL} from "../../../../utils/constants";
import {processedErrorMessage} from "../../../../services/api-calls/helpers";
import apiCall from "../../../../services/api-calls/all";
import Spinner from '../../../atoms/Spinner/Spinner';

const { createUser } = apiCall();

const breadcrumbs = [
	{
		title: 'Parametría Usuarios',
		href: ''
	},
	{
		title: 'Crear nuevo usuario',
		href: ''
	}
];

const UserCreate = () => {
	const [loading, setLoading] = useState(false);
	const { redirect, setUrlToRedirect } = useRedirect();

	let newUser = null

	const handleCreate = async values => {
		if (loading) return;
		setLoading(true);
		try {
			//fix para garantizar canSeeRestricted envie valor. existe un bug que lo saca del objeto si tiene false.
			let valuesFinal = values;
			if(!values.canSeeRestricted)
				valuesFinal = {...values, canSeeRestricted:false};

			await createUser(valuesFinal);
			message.success('Acción realizada con éxito');
			setLoading(false);
			setUrlToRedirect(ADMIN_USER_LIST_URL);
		} catch (error) {
			const {data} = error.response
			const errorMessage = processedErrorMessage(error, data);
			message.error(errorMessage);
			setLoading(false);
		}
	};

	return (
		<Spinner loading={loading}>
			{redirect()}
			<History breadcrumbs={breadcrumbs} />
			<div className="title">
				<TitlePage text="CREAR NUEVO USUARIO" />
			</div>
			<UserForm user={newUser} handleSubmit={handleCreate}/>
		</Spinner>
	)
}

export default UserCreate;
