import React, { useState, useEffect } from 'react';
import Price from '../../molecules/Price/price';
import { message } from 'antd';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCalls from '../../../services/api-calls/all';
import { downloadPdf } from '../../../utils/func-helpers';
import { PAGE_SIZE } from '../../../utils/constants';
import Spinner from '../../atoms/Spinner/Spinner';

const { getPriceLists, downloadFile } = apiCalls();
export const PriceList = () => {
  const [priceLists, setPriceLists] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearchPrices = async () => {
    try {
      setLoading(true);
      const { content } = await getPriceLists({ page: 0, size: PAGE_SIZE });
      setPriceLists(content);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const prepareDownload = async file => {
    if (file) {
      try {
        setLoading(true);
        const fileData = await downloadFile(file.id);
        setLoading(false);
        downloadPdf(fileData, file.fileName);
      } catch (error) {
        setLoading(false);
        message.error(processedErrorMessage(error));
      }
    }
  };

  const __renderPriceListItem = () =>
    priceLists.map(({ id, name, image, producerBusinessUnit, file }) => (
      <div className="price-line" key={id}>
        <Price
          img={image}
          description={name}
          colorTag={producerBusinessUnit.description === 'Consumer' ? 'purple' : 'orange'}
          tagText={producerBusinessUnit.description}
          handleDownload={() => prepareDownload(file)}
        />
      </div>
    ));

  useEffect(() => {
    handleSearchPrices();
  }, []);

  return (
    <Spinner loading={loading}>
      <div className="page-content">
        {priceLists.length > 0 ? __renderPriceListItem() : 'No hay resultados'}
      </div>
    </Spinner>
  );
};
