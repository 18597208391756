import React from 'react';
import { Form, Input, Tooltip } from 'antd';
import {
  REQUIRED_FIELD_RULE,
  PASSWORD_RULE,
  CONFIRM_PASSWORD_RULE
} from '../../../utils/validation-rules';

const UserFormCreatePassword = () => {
  return (
    <>
      <div className="_input">
        <label className="input-label">
          Contraseña <span>*</span>
        </label>
        <Tooltip title="La password debe contener un minimo de 6 caracteres, al menos un numero y al menos una letra.">
          <Form.Item rules={[REQUIRED_FIELD_RULE, PASSWORD_RULE]} name="password">
            <Input.Password className="input" placeholder="Contraseña" />
          </Form.Item>
        </Tooltip>
      </div>
      <div className="_input">
        <label className="input-label">
          Confirmar contraseña <span>*</span>
        </label>
        <Form.Item
          rules={[REQUIRED_FIELD_RULE, PASSWORD_RULE, CONFIRM_PASSWORD_RULE]}
          dependencies={['password']}
          name="confirmPassword"
        >
          <Input.Password className="input" placeholder="Confirmar contraseña" />
        </Form.Item>
      </div>
    </>
  );
};

export default UserFormCreatePassword;
