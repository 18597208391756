import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, InputNumber, Popconfirm, Space, Table } from 'antd';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCall from '../../../services/api-calls/all';
import { DeleteOutlined, EyeFilled } from '@ant-design/icons';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import { UserHelpers } from '../../../utils/user-helpers';
import { REQUIRED_FIELD_RULE } from '../../../utils/validation-rules';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProperties
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input maxLength={20} />;
  return (
    <td {...restProperties}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[REQUIRED_FIELD_RULE]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const {
  getAdminSurveyBrands,
  saveAdminSurveyBrands,
  updateAdminSurveyBrands,
  deleteAdminSurveyBrands
} = apiCall();
const AdminSurveyBrandsTable = ({setLoading}) => {
  const [form] = Form.useForm();
  const [brands, setBrands] = useState([]);
  const [editingID, setEditingID] = useState('');
  const { isViewer } = UserHelpers();

  const isEditing = record => record.id === editingID;

  const edit = record => {
    form.setFieldsValue({
      ...record
    });
    setEditingID(record.id);
  };

  const cancel = () => {
    setEditingID('');
    setBrands(brands.filter(item => item.id > 0));
  };

  const update = async row => {
    try {
      setLoading(true);
      const row = await form.validateFields();
      await updateAdminSurveyBrands(editingID, row);
      await _getAdminSurveyBrands();
      setEditingID('');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      processedErrorMessage(error);
    }
  };

  const save = async row => {
    try {
      setLoading(true);
      const row = await form.validateFields();
      await saveAdminSurveyBrands(row);
      await _getAdminSurveyBrands();
      setEditingID('');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      processedErrorMessage(error);
    }
  };

  const remove = async row => {
    try {
      setLoading(true);
      await deleteAdminSurveyBrands(row.id);
      await _getAdminSurveyBrands();
    } catch (error) {
      setLoading(false);
      processedErrorMessage(error);
    }
  };

  const columns = [
    {
      title: 'DESCRIPCIÓN',
      dataIndex: 'description',
      editable: true
    },
    {
      title: 'ACCIONES',
      dataIndex: 'operation',
      width: '20%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <ButtonPrimary
              onClick={() => {
                if (editingID) {
                  update(record);
                } else {
                  save(record);
                }
              }}
              theme="btn-save"
              text="GUARDAR"
              type="submit"
            />
            <ButtonSecondary text="CANCELAR" onClick={cancel} theme="btn-cancel" />
          </Space>
        ) : (
          <Space>
            <ButtonPrimary
              disabled={isViewer() || editingID !== ''}
              onClick={() => edit(record)}
              theme={isViewer() ? 'disabled' : 'promo-btn'}
              iconLeft={<EyeFilled />}
            />
            <Popconfirm title="¿Desea eliminar este registro?" onConfirm={() => remove(record)}>
              <ButtonPrimary
                disabled={isViewer()}
                theme={isViewer() ? 'disabled' : 'btn-remove-survey'}
                iconLeft={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        );
      }
    }
  ];
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  const handleAdd = () => {
    const newData = {
      id: 0,
      description: ''
    };
    edit(newData);
    setBrands([...brands, newData]);
  };

  const _getAdminSurveyBrands = async () => {
    try {
      setLoading(true);
      const response = await getAdminSurveyBrands();
      setBrands(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      processedErrorMessage(error);
    }
  };

  useEffect(() => {
    _getAdminSurveyBrands();
  }, []);

  return (
    <Form form={form} component={false}>
      <div className="text-survey-container">
        {!isViewer() && (
          <div className="btn-create-survey">
            <ButtonPrimary onClick={() => handleAdd()} text="NUEVA MARCA" theme="btn_blue" />
          </div>
        )}
      </div>
      <Table
        className="table-users"
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={brands}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel
        }}
      />
    </Form>
  );
};

AdminSurveyBrandsTable.propTypes = {
  setLoading: PropTypes.func.isRequired
}

export default AdminSurveyBrandsTable;
