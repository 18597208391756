import React, { useEffect, useState } from 'react';
import './_style.scss';
import PropTypes from 'prop-types';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import { Form, Input, message, Select, Divider, InputNumber, Checkbox } from 'antd';
import { isEmpty } from '../../../utils/func-helpers';
import {
  REQUIRED_FIELD_RULE,
  EMAIL_RULE,
  CONFIRM_USERNAME_RULE
} from '../../../utils/validation-rules';
import apiCall from '../../../services/api-calls/all';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import { ADMIN_USER_LIST_URL } from '../../../utils/constants';
import { useRedirect } from '../../Router/redirect';

import UserFormCreatePassword from '../../molecules/UserFormCreatePassword/UserFormCreatePassword';
import UserFormEditPassword from '../../molecules/UserFormEditPassword/UserFormEditPassword';
import Spinner from '../../atoms/Spinner/Spinner';

const { getClients, getRoles, getBranchOffices } = apiCall();

const UserForm = ({ user, handleSubmit, submitText }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [clients, setClients] = useState([]);
  const [clientsLoading, setClientsLoading] = useState(false);
  const [client, setClient] = useState(null);
  const [roles, setRoles] = useState([]);
  const [rolesLoading, setRolesLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [branchOffices, setBranchOffices] = useState([]);
  const [branchOfficesLoading, setBranchOfficesLoading] = useState(false);
  const { redirect, setUrlToRedirect } = useRedirect();
  const [loading, setLoading] = useState(false);

  const searchClients = async () => {
    try {
      setClientsLoading(true);
      const response = await getClients();
      setClients(response);
      setClientsLoading(false);
    } catch (error) {
      setClientsLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const handleClient = async id => {
    try {
      setBranchOfficesLoading(true);
      form.setFieldsValue({
        idBranchOffice: undefined
      });
      const response = await getBranchOffices({
        idCustomer: id
      });
      setClient(id);
      setBranchOffices(response);
    } catch (error) {
      branchOfficesLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const searchRoles = async () => {
    try {
      setRolesLoading(true);
      const response = await getRoles();
      setRoles(response);
      setRolesLoading(false);
    } catch (error) {
      setRolesLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const setUser = async user => {
    if (user && !isEmpty(user)) {
      await handleClient(user.customer.id);
      setClient(user.customer.id);
      form.setFieldsValue({
        name: user.name,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        username: user.username,
        role: user.role,
        idCustomer: user.customer.id,
        canSeeRestricted: user.canSeeRestricted
      });
      setEditMode(true);
    }
  };

  useEffect(() => {
    searchRoles();
    searchClients();
  }, []);

  useEffect(() => {
    setUser(user);
  }, [user]);

  useEffect(() => {
    if (user && !isEmpty(user)) setLoading(true);
  }, [user]);

  useEffect(() => {
    if (user && !isEmpty(user)) {
      form.setFieldsValue({
        idBranchOffice: branchOffices.find(branchOffice => branchOffice.id === user.idBranchOffice)
          ? user.idBranchOffice
          : undefined
      });
      setEditMode(true);
	}
	setBranchOfficesLoading(false);
    setLoading(false);
  }, [branchOffices]);

  return (
    <Spinner loading={loading}>
      <Form form={form} onFinish={handleSubmit}>
        {redirect()}
        <div className="page-content user-form">
          <div className="_input">
            <label className="input-label">
              Nombre <span>*</span>
            </label>
            <Form.Item rules={[REQUIRED_FIELD_RULE]} name="name">
              <Input className="input" placeholder="Nombre" />
            </Form.Item>
          </div>
          <div className="_input">
            <label className="input-label">
              Apellido <span>*</span>
            </label>
            <Form.Item rules={[REQUIRED_FIELD_RULE]} name="lastName">
              <Input className="input" placeholder="Apellido" />
            </Form.Item>
          </div>
          <div className="_input">
            <label className="input-label">
              Usuario <span>*</span>
            </label>
            <Form.Item rules={[REQUIRED_FIELD_RULE, EMAIL_RULE]} name="username">
              <Input className="input" placeholder="Usuario" />
            </Form.Item>
          </div>
          <div className="_input">
            <label className="input-label">
              Confirmar Usuario <span>*</span>
            </label>
            <Form.Item
              rules={[REQUIRED_FIELD_RULE, EMAIL_RULE, CONFIRM_USERNAME_RULE]}
              dependencies={['username']}
              name="email"
            >
              <Input className="input" placeholder="Confirmar usuario" />
            </Form.Item>
          </div>
          <div className="_input">
            <label className="input-label">
              Cliente <span>*</span>
            </label>
            <Form.Item rules={[REQUIRED_FIELD_RULE]} name="idCustomer">
              <Select
                loading={clientsLoading}
                disabled={editMode}
                placeholder="Seleccione un cliente"
                style={{ width: '100%' }}
                onChange={handleClient}
              >
                {clients.map(({ id, registeredName }) => (
                  <Option key={id} value={id}>
                    {registeredName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="_input">
            <label className="input-label">
              Sucursal <span>*</span>
            </label>
            <Form.Item rules={[REQUIRED_FIELD_RULE]} name="idBranchOffice">
              <Select
                loading={branchOfficesLoading}
                placeholder="Debe seleccionar un cliente"
                style={{ width: '100%' }}
              >
                {branchOffices.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {client && branchOffices.length === 0 ? (
              <div className="ant-form-item-explain-branch-offices">
                <div>¡No existen sucursales habilitadas!</div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="_input">
            <label className="input-label">
              Teléfono <span>*</span>
            </label>
            <Form.Item rules={[REQUIRED_FIELD_RULE]} name="phone">
              <InputNumber className="input" placeholder="Teléfono" />
            </Form.Item>
          </div>

          <Divider />
          {user ? <UserFormEditPassword /> : <UserFormCreatePassword />}
          <Divider />
          <div className="_input">
            <label className="input-label">
              Tipo de Usuario <span>*</span>
            </label>
            <Form.Item rules={[REQUIRED_FIELD_RULE]} name="role">
              <Select
                loading={rolesLoading}
                disabled={editMode}
                placeholder="Seleccione tipo de usuario"
                style={{ width: '100%' }}
              >
                {roles.map((role, index) => (
                  <Option key={index} value={role.code}>
                    {role.description}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

        <div className="_input">
            <label className="input-label">
                Puede ver productos restringidos <span>*</span>
            </label>
            <Form.Item name="canSeeRestricted" valuePropName="checked">
                <Checkbox />
            </Form.Item>
        </div>

        </div>

        <div className="buttons-container">
          <ButtonSecondary
            text="CANCELAR"
            theme="btn-cancel"
            onClick={() => setUrlToRedirect(ADMIN_USER_LIST_URL)}
          />
          <ButtonPrimary text={submitText} type="submit" theme="btn-save" />
        </div>
      </Form>
    </Spinner>
  );
};

UserForm.propTypes = {
  user: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string
};

UserForm.defaultProps = {
  submitText: 'GUARDAR'
};

export default UserForm;
