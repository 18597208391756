import React from 'react';
import {Space} from 'antd';
import ButtonPrimary from '../../components/atoms/ButtonPrimary/button-primary';
import {
  EyeFilled,
  DeleteOutlined,
  DownloadOutlined
} from '@ant-design/icons';

export const columns = (onEdit, onDestroy, onDownload) => [
  {
    title: 'TÍTULO',
    dataIndex: 'title',
    key: 'titulo',
    width: 200
  },
  {
    title: 'VIGENCIA',
    key: 'vigencia',
    render: (text, record) => `${record.fromDate} - ${record.toDate}`,
    width: 200
  },
  {
    title: 'ESTADO',
    dataIndex: 'promotionState',
    key: 'estado',
    width: 100,
    render: (promotionState) => promotionState.description
  },
  {
    title: 'PROTOCOLO',
    dataIndex: 'id',
    key: 'protocolo',
    width: 150,
    render: (id) => (
      <ButtonPrimary theme="protocolo_btn" text="Descargar" iconLeft={<DownloadOutlined />} onClick={() => onDownload(id)} />
    )
  },
  {
    dataIndex: 'user',
    title: 'USUARIO',
    key: 'usuario',
    width: 250
  },
  {
    dataIndex: 'id',
    title: 'ACCIONES',
    key: 'acciones',
    width: 200,
    render: (id, record) => (
      <Space>
        {onEdit && <ButtonPrimary theme="ver_promo_btn" iconLeft={<EyeFilled />} onClick={() => onEdit(id)} /> }
        {record.promotionState.code === 'PROMOTION_STATE_ACTIVE' && onDestroy && <ButtonPrimary theme="eliminar_btn" iconLeft={<DeleteOutlined />} onClick={() => onDestroy(id)} /> }
      </Space>
    )
  }
];
