export const REQUIRED_FIELD = 'Campo obligatorio';
export const NUMERIC_FIELD = 'Campo numerico';
export const NUMERIC_FIELD_WITH_SPECIAL_CHARS = 'Campo numerico con caracteres especiales';
export const POSITIVE_NUMERIC_FIELD = 'Campo numerico mayor a 0 (cero)';
export const PASSWORD = 'Contraseña invalida';
export const PASSWORD_NOT_MATCH = '¡Las dos contraseñas que ingresó no coinciden!';
export const EMAIL_NOT_MATCH = '¡Los nombres de Usuario no coinciden!';
export const AVAILABLE_STOCK_MUST_BE_LESS =
  'El stock disponible debe ser menor o igual que el stock real';
export const EMAIL = 'E-mail invalido';
export const PHONE = 'Invalid phone';
export const EXPIRED_SESSION_MESSAGE = 'Su sesión ha caducado. Por favor Iniciar sesión.';
export const LIMIT_FOR_MAX_VALUE = 'Este campo no puede ser mayor que :max';
