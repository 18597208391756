import helpers from './helpers';

const getUsersFiltered = makeGetRequest => data => makeGetRequest('users/filters', data);

const showUser = makeGetRequest => id => makeGetRequest(`users/${id}`);

const createUser = makePostFileRequest => data => makePostFileRequest('users', data);

const editUser = makePutRequest => (id, data) => makePutRequest(`users/${id}`, data);

const destroyUser = makeDeleteRequest => id => makeDeleteRequest(`users/${id}`);

const enableUser = makeGetRequest => id => makeGetRequest(`users/enable/${id}`);

const disableUser = makeGetRequest => id => makeGetRequest(`users/disable/${id}`);

const getProfile = makeGetRequest => () => makeGetRequest('users/profile');

export default client => {
  const { makeGetRequest, makePostRequest, makePutRequest, makeDeleteRequest } = helpers(client);
  return {
    getUsersFiltered: getUsersFiltered(makeGetRequest),
    showUser: showUser(makeGetRequest),
    createUser: createUser(makePostRequest),
    editUser: editUser(makePutRequest),
    destroyUser: destroyUser(makeDeleteRequest),
    enableUser: enableUser(makeGetRequest),
    disableUser: disableUser(makeGetRequest),
    getProfile: getProfile(makeGetRequest)
  };
};
