import React from 'react';
import { Router as AppRouter, Switch } from 'react-router-dom';
import PrivateRoute from './private-route';

// Front routes
import Login from '../pages/Login/login';
import Home from '../pages/Home/Home';
import News from '../pages/News/news';
import Promotions from '../pages/Promotions/promotions';
import Error404 from '../pages/Error404/error404';

// Admin routes
import AdminNewsEdit from '../pages/Admin/NewsEdit/NewsEdit';
import AdminNews from '../pages/Admin/News/News';
import AdminPromotions from '../pages/Admin/Promotions/Promotions';
import AdminPromotionsCreate from '../pages/Admin/PromotionsCreate/PromotionsCreate';
import AdminPromotionsEdit from '../pages/Admin/PromotionsEdit/PromotionsEdit';
import AdminPriceLists from '../pages/Admin/PriceList/PriceList';
import AdminPriceListsCreate from '../pages/Admin/PriceListCreate/PriceListCreate';
import AdminPriceListsEdit from '../pages/Admin/PriceListEdit/PriceListEdit';
import AdminClients from '../pages/Admin/Clients/Clients';
import AdminClientCreate from '../pages/Admin/ClientCreate/ClientCreate';
import AdminClientEdit from '../pages/Admin/ClientEdit/ClientEdit';
import AdminUserList from '../pages/Admin/UserList/UserList';

import {
  ADMIN_CATALOGUE_CREATE,
  ADMIN_CATALOGUE_EDIT,
  ADMIN_CATALOGUE_LIST_URL,
  ADMIN_CLIENTS_CREATE_URL,
  ADMIN_CLIENTS_URL,
  ADMIN_EXPENSE_REPORT,
  ADMIN_NEWS_URL,
  ADMIN_ORDER_EDIT,
  ADMIN_ORDER_LIST,
  ADMIN_PRICE_LIST_CREATE_URL,
  ADMIN_PRICE_LIST_URL,
  ADMIN_PRODUCTIVITY_CREATE,
  ADMIN_PRODUCTIVITY_EDIT,
  ADMIN_PROFILE,
  ADMIN_PROMOTIONS_CREATE_URL,
  ADMIN_PROMOTIONS_URL,
  ADMIN_SURVEY,
  ADMIN_USER_CREATE,
  ADMIN_USER_EDIT,
  ADMIN_USER_LIST_URL,
  CART_URL,
  CATALOGUE_LIST,
  DASHBOARD,
  EXPENSE,
  HOME_URL,
  LOGIN_URL,
  NEWS_URL,
  PERMISSION_ADMIN_CATALOG,
  PERMISSION_ADMIN_CATALOG_CREATE,
  PERMISSION_ADMIN_CATALOG_EDIT,
  PERMISSION_ADMIN_CLIENTS,
  PERMISSION_ADMIN_CLIENTS_CREATE,
  PERMISSION_ADMIN_CLIENTS_EDIT,
  PERMISSION_ADMIN_EDIT_ORDERS,
  PERMISSION_ADMIN_EXPENSE_REPORT,
  PERMISSION_ADMIN_NEWS,
  PERMISSION_ADMIN_NEWS_EDIT,
  PERMISSION_ADMIN_ORDERS,
  PERMISSION_ADMIN_PRICE_LIST,
  PERMISSION_ADMIN_PRICE_LIST_CREATE,
  PERMISSION_ADMIN_PRICE_LIST_EDIT,
  PERMISSION_ADMIN_PRODUCTIVITY_CREATE,
  PERMISSION_ADMIN_PRODUCTIVITY_EDIT,
  PERMISSION_ADMIN_PROMOTIONS,
  PERMISSION_ADMIN_PROMOTIONS_CREATE,
  PERMISSION_ADMIN_PROMOTIONS_EDIT,
  PERMISSION_ADMIN_SURVEYS,
  PERMISSION_DASHBOARD,
  PERMISSION_EXPENSE_REPORT,
  PERMISSION_EXPENSE_REPORT_CREATE,
  PERMISSION_NEWS,
  PERMISSION_PRE_APPROVAL,
  PERMISSION_PRE_APPROVAL_CREATE,
  PERMISSION_PRE_APPROVAL_EDIT,
  PERMISSION_PRICE_LIST,
  PERMISSION_PRICE_SURVEYS_CREATE,
  PERMISSION_PRICE_SURVEYS_EDIT,
  PERMISSION_PRODUCT_SURVEYS_CREATE,
  PERMISSION_PRODUCT_SURVEYS_EDIT,
  PERMISSION_PRODUCTIVITY,
  PERMISSION_PROMOTIONS,
  PERMISSION_RENDITIONS,
  PERMISSION_SURVEYS,
  PREAPPROVAL,
  PREAPPROVAL_CREATE,
  PREAPPROVAL_EDIT,
  PRICE_LIST,
  PRICE_SURVEYS_CREATE,
  PRICE_SURVEYS_EDIT,
  PRODUCT_SURVEYS_CREATE,
  PRODUCT_SURVEYS_EDIT,
  PRODUCTIVITY,
  PROMOTIONS_URL,
  RENDITIONS_CREATE,
  SURVEYS,
  REFUND,
  REFUND_EDIT,
  REFUND_CREATE,
  PERMISSION_REFUND_CREATE,
  PERMISSION_REFUND_EDIT,
  PERMISSION_REFUND,
} from '../../utils/constants';

import history from './history';
import UserCreate from '../pages/Admin/UserCreate/UserCreate';
import UserEdit from '../pages/Admin/UserEdit/UserEdit';
import CatalogueCreate from '../pages/Admin/CatalogueCreate/CatalogueCreate';
import CatalogueEdit from '../pages/Admin/CatalogueEdit/CatalogueEdit';
import Catalogue from '../pages/Catalogue/Catalogue';
import CatalogueAdmin from '../pages/Admin/Catalogue/Catalogue';
import Cart from '../pages/Cart/cart';
import PriceList from '../pages/PriceList/price-list';
import OrderEdit from '../pages/Admin/OrderEdit/OrderEdit';
import OrderListPage from '../pages/Admin/OrderList/OrderList';
import Profile from '../pages/Profile/profile';
import Productivity from '../pages/Admin/Productivity/Productivity';
import ProductivityCreate from '../pages/Admin/ProductivityCreate/ProductivityCreate';
import ProductivityEdit from '../pages/Admin/ProductivityEdit/ProductivityEdit';
import SurveyCreate from '../pages/SurveyCreate/SurveyCreate';
import SurveyEdit from '../pages/SurveyEdit/SurveyEdit';
import SurveyProductCreate from '../pages/SurveyProductCreate/SurveyProductCreate';
import SurveyProductEdit from '../pages/SurveyProductEdit/SurveyProductEdit';
import SurveyClient from '../pages/SurveyClient/survey-client';
import AdminSurvey from '../pages/Admin/AdminSurvey/AdminSurvey';
import ExpenseListAdmin from '../pages/Admin/ExpenseList/ExpenseList';
import RenditionsClient from '../pages/RenditionsClient/renditions-client';
import RenditionsCreate from '../pages/RenditionsCreate/RenditionsCreate';
import Dashboard from '../pages/Admin/Dashboard/Dashboard';
import PreApproval from '../pages/PreApproval/PreApproval';
import PreApprovalCreate from '../pages/PreApprovalCreate/PreApprovalCreate';
import PreApprovalEdit from '../pages/PreApprovalEdit/PreApprovalEdit';
import Refunds from '../pages/Refunds/Refunds.jsx';
import RefundsEdit from '../pages/RefundsEdit/RefundsEdit.jsx';
import RefundsCreate from '../pages/RefundsCreate/RefundsCreate.jsx';

const routesConfig = [
  {
    path: LOGIN_URL,
    component: Login,
    requireAuthentication: false,
    exact: true
  },
  { path: HOME_URL, component: Home, requireAuthentication: true, exact: true },
  {
    path: CART_URL,
    component: Cart,
    requireAuthentication: true,
    exact: true
  },
  {
    path: NEWS_URL,
    component: News,
    requireAuthentication: true,
    exact: true,
    permission: PERMISSION_NEWS
  },
  {
    path: PRICE_LIST,
    component: PriceList,
    requireAuthentication: true,
    exact: true,
    permission: PERMISSION_PRICE_LIST
  },
  {
    path: ADMIN_NEWS_URL,
    component: AdminNews,
    requireAuthentication: true,
    exact: true,
    permission: PERMISSION_ADMIN_NEWS
  },
  {
    path: `${ADMIN_NEWS_URL}/:id`,
    component: AdminNewsEdit,
    requireAuthentication: true,
    exact: true,
    permission: PERMISSION_ADMIN_NEWS_EDIT
  },
  {
    path: PROMOTIONS_URL,
    component: Promotions,
    requireAuthentication: true,
    exact: true,
    permission: PERMISSION_PROMOTIONS
  },
  {
    path: ADMIN_PROMOTIONS_URL,
    component: AdminPromotions,
    requireAuthentication: true,
    exact: true,
    permission: PERMISSION_ADMIN_PROMOTIONS
  },
  {
    path: ADMIN_PROMOTIONS_CREATE_URL,
    component: AdminPromotionsCreate,
    requireAuthentication: true,
    exact: true,
    permission: PERMISSION_ADMIN_PROMOTIONS_CREATE
  },
  {
    path: `${ADMIN_PROMOTIONS_URL}/:id`,
    component: AdminPromotionsEdit,
    requireAuthentication: true,
    exact: true,
    permission: PERMISSION_ADMIN_PROMOTIONS_EDIT
  },
  {
    path: ADMIN_PRICE_LIST_URL,
    component: AdminPriceLists,
    requireAuthentication: true,
    exact: true,
    permission: PERMISSION_ADMIN_PRICE_LIST
  },
  {
    path: ADMIN_PRICE_LIST_CREATE_URL,
    component: AdminPriceListsCreate,
    requireAuthentication: true,
    exact: true,
    permission: PERMISSION_ADMIN_PRICE_LIST_CREATE
  },
  {
    path: `${ADMIN_PRICE_LIST_URL}/:id`,
    component: AdminPriceListsEdit,
    requireAuthentication: true,
    exact: true,
    permission: PERMISSION_ADMIN_PRICE_LIST_EDIT
  },
  {
    path: ADMIN_CLIENTS_URL,
    component: AdminClients,
    requireAuthentication: true,
    exact: true,
    permission: PERMISSION_ADMIN_CLIENTS
  },
  {
    path: ADMIN_CLIENTS_CREATE_URL,
    component: AdminClientCreate,
    requireAuthentication: true,
    exact: true,
    permission: PERMISSION_ADMIN_CLIENTS_CREATE
  },
  {
    path: `${ADMIN_CLIENTS_URL}/:id`,
    component: AdminClientEdit,
    requireAuthentication: true,
    exact: true,
    permission: PERMISSION_ADMIN_CLIENTS_EDIT
  },
  {
    path: ADMIN_USER_LIST_URL,
    component: AdminUserList,
    requireAuthentication: true,
    exact: true
  },
  {
    path: ADMIN_USER_CREATE,
    component: UserCreate,
    requireAuthentication: true,
    exact: true
  },
  {
    path: ADMIN_USER_EDIT,
    component: UserEdit,
    requireAuthentication: true,
    exact: true
  },
  {
    path: CATALOGUE_LIST,
    component: Catalogue,
    requireAuthentication: true,
    exact: true
  },
  {
    path: ADMIN_CATALOGUE_LIST_URL,
    component: CatalogueAdmin,
    requireAuthentication: true,
    permission: PERMISSION_ADMIN_CATALOG,
    exact: true
  },
  {
    path: ADMIN_CATALOGUE_CREATE,
    component: CatalogueCreate,
    requireAuthentication: true,
    permission: PERMISSION_ADMIN_CATALOG_CREATE,
    exact: true
  },
  {
    path: ADMIN_CATALOGUE_EDIT,
    component: CatalogueEdit,
    requireAuthentication: true,
    permission: PERMISSION_ADMIN_CATALOG_EDIT,
    exact: true
  },
  {
    path: ADMIN_ORDER_EDIT,
    component: OrderEdit,
    requireAuthentication: true,
    permission: PERMISSION_ADMIN_EDIT_ORDERS,
    exact: true
  },
  {
    path: ADMIN_ORDER_LIST,
    component: OrderListPage,
    requireAuthentication: true,
    permission: PERMISSION_ADMIN_ORDERS,
    exact: true
  },
  {
    path: ADMIN_PROFILE,
    component: Profile,
    requireAuthentication: true,
    exact: true
  },
  {
    path: PRODUCTIVITY,
    component: Productivity,
    requireAuthentication: true,
    permission: PERMISSION_PRODUCTIVITY,
    exact: true
  },
  {
    path: ADMIN_PRODUCTIVITY_CREATE,
    component: ProductivityCreate,
    requireAuthentication: true,
    permission: PERMISSION_ADMIN_PRODUCTIVITY_CREATE,
    exact: true
  },
  {
    path: ADMIN_PRODUCTIVITY_EDIT,
    component: ProductivityEdit,
    requireAuthentication: true,
    permission: PERMISSION_ADMIN_PRODUCTIVITY_EDIT,
    exact: true
  },
  {
    path: SURVEYS,
    component: SurveyClient,
    requireAuthentication: true,
    permission: PERMISSION_SURVEYS,
    exact: true
  },
  {
    path: PRICE_SURVEYS_CREATE,
    component: SurveyCreate,
    requireAuthentication: true,
    permission: PERMISSION_PRICE_SURVEYS_CREATE,
    exact: true
  },
  {
    path: PRICE_SURVEYS_EDIT,
    component: SurveyEdit,
    requireAuthentication: true,
    permission: PERMISSION_PRICE_SURVEYS_EDIT,
    exact: true
  },
  {
    path: PRODUCT_SURVEYS_CREATE,
    component: SurveyProductCreate,
    requireAuthentication: true,
    permission: PERMISSION_PRODUCT_SURVEYS_CREATE,
    exact: true
  },
  {
    path: PRODUCT_SURVEYS_EDIT,
    component: SurveyProductEdit,
    requireAuthentication: true,
    permission: PERMISSION_PRODUCT_SURVEYS_EDIT,
    exact: true
  },
  {
    path: ADMIN_SURVEY,
    component: AdminSurvey,
    requireAuthentication: true,
    permission: PERMISSION_ADMIN_SURVEYS,
    exact: true
  },
  {
    path: ADMIN_EXPENSE_REPORT,
    component: ExpenseListAdmin,
    requireAuthentication: true,
    permission: PERMISSION_ADMIN_EXPENSE_REPORT,
    exact: true
  },
  {
    path: EXPENSE,
    component: RenditionsClient,
    requireAuthentication: true,
    permission: PERMISSION_EXPENSE_REPORT,
    exact: true
  },
  {
    path: RENDITIONS_CREATE,
    component: RenditionsCreate,
    requireAuthentication: true,
    permission: PERMISSION_EXPENSE_REPORT_CREATE,
    exact: true
  },
  {
    path: DASHBOARD,
    component: Dashboard,
    requireAuthentication: true,
    permission: PERMISSION_DASHBOARD,
    exact: true
  },
  {
    path: PREAPPROVAL,
    component: PreApproval,
    requireAuthentication: true,
    permission: PERMISSION_PRE_APPROVAL,
    exact: true
  },
  {
    path: PREAPPROVAL_CREATE,
    component: PreApprovalCreate,
    requireAuthentication: true,
    permission: PERMISSION_PRE_APPROVAL_CREATE,
    exact: true
  },
  {
    path: PREAPPROVAL_EDIT,
    component: PreApprovalEdit,
    requireAuthentication: true,
    permission: PERMISSION_PRE_APPROVAL_EDIT,
    exact: true
  },
  { path: REFUND, component: Refunds, requireAuthentication: true, exact: true, permission: PERMISSION_REFUND },
  { path: REFUND_EDIT, component: RefundsEdit, requireAuthentication: true, exact: true, permission: PERMISSION_REFUND_EDIT },
  { path: REFUND_CREATE, component: RefundsCreate, requireAuthentication: true, exact: true, permission: PERMISSION_REFUND_CREATE },

  { component: Error404, requireAuthentication: false }
];

export const Router = () => {
  const routes = routesConfig.map((route, index) => <PrivateRoute key={index} {...route} />);
  return (
    <div className="AppContainer">
      <div className="MainContent">
        <div>
          <AppRouter history={history}>
            <Switch>{routes}</Switch>
          </AppRouter>
        </div>
      </div>
    </div>
  );
};
