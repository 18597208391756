import React from 'react';
import TitlePage from '../../atoms/TitlePage/TitlePage';
import CatalogueList from "../../organism/CatalogueList/CatalogueList";

const Catalogue = () => {

    return (
        <div>
            <CatalogueList/>
        </div>
    )
};

export default Catalogue;
