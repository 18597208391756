import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../../services/providers/user-context.jsx';
import Header from '../molecules/header/header';
import { LOGIN_URL } from '../../utils/constants';
import TopBar from '../../components/molecules/TopBar/topBar';
import img from '../../../src/img/avatar1.png';
import {PermissionsMiddleware} from "../../services/middlewares/permissionsMiddleware";

const PrivateRoute = ({
  component: Component,
  requireAuthentication,
  replaceHeader: ReplaceHeader,
  permission: Permission,
  ...rest
}) => {
  const { user } = useContext(UserContext);
  const { menus } = user.primaryNavbar ? user.primaryNavbar : {menus:null};

  if (!requireAuthentication) {
    return <Route {...rest} render={properties => <Component {...properties} />} />;
  }
  return user.accessToken ? (
    <div className="Container">
      {ReplaceHeader ? <ReplaceHeader /> : <Header />}
      <div className="Content">
        <TopBar topbarEntries={menus} name={user.name} img={img} />
        <div className="main-container-banner">
          <div className="img-banner" />
        </div>
        <div className="main-container-content">
          <Route {...rest} render={properties => (
              <PermissionsMiddleware permission={Permission} component={<Component {...properties} />}/>
          )} />
        </div>
      </div>
    </div>
  ) : (
    <Redirect to={LOGIN_URL} />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string,
  requireAuthentication: PropTypes.bool.isRequired
};

PrivateRoute.defaultProps = {
  exact: false
};

export default PrivateRoute;
