import client from './http-client';
import multiClient from './http-client-multipart';
import fileClient from './http-client-file';
import user from './user.api-calls';
import auth from './auth.api-calls';
import authMock from './mocks/auth.api-calls.mock';
import userMock from './mocks/user.api-calls.mock';
import usersMock from './mocks/users.api-calls.mock';
import promotionMock from './mocks/promotions.api-calls.mock';
import priceListMock from './mocks/price-lists.api-calls.mock';
import clientMock from './mocks/client.api-calls.mock';
import locationMock from './mocks/locations.api-calls.mock';
import file from './file.api-calls';
import news from './news.api-calls';
import promotions from './promotions.api-calls';
import downloadFile from './files.api-calls';
import priceLists from './price-lists.api-calls';
import clients from './client.api-calls';
import users from './users.api-calls';
import roles from './roles.api-calls';
import locations from './locations.api-calls';
import branchOffices from './branchOffices.api-calls';
import categories from './categories.api-calls';
import products from './products.api-calls';
import cart from './cart.api-calls';
import purchase from './purchase.api-calls';
import orders from './order.api-calls';
import calendar from './calendar.api-calls';
import productivity from './productivity.api-calls';
import survey from './survey.api-calls';
import expense from './expence.api-calls';
import markets from './markets.api-calls';
import aros from './aros.api-calls';
import lines from './lines.api-calls';
import channels from './channels.api-calls';
import preapproval from './preApproval.api-calls';
import refund from './refund.api-calls';
import notifications from './notifications.api-calls';
import appVersion from './appVersion.api-calls'
import skus from './skus.api-calls'

const defaultClient = client({});
const multipartClient = multiClient({});
const downloadFileClient = fileClient({});

export default () =>
  process.env.REACT_APP_CUSTOM_ENV === 'mocked'
    ? {
        ...authMock(defaultClient),
        ...userMock(defaultClient),
        ...usersMock(defaultClient),
        ...promotionMock(defaultClient),
        ...priceListMock(defaultClient),
        ...clientMock(defaultClient),
        ...locationMock(defaultClient),
        ...usersMock(defaultClient)
      }
    : {
        ...auth(defaultClient),
        ...user(defaultClient),
        ...cart(defaultClient),
        ...users(defaultClient),
        ...roles(defaultClient),
        ...file(multipartClient),
        ...news(multipartClient),
        ...promotions(multipartClient),
        ...downloadFile(downloadFileClient),
        ...priceLists(multipartClient),
        ...clients(multipartClient),
        ...locations(defaultClient),
        ...branchOffices(defaultClient),
        ...categories(defaultClient),
        ...products(defaultClient),
        ...purchase(defaultClient),
        ...orders(defaultClient),
        ...calendar(defaultClient),
        ...productivity(defaultClient),
        ...survey(defaultClient),
        ...expense(defaultClient),
        ...markets(defaultClient),
        ...aros(defaultClient),
        ...lines(defaultClient),
        ...channels(defaultClient),
        ...preapproval(defaultClient),
        ...refund(defaultClient),
        ...notifications(defaultClient),
        ...appVersion(defaultClient),
        ...skus(defaultClient)
      };
