import React, { useState, useEffect } from 'react';
import './_style.scss';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import History from '../../../atoms/Breadcrumb/history';
import PromotionForm from '../../../organism/PromotionForm/PromotionForm';
import apiCall from '../../../../services/api-calls/all';
import { message } from 'antd';
import { processedErrorMessage } from '../../../../services/api-calls/helpers';
import { ADMIN_PROMOTIONS_URL } from '../../../../utils/constants';
import { isEmpty } from "../../../../utils/func-helpers";

const { showPromotion } = apiCall();

const NewsEdit = ({ match }) => {
  const { params } = match;
  const [promotion, setPromotion] = useState({});

  const handleShowPromotion = async () => {
    try {
      const response = await showPromotion(params.id);
      setPromotion(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const breadcrumbs = [
    {
      title: 'Parametría Promociones',
      href: ADMIN_PROMOTIONS_URL,
    },
    {
      title: 'Editar Promociones',
      href: '',
    }
  ];

  useEffect(() => {
    handleShowPromotion();
  }, []);

  return (
    <div>
      <History breadcrumbs={breadcrumbs} />
      <TitlePage text="EDITAR PROMOCIÓN" />
      {!isEmpty(promotion) ? <PromotionForm promotion={promotion} /> : ''}
    </div>
  );
};

export default NewsEdit;
