import helpers from './helpers';

const getNews = makeGetRequest => data => makeGetRequest('news', data);

const showNew = makeGetRequest => id => makeGetRequest(`news/${id}`);

const getNewOrders = makeGetRequest => data => makeGetRequest(`news/orders`, data);

const editNew = makePutFileRequest => (id, data) => makePutFileRequest(`news/${id}`, data);

export default client => {
  const { makeGetRequest, makePutFileRequest } = helpers(client);
  return {
    getNews: getNews(makeGetRequest),
    showNew: showNew(makeGetRequest),
    getNewOrders: getNewOrders(makeGetRequest),
    editNew: editNew(makePutFileRequest)
  };
};
