import React, { useState } from 'react';
import { message, Select } from 'antd';
import './_style.scss';
import PropTypes from 'prop-types';
import PaginatedTable from '../../molecules/PaginatedTable/paginated-table.jsx';
import { columns } from '../../../utils/tables/table-dashboard-productivity';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCalls from '../../../services/api-calls/all';

import { PAGE_SIZE } from '../../../utils/constants';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import Spinner from '../../atoms/Spinner/Spinner';

const { getProductivityDashboard } = apiCalls();

const DashboardProductivityList = ({ filters, filterParams }) => {
  const [reset, setReset] = useState(false);
  let mergeFilters = [reset];
  const [clickedApply, setClickedApply] = useState(false);
  const [loading, setLoading] = useState(false);

  const productivityFiltered = async (page, pageSize, onResult) => {
    try {
      const f = {
        page,
        size: pageSize,
        ...filterParams
      };
      setLoading(true);
      const response = await getProductivityDashboard(f);
      setLoading(false);
      onResult(response);
    } catch (error) {
      setLoading(false);
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  const updateFilters = () => {
    mergeFilters = [...filters, reset];
    if (filterParams.year && filterParams.months) {
      setReset(!reset);
      setClickedApply(true);
    }
    else {
      message.error("Por favor seleccione año y mes");
    }
  };

  return (
    <>
      <div className="dashboard-filtersTab-container-productivity">
        <div className="dashboard-input-button">
          <ButtonPrimary onClick={updateFilters} text={'APLICAR'} />
        </div>
      </div>
      <Spinner loading={loading}>
        <PaginatedTable
          className="table-users"
          columns={columns()}
          onFetch={clickedApply ? productivityFiltered : () => {}}
          pageSize={PAGE_SIZE}
          filters={mergeFilters}
          // scroll={{ x: 420, y:300 }}
        />
      </Spinner>
    </>
  );
};

DashboardProductivityList.propTypes = {
  filterParams: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired
};
export default DashboardProductivityList;
