import helpers from './helpers';

const getAllProductivity = makeGetRequest => data => makeGetRequest('/productivity', data);
const createProductivity = makePostRequest => data => makePostRequest('/productivity', data);
const updateProductivity = makePutRequest => (id, data) =>
  makePutRequest(`/productivity/${id}`, data);
const getProductivity = makeGetRequest => id => makeGetRequest(`/productivity/${id}`);
const deleteProductivity = makeDeleteRequest => id => makeDeleteRequest(`/productivity/${id}`);

// Dashboard
const getProductivityDashboard = makeGetRequest => data =>
  makeGetRequest('/productivity/dashboard', data);

export default client => {
  const { makeGetRequest, makePostRequest, makePutRequest, makeDeleteRequest } = helpers(client);
  return {
    createProductivity: createProductivity(makePostRequest),
    updateProductivity: updateProductivity(makePutRequest),
    getProductivity: getProductivity(makeGetRequest),
    getAllProductivity: getAllProductivity(makeGetRequest),
    deleteProductivity: deleteProductivity(makeDeleteRequest),
    getProductivityDashboard: getProductivityDashboard(makeGetRequest)
  };
};
