import React from 'react';
import './style.scss';

import AdminExpenseList from '../../../organism/AdminExpenseList/AdminExpenseList';
import TitlePage from '../../../atoms/TitlePage/TitlePage';

const ExpenseListAdmin = () => (
  <div className="main-renditions-container">
    <TitlePage text="PARAMETRÍA RENDICIONES" />
    <AdminExpenseList />
  </div>
);

export default ExpenseListAdmin;
