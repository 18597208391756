import helpers from './helpers';

const downloadFile = makeGetRequest => id => makeGetRequest(`file/download/${id}`);

// productivity
const exportProductivity = makeGetFileRequest => data =>
  makeGetFileRequest('/productivity/export', data);

// surveys
const exportSurveyPrice = makeGetFileRequest => data =>
  makeGetFileRequest('/surveys/report-price/export', data);

// surveys
const exportSurveyProduct = makeGetFileRequest => data =>
  makeGetFileRequest('/surveys/report-product/export', data);

// expense
const downloadExpenseReport = makeGetFileRequest => data =>
  makeGetFileRequest('/expense-report/export-template', data);

const downloadExpenseReportDashboard = makeGetFileRequest => data =>
  makeGetFileRequest('/expense-report/dashboard/export', data);

const importTemplateExpense = makePutFileRequest => data =>
  makePutFileRequest('/expense-report/import-template', data);

const downloadExpenseReportTemplate = makeGetFileRequest => () =>
  makeGetFileRequest('/expense-report/export-template');

// dashboard
const exportProductivityDashboard = makeGetFileRequest => data =>
  makeGetFileRequest('/productivity/dashboard/export', data);

// products
const downloadProductsTemplate = makeGetFileRequest => data =>
  makeGetFileRequest('/products/download-template', data);

//orders
const exportOrder = makeGetFileRequest => id => makeGetFileRequest(`orders/${id}/export`);

//sku
const downloadSku = makeGetFileRequest => () => makeGetFileRequest('/skus/download');

export default client => {
  const { makeGetRequest, makeGetFileRequest, makePutFileRequest } = helpers(client);
  return {
    downloadFile: downloadFile(makeGetRequest),
    exportProductivity: exportProductivity(makeGetFileRequest),
    exportSurveyPrice: exportSurveyPrice(makeGetFileRequest),
    exportSurveyProduct: exportSurveyProduct(makeGetFileRequest),
    downloadExpenseReport: downloadExpenseReport(makeGetFileRequest),
    exportProductivityDashboard: exportProductivityDashboard(makeGetFileRequest),
    downloadExpenseReportDashboard: downloadExpenseReportDashboard(makeGetFileRequest),
    importTemplateExpense: importTemplateExpense(makePutFileRequest),
    downloadExpenseReportTemplate: downloadExpenseReportTemplate(makeGetFileRequest),
    downloadProductsTemplate: downloadProductsTemplate(makeGetFileRequest),
    exportOrder: exportOrder(makeGetFileRequest),
    downloadSku: downloadSku(makeGetFileRequest)
  };
};
