import React from 'react';
import { Checkbox, Space, Tooltip } from 'antd';
import ButtonPrimary from '../../components/atoms/ButtonPrimary/button-primary';
import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  EyeFilled,
  DownloadOutlined
} from '@ant-design/icons';
import { dateFormat } from '../func-helpers';
import { APPROVED, DECLINED, PENDING } from '../constants';

export const columns = (onEdit, onDownload) => [
  {
    title: 'RAZÓN SOCIAL',
    dataIndex: 'registeredName',
    key: 'name'
  },
  {
    title: 'SUCURSAL',
    dataIndex: 'branchName',
    key: 'shop'
  },
  {
    title: 'NÚMERO DE PEDIDO',
    dataIndex: 'id',
    key: 'orderNumber'
  },
  {
    title: 'FECHA',
    dataIndex: 'created',
    key: 'date',
    render: created => <p>{dateFormat(created)}</p>
  },
  {
    title: 'ESTADO',
    dataIndex: 'state',
    key: 'status',
    render: ({ description }) => {
      if (description === PENDING)
        return (
          <Tooltip title="Pendiente">
            <ClockCircleFilled style={{ color: '#FED102' }} />
          </Tooltip>
        );
      if (description === APPROVED)
        return (
          <Tooltip title="Confirmado">
            <CheckCircleFilled style={{ color: '#5BC535' }} />
          </Tooltip>
        );
      if (description === DECLINED)
        return (
          <Tooltip title="Rechazado">
            <CloseCircleFilled style={{ color: '#FF2E2E' }} />
          </Tooltip>
        );
    }
  },
  {
    title: 'ACCIONES',
    key: 'acciones',
    render: ({ id }) => (
      <Space>
        <ButtonPrimary
          onClick={() => onDownload(id)}
          theme="actions-preapproval-btn"
          iconLeft={<DownloadOutlined />}
        />
        <ButtonPrimary onClick={() => onEdit(id)} theme="promo-btn" iconLeft={<EyeFilled />} />
      </Space>
    )
  }
];
