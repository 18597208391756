const getStates = () => [
  {
    id: 1,
    name: "Buenos Aires"
  },
  {
    id: 2,
    name: "La Pampa"
  }
];

const getCities = () => [
  {
    id: 1,
    name: "Lomas de Zamora"
  },
  {
    id: 2,
    name: "Almirante Brown"
  }
];

export default () => ({
  getStates,
  getCities
});
