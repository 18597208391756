import helpers from './helpers';

const getRoles  = makeGetRequest => () => makeGetRequest('roles');

const getRolesFiltered  = makeGetRequest => data => makeGetRequest('roles/filtered', data);

const showRole = makeGetRequest => id => makeGetRequest(`roles/${id}`);

const createRole = makePostFileRequest => (data) => makePostFileRequest(`roles`, data);

const editRole = makePutFileRequest => (id, data) => makePutFileRequest(`roles/${id}`, data);

const destroyRole = makeDeleteRequest => (id) => makeDeleteRequest(`roles/${id}`);

export default client => {
	const { makeGetRequest, makePostFileRequest, makePutFileRequest, makeDeleteRequest } = helpers(client);
	return {
		getRolesFiltered: getRolesFiltered(makeGetRequest),
		getRoles: getRoles(makeGetRequest),
		showRole: showRole(makeGetRequest),
		createRole: createRole(makePostFileRequest),
		editRole: editRole(makePutFileRequest),
		destroyRole: destroyRole(makeDeleteRequest)
	};
};
