import React, { useEffect, useState } from 'react';
import './_style.scss';
import { message, Select, Table, Descriptions, Card } from 'antd';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import PropTypes from 'prop-types';
import apiCalls from '../../../services/api-calls/all';
import { PAGE_SIZE } from '../../../utils/constants';
import { columns } from '../../../utils/tables/table-expense-dashboard';
import PaginatedTable from '../../molecules/PaginatedTable/paginated-table';
import { columns as columnsTop } from '../../../utils/tables/table-expense-dashboard-top';
import { columns as columnsMarket } from '../../../utils/tables/table-expense-dashboard-market';
import { columns as columnsLines } from '../../../utils/tables/table-expense-dashboard-lines';
import { columns as columnsAros } from '../../../utils/tables/table-expense-dashboard-aros';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import Spinner from '../../atoms/Spinner/Spinner';
import { isEmpty } from '../../../utils/func-helpers';

const {
  getChannels,
  getPromotionsList,
  getExpenseReportDashboardLines,
  getExpenseReportDashboardMarkets,
  getExpenseReportDashboardAros
} = apiCalls();

export const DashboardExpenseList = ({
  filters,
  filterParams,
  channel,
  setChannel,
  promotion,
  setPromotion
}) => {
  const [reset, setReset] = useState(false);

  const [markets, setMarkets] = useState([]);

  const [aros, setAros] = useState([]);

  const [lines, setLines] = useState([]);

  const [channels, setChannels] = useState([]);
  const [channelsLoading, setChannelsLoading] = useState(false);

  const [promotions, setPromotions] = useState([]);
  const [promotionsLoading, setPromotionsLoading] = useState(false);

  const [promotionDisabled, setPromotionDisabled] = useState(true);
  const [clickedApply, setClickedApply] = useState(false);

  const [loading, setLoading] = useState(false);

  let mergeFilters = [reset];

  const { Option } = Select;

  const updateFilters = () => {
    mergeFilters = [...filters, reset];
    if (filterParams.year && filterParams.months) {
      setReset(!reset);
      setClickedApply(true);
      _getMarkets();
      _getLines();
      _getAros();
      setClickedApply(false);
    } else {
      message.error('Por favor seleccione año y mes');
    }
  };

  const _getMarkets = async () => {
    if (_filterParamsDefined()) {
      try {
        setLoading(true);
        const filter = {
          months: filterParams.months,
          year: filterParams.year,
          states: filterParams.states,
          customers: filterParams.customers,
          idChannel: channel,
          idPromotion: promotion
        };
        const response = await getExpenseReportDashboardMarkets(filter);
        setMarkets(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        const errorMessage = processedErrorMessage(error);
        message.error(errorMessage);
      }
    }
  };

  const _getAros = async () => {
    if (_filterParamsDefined()) {
      try {
        setLoading(true);
        const filter = {
          months: filterParams.months,
          year: filterParams.year,
          states: filterParams.states,
          customers: filterParams.customers,
          idChannel: channel,
          idPromotion: promotion
        };
        const response = await getExpenseReportDashboardAros(filter);
        setAros(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        const errorMessage = processedErrorMessage(error);
        message.error(errorMessage);
      }
    }
  };

  const _getLines = async () => {
    if (_filterParamsDefined()) {
      try {
        const filter = {
          months: filterParams.months,
          year: filterParams.year,
          states: filterParams.states,
          customers: filterParams.customers,
          idChannel: channel,
          idPromotion: promotion
        };
        setLoading(true);
        const response = await getExpenseReportDashboardLines(filter);
        setLines(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        const errorMessage = processedErrorMessage(error);
        message.error(errorMessage);
      }
    }
  };

  const _getChannels = async () => {
    try {
      setChannelsLoading(true);
      const response = await getChannels();
      setChannels(response);
      setChannelsLoading(false);
    } catch (error) {
      setChannelsLoading(false);
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  const _getPromotions = async () => {
    try {
      setPromotionsLoading(true);
      const response = await getPromotionsList();
      setPromotions(response);
      setPromotionsLoading(false);
    } catch (error) {
      setPromotionsLoading(false);
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  useEffect(() => {
    _getMarkets();
    _getAros();
    _getLines();
    _getChannels();
    _getPromotions();
  }, []);

  const _filterParamsDefined = () =>
    filterParams.months !== '' &&
    filterParams.months !== undefined &&
    filterParams.year !== '' &&
    filterParams.year !== undefined;

  const noData = !clickedApply ? 'No hay datos' : 'No existen resultados válidos para su búsqueda.';

  return (
    <>
      <div className="dashboard-filtersTab-container">
        <div className="dashboard-input">
          <label className="input-label">CANAL DE VENTA</label>
          <Select
            placeholder="Canal de venta"
            loading={channelsLoading}
            disabled={channelsLoading}
            onChange={item => {
              if (item === undefined) {
                setPromotionDisabled(true);
                setPromotion(undefined);
                setChannel(undefined);
              }
              const i = JSON.parse(item);
              if (i.name === 'PROMOCION') setPromotionDisabled(false);
              else {
                setPromotionDisabled(true);
                setPromotion(undefined);
              }
              setChannel(i.id);
            }}
          >
            {channels.map(item => (
              <Option key={item.id} value={JSON.stringify(item)}>
                {item.name}
              </Option>
            ))}
            <Option key={channels.length} value={undefined}>
              NINGUNO
            </Option>
          </Select>
        </div>
        <div className="dashboard-input">
          <label className="input-label">PROMOCIÓN</label>
          <Select
            loading={promotionsLoading}
            disabled={promotionDisabled}
            value={promotion}
            placeholder="Promoción"
            onChange={value => setPromotion(value)}
          >
            {promotions.map(item => (
              <Option key={item.id} value={item.id}>
                {item.title}
              </Option>
            ))}
          </Select>
        </div>
        <div className="dashboard-input-button">
          <ButtonPrimary onClick={updateFilters} text={'APLICAR'} />
        </div>
      </div>
      <div className="summary-title-container">
        <span>RESULTADOS</span>
      </div>
      <div className="dashboard-results">
        <Spinner loading={loading}>
          <div className="market-tablet-content">
            <Card title="Markets" size="small" type="inner" bordered={false}>
              <p className="title-color">Unidades</p>
              <hr />
              <p className="title-color">%</p>
            </Card>
            {isEmpty(markets) ? (
              <Card title="Información" size="small" type="inner" bordered={false}>
                <p>{noData}</p>
                <hr />
                <p>{noData}</p>
              </Card>
            ) : (
              markets.map(market => (
                <Card
                  title={`Market ${market.description.replace('Market ', '')}`}
                  size="small"
                  type="inner"
                  bordered={false}
                >
                  <p>{market.soldUnits}</p>
                  <hr />
                  <p>{market.percentage}</p>
                </Card>
              ))
            )}
          </div>

          <div className="market-tablet-content">
            <Card title="Línea" size="small" type="inner" bordered={false}>
              <p className="title-color">Unidades</p>
              <hr />
              <p className="title-color">%</p>
            </Card>
            {isEmpty(lines) ? (
              <Card title="Información" size="small" type="inner" bordered={false}>
                <p>{noData}</p>
                <hr />
                <p>{noData}</p>
              </Card>
            ) : (
              lines.map(line => (
                <Card
                  title={`Línea ${line.description.replace(' Líneas', '')}`}
                  size="small"
                  type="inner"
                  bordered={false}
                >
                  <p>{line.soldUnits}</p>
                  <hr />
                  <p>{line.percentage}</p>
                </Card>
              ))
            )}
          </div>

          <div className="market-tablet-content">
            <Card type="inner" title="Aro" size="small" bordered={false}>
              <p className="title-color">Unidades</p>
              <hr />
              <p className="title-color">%</p>
            </Card>
            {isEmpty(aros) ? (
              <Card title="Información" size="small" type="inner" bordered={false}>
                <p>{noData}</p>
                <hr />
                <p>{noData}</p>
              </Card>
            ) : (
              aros.map(aro => (
                <Card
                  title={`Aro ${aro.description.replace(' Aros', '')}`}
                  size="small"
                  type="inner"
                  bordered={false}
                >
                  <p>{aro.soldUnits}</p>
                  <hr />
                  <p>{aro.percentage}</p>
                </Card>
              ))
            )}
          </div>
        </Spinner>
      </div>
    </>
  );
};

DashboardExpenseList.propTypes = {
  filterParams: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired
};

export default DashboardExpenseList;
