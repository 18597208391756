import helpers from './helpers';

// prices
const getBrands = makeGetRequest => () => makeGetRequest('/surveys/report-price/brands');
const getPaymentMethods = makeGetRequest => () =>
  makeGetRequest('/surveys/report-price/counter-payment-methods');
const getResaleMethods = makeGetRequest => () =>
  makeGetRequest('/surveys/report-price/resale-payment-methods');
const getMeasures = makeGetRequest => () => makeGetRequest('/surveys/report-price/measures');
const saveReportPrice = makePostRequest => data => makePostRequest('/surveys/report-price', data);
const getSurveyById = makeGetRequest => id => makeGetRequest(`/surveys/report-price/${id}`);
const getSurveysPrice = makeGetRequest => data => makeGetRequest('/surveys/report-price', data);
const updateSurveyPrice = makePutRequest => (id, data) =>
  makePutRequest(`/surveys/report-price/${id}`, data);
const deleteSurveyPrice = makeDeleteRequest => (id, data) =>
  makeDeleteRequest(`/surveys/report-price/${id}`, data);

// product
const saveSurveyProduct = makePostRequest => data =>
  makePostRequest('/surveys/report-product', data);
const updateSurveyProduct = makePutRequest => (id, data) =>
  makePutRequest(`/surveys/report-product/${id}`, data);
const getSurveyProduct = makeGetRequest => id => makeGetRequest(`/surveys/report-product/${id}`);
const getSurveyProducts = makeGetRequest => data => makeGetRequest('/surveys/report-product', data);
const deleteSurveyProduct = makeDeleteRequest => id =>
  makeDeleteRequest(`/surveys/report-product/${id}`);

// parametria brands
const saveAdminSurveyBrands = makePostRequest => data => makePostRequest('/surveys/brands', data);
const getAdminSurveyBrands = makeGetRequest => () => makeGetRequest('/surveys/brands');
const updateAdminSurveyBrands = makePutRequest => (id, data) =>
  makePutRequest(`/surveys/brands/${id}`, data);
const deleteAdminSurveyBrands = makeDeleteRequest => (id, data) =>
  makeDeleteRequest(`/surveys/brands/${id}`, data);

// parametria measures
const saveAdminSurveyMeasure = makePostRequest => data =>
  makePostRequest('/surveys/measures', data);
const updateAdminSurveyMeasure = makePutRequest => (id, data) =>
  makePutRequest(`/surveys/measures/${id}`, data);
const deleteAdminSurveyMeasure = makeDeleteRequest => id =>
  makeDeleteRequest(`/surveys/measures/${id}`);
const getAdminSurveyMeasure = makeGetRequest => () => makeGetRequest('/surveys/measures/');

export default client => {
  const { makeGetRequest, makePostRequest, makePutRequest, makeDeleteRequest } = helpers(client);
  return {
    getBrands: getBrands(makeGetRequest),
    getPaymentMethods: getPaymentMethods(makeGetRequest),
    getResaleMethods: getResaleMethods(makeGetRequest),
    getMeasures: getMeasures(makeGetRequest),
    saveReportPrice: saveReportPrice(makePostRequest),
    getSurveyById: getSurveyById(makeGetRequest),
    updateSurveyPrice: updateSurveyPrice(makePutRequest),
    getSurveysPrice: getSurveysPrice(makeGetRequest),
    deleteSurveyPrice: deleteSurveyPrice(makeDeleteRequest),
    // products
    saveSurveyProduct: saveSurveyProduct(makePostRequest),
    getSurveyProduct: getSurveyProduct(makeGetRequest),
    updateSurveyProduct: updateSurveyProduct(makePutRequest),
    getSurveyProducts: getSurveyProducts(makeGetRequest),
    deleteSurveyProduct: deleteSurveyProduct(makeDeleteRequest),

    // Parametria brands
    saveAdminSurveyBrands: saveAdminSurveyBrands(makePostRequest),
    getAdminSurveyBrands: getAdminSurveyBrands(makeGetRequest),
    updateAdminSurveyBrands: updateAdminSurveyBrands(makePutRequest),
    deleteAdminSurveyBrands: deleteAdminSurveyBrands(makeDeleteRequest),

    // Parametria measure
    saveAdminSurveyMeasure: saveAdminSurveyMeasure(makePostRequest),
    getAdminSurveyMeasure: getAdminSurveyMeasure(makeGetRequest),
    updateAdminSurveyMeasure: updateAdminSurveyMeasure(makePutRequest),
    deleteAdminSurveyMeasure: deleteAdminSurveyMeasure(makeDeleteRequest)
  };
};
