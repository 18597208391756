import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './_style.scss';
import UploadImage from '../../molecules/UploadImage/UploadImage';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import { Form, Input, InputNumber, message, Select } from 'antd';
import { useRedirect } from '../../Router/redirect';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import { ADMIN_CATALOGUE_LIST_URL } from '../../../utils/constants';
import { REQUIRED_FIELD_RULE } from '../../../utils/validation-rules';
import apiCall from '../../../services/api-calls/all';
import { isEmpty } from '../../../utils/func-helpers';
import { AVAILABLE_STOCK_MUST_BE_LESS, EMAIL_NOT_MATCH } from '../../../utils/validation-messages';
import Spinner from '../../atoms/Spinner/Spinner';

const { getCategories } = apiCall();
const { Option } = Select;
const { TextArea } = Input;

const CatalogueForm = ({ product = null, handleSubmit, submitText }) => {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [image, setImage] = useState(
    product && product.hasOwnProperty('image') ? product.image : null
  );
  const [stockReal, setStockReal] = useState(null);
  const [imageList, setImageList] = useState([]);
  const { redirect, setUrlToRedirect } = useRedirect();
  const [loading, setloading] = useState(false);

  const handleStockReal = e => {
    if (e) setStockReal(e);
    else setStockReal(0);
  };

  const searchCategories = async () => {
    try {
      setCategoriesLoading(true);
      const response = await getCategories();
      setCategories(response);
      setCategoriesLoading(false);
    } catch (error) {
      setCategoriesLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const updateFileData = (set, data, setObject) => {
    form.setFieldsValue(set);
    if (data) {
      setObject([
        {
          uid: '1',
          name: data.name ? data.name : product.title
        }
      ]);
    } else {
      setObject([]);
    }
  };

  useEffect(() => {
    searchCategories();
  }, []);

  useEffect(() => {
    updateFileData({ image }, image, setImageList);
  }, [image]);

  useEffect(() => {
    if (product && !isEmpty(product)) setloading(true);
  }, [product]);

  useEffect(() => {
    if (product && !isEmpty(product)) {
      form.setFieldsValue({
        ...product
      });
      setImage(product.image);
      setStockReal(product.realStock);
    }
    setloading(false);
  }, [product]);

  return (
    <Spinner loading={loading}>
      <Form form={form} onFinish={handleSubmit} className="catalogue-form-container">
        {redirect()}
        <div className="page-content catalogue-form">
          <div className="load-image">
            <Form.Item name="image">
              <UploadImage
                defaultImage={image ? { fileUrl: image } : {}}
                setFile={setImage}
                formats={['.png', '.jpg']}
                description="Los formatos aceptados son .jpg y .png. El tamaño máximo permitido para los archivos es 5MB."
                fileList={imageList}
              />
            </Form.Item>
          </div>
          <div className="inputs-container">
            <div className="input margin">
              <label className="input-label">
                Código <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="code">
                <Input className="input" placeholder="Codigo" />
              </Form.Item>
            </div>
            <div className="input margin">
              <label className="input-label">
                Categoría <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="idProductCategory">
                <Select loading={categoriesLoading} placeholder="Categoría">
                  {categories.map((category, index) => (
                    <Option key={index} value={category.id}>
                      {category.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="input margin">
              <label className="input-label">
                Título <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="title">
                <Input className="input" placeholder="Título" maxLength="50" />
              </Form.Item>
            </div>
            <div className="input margin">
              <label className="input-label">
                Subtítulo <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="description">
                <TextArea className="input" placeholder="Subtítulo" maxLength="50" />
              </Form.Item>
            </div>
            <div className="input margin">
              <label className="input-label">
                Stock Real <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="realStock">
                <InputNumber
                  min={0}
                  onChange={handleStockReal}
                  className="input"
                  placeholder="Stock Real"
                />
              </Form.Item>
            </div>
            <div className="input margin">
              <label className="input-label">
                Stock Disponible <span>*</span>
              </label>
              <Form.Item
                rules={[
                  REQUIRED_FIELD_RULE,
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('realStock') >= value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(AVAILABLE_STOCK_MUST_BE_LESS);
                    }
                  })
                ]}
                name="availableStock"
              >
                <InputNumber
                  min={0}
                  max={stockReal}
                  className="input"
                  placeholder="Stock Disponible"
                />
              </Form.Item>
            </div>
            <div className="input margin">
              <label className="input-label">
                Precio <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="price">
                <InputNumber min={0} step={0.01} className="input" placeholder="Precio" />
              </Form.Item>
            </div>
            <div className="input margin">
              <label className="input-label">
                Puntos <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="points">
                <InputNumber min={0} className="input" placeholder="Puntos" />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="buttons-container">
          <ButtonSecondary
            text="CANCELAR"
            theme="btn-cancelar"
            onClick={() => setUrlToRedirect(ADMIN_CATALOGUE_LIST_URL)}
          />
          <ButtonPrimary text={submitText} theme="btn-guardar" type="submit" />
        </div>
      </Form>
    </Spinner>
  );
};

CatalogueForm.propTypes = {
  product: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string
};

CatalogueForm.defaultProps = {
  product: null,
  submitText: 'GUARDAR'
};
export default CatalogueForm;
