import React from 'react';
import { Router } from './components/Router/router';
import 'antd/dist/antd.css';
import { UserProvider } from './services/providers/user-context';
import { CartProvider } from './services/providers/cart-provider';
import esES from 'antd/es/locale/es_ES';
import './App.scss';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import { NavbarProvider } from './services/providers/navbar-context';
import Footer from './components/atoms/Footer/Footer';

moment.locale('es');

const App = () => (
  <UserProvider>
    <NavbarProvider>
      <CartProvider>
        <ConfigProvider locale={esES}>
          <div className="App">
            <Router />
            <Footer />
          </div>
        </ConfigProvider>
      </CartProvider>
    </NavbarProvider>
  </UserProvider>
);

export default App;
