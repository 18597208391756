import React, {useContext, useState, useEffect} from 'react';
import './style.scss';
import Stock from '../../atoms/Stock/stock';
import PuntosIcon from '../../../img/star.svg';
import StockIcon from '../../../img/stock.svg';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import { ShoppingCartOutlined } from '@ant-design/icons';
import UserHelpers from "../../../utils/user-helpers";
import {CartContext} from "../../../services/providers/cart-provider";
import {message} from "antd";
import {processedErrorMessage} from "../../../services/api-calls/helpers";

const CatalogueCardsAdmin = ({ id, code, title, description, img, puntos, stock, precio }) => {
    const { canAddToCart } = UserHelpers();
    const {cartStatus, addItemToCart} = useContext(CartContext)
    const [added, setAdded] = useState(true);


    const onClickHandle = () => {
        setAdded(true)
        addItemToCart({idProduct: id}).then((response) => {
            message.success(response)
        }).catch(error => {
            message.error(processedErrorMessage(error))
        })
    }

    useEffect(()=>{
        if(cartStatus.products){
            setAdded(cartStatus.products.indexOf(id)>-1)
        }
    },[cartStatus])


    return (
        <div className="catalogue-card-container">
            <div
                className="catalogue-card-img-container"
                style={{
                    background: `url(${img}) no-repeat center center`,
                    backgroundSize: 'cover',
                    height: '250px',
                    borderRadius: '4px 4px 0 0'
                }}
            />
            <div className="catalogue-card-title">
                <p>{title}</p>
            </div>
            <div className="catalogue-card-description">
                <p>{description}. Código {code}.</p>
            </div>
            <div className="details-container">
                <Stock title="PUNTOS" icon={PuntosIcon} quantity={puntos} />
                <Stock title="STOCK" icon={StockIcon} quantity={stock} />
            </div>
            <div className="price-container">
                <h2>{precio}</h2>
                <ButtonPrimary disabled={!canAddToCart() || added} theme={!canAddToCart() || added ? 'disabled':''}
                               onClick={onClickHandle} text="AÑADIR AL CARRITO" iconLeft={<ShoppingCartOutlined />} />
            </div>
        </div>
    )
};

export default CatalogueCardsAdmin;
