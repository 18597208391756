import helpers from './helpers';

const getPreApprovalCategories = makeGetRequest => data =>
  makeGetRequest('/pre-approval/categories', data);

const getPreApproval = makeGetRequest => data => makeGetRequest('/pre-approval', data);

const getPreApprovalById = makeGetRequest => id => makeGetRequest(`/pre-approval/${id}`);

const updatePreApproval = makePutFileRequest => (id, data) =>
  makePutFileRequest(`/pre-approval/${id}`, data);

const getPreApprovalSubCategories = makeGetRequest => data =>
  makeGetRequest('/pre-approval/subcategories', data);

const getPreApprovalTypes = makeGetRequest => data => makeGetRequest('/pre-approval/types', data);

const createPreApproval = makePostFileRequest => data => makePostFileRequest('/pre-approval', data);

const getPreApprovalStates = makeGetRequest => () => makeGetRequest('/pre-approval/states');

const confirmPreApproval = makePutRequest => id => makePutRequest(`/pre-approval/${id}/confirm`);

const rejectPreApproval = makePutRequest => id => makePutRequest(`/pre-approval/${id}/reject`);

export default client => {
  const { makeGetRequest, makePutFileRequest, makePostFileRequest, makePutRequest } = helpers(
    client
  );
  return {
    getPreApprovalCategories: getPreApprovalCategories(makeGetRequest),
    getPreApprovalSubCategories: getPreApprovalSubCategories(makeGetRequest),
    getPreApprovalTypes: getPreApprovalTypes(makeGetRequest),
    createPreApproval: createPreApproval(makePostFileRequest),
    getPreApprovalStates: getPreApprovalStates(makeGetRequest),
    getPreApproval: getPreApproval(makeGetRequest),
    getPreApprovalById: getPreApprovalById(makeGetRequest),
    updatePreApproval: updatePreApproval(makePutFileRequest),
    confirmPreApproval: confirmPreApproval(makePutRequest),
    rejectPreApproval: rejectPreApproval(makePutRequest)
  };
};
