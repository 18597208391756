import React, { useState, useEffect } from 'react';
import './_style.scss';
import TitlePage from '../../atoms/TitlePage/TitlePage';
import History from '../../atoms/Breadcrumb/history';
import { message } from 'antd';
import SurveyForm from '../../organism/SurveyForm/SurveyForm';
import { SURVEYS } from '../../../utils/constants';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCall from '../../../services/api-calls/all';
import { useRedirect } from '../../Router/redirect';
import Spinner from '../../atoms/Spinner/Spinner';

const breadcrumbs = [
  {
    title: 'Relevamientos',
    href: SURVEYS
  },
  {
    title: 'Editar Relevamiento',
    href: ''
  }
];
const { saveReportPrice, getSurveyById, updateSurveyPrice } = apiCall();
const SurveyEdit = ({ match }) => {
  const { params } = match;
  const [survey, setSurvey] = useState({});
  const { redirect, setUrlToRedirect } = useRedirect();
  const [loading, setLoading] = useState(false);

  const handleUpdate = async values => {
    try {
      setLoading(true);
      await updateSurveyPrice(params.id, Object.assign({}, {
        counterPrice: null,
        idCounterPaymentMethod: null,
        idResalePaymentMethod: null,
        resalePrice: null,
        observations: null
      }, values));
      setLoading(false);
      message.success('¡Tu relevamiento ha sido actualizado!');
      setUrlToRedirect(SURVEYS);
    } catch (error) {
      const { data } = error.response;
      const errorMessage = processedErrorMessage(error, data);
      setLoading(false);
      message.error(errorMessage);
    }
  };
  const _getSurveyById = async () => {
    try {
      setLoading(true);
      const response = await getSurveyById(params.id);
      setSurvey(response);
      setLoading(false);
    } catch (error) {
      const { data } = error.response;
      const errorMessage = processedErrorMessage(error, data);
      setLoading(false);
      message.error(errorMessage);
    }
  };
  useEffect(() => {
    _getSurveyById();
  }, []);

  return (
    <Spinner loading={loading}>
      <div className="create-survey-container">
        {redirect()}
        <History breadcrumbs={breadcrumbs} />
        <div className="title">
          <TitlePage text="EDITAR RELEVAMIENTO" />
        </div>
        <SurveyForm survey={survey} handleSubmit={handleUpdate} />
      </div>
    </Spinner>
  );
};

export default SurveyEdit;
