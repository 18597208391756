import React, { useState, useEffect } from 'react';
import './_style.scss';
import { Input, Select, message, Space } from 'antd';

import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import PaginatedTable from '../../molecules/PaginatedTable/paginated-table.jsx';
import { columns } from '../../../utils/tables/table-clients-admin';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCalls from '../../../services/api-calls/all';
import { ADMIN_CLIENTS_CREATE_URL, ADMIN_CLIENTS_URL, PAGE_SIZE } from '../../../utils/constants';
import { useRedirect } from '../../Router/redirect';

import { DownloadOutlined } from '@ant-design/icons';
import { UserHelpers } from '../../../utils/user-helpers';
import { downloadPdf } from '../../../utils/func-helpers';

const { getClientsFiltered, getStates, getCities, downloadCustomersFile } = apiCalls();

const { Search } = Input;

const AdminClientLists = () => {
  const [search, setSearch] = useState('');
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const filters = [state, city, search];
  const { redirect, setUrlToRedirect } = useRedirect();
  const { canCreate, canUpdate, canView } = UserHelpers();
  const { Option } = Select;

  const formatTableData = data =>
    data.content.map((client, i) => ({ key: (i + 1).toString(), ...client }));

  const handleOnEdit = id => {
    setUrlToRedirect(`${ADMIN_CLIENTS_URL}/${id}`);
  };

  const searchCities = async idState => {
    try {
      const response = await getCities(idState);
      setCities(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const searchStates = async () => {
    try {
      const response = await getStates();
      setStates(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const prepareDownload = async () => {
    try {
      const filter = {
        search,
        idState: state ? state : null,
        idCity: city ? city : null
      };
      const fileData = await downloadCustomersFile(filter);
      downloadPdf(fileData, 'clientes.csv');
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const populateClients = async (page, pageSize, onResult) => {
    try {
      const filter = {
        page,
        size: pageSize,
        search,
        idState: state ? state : null,
        idCity: city ? city : null
      };
      const response = await getClientsFiltered(filter);
      onResult(response);
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  useEffect(() => {
    if (state) {
      setCity(undefined);
      setCities([]);
      searchCities(state);
    } else {
      setCity(undefined);
      setCities([]);
    }
  }, [state]);

  useEffect(() => {
    searchStates();
  }, []);

  return (
    <>
      {redirect()}
      <div className="inputs_container">
        <Space className="admin-promotions">
          <div className="search_btn">
            <Search
                className="search_btn"
                placeholder="Buscar"
                onSearch={value => setSearch(value)}
                enterButton
            />
          </div>
          <div className="select-container">
            <Select
              className="select-clients"
              placeholder="Provincia"
              onChange={value => setState(value)}
            >
              {states.map(state => (
                <Option key={state.id} value={state.id}>
                  {state.name}
                </Option>
              ))}
              <Option key={0} value={0}>
                Todas
              </Option>
            </Select>
          </div>
          <div className="select-container">
            <Select
              className="select-clients"
              disabled={cities.length === 0}
              value={state > 0 ? city : undefined}
              placeholder="Localidad"
              onChange={value => setCity(value)}
            >
              {cities.map(city => (
                <Option key={city.id} value={city.id}>
                  {city.name}
                </Option>
              ))}
              <Option key={0} value={0}>
                Todas
              </Option>
            </Select>
          </div>
          <div className="btns-clients-container">
            <div className="btn-download-clients">
              <ButtonPrimary
                theme="btn-blue"
                text="DESCARGAR"
                iconLeft={<DownloadOutlined />}
                onClick={prepareDownload}
              />
            </div>
            <div className="btn-create-clients">
              {canCreate() && (
                <ButtonPrimary
                  theme="btn-blue"
                  text="CREAR NUEVO CLIENTE"
                  onClick={() => setUrlToRedirect(ADMIN_CLIENTS_CREATE_URL)}
                />
              )}
            </div>
          </div>
        </Space>
      </div>
      <div className="page-content">
        <PaginatedTable
          className="table_promociones"
          columns={columns(canUpdate() || canView() ? handleOnEdit : null)}
          onFetch={populateClients}
          onFormat={formatTableData}
          pageSize={PAGE_SIZE}
          filters={filters}
          scroll={{ x: 800, y: '40vh' }}
        />
      </div>
    </>
  );
};

export default AdminClientLists;
