const getUsers = () => ({content: [
	{
		"id": 8,
		"name": "Federico",
		"lastName": "Catalá",
		"email": "fcatala@atixlabs.com",
		"phone": "42358350",
		"username": "fcatala@atixlabs.com",
		"role": {
			"id": 1,
			"code": "ROLE_ADMIN",
			"description": "Administrator"
		},
		"active": true,
		"customer": {
			"id": 1,
			"imagePath": "/tmp/goodyear-files/default-news2020-09-28T16:11:35.409961.jpg",
			"registeredName": "GOODYEAR S.R.L",
			"commercialName": "GoodYear",
			"cuit": "123456789",
			"taxAddress": "Dirección fiscal",
			"city": {
				"id": 1,
				"name": "Lomas de Zamora"
			},
			"branchOffices": [
				{
					"id": 1,
					"name": "Nombre",
					"address": "Direccion",
					"city": {
						"id": 1,
						"name": "Lomas de Zamora"
					},
					"active": true,
					"responsiblePhone": "1234",
					"responsibleEmail": "asd@email.com",
					"sapCode": 3
				}
			],
			"sapCode": 1
		},
		"branchOffice": {
			"id": 1,
			"name": "Nombre",
			"address": "Direccion",
			"city": {
				"id": 1,
				"name": "Lomas de Zamora"
			},
			"active": true,
			"responsiblePhone": "1234",
			"responsibleEmail": "asd@email.com",
			"sapCode": 3
		},
		"points": 1000
	},
	{
		"id": 9,
		"name": "Federico 2",
		"lastName": "Catalá",
		"email": "fcatala@atixlabs.com",
		"phone": "42358357",
		"username": "fcatala@atixlabs.com",
		"role": {
			"id": 1,
			"code": "ROLE_ADMIN",
			"description": "Administrator"
		},
		"active": true,
		"customer": {
			"id": 1,
			"imagePath": "/tmp/goodyear-files/default-news2020-09-28T16:11:35.409961.jpg",
			"registeredName": "GOODYEAR S.R.L",
			"commercialName": "GoodYear",
			"cuit": "123456789",
			"taxAddress": "Dirección fiscal",
			"city": {
				"id": 1,
				"name": "Lomas de Zamora"
			},
			"branchOffices": [
				{
					"id": 1,
					"name": "Nombre",
					"address": "Direccion",
					"city": {
						"id": 1,
						"name": "Lomas de Zamora"
					},
					"active": true,
					"responsiblePhone": "1234",
					"responsibleEmail": "asd@email.com",
					"sapCode": 3
				}
			],
			"sapCode": 1
		},
		"branchOffice": {
			"id": 1,
			"name": "Nombre",
			"address": "Direccion",
			"city": {
				"id": 1,
				"name": "Lomas de Zamora"
			},
			"active": true,
			"responsiblePhone": "1234",
			"responsibleEmail": "asd@email.com",
			"sapCode": 3
		},
		"points": 1000
	}
]});

const getUsersFiltered = () => (
	{content: [
	{
		"id": 8,
		"name": "Federico",
		"lastName": "Catalá",
		"email": "fcatala@atixlabs.com",
		"phone": "42358350",
		"username": "fcatala@atixlabs.com",
		"role": {
			"id": 1,
			"code": "ROLE_ADMIN",
			"description": "Administrator"
		},
		"active": true,
		"customer": {
			"id": 1,
			"imagePath": "/tmp/goodyear-files/default-news2020-09-28T16:11:35.409961.jpg",
			"registeredName": "GOODYEAR S.R.L",
			"commercialName": "GoodYear",
			"cuit": "123456789",
			"taxAddress": "Dirección fiscal",
			"city": {
				"id": 1,
				"name": "Lomas de Zamora"
			},
			"branchOffices": [
				{
					"id": 1,
					"name": "Nombre",
					"address": "Direccion",
					"city": {
						"id": 1,
						"name": "Lomas de Zamora"
					},
					"active": true,
					"responsiblePhone": "1234",
					"responsibleEmail": "asd@email.com",
					"sapCode": 3
				}
			],
			"sapCode": 1
		},
		"branchOffice": {
			"id": 1,
			"name": "Nombre",
			"address": "Direccion",
			"city": {
				"id": 1,
				"name": "Lomas de Zamora"
			},
			"active": true,
			"responsiblePhone": "1234",
			"responsibleEmail": "asd@email.com",
			"sapCode": 3
		},
		"points": 1000
	},
			{
				"id": 10,
				"name": "Federico",
				"lastName": "Catalá",
				"email": "fcatala@atixlabs.com",
				"phone": "42358350",
				"username": "fcatala@atixlabs.com",
				"role": {
					"id": 1,
					"code": "ROLE_ADMIN",
					"description": "Administrator"
				},
				"active": true,
				"customer": {
					"id": 1,
					"imagePath": "/tmp/goodyear-files/default-news2020-09-28T16:11:35.409961.jpg",
					"registeredName": "GOODYEAR S.R.L",
					"commercialName": "GoodYear",
					"cuit": "123456789",
					"taxAddress": "Dirección fiscal",
					"city": {
						"id": 1,
						"name": "Lomas de Zamora"
					},
					"branchOffices": [
						{
							"id": 1,
							"name": "Nombre",
							"address": "Direccion",
							"city": {
								"id": 1,
								"name": "Lomas de Zamora"
							},
							"active": true,
							"responsiblePhone": "1234",
							"responsibleEmail": "asd@email.com",
							"sapCode": 3
						}
					],
					"sapCode": 1
				},
				"branchOffice": {
					"id": 1,
					"name": "Nombre",
					"address": "Direccion",
					"city": {
						"id": 1,
						"name": "Lomas de Zamora"
					},
					"active": true,
					"responsiblePhone": "1234",
					"responsibleEmail": "asd@email.com",
					"sapCode": 3
				},
				"points": 1000
			}
],
		"pageable": {
			"sort": {
				"sorted": false,
				"unsorted": true,
				"empty": true
			},
			"pageNumber": 0,
			"pageSize": 20,
			"offset": 0,
			"unpaged": false,
			"paged": true
		},
		"last": true,
		"totalPages": 1,
		"totalElements": 1,
		"first": true,
		"sort": {
			"sorted": false,
			"unsorted": true,
			"empty": true
		},
		"number": 0,
		"numberOfElements": 1,
		"size": 20,
		"empty": false});



export default () => ({
	getUsers,
	getUsersFiltered
});