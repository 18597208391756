import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, message, Select, InputNumber } from 'antd';
import { REQUIRED_FIELD_RULE, EMAIL_RULE } from '../../../utils/validation-rules';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCall from '../../../services/api-calls/all';
const { Option } = Select;

const { getStates, getCities } = apiCall();

const ClientFormPersonalData = ({ form, defaultStateId, defaultCityId }) => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const searchStates = async () => {
    try {
      const response = await getStates();
      setStates(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const searchCities = async idState => {
    try {
      const response = await getCities(idState);
      setCities(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  useEffect(() => {
    searchStates();
  }, []);

  useEffect(() => {
    if (defaultStateId && defaultCityId) {
      searchCities(defaultStateId).then(() => {
        form.setFieldsValue({
          idState: defaultStateId,
          idCity: defaultCityId
        });
      });
    }
  }, [defaultStateId, defaultCityId]);

  return (
    <>
      <div className="input input-margin-right ">
        <span className="input-label">Razón Social*</span>
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name="registeredName">
          <Input className="input" placeholder="Razón Social" />
        </Form.Item>
      </div>
      <div className="input">
        <span className="input-label">Nombre Comercial*</span>
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name="commercialName">
          <Input className="input" placeholder="Nombre Comercial" />
        </Form.Item>
      </div>
      <div className="input input-margin-right ">
        <span className="input-label">CUIT*</span>
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name="cuit">
          <InputNumber className="input" placeholder="CUIT" />
        </Form.Item>
      </div>
      <div className="input">
        <span className="input-label">Direccion Fiscal*</span>
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name="taxAddress">
          <Input className="input" placeholder="Direccion Fiscal" />
        </Form.Item>
      </div>
      <div className="input input-margin-right ">
        <span className="text-area-label">Provincia*</span>
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name="idState">
          <Select
            placeholder="Provincia"
            onChange={value => {
              form.setFieldsValue({
                idCity: null
              });
              searchCities(value);
            }}
          >
            {states.map((state, index) => (
              <Option key={index} value={state.id}>
                {state.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className="input">
        <span className="text-area-label">Localidad*</span>
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name="idCity">
          <Select disabled={!cities.length} placeholder="Localidad">
            {cities.map((city, index) => (
              <Option key={index} value={city.id}>
                {city.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className="input">
        <span className="input-label">Código sap*</span>
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name="sapCode">
          <InputNumber className="input" placeholder="Código sap" maxLength={6} />
        </Form.Item>
      </div>
      <div className="subtitle-container">
        <p>DATOS RESPONSABLE</p>
      </div>
      <div className="input-group">
        <div className="input-phone">
          <span className="input-label">Teléfono*</span>
          <Form.Item rules={[REQUIRED_FIELD_RULE]} name="responsiblePhone">
            <Input className="input" placeholder="Teléfono" />
          </Form.Item>
        </div>
        <div className="input">
          <span className="input-label">E-mail*</span>
          <Form.Item rules={[REQUIRED_FIELD_RULE, EMAIL_RULE]} name="responsibleEmail">
            <Input className="input" placeholder="E-mail" />
          </Form.Item>
        </div>
      </div>
    </>
  );
};

ClientFormPersonalData.propTypes = {
  form: PropTypes.object.isRequired,
  defaultStateId: PropTypes.number,
  defaultCityId: PropTypes.number
};

export default ClientFormPersonalData;
