import React from 'react';
import { Checkbox, Space, Tooltip } from 'antd';
import ButtonPrimary from '../../components/atoms/ButtonPrimary/button-primary';
import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  EyeFilled,
  DownloadOutlined
} from '@ant-design/icons';
import { dateFormat } from '../func-helpers';
import img from '../../img/gorra.jpg';
import OrderDetail from '../../components/organism/OrderDetailModal/order-detail-modal';
import { APPROVED, DECLINED, PENDING } from '../constants';

export const columns = (onEdit, onDownload) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'name'
  },
  {
    title: 'ESTADO',
    dataIndex: 'state',
    key: 'status',
    render: ({ description }) => {
      if (description === PENDING)
        return (
          <Tooltip title="Pendiente">
            <ClockCircleFilled style={{ color: '#FED102' }} />
          </Tooltip>
        );
      if (description === APPROVED)
        return (
          <Tooltip title="Confirmado">
            <CheckCircleFilled style={{ color: '#5BC535' }} />
          </Tooltip>
        );
      if (description === DECLINED)
        return (
          <Tooltip title="Rechazado">
            <CloseCircleFilled style={{ color: '#FF2E2E' }} />
          </Tooltip>
        );
    }
  },
  {
    title: 'NOTIFICACIONES',
    dataIndex: 'modified',
    render: modified =>
      modified && (
        <div className="modifications">
          <ExclamationCircleFilled
            style={{ color: '#FF5C00', fontSize: '20px', marginRight: '10px' }}
          />{' '}
          Su pedido ha sufrido modificaciones
        </div>
      )
  },

  {
    title: 'ACCIONES',
    key: 'acciones',
    render: ({ id }) => (
      <Space>
        <ButtonPrimary
          onClick={() => onDownload(id)}
          theme="actions-preapproval-btn"
          iconLeft={<DownloadOutlined />}
        />
        <ButtonPrimary onClick={() => onEdit(id)} theme="promo-btn" iconLeft={<EyeFilled />} />
      </Space>
    )
  }
];
