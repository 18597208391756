import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Upload, message } from 'antd';
import loadImage from '../../../img/load-image.svg';
import { UploadOutlined } from '@ant-design/icons';
import './style.scss';

const { Dragger } = Upload;

const UploadImage = ({ description, formats, setFile, fileList, defaultImage, required }) => {
  const [fileUrl, setFileUrl] = useState(null);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    return reader.readAsDataURL(img);
  };

  const properties = {
    name: 'file',
    accept: formats,
    multiple: false,
    beforeUpload: fileTemporary => {
      const isJpgOrPng = fileTemporary.type === 'image/jpeg' || fileTemporary.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('La imagen cargada no respeta las condiciones de formato.');
      }
      const isLt5M = fileTemporary.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error('La imagen cargada no respeta el limite de 5MB');
      }
      return !isJpgOrPng || !isLt5M;
    }
  };

  const onFileChanged = data => {
    const { status } = data.file;

    if (
      status === 'done' ||
      (status !== 'uploading' && status !== 'error' && status !== 'removed')
    ) {
      setFile(data.file);
      getBase64(data.file, fileUrl => {
        setFileUrl({ fileUrl });
      });
      //message.success(`${data.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`La imagen cargada no respeta las condiciones de formato.`);
    } else if (status === 'removed') {
      setFile(null);
      setFileUrl(null);
    }
  };

  return (
    <div>
      <div className="image-container">
        <img
          className="image"
          src={fileUrl ? fileUrl.fileUrl : defaultImage.fileUrl ? defaultImage.fileUrl : loadImage}
          alt=""
        />
      </div>
      <div> {required ? <label className="input-label">Imagen requerida <span>*</span> </label> : ''}
        <div className="load-image-title-container">
          <Dragger {...properties} onChange={onFileChanged} fileList={fileList}>
            <button type="button">
              <UploadOutlined color="red" />
              <span className="load-image-title">CARGAR IMAGEN</span>
            </button>
          </Dragger>
        </div>
        <div>
          <p className="load-image-description">{description}</p>
        </div>
      </div>
    </div>
  );
};

UploadImage.propTypes = {
  defaultImage: PropTypes.object,
  description: PropTypes.string.isRequired,
  formats: PropTypes.array.isRequired,
  setFile: PropTypes.func.isRequired,
  fileList: PropTypes.array.isRequired,
  required: PropTypes.bool
};

export default UploadImage;
