import helpers from './helpers';

const getPromotions = makeGetRequest => data => makeGetRequest('promotions/current', data);

const getPromotionsFiltered = makeGetRequest => data => makeGetRequest('promotions/filtered', data);

const getPromotionStates = makeGetRequest => data => makeGetRequest('promotions/states', data);

const showPromotion = makeGetRequest => id => makeGetRequest(`promotions/${id}`);

const createPromotion = makePostFileRequest => data => makePostFileRequest('promotions', data);

const editPromotion = makePutFileRequest => (id, data) =>
  makePutFileRequest(`promotions/${id}`, data);

const destroyPromotion = makePutRequest => id => makePutRequest(`promotions/inactivate/${id}`);

const getPromotionsList = makeGetRequest => () => makeGetRequest('/promotions/list');

export default client => {
  const { makeGetRequest, makePostFileRequest, makePutFileRequest, makePutRequest } = helpers(
    client
  );
  return {
    getPromotions: getPromotions(makeGetRequest),
    getPromotionsFiltered: getPromotionsFiltered(makeGetRequest),
    getPromotionStates: getPromotionStates(makeGetRequest),
    showPromotion: showPromotion(makeGetRequest),
    createPromotion: createPromotion(makePostFileRequest),
    editPromotion: editPromotion(makePutFileRequest),
    destroyPromotion: destroyPromotion(makePutRequest),
    getPromotionsList: getPromotionsList(makeGetRequest)
  };
};
