import helpers from './helpers';

const getCategories  = makeGetRequest => data => makeGetRequest('products/categories', data);

export default client => {
	const { makeGetRequest } = helpers(client);
	return {
		getCategories: getCategories(makeGetRequest)
	};
};
