import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../../services/providers/user-context';
import { loginInputs } from '../../../utils/form_inputs/inputs-login';
import apiCalls from '../../../services/api-calls/all';
import AntForm from '../../molecules/ant-form';
import { EXPIRED_SESSION_MESSAGE } from '../../../utils/validation-messages';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import { CREDENTIALS_URL, EXPIRED_SESSION } from '../../../utils/constants';
import './_style.scss';
import '../../../css/app.scss';
import Loader from '../../atoms/Loader/loader';
import logo from '../../../img/logo-login.png';
import { message } from 'antd';

const { loginRequest } = apiCalls();

const expiredSession = () => {
  message.error(EXPIRED_SESSION_MESSAGE);
  localStorage.removeItem(EXPIRED_SESSION);
};

const Login = ({ history }) => {
  const { user, setUser } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (user.accessToken) {
      history.push(CREDENTIALS_URL);
    }
  }, [user.accessToken, history]);

  const expiredSessionFlag = localStorage.getItem(EXPIRED_SESSION);

  const login = async values => {
    setLoading(true);
    setErrorMessage(null);
    try {
      const response = await loginRequest(values);
      setUser(response.data);
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      setErrorMessage(errorMessage);
      setLoading(false);
    }
  };

  return (
    <div className="container-app-login">
      {expiredSessionFlag ? expiredSession() : undefined}
      <div className="login-logo" />
      <div className="login-form-container">
        <div className="logo-container">
          <img style={{ width: '50%' }} src={logo} alt="" />
        </div>
        <div className="login-title">
          <h1>BIENVENIDOS A NUESTRA INTRANET</h1>
        </div>
        <div className="login-description">
          <p>Ingresa tus datos</p>
        </div>

        <div className="form-with-loader">
          <AntForm
            inputs={loginInputs}
            handleSubmit={login}
            submitText={'Login'}
            disabled={loading}
          />
          <Loader loading={loading} />
          {errorMessage && <div className="error">{errorMessage}</div>}
        </div>
      </div>
    </div>
  );
};

export default Login;
