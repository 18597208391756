import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, InputNumber, message, Radio, Select } from 'antd';
import { REQUIRED_FIELD_RULE, EMAIL_RULE } from '../../../utils/validation-rules';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCall from '../../../services/api-calls/all';
import ButtonPrimary from "../../atoms/ButtonPrimary/button-primary";
const { Option } = Select;

const { getCities } = apiCall();

const ClientFormBranchOffice = ({ form, states, index, defaultStateId, defaultCityId, onDestroy }) => {
  const [cities, setCities] = useState([]);

  const searchCities = async idState => {
    try {
      const response = await getCities(idState);
      setCities(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  useEffect(() => {
    if (defaultStateId && defaultCityId) {
      searchCities(defaultStateId).then(() => {
        let object = {};
        object[`branch${index}`] = {
          idState: defaultStateId,
          idCity: defaultCityId
        };
        form.setFieldsValue(object);
      });
    }
  }, [defaultStateId, defaultCityId]);

  return (
    <>
      <div className="input input-margin-right ">
        <span className="input-label">Nombre Comercial*</span>
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name={[`branch${index}`, 'name']}>
          <Input className="input" placeholder="Nombre Comercial" />
        </Form.Item>
      </div>
      <div className="input">
        <span className="input-label">Direccion Fiscal*</span>
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name={[`branch${index}`, 'address']}>
          <Input className="input" placeholder="Direccion Fiscal" />
        </Form.Item>
      </div>
      <div className="input input-margin-right ">
        <span className="text-area-label">Provincia*</span>
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name={[`branch${index}`, 'idState']}>
          <Select
            placeholder="Provincia"
            onChange={value => {
              let object = {};
              object[`branch${index}`] = { idCity: null };
              form.setFieldsValue(object);
              searchCities(value);
            }}
          >
            {states.map((state, index) => (
              <Option key={index} value={state.id}>
                {state.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className="input">
        <span className="text-area-label">Localidad*</span>
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name={[`branch${index}`, 'idCity']}>
          <Select disabled={!cities.length} placeholder="Localidad">
            {cities.map((city, index) => (
              <Option key={index} value={city.id}>
                {city.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className="input input-margin-right ">
        <span className="input-label">Código sap*</span>
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name={[`branch${index}`, 'sapCode']}>
          <InputNumber className="input" placeholder="Código sap" maxLength={6} />
        </Form.Item>
      </div>
      <div className="input">
        <span className="input-label">Puntos*</span>
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name={[`branch${index}`, 'points']}>
          <InputNumber className="input" placeholder="Puntos" />
        </Form.Item>
      </div>
      <div className="radio-buttons">
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name={[`branch${index}`, 'active']}>
          <Radio.Group
            onChange={event => {
              let object = {};
              object[`branch${index}`] = { active: event.target.value };
              form.setFieldsValue(object);
            }}
          >
            <Radio value={true}>Habilitar</Radio>
            <Radio value={false}>Deshabilitar</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <div className="subtitle-container">
        <p>DATOS RESPONSABLE SUCURSAL</p>
      </div>
      <div className="input-phone">
        <span className="input-label">Teléfono*</span>
        <Form.Item rules={[REQUIRED_FIELD_RULE]} name={[`branch${index}`, 'responsiblePhone']}>
          <Input className="input" placeholder="Teléfono" />
        </Form.Item>
      </div>
      <div className="input">
        <span className="input-label">E-mail*</span>
        <Form.Item rules={[REQUIRED_FIELD_RULE, EMAIL_RULE]} name={[`branch${index}`, 'responsibleEmail']}>
          <Input className="input" placeholder="E-mail" />
        </Form.Item>
      </div>
      { onDestroy ? (
        <div className="destroy-container-button">
          <ButtonPrimary
            onClick={onDestroy}
            text="Eliminar Sucursal"
            theme="btn-destroy"
          />
        </div>
      ) : ''}
    </>
  );
};

ClientFormBranchOffice.propTypes = {
  form: PropTypes.object,
  states: PropTypes.array,
  index: PropTypes.number
};

export default ClientFormBranchOffice;
