import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './_style.scss';
import UploadImage from '../../molecules/UploadImage/UploadImage';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import { Collapse, Form, message } from 'antd';
import { useRedirect } from '../../Router/redirect';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import { ADMIN_CLIENTS_URL } from '../../../utils/constants';
import apiCall from '../../../services/api-calls/all';
import { isEmpty } from '../../../utils/func-helpers';
import ClientFormPersonalData from '../../molecules/ClientFormPersonalData/ClientFormPersonalData';
import ClientFormBranchOffice from '../../molecules/ClientFormBranchOffice/ClientFormBranchOffice';
import { UserHelpers } from '../../../utils/user-helpers';
import { REQUIRED_FIELD_RULE } from '../../../utils/validation-rules';

const { editClient, createClient, getStates } = apiCall();
const { Panel } = Collapse;

const ClientForm = ({ client = null }) => {
  const [form] = Form.useForm();
  const [states, setStates] = useState([]);
  const [image, setImage] = useState(
    client && client.hasOwnProperty('image') ? client.image : null
  );
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [branchOffices, setBranchOffices] = useState([0]);
  const [activeKeys, setActiveKeys] = useState([1]);
  const [branchCounter, setBranchCounter] = useState(0);
  const { redirect, setUrlToRedirect } = useRedirect();
  const { canCreate, canUpdate } = UserHelpers();

  const handleSubmit = async values => {
    if (loading) return;
    setLoading(true);
    try {
      const formData = new FormData();
      delete values['image'];
      values['branchOffices'] = branchOffices.map((branchOffice, index) => {
        const data =
          client && client.branchOffices.hasOwnProperty(branchOffice)
            ? Object.assign({ id: client.branchOffices[branchOffice].id }, values[`branch${branchOffice}`])
            : values[`branch${branchOffice}`];
        delete values[`branch${branchOffice}`];
        return data;
      });

      formData.set('customerProperties', JSON.stringify(values));

      if (image) {
        image.name ? formData.set('image', image) : formData.set('image', new Blob())
      } else {
        formData.set('image', null);
      }

      if (client) {
        await editClient(client.id, formData);
      } else {
        await createClient(formData);
      }
      message.success('Acción realizada con éxito');
      setLoading(false);
      setUrlToRedirect(ADMIN_CLIENTS_URL);
    } catch (error) {
      const errorMessage =
        error.response && error.response.data ? error.response.data : processedErrorMessage(error);
      message.error(errorMessage);
      setLoading(false);
    }
  };

  const searchStates = async () => {
    try {
      const response = await getStates();
      setStates(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const handleDestroy = (index, branchOffice) => {
    const branch = [...branchOffices];
    branch.splice(index, 1);
    setBranchOffices(branch);
    let object = {};
    object[`branch${branchOffice}`] = {};
    form.setFieldsValue(object);
  };

  useEffect(() => {
    setActiveKeys(branchOffices.length);
  }, [branchOffices]);

  useEffect(() => {
    searchStates();
  }, []);

  useEffect(() => {
    form.setFieldsValue({ image });
    if (image) {
      setImageList([
        {
          uid: '1',
          name: image.name ? image.name : image.title
        }
      ]);
    } else {
      setImageList([]);
    }
  }, [image]);

  useEffect(() => {
    if (client && !isEmpty(client)) {
      form.setFieldsValue(client);
      client.branchOffices.map((branchOffice, index) => {
        let object = {};
        object[`branch${index}`] = branchOffice;
        form.setFieldsValue(object);
      });
      setBranchOffices(Array(client.branchOffices.length).fill().map((office, index) => index));
      setBranchCounter(client.branchOffices.length);
      setActiveKeys(Array(client.branchOffices.length).fill().map((office, index) => index + 1));
      setImage(client.image);
    }
  }, [client]);

  return (
    <Form form={form} onFinish={handleSubmit} className="client-form-container">
      {redirect()}
      <div className="page-content client-form">
        <div className="load-image">
          <Form.Item name="image">
            <UploadImage
              defaultImage={image ? { fileUrl: image } : {}}
              setFile={setImage}
              formats={['.png', '.jpg']}
              description="Los formatos aceptados son .jpg y .png. El tamaño máximo permitido para los archivos es 5MB."
              fileList={imageList}
            />
          </Form.Item>
        </div>
        <div className='form-container'>
          <div className="inputs-container">
            <ClientFormPersonalData
                form={form}
                defaultStateId={client ? client.idState : null}
                defaultCityId={client ? client.idCity : null}
            />
            <Collapse
                activeKey={activeKeys}
                onChange={keys => setActiveKeys(keys)}
                className="accordion"
            >
              {branchOffices.map((branchOffice, index) => (
                  <Panel header={`Sucursal ${index + 1}`} key={index + 1}>
                    <ClientFormBranchOffice
                        form={form}
                        states={states}
                        index={branchOffice}
                        defaultStateId={
                          client && client.branchOffices.hasOwnProperty(branchOffice)
                              ? client.branchOffices[branchOffice].idState
                              : null
                        }
                        defaultCityId={
                          client && client.branchOffices.hasOwnProperty(branchOffice)
                              ? client.branchOffices[branchOffice].idCity
                              : null
                        }
                        onDestroy={
                          (branchOffices.length > 1 && !client) || (client && !client.branchOffices.hasOwnProperty(branchOffice))
                              ? () => handleDestroy(index, branchOffice)
                              : null
                        }
                    />
                  </Panel>
              ))}
            </Collapse>
            <ButtonPrimary
                text="Agregar Sucursal"
                theme="btn-add"
                onClick={() => {
                  const branch = [...branchOffices];
                  branch.push(branchCounter + 1);
                  setBranchCounter(branchCounter + 1);
                  setBranchOffices(branch);
                }}
            />
          </div>
        </div>

      </div>
      <div className="buttons-container">
        <ButtonSecondary
          text="CANCELAR"
          theme="btn-cancelar"
          onClick={() => setUrlToRedirect(ADMIN_CLIENTS_URL)}
        />
        <ButtonPrimary
          text="GUARDAR"
          theme={
            (canCreate() && !client) || (canUpdate() && client) ? 'btn-guardar' : 'ThemeDisable'
          }
          type="submit"
          disabled={(!canCreate() && !client) || (!canUpdate() && client)}
        />
      </div>
    </Form>
  );
};

ClientForm.propTypes = {
  client: PropTypes.object
};

export default ClientForm;
