import React, { useState, useEffect } from 'react';
import './_style.scss';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import ButtonPrimary from '../../../atoms/ButtonPrimary/button-primary';
import { Menu, Dropdown, Button, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import apiCalls from '../../../../services/api-calls/all';
import OrderList from '../../../organism/OrderList/OrderList';
import { processedErrorMessage } from '../../../../services/api-calls/helpers';
import Search from 'antd/es/input/Search';
import { PAGE_SIZE } from '../../../../utils/constants';
import Spinner from '../../../atoms/Spinner/Spinner';

const { getOrders, getOrderStates } = apiCalls();

const menu = (
  <Menu>
    <Menu.Item key="1">1st menu item</Menu.Item>
    <Menu.Item key="2">2nd menu item</Menu.Item>
    <Menu.Item key="3">3rd menu item</Menu.Item>
  </Menu>
);

const OrderListPage = () => {
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState([]);
  const [stateSelected, setStateSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [reset, setReset] = useState(false);
  const filters = [search, stateSelected, reset];
  const [loading, setLoading] = useState(false);

  const ordersFiltered = async (page, pageSize, onResult) => {
    try {
      setLoading(true);
      const filter = {
        page,
        size: pageSize,
        search: search ? search : null,
        states: stateSelected && !stateSelected.includes(-1) ? stateSelected.join(',') : null
      };
      const response = await getOrders(filter);
      setLoading(false);
      onResult(response);
    } catch (error) {
      setLoading(false);
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  const _getOrderStates = async () => {
    try {
      setLoading(true);
      const response = await getOrderStates();
      setStatus(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  const handleState = stateId => {
    // reset other pills
    if (stateId === -1) setStateSelected([-1]);
    else {
      const selected = stateSelected.includes(-1) ? [] : stateSelected;
      const exist = selected.indexOf(stateId);
      exist > -1 ? selected.splice(exist, 1) : selected.push(stateId);
      setStateSelected([...new Set(selected)]);
    }
  };

  const renderOrderStates = () =>
    status.map(s => (
      <ButtonPrimary
        text={s.description}
        onClick={() => handleState(s.id)}
        theme={stateSelected.includes(s.id) ? 'filters-style active' : 'filters-style'}
      />
    ));

  useEffect(() => {
    if (stateSelected.length === 0) setStateSelected([-1]);
  }, [stateSelected]);

  useEffect(() => {
    _getOrderStates();
  }, []);

  return (
    <Spinner loading={loading}>
      <div className="orders-admin-container">
        <div className="title">
          <TitlePage text="PARAMETRÍA PEDIDOS" />
        </div>
        <div className="filters-head-container">
          <div className="search_btn">
            <Search
              className="search_btn"
              placeholder="Buscar"
              onSearch={value => setSearch(value)}
              enterButton
            />
          </div>
          <div className="filters-container">
            <ButtonPrimary
              text="TODOS"
              onClick={() => handleState(-1)}
              theme={stateSelected.includes(-1) ? 'filters-style active' : 'filters-style'}
            />
            {renderOrderStates()}
          </div>
        </div>
        <div className="page-content">
          <OrderList orders={ordersFiltered} filters={filters} />
        </div>
      </div>
    </Spinner>
  );
};

export default OrderListPage;
