import React, { useRef, useState, useEffect } from 'react';
import './_style.scss';
import { Input, message, Modal, Select } from 'antd';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import PaginatedTable from '../../molecules/PaginatedTable/paginated-table.jsx';
import { columns } from '../../../utils/tables/table-refund-list';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCalls from '../../../services/api-calls/all';
import { get } from 'lodash';

import {
  PAGE_SIZE,
  REFUND_EDIT,
  PENDING
} from '../../../utils/constants';
import { useRedirect } from '../../Router/redirect';
import { UserHelpers } from '../../../utils/user-helpers';
import { downloadPdf } from '../../../utils/func-helpers';
import { UserContext } from '../../../services/providers/user-context';
import Spinner from '../../atoms/Spinner/Spinner';
import PopUp from '../../molecules/PopUp/popUp';

const {
  getRefund,
  confirmRefund,
  rejectRefund,
  getRefundStates
} = apiCalls();
const { Search } = Input;

const RefundList = () => {
  const [search, setSearch] = useState('');
  const [states, setStates] = useState([]);
  const [statesSelected, setStatesSelected] = useState([-1]);
  const [reset, setReset] = useState(false);
  const [refundState, setRefundState] = useState({});
  const filters = [search, statesSelected, reset];
  const { isViewer, isCustomer } = UserHelpers();
  const { redirect, setUrlToRedirect } = useRedirect();
  const [loading, setloading] = useState(false);
  const [refundId, setRefundId] = useState(undefined);
  const [refundActionLabel, setRefundActionLabel] = useState('');
  const [refundAction, setRefundAction] = useState(undefined);
  const modal = useRef();
  const CONFIRM_ACTION = 'CONFIRM';
  const REJECT_ACTION = 'REJECT';

  const refundFiltered = async (page, pageSize, onResult) => {
    try {
      setloading(true);
      const filter = {
        page,
        size: pageSize,
        search,
        states: statesSelected && !statesSelected.includes(-1) ? statesSelected.join(',') : null
      };
      const response = await getRefund(filter);
      setloading(false);
      onResult(response);
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      setloading(false);
      message.error(errorMessage);
    }
  };

  const _getRefundStates = async () => {
    try {
      setloading(true);
       const response = await getRefundStates();
       setStates(response);
       setloading(false);
    } catch (error) {
      setloading(false);
      message.error(processedErrorMessage(error, get(error, 'response.data')));
    }
  };

  const handleStates = categoryId => {
    if (categoryId === -1) setStatesSelected([-1]);
    else {
      const selected = statesSelected.includes(-1) ? [] : statesSelected;
      const exist = selected.indexOf(categoryId);
      exist > -1 ? selected.splice(exist, 1) : selected.push(categoryId);
      console.log(selected);
      setStatesSelected([...new Set(selected)]);
    }
  };

  const onEdit = (id, state) => {
    setRefundState(state);
    setUrlToRedirect(`${REFUND_EDIT.replace(':id', id)}`);
  };

  const statusIsPending = state => state.description === PENDING;

  const isDisabled = state => isViewer() || isCustomer() || !statusIsPending(state);

  const handleOnConfirm = async () => {
    try {
      setloading(true);
      await confirmRefund(refundId);
      setReset(!reset);
      setloading(false);
    } catch (error) {
      setloading(false);
      message.error(processedErrorMessage(error, get(error, 'response.data')));
    }
  };

  const handleOnReject = async () => {
    try {
      setloading(true);
      await rejectRefund(refundId);
      setReset(!reset);
      setloading(false);
    } catch (error) {
      setloading(false);
      message.error(processedErrorMessage(error, get(error, 'response.data')));
    }
  };

  const modalActionButtons = () => (
    <div className="buttons-container">
      <ButtonPrimary
        text="CONFIRMAR"
        onClick={() => {
          modal.current.hiddenModal();
          if (refundAction === CONFIRM_ACTION)
            handleOnConfirm(refundId);
          if (refundAction === REJECT_ACTION)
            handleOnReject(refundId);
        }}
        theme="confirm-btn"
      />
      <ButtonPrimary text="CANCELAR" onClick={() => modal.current.hiddenModal()} theme="cancel" />
    </div>
  );

  const onConfirm = id => {
    setRefundId(id);
    setRefundActionLabel('Confirmar')
    setRefundAction(CONFIRM_ACTION);
    modal.current.showModal();
  }

  const onReject = id => {
    setRefundId(id);
    setRefundActionLabel('Rechazar')
    setRefundAction(REJECT_ACTION);
    modal.current.showModal();
  }

  useEffect(() => {
    if (statesSelected.length === 0) setStatesSelected([-1]);
  }, [statesSelected]);

  useEffect(() => {
    _getRefundStates();
  }, []);

  return (
    <Spinner loading={loading}>
      {redirect({}, refundState && refundState)}
      <div className="inputs-main-container-refunds">
        <div className="inputs_container">
          <div className="search-container-renditions-admin">
            <Search
              className="search_btn"
              placeholder="BUSCAR"
              enterButton
              onSearch={value => setSearch(value)}
            />
          </div>
          <div className="refunds-filters-container">
            <ButtonPrimary
              text="TODOS"
              onClick={() => handleStates(-1)}
              theme={statesSelected.includes(-1) ? 'filters-style active' : 'filters-style'}
            />
            {states.map(state => (
              <ButtonPrimary
                key={state.id}
                text={state.description}
                onClick={() => handleStates(state.id)}
                theme={statesSelected.includes(state.id) ? 'filters-style active' : 'filters-style'}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="page-content">
        <PaginatedTable
          className="table_refunds"
          columns={columns({
            onEdit,
            onConfirm,
            onReject,
            isDisabled
          })}
          onFetch={refundFiltered}
          pageSize={PAGE_SIZE}
          filters={filters}
          scroll={{ x: 420 }}
        />
      </div>
      <PopUp title={`${refundActionLabel} Reembolso`} ref={modal} footer={modalActionButtons()}>
        {`¿Desea ${refundActionLabel.toLowerCase()} el Reembolso seleccionado?`}
      </PopUp>
    </Spinner>
  );
};

export default RefundList;
