import React, { useContext, useState, useEffect, useRef } from 'react';
import './_style.scss';
import { Input, message, Modal, Select } from 'antd';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import PaginatedTable from '../../molecules/PaginatedTable/paginated-table.jsx';
import { columns } from '../../../utils/tables/table-preapproval-list';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCalls from '../../../services/api-calls/all';
import { get } from 'lodash';

import {
  PAGE_SIZE,
  PREAPPROVAL_CREATE,
  PREAPPROVAL_EDIT,
  PREAPPROVAL_PENDING,
  PERMISSION_PRE_APPROVAL_CREATE,
  REFUND_CREATE,
  PREAPPROVAL_APPROVED,
  PERMISSION_REFUND_CREATE
} from '../../../utils/constants';
import { useRedirect } from '../../Router/redirect';
import { UserHelpers } from '../../../utils/user-helpers';
import { downloadPdf } from '../../../utils/func-helpers';
import { UserContext } from '../../../services/providers/user-context';
import Spinner from '../../atoms/Spinner/Spinner';
import PopUp from '../../molecules/PopUp/popUp';

const {
  getPreApproval,
  getPreApprovalStates,
  downloadFile,
  confirmPreApproval,
  rejectPreApproval
} = apiCalls();
const { Search } = Input;

const PreApprovalList = () => {
  const [search, setSearch] = useState('');
  const [states, setStates] = useState([]);
  const [statesSelected, setStatesSelected] = useState([-1]);
  const [reset, setReset] = useState(false);
  const [preApprovalState, setPreApprovalState] = useState({});
  const filters = [search, statesSelected, reset];
  const { isViewer, isAdmin, hasPermissionFor } = UserHelpers();
  const { redirect, setUrlToRedirect } = useRedirect();
  const { user } = useContext(UserContext);
  const [loading, setloading] = useState(false);
  const [preApprovalId, setPreApprovalId] = useState(undefined);
  const [preApprovalActionLabel, setPreApprovalActionLabel] = useState('');
  const [preApprovalAction, setPreApprovalAction] = useState(undefined);
  const modal = useRef();
  const CONFIRM_ACTION = 'CONFIRM';
  const REJECT_ACTION = 'REJECT';

  const preApprovalFiltered = async (page, pageSize, onResult) => {
    try {
      setloading(true);
      const filter = {
        page,
        size: pageSize,
        search,
        states: statesSelected && !statesSelected.includes(-1) ? statesSelected.join(',') : null
      };
      const response = await getPreApproval(filter);
      setloading(false);
      onResult(response);
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      setloading(false);
      message.error(errorMessage);
    }
  };

  const prepareDownload = async (id, fileName) => {
    try {
      setloading(true);
      const fileData = await downloadFile(id);
      setloading(false);
      downloadPdf(fileData, fileName);
    } catch (error) {
      setloading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const _getPreApprovalStates = async () => {
    try {
      setloading(true);
      const response = await getPreApprovalStates();
      setStates(response);
      setloading(false);
    } catch (error) {
      setloading(false);
      message.error(processedErrorMessage(error, get(error, 'response.data')));
    }
  };

  const handleStates = categoryId => {
    // reset other pills
    if (categoryId === -1) setStatesSelected([-1]);
    else {
      const selected = statesSelected.includes(-1) ? [] : statesSelected;
      const exist = selected.indexOf(categoryId);
      exist > -1 ? selected.splice(exist, 1) : selected.push(categoryId);
      console.log(selected);
      setStatesSelected([...new Set(selected)]);
    }
  };

  const onEdit = (id, state) => {
    setPreApprovalState(state);
    setUrlToRedirect(`${PREAPPROVAL_EDIT.replace(':id', id)}`);
  };

  const statusIsPending = state => state.description === PREAPPROVAL_PENDING;

  const isDisabled = state => !isAdmin() || !statusIsPending(state);

  const statusIsConfirmed = state => state.description === PREAPPROVAL_APPROVED;

  const isConfirmed = state => hasPermissionFor(PERMISSION_REFUND_CREATE) && statusIsConfirmed(state);

  const handleOnConfirm = async () => {
    try {
      setloading(true);
      await confirmPreApproval(preApprovalId);
      setReset(!reset);
      setloading(false);
    } catch (error) {
      setloading(false);
      message.error(processedErrorMessage(error, get(error, 'response.data')));
    }
  };

  const handleOnReject = async () => {
    try {
      setloading(true);
      await rejectPreApproval(preApprovalId);
      setReset(!reset);
      setloading(false);
    } catch (error) {
      setloading(false);
      message.error(processedErrorMessage(error, get(error, 'response.data')));
    }
  };

  const onCreateRefund = id => {
    setUrlToRedirect(`${REFUND_CREATE.replace(':id', id)}`);
  };

  useEffect(() => {
    if (statesSelected.length === 0) setStatesSelected([-1]);
  }, [statesSelected]);

  useEffect(() => {
    _getPreApprovalStates();
  }, []);

  const modalActionButtons = () => (
    <div className="buttons-container">
      <ButtonPrimary
        text="CONFIRMAR"
        onClick={() => {
          modal.current.hiddenModal();
          if (preApprovalAction === CONFIRM_ACTION)
            handleOnConfirm(preApprovalId);
          if (preApprovalAction === REJECT_ACTION)
            handleOnReject(preApprovalId);
        }}
        theme="confirm-btn"
      />
      <ButtonPrimary text="CANCELAR" onClick={() => modal.current.hiddenModal()} theme="cancel" />
    </div>
  );

  const onConfirm = id => {
    setPreApprovalId(id);
    setPreApprovalActionLabel('Confirmar')
    setPreApprovalAction(CONFIRM_ACTION);
    modal.current.showModal();
  }

  const onReject = id => {
    setPreApprovalId(id);
    setPreApprovalActionLabel('Rechazar')
    setPreApprovalAction(REJECT_ACTION);
    modal.current.showModal();
  }

  return (
    <Spinner loading={loading}>
      {redirect({}, preApprovalState && preApprovalState)}
      <div className="inputs_main_container_preapproval">
        <div className="inputs_container">
          <div className="search-container-renditions-admin">
            <Search
              className="search_btn"
              placeholder="Buscar"
              enterButton
              onSearch={value => setSearch(value)}
            />
          </div>
          <div className="approval-filters-container">
            <ButtonPrimary
              text="TODOS"
              onClick={() => handleStates(-1)}
              theme={statesSelected.includes(-1) ? 'filters-style active' : 'filters-style'}
            />
            {states.map(state => (
              <ButtonPrimary
                key={state.id}
                text={state.description}
                onClick={() => handleStates(state.id)}
                theme={statesSelected.includes(state.id) ? 'filters-style active' : 'filters-style'}
              />
            ))}
          </div>
        </div>
        <ButtonPrimary
          text="CREAR PREAPROBACIÓN"
          disabled={!hasPermissionFor(PERMISSION_PRE_APPROVAL_CREATE)}
          theme={
            !hasPermissionFor(PERMISSION_PRE_APPROVAL_CREATE)
              ? 'btn-blue-approval disabled'
              : 'btn-blue-approval'
          }
          onClick={() => setUrlToRedirect(PREAPPROVAL_CREATE)}
        />
      </div>
      <div className="page-content">
        <PaginatedTable
          className="table-users"
          columns={columns({
            onEdit,
            isDisabled,
            isConfirmed,
            prepareDownload,
            onConfirm,
            onReject,
            onCreateRefund
          })}
          onFetch={preApprovalFiltered}
          pageSize={PAGE_SIZE}
          filters={filters}
          scroll={{ x: 420, y:300 }}
        />
      </div>
      <PopUp title={`${preApprovalActionLabel} Pre-Aprobación`} ref={modal} footer={modalActionButtons()}>
        {`¿Desea ${preApprovalActionLabel.toLowerCase()} la Pre-Aprobacion seleccionada?`}
      </PopUp>
    </Spinner>
  );
};

export default PreApprovalList;
