import React, { useEffect, useState } from 'react';
import './_style.scss';
import { message } from 'antd';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import History from '../../../atoms/Breadcrumb/history';
import NewPeridForm from '../../../organism/NewPeriodForm/NewPeriodForm';
import { PRODUCTIVITY } from '../../../../utils/constants';
import apiCall from '../../../../services/api-calls/all';
import { useRedirect } from '../../../Router/redirect';
import { processedErrorMessage } from '../../../../services/api-calls/helpers';
import { cleanObjectValues } from '../../../../utils/func-helpers';
import Spinner from '../../../atoms/Spinner/Spinner';

const { getProductivity, updateProductivity } = apiCall();
const ProductivityEdit = ({ match }) => {
  const { params } = match;
  const { redirect, setUrlToRedirect } = useRedirect();
  const [loading, setLoading] = useState(false);
  const [productivity, setProductivity] = useState(false);

  const breadcrumbs = [
    {
      title: 'Productividad',
      href: PRODUCTIVITY
    },
    {
      title: 'Editar periodo',
      href: ''
    }
  ];

  const handleShowUser = async () => {
    try {
      setLoading(true);
      const response = await getProductivity(params.id);
      setProductivity(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const handleEdit = async values => {
    if (loading) return;
    setLoading(true);
    try {
      const { year, month, ...rest } = values;
      const {
        radialPassenger16,
        radialPassenger17,
        radialPassenger18,
        radialPickup16,
        radialPickup17,
        radialPickup18,
        servicedVehicles,
        servicedVehiclesTireMounting,
        alignments,
        serviceBoxes,
        balances,
        workedDays
      } = rest;
      const {
        counterTires,
        resaleTires,
        spareParts,
        rebuilds,
        tireRepairServices,
        autoRepairServices,
        various
      } = rest;
      const { goodyear, kelly, others } = rest;
      let unitSales = {
        radialPassenger16,
        radialPassenger17,
        radialPassenger18,
        radialPickup16,
        radialPickup17,
        radialPickup18,
        servicedVehicles,
        servicedVehiclesTireMounting,
        alignments,
        serviceBoxes,
        balances,
        workedDays
      };
      unitSales = cleanObjectValues(unitSales);
      let billing = {
        counterTires,
        resaleTires,
        spareParts,
        rebuilds,
        tireRepairServices,
        autoRepairServices,
        various
      };
      billing = cleanObjectValues(billing);

      let inventory = { goodyear, kelly, others };
      inventory = cleanObjectValues(inventory);

      let parameters = { year, month };
      parameters =
        Object.keys(unitSales).length > 0
          ? Object.assign({}, parameters, { unitSales })
          : parameters;
      parameters =
        Object.keys(billing).length > 0 ? Object.assign({}, parameters, { billing }) : parameters;
      parameters =
        Object.keys(inventory).length > 0
          ? Object.assign({}, parameters, { inventory })
          : parameters;

      await updateProductivity(productivity.id, { year, month, unitSales, billing, inventory });
      message.success('Acción realizada con éxito');
      setLoading(false);
      setUrlToRedirect(PRODUCTIVITY);
    } catch (error) {
      console.log(error);
      const { data } = error.response;
      const errorMessage = processedErrorMessage(error, data);
      message.error(errorMessage);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleShowUser();
  }, []);

  return (
    <Spinner loading={loading} >
      <div className="productivity-container">
        {redirect()}
        <History breadcrumbs={breadcrumbs} />
        <TitlePage text="EDITAR PERIODO" />
        <NewPeridForm productivity={productivity} handleSubmit={handleEdit} editMode />
      </div>
    </Spinner>
  );
};
export default ProductivityEdit;
