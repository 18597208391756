import React from 'react';
import { Checkbox, Space } from 'antd';
import ButtonPrimary from '../../components/atoms/ButtonPrimary/button-primary';
import { DeleteOutlined, EyeFilled } from '@ant-design/icons';

export const columns = (onEdit, onDelete, isViewer) => [
  {
    title: 'FECHA CREACIÓN',
    dataIndex: 'creationDate',
    width: 150
  },
  {
    title: 'FECHA RELEVAMIENTO',
    dataIndex: 'surveyDate',
    width: 175
  },
  {
    title: 'USUARIO',
    dataIndex: 'user',
    width: 200
  },
  {
    title: 'PRODUCTO',
    dataIndex: 'product',
    width: 200
  },
  {
    dataIndex: 'measure',
    title: 'MEDIDA',
    width: 100
  },
  {
    dataIndex: 'estimatedMonthlyAmount',
    title: 'CANTIDAD ESTIMADA',
    width: 175
  },
  {
    title: 'ACCIONES',
    render: ({ id }) => (
      <Space>
        {onEdit && (
          <ButtonPrimary theme="promo-btn" iconLeft={<EyeFilled />} onClick={() => onEdit(id)} />
        )}
        {
          <ButtonPrimary
            disabled={isViewer}
            theme={isViewer ? 'disabled' : 'btn-remove-survey'}
            onClick={() => onDelete(id)}
            iconLeft={<DeleteOutlined />}
          />
        }
      </Space>
    ),
    width: 150
  }
];
