import React from 'react';
import { Card } from 'antd';
import './style.scss';
import editIcon from '../../../img/edit-icon.svg';
import { useRedirect } from '../../Router/redirect';
import { ADMIN_NEWS_URL } from "../../../utils/constants";

const { Meta } = Card;
const AdminMainCard = ({ id, title, fecha, img, description }) => {
  const { redirect, setUrlToRedirect } = useRedirect();
  return (
    <div className="main-card-container">
      {redirect()}
      <div
        className="main-card-img-container"
        style={{
          background: `url(${img}) no-repeat center center`,
          backgroundSize: 'cover',
          width: '32%',
          borderRadius: '4px 0 0 4px'
        }}
      />
      <div className="main-card-text-content">
        <div onClick={() => setUrlToRedirect(`${ADMIN_NEWS_URL}/${id}`)}>
          <img className="edit-icon-admin" src={editIcon} alt="" />
        </div>
        <p className="label-date">{fecha}</p>
        <h3 className="card-title">{title}</h3>
        <p className="card-description">{description}</p>
      </div>
    </div>
  );
}

export default AdminMainCard;
