import React, { useEffect, useState } from 'react';
import './style.scss';
import { Button, Dropdown, Menu, message, Select, Table, Tabs } from 'antd';
import TitlePage from '../../../atoms/TitlePage/TitlePage';
import ButtonPrimary from '../../../atoms/ButtonPrimary/button-primary';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import DashboardProductivityList from '../../../organism/DashboardProductivityList/DashboardProductivityList';
import { processedErrorMessage } from '../../../../services/api-calls/helpers';
import apiCall from '../../../../services/api-calls/all';
import { DashboardExpenseList } from '../../../organism/DashboardExpenseList/DashboardExpenseList';
import { downloadPdf, extractContentDispositionFilename } from '../../../../utils/func-helpers';
import get from 'lodash';
import Spinner from '../../../atoms/Spinner/Spinner';

const { TabPane } = Tabs;

function handleMenuClick(e) {
  message.info('Click on menu item.');
  console.log('click', e);
}

const {
  getMonths,
  getYears,
  getClients,
  getStates,
  exportProductivityDashboard,
  downloadExpenseReportDashboard
} = apiCall();

const Dashboard = () => {
  const [months, setMonths] = useState([]);
  const [monthsLoading, setMonthsLoading] = useState(false);
  const [years, setYears] = useState([]);
  const [monthsSelected, setMonthsSelected] = useState([]);
  const [yearsLoading, setYearsLoading] = useState(false);
  const [year, setYear] = useState('');
  const [clients, setClients] = useState([]);
  const [clientsLoading, setClientsLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [clientsSelected, setClientsSelected] = useState([]);
  const [statesSelected, setStatesSelected] = useState([]);
  const [statesLoading, setStatesLoading] = useState(false);
  const [tab, setTab] = useState('expense');
  const filters = [statesSelected, monthsSelected, year, clientsSelected];
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [channel, setChannel] = useState(undefined);
  const [promotion, setPromotion] = useState(undefined);

  function downloadFile() {
    if (tab === 'expense') _downloadExpenseReportDashboard();
    if (tab === 'productivity') {
      downloadExportProductivity();
    }
  }

  const downloadExportProductivity = async key => {
    try {
      setLoading(true);
      const parameters = {
        year,
        months: monthsSelected.join(),
        idStates: statesSelected.join(),
        idCustomers: clientsSelected.join()
      };
      const response = await exportProductivityDashboard(parameters);
      const filename = extractContentDispositionFilename(response, 'productividad.xlsx');
      setLoading(false);
      downloadPdf(response.data, filename);
    } catch (error) {
      setLoading(false);
      const m = get(error, 'response.data');
      processedErrorMessage(error, m);
    }
  };

  const _downloadExpenseReportDashboard = async key => {
    try {
      setLoading(true);
      const parameters = {
        year,
        months: monthsSelected.join(),
        idStates: statesSelected.join(),
        idCustomers: clientsSelected.join(),
        idChannel: channel,
        idPromotion: promotion
      };
      const response = await downloadExpenseReportDashboard(parameters);
      const filename = extractContentDispositionFilename(response, 'rendiciones.xlsx');
      setLoading(false);
      downloadPdf(response.data, filename);
    } catch (error) {
      setLoading(false);
      const m = get(error, 'response.data');
      processedErrorMessage(error, m);
    }
  };

  const _getMonths = async () => {
    try {
      setMonthsLoading(true);
      const response = await getMonths();
      setMonths(response);
      setMonthsLoading(false);
    } catch (error) {
      setMonthsLoading(false);
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };
  const _getYears = async () => {
    try {
      setYearsLoading(true);
      const response = await getYears({ next: 5, last: 5 });
      setYears(response);
      setYearsLoading(false);
    } catch (error) {
      setYearsLoading(false);
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };
  const _getClients = async (states = null) => {
    try {
      setClientsLoading(true);
      setClientsSelected([]);
      setClients([]);
      const response = await getClients({ states });
      setClients(response);
      setClientsLoading(false);
    } catch (error) {
      setClientsLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const _getStates = async (state = null) => {
    try {
      setStatesLoading(true);
      const response = await getStates(state);
      setStates(response);
      setStatesLoading(false);
    } catch (error) {
      setStatesLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  useEffect(() => {
    _getMonths();
    _getYears();
    _getClients();
    _getStates(states);
  }, []);

  useEffect(() => {
    _getClients(statesSelected.join());
  }, [statesSelected]);

  return (
    <Spinner loading={loading}>
      <div className="main-dashboard-container">
        <div className="dashboard-filters">
          <TitlePage text="DASHBOARD" />
          <div className="btn-export-dashboard">
            <ButtonPrimary onClick={downloadFile} theme="btn-blue" text="EXPORTAR" />
          </div>
        </div>
        <div className="dashboard-filters">
          <div className="dashboard-input">
            <Select
              placeholder={
                <>
                  Año <span className="required">*</span>
                </>
              }
              onChange={value => setYear(value)}
              loading={yearsLoading}
              disabled={yearsLoading}
            >
              {years.map(year => (
                <Option key={year} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </div>
          <div className="dashboard-input">
            <Select
              mode="multiple"
              allowClear
              maxTagCount={2} //responsive is not working
              placeholder={
                <>
                  Mes <span className="required">*</span>
                </>
              }
              onChange={value => setMonthsSelected(value)}
              loading={monthsLoading}
              disabled={monthsLoading}
              showSearch={false}
            >
              {months.map(month => (
                <Option key={month.id} value={month.id}>
                  {month.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="dashboard-input">
            <Select
              mode="multiple"
              allowClear
              maxTagCount={1}
              placeholder="Provincia"
              loading={statesLoading}
              disabled={statesLoading}
              onChange={value => {
                setStatesSelected(value);
              }}
              showSearch={false}
            >
              {states.map((state, index) => (
                <Option key={index} value={state.id}>
                  {state.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="dashboard-input">
            <Select
              mode="multiple"
              allowClear
              maxTagCount={1}
              placeholder="Razón social"
              loading={clientsLoading}
              disabled={clientsLoading}
              value={clientsSelected}
              onChange={value => {
                setClientsSelected(value);
              }}
              showSearch={false}
            >
              {clients.map(({ id, registeredName }) => (
                <Option key={id} value={id}>
                  {registeredName}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="dashboard-tabs-container">
          <Tabs defaultActiveKey="1" onChange={key => setTab(key)}>
            <TabPane tab="RENDICIONES" key="expense">
              <div className="page-content">
                <DashboardExpenseList
                  filters={filters}
                  filterParams={{
                    year,
                    states: statesSelected.join(),
                    customers: clientsSelected.join(),
                    months: monthsSelected.join()
                  }}
                  channel={channel}
                  setChannel={setChannel}
                  promotion={promotion}
                  setPromotion={setPromotion}
                />
              </div>
            </TabPane>
            <TabPane tab="PRODUCTIVIDAD" key="productivity">
              <div className="page-content">
                <DashboardProductivityList
                  filters={filters}
                  filterParams={{
                    states: statesSelected.join(),
                    months: monthsSelected.join(),
                    year,
                    customers: clientsSelected.join()
                  }}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Spinner>
  );
};

export default Dashboard;
