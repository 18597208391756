import helpers from './helpers';

const getClients = makeGetRequest => data => makeGetRequest('customers', data);

const getClientsFiltered = makeGetRequest => data => makeGetRequest('customers/filtered', data);

const showClient = makeGetRequest => id => makeGetRequest(`customers/${id}`);

const createClient = makePostFileRequest => data => makePostFileRequest('customers', data);

const editClient = makePutFileRequest => (id, data) => makePutFileRequest(`customers/${id}`, data);

const destroyClient = makeDeleteRequest => id => makeDeleteRequest(`customers/${id}`);

const downloadCustomersFile = makeGetRequest => data =>
  makeGetRequest('customers/download/filters', data);

const getCustomerByUser = makeGetRequest => () => makeGetRequest('customers/users');

export default client => {
  const { makeGetRequest, makePostFileRequest, makePutFileRequest, makeDeleteRequest } = helpers(
    client
  );
  return {
    getClientsFiltered: getClientsFiltered(makeGetRequest),
    getClients: getClients(makeGetRequest),
    showClient: showClient(makeGetRequest),
    createClient: createClient(makePostFileRequest),
    editClient: editClient(makePutFileRequest),
    destroyClient: destroyClient(makeDeleteRequest),
    downloadCustomersFile: downloadCustomersFile(makeGetRequest),
    getCustomerByUser: getCustomerByUser(makeGetRequest)
  };
};
