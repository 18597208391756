import React, { useState, forwardRef, useImperativeHandle } from 'react';
import './style.scss';
import { Modal } from 'antd';

const PopUp = forwardRef((properties, reference) => {
  const [visible, setVisible] = useState(false);

  const { title, footer, children, width } = properties;

  const handleOk = e => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useImperativeHandle(reference, () => ({
    showModal() {
      setVisible(true);
    },
    hiddenModal() {
      setVisible(false);
    }
  }));

  return (
    <Modal
      centered
      className="modal-content"
      visible={visible}
      title={title}
      onOk={handleOk}
      onCancel={handleCancel}
      width={width}
      footer={footer}
    >
      {children}
    </Modal>
  );
});

export default PopUp;
