import helpers from './helpers';

const getCart = makeGetRequest => () => makeGetRequest('/cart');
const getCartStatus = makeGetRequest => () => makeGetRequest('/cart/status');
const addToCart = makePutRequest => (data) => makePutRequest('/cart', data);
const removeItemFromCart = makePutRequest => (data) => makePutRequest('/cart/remove-item', data);

export default client => {
	const { makeGetRequest, makePutRequest } = helpers(client);
	return {
		getCart: getCart(makeGetRequest),
		getCartStatus: getCartStatus(makeGetRequest),
		addToCart: addToCart(makePutRequest),
		removeItemFromCart: removeItemFromCart(makePutRequest)
	};
};
