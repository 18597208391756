import helpers from './helpers';

const getLines = makeGetRequest => () => makeGetRequest('/lines');

export default client => {
  const { makeGetRequest } = helpers(client);
  return {
    getLines: getLines(makeGetRequest)
  };
};
