import React from 'react';
import './_style.scss';

const TitlePage = ({ text, className }) => (
  <div className={(className, 'TitlePage')}>
    <h1>{text}</h1>
  </div>
);

export default TitlePage;
