import React from 'react';
import './style.scss';
import Stock from '../../atoms/Stock/stock';
import PuntosIcon from '../../../img/star.svg';
import StockIcon from '../../../img/stock.svg';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import { ShoppingCartOutlined } from '@ant-design/icons';
import {ADMIN_CATALOGUE_EDIT} from "../../../utils/constants";
import {useRedirect} from "../../Router/redirect";
import {UserHelpers} from "../../../utils/user-helpers";



const CatalogueCardsAdmin = ({ id, code, title, description, img, puntos, stock, precio, enable, enableAction }) => {
    const { redirect, setUrlToRedirect } = useRedirect();
    const { canUpdate } = UserHelpers();
    return (
        <div className="catalogue-card-container">
            {redirect()}
            <div
                className="catalogue-card-img-container"
                style={{
                    background: `url(${img}) no-repeat center center`,
                    backgroundSize: 'cover',
                    height: '250px',
                    borderRadius: '4px 4px 0 0'
                }}
            />
            <div className="catalogue-card-title">
                <p>{title}</p>
            </div>
            <div className="catalogue-card-description">
                <p>{description}. Código {code}.</p>
            </div>
            <div className="details-container">
                <Stock title="PUNTOS" icon={PuntosIcon} quantity={puntos} />
                <Stock title="STOCK" icon={StockIcon} quantity={stock} />
            </div>
            <div className="price-container">
                <h2>{precio}</h2>
                <div className="btns-container">
                    <ButtonPrimary disabled={!canUpdate()} text={enable ? "BLOQUEAR": "ACTIVAR"} theme={!canUpdate() ? 'disabled' : enable ? 'cancel':'btns-product'} onClick={() => enableAction(id, !enable)} />
                    {canUpdate() ? <ButtonPrimary text="EDITAR" theme="btn-primary" onClick={() => setUrlToRedirect(ADMIN_CATALOGUE_EDIT.replace(':id', id))} /> : ''}
                </div>
            </div>
        </div>
    )
};

export default CatalogueCardsAdmin;
