import React, { useState, useRef } from 'react';
import { message } from 'antd';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCalls from '../../../services/api-calls/all';
import { downloadPdf, extractContentDispositionFilename } from '../../../utils/func-helpers';
import { PAGE_SIZE, PRICE_SURVEYS_CREATE, PRICE_SURVEYS_EDIT } from '../../../utils/constants';
import { columns } from '../../../utils/tables/table-survey-price-list';
import PaginatedTable from '../../molecules/PaginatedTable/paginated-table';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import { useRedirect } from '../../Router/redirect';
import { UserHelpers } from '../../../utils/user-helpers';
import Spinner from '../../atoms/Spinner/Spinner';
import PopUp from '../../molecules/PopUp/popUp';

const { getSurveysPrice, exportSurveyPrice, deleteSurveyPrice } = apiCalls();
export const SurveyPriceList = () => {
  const [reset, setReset] = useState(false);
  const { isViewer } = UserHelpers();
  const filters = [reset];
  const { redirect, setUrlToRedirect } = useRedirect();
  const [loading, setLoading] = useState(false);
  const modal = useRef();
  const [priceId, setPriceId] = useState(undefined);

  const prepareDownload = async () => {
    try {
      setLoading(true);
      const response = await exportSurveyPrice();
      const filename = extractContentDispositionFilename(response, 'Relevamientos.xlsx');
      setLoading(false);
      downloadPdf(response.data, filename);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteSurveyPrice(priceId);
      setReset(true);
      setLoading(false);
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      setLoading(false);
      message.error(errorMessage);
    }
  };

  const onDelete = id => {
    setPriceId(id);
    modal.current.showModal();
  };

  const surveyPriceFiltered = async (page, pageSize, onResult) => {
    try {
      const filter = {
        page,
        size: pageSize
      };
      setLoading(true);
      const response = await getSurveysPrice(filter);
      onResult(response);
      setLoading(false);
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      setLoading(false);
      message.error(errorMessage);
    }
  };

  const handleOnEdit = id => {
    setUrlToRedirect(PRICE_SURVEYS_EDIT.replace(':id', id));
  };

  const modalActionButtons = () => (
    <div className="buttons-container">
      <ButtonPrimary
        text="CONFIRMAR"
        onClick={() => {
          modal.current.hiddenModal();
          handleDelete();
        }}
        theme="confirm-btn"
      />
      <ButtonPrimary text="CANCELAR" onClick={() => modal.current.hiddenModal()} theme="cancel" />
    </div>
  );

  return (
    <Spinner loading={loading}>
      <div className="page-content">
        {redirect()}
        <div className="text-survey-container">
          <div className="btn-create-survey">
            <ButtonPrimary text="EXPORTAR" onClick={() => prepareDownload()} theme="btn-export" />
            <ButtonPrimary
              disabled={isViewer()}
              text="+ AGREGAR RELEVAMIENTO"
              onClick={() => setUrlToRedirect(PRICE_SURVEYS_CREATE)}
              theme={isViewer() ? 'disabled' : 'btn-add-survey'}
            />
          </div>
        </div>
        <PaginatedTable
          className="table-users"
          columns={columns(handleOnEdit, onDelete, isViewer())}
          onFetch={surveyPriceFiltered}
          pageSize={PAGE_SIZE}
          filters={filters}
          scroll={{ x: 420, y: 300 }}
        />
      </div>
      <PopUp title="Eliminar Relevamiento" ref={modal} footer={modalActionButtons()}>
        ¿Desea eliminar el Relevamiento seleccionado?
      </PopUp>
    </Spinner>
  );
};
