import React, { useEffect, useRef, useState } from 'react';
import { Input, message, Modal, Select, Space } from 'antd';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import PaginatedTable from '../../molecules/PaginatedTable/paginated-table.jsx';
import { columns } from '../../../utils/tables/table-productivity-list-admin';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCalls from '../../../services/api-calls/all';
import PopUpExportProductivity from '../../molecules/PopUp/popUp';

import {
  ADMIN_PRODUCTIVITY_CREATE,
  ADMIN_PRODUCTIVITY_LIST,
  PAGE_SIZE
} from '../../../utils/constants';
import { useRedirect } from '../../Router/redirect';
import { UserHelpers } from '../../../utils/user-helpers';
import { downloadPdf, downloadXLSX } from '../../../utils/func-helpers';
import { FILENAME_CONTENT_DISPOSITION } from '../../../utils/validation-regular-exp';
import Spinner from '../../atoms/Spinner/Spinner';

const {
  getAllProductivity,
  deleteProductivity,
  getYears,
  getClients,
  getBranchOffices,
  exportProductivity,
  getCustomerByUser
} = apiCalls();
const { Search } = Input;

const ProductivityList = () => {
  const [search, setSearch] = useState('');
  const [years, setYears] = useState([]);
  const [clients, setClients] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);
  const [year, setYear] = useState('');
  const [idCustomer, setIdCustomer] = useState(undefined);
  const [idBrachOffice, setIdBrachOffice] = useState(null);
  const [fileYear, setFileYear] = useState(null);
  const [reset, setReset] = useState(false);
  const { Option } = Select;
  const filters = [search, year, reset];
  const { confirm } = Modal;
  const modal = useRef();
  const { canCreate, canUpdate, isViewer, isCustomer } = UserHelpers();
  const { redirect, setUrlToRedirect } = useRedirect();
  const [loading, setLoading] = useState(false);

  const formatTableData = data =>
    data.content.map((user, i) => ({ key: (i + 1).toString(), ...user }));

  const handleOnEdit = id => {
    setUrlToRedirect(`${ADMIN_PRODUCTIVITY_LIST}/${id}`);
  };

  const productivityFiltered = async (page, pageSize, onResult) => {
    try {
      const filter = {
        page,
        size: pageSize,
        search,
        year
      };
      setLoading(true);
      const response = await getAllProductivity(filter);
      onResult(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  const _exportProductivity = async () => {
    try {
      const parameters = {
        idCustomer,
        idBrachOffice,
        year: fileYear
      };
      setLoading(true);
      const response = await exportProductivity(parameters);
      const r = new RegExp(FILENAME_CONTENT_DISPOSITION);
      const filename = r.exec(response.headers['content-disposition'])[0];
      setLoading(false);
      downloadPdf(response.data, filename);
    } catch (error) {
      const { response } = error;
      const errorMessage = processedErrorMessage(error, response.data);
      setLoading(false);
      message.error(errorMessage);
    }
  };

  const modalConfirmDisabled = idCustomer === undefined || idBrachOffice === null || fileYear === null;

  const modalActionButtons = () => (
    <div className="buttons-container">
      <ButtonPrimary
        text="CONFIRMAR"
        disabled={modalConfirmDisabled}
        onClick={() => {
          modal.current.hiddenModal();
          _exportProductivity();
        }}
        theme={modalConfirmDisabled ? "confirm-btn disabled" : "confirm-btn"}
      />
      <ButtonPrimary text="CANCELAR" onClick={() => modal.current.hiddenModal()} theme="cancel" />
    </div>
  );

  const _getYears = async () => {
    try {
      const response = await getYears({ next: 5, last: 5 });
      setYears(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const searchBranchOffices = async idCustomer => {
    try {
      const response = await getBranchOffices({ idCustomer });
      setBranchOffices(response);
      console.log(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const _getClients = async () => {
    try {
      const response = isCustomer() ? (await getCustomerByUser())[0] : await getClients();
      console.log(Array.isArray(response));
      if (Array.isArray(response)) setClients(response);
      else {
        await setClients([response]);
        await setIdCustomer(response.id);
        await searchBranchOffices(response.id);
      }
    } catch (error) {
      console.log(error);
      message.error(processedErrorMessage(error));
    }
  };

  const onDelete = productivity => {
    confirm({
      title: `¿ Desea eliminar el relevamiento de productividad: ${productivity.month.name} / ${productivity.year} ?`,
      async onOk() {
        try {
          setLoading(true);
          const response = await deleteProductivity(productivity.id);
          setReset(!reset);
          setLoading(false);
          message.success(response.data);
        } catch (error) {
          setLoading(false);
          const errorMessage = processedErrorMessage(error);
          message.error(errorMessage);
        }
      },
      onCancel() {}
    });
  };

  useEffect(() => {
    _getYears();
    _getClients();
  }, []);

  return (
    <Spinner loading={loading}>
      {redirect()}
      <div className="inputs_container">
        <div className="search_btn">
          <Search
            className="search_btn"
            placeholder="Buscar"
            onSearch={value => setSearch(value)}
            enterButton
          />
        </div>
        <Select
          className={'year-filter'}
          placeholder="Seleccione un Año"
          onChange={value => setYear(value)}
        >
          <Option value={''}>Todas</Option>
          {years.map(year => (
            <Option key={year} value={year}>
              {year}
            </Option>
          ))}
        </Select>
        <Space className="admin-promotions">
          <div className="btn_crear">
            <ButtonPrimary
              theme="btn-blue"
              text="EXPORTAR PRODUCTIVIDAD"
              onClick={() => modal.current.showModal()}
            />
            {(canCreate() || !isViewer()) && (
              <ButtonPrimary
                theme="btn_blue"
                text="CREAR NUEVO PERIODO"
                onClick={() => setUrlToRedirect(ADMIN_PRODUCTIVITY_CREATE)}
              />
            )}
          </div>
        </Space>
      </div>
      <div className="page-content">
        <PaginatedTable
          className="table-users"
          columns={columns(
            canUpdate() || isCustomer() || isViewer() ? handleOnEdit : null,
            onDelete,
            isViewer()
          )}
          onFetch={productivityFiltered}
          onFormat={formatTableData}
          pageSize={PAGE_SIZE}
          filters={filters}
          scroll={{ x: 420, y: 300 }}
        />
      </div>
      <PopUpExportProductivity
        title={'Exportar Productividad'}
        ref={modal}
        footer={modalActionButtons()}
      >
        <Space>
          <Select
            style={{
              width: '150px'
            }}
            defaultValue={idCustomer}
            disabled={isCustomer()}
            placeholder="Cliente"
            onChange={value => {
              setIdCustomer(value);
              searchBranchOffices(value);
            }}
          >
            {clients.map(({ id, registeredName }) => (
              <Option key={id} value={id}>
                {registeredName}
              </Option>
            ))}
          </Select>
          <Select
            style={{
              width: '150px'
            }}
            className={'year-filter'}
            placeholder="Sucursal"
            onChange={value => {
              setIdBrachOffice(value);
            }}
          >
            {branchOffices.map(({ id, name }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
          </Select>
          <Select
            style={{
              width: '150px'
            }}
            className={'year-filter'}
            placeholder="Año"
            onChange={value => setFileYear(value)}
          >
            {years.map(year => (
              <Option key={year} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Space>
      </PopUpExportProductivity>
    </Spinner>
  );
};

export default ProductivityList;
