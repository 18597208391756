import React from 'react';
import './style.scss';
import editIcon from '../../../img/edit-icon.svg';
import { useRedirect } from '../../Router/redirect';
import { ADMIN_NEWS_URL } from '../../../utils/constants';
import { UserHelpers } from '../../../utils/user-helpers';

const CardsAdmin = ({ id, title, fecha, img }) => {
  const { redirect, setUrlToRedirect } = useRedirect();
  const { canUpdate } = UserHelpers();

  return (
    <div className="card-container">
      {redirect()}
      <div
        className="card-img-container"
        style={{
          background: `url(${img}) no-repeat center center`,
          backgroundSize: 'cover',
          height: '180px',
          borderRadius: '4px 4px 0 0'
        }}
      />
      <div className="card-content">
        <p className="label-date">{fecha}</p>
        <h3 className="card-title">{title}</h3>
        <div className="icon-plus">
          {canUpdate() && (
            <button
              className="edit-icon"
              onClick={() => setUrlToRedirect(`${ADMIN_NEWS_URL}/${id}`)}
            >
              <img src={editIcon} alt="" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardsAdmin;
