import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './_style.scss';
import UploadImage from '../../molecules/UploadImage/UploadImage';
import ImageLabel from '../../atoms/ImageLabel/ImageLabel';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import {DatePicker, Form, Input, message, Select, Space} from 'antd';
import { useRedirect } from '../../Router/redirect';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import { ADMIN_PROMOTIONS_URL } from '../../../utils/constants';
import { REQUIRED_FIELD_RULE } from '../../../utils/validation-rules';
import apiCall from '../../../services/api-calls/all';
import { isEmpty } from '../../../utils/func-helpers';
import moment from 'moment';

const { getPromotionStates, createPromotion, editPromotion } = apiCall();
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const PromotionForm = ({ promotion = null }) => {
  const [form] = Form.useForm();
  const [states, setStates] = useState([]);
  const [image, setImage] = useState(
    promotion && promotion.hasOwnProperty('image') ? promotion.image : null
  );
  const [file, setFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { redirect, setUrlToRedirect } = useRedirect();

  const searchStates = async () => {
    try {
      const response = await getPromotionStates();
      setStates(response);
    } catch (error) {
      message.error(processedErrorMessage(error));
    }
  };

  const handleSubmit = async values => {
    if (loading) return;
    setLoading(true);
    try {
      const formData = new FormData();
      Object.keys(values).map(key => {
        if (key !== 'image' && key !== 'file' && values[key]) formData.set(key, values[key]);
      });
      if (image.name) {
        formData.set('image', image);
      } else {
        formData.set('image', null);
      }
      if (file.name) {
        formData.set('file', file);
      } else {
        formData.set('file', null);
      }
      if (values.hasOwnProperty('date') && values.date) {
        formData.set('fromDate', moment(values.date[0]).format('YYYY-MM-DD'));
        formData.set('toDate', moment(values.date[1]).format('YYYY-MM-DD'));
      }

      if (promotion) {
        await editPromotion(promotion.id, formData);
      } else {
        await createPromotion(formData);
      }
      message.success('Acción realizada con éxito');
      setLoading(false);
      setUrlToRedirect(ADMIN_PROMOTIONS_URL);
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
      setLoading(false);
    }
  };

  const updateFileData = (set, data, setObject) => {
    form.setFieldsValue(set);
    if (data) {
      setObject([
        {
          uid: '1',
          name: data.name ? data.name : promotion.title
        }
      ]);
    } else {
      setObject([]);
    }
  };

  const handleRangePicker = (value, date) => {
    value ? form.setFieldsValue({date}) : form.setFieldsValue({date:value})
  }

  useEffect(() => {
    updateFileData({  image  }, image, setImageList);
  }, [image]);

  useEffect(() => {
    updateFileData({  file  }, file, setFileList);
  }, [file]);

  useEffect(() => {
    searchStates();
  }, []);

  useEffect(() => {
    if  (promotion && !isEmpty(promotion)) {
      form.setFieldsValue({
        title: promotion.title,
        description: promotion.description,
        date: [moment(promotion.fromDate), moment(promotion.toDate)],
        file: promotion.file.fileName,
        image: promotion.image,
        idState: promotion.promotionState.id
      });
      setImage(promotion.image);
      setFile(promotion.file.fileName);
    }
  }, [promotion]);

  return (
    <Form form={form} onFinish={handleSubmit}>
      {redirect()}
      <div className="page-content promotions-form">
        <div className="load-image">
          <Form.Item rules={[REQUIRED_FIELD_RULE]} name="image">
            <UploadImage
              defaultImage={image ? { fileUrl: image } : {}}
              setFile={setImage}
              formats={['.png', '.jpg']}
              description="Los formatos aceptados son .jpg y .png. El tamaño máximo permitido para los archivos es 5MB."
              fileList={imageList}
              required={true}
            />
          </Form.Item>
        </div>
        <div className="inputs-container">
          <div className="input margin">
            <label className="input-label">Titulo <span>*</span></label>
            <Form.Item rules={[REQUIRED_FIELD_RULE]} name="title">
              <Input className="input" placeholder="Titulo" />
            </Form.Item>
          </div>
          <div className="input margin">
            <label className="input-label">Vigencia <span>*</span></label>
            <Form.Item rules={[REQUIRED_FIELD_RULE]} name="date">
              <Space direction="vertical" size={12}>
                <RangePicker
                  defaultValue={promotion && !isEmpty(promotion) ? [moment(promotion.fromDate), moment(promotion.toDate)] : null}
                  format="YYYY-MM-DD"
                  onChange={handleRangePicker}
                />
              </Space>
            </Form.Item>
          </div>
          <div className="input margin">
            <label className="input-label">Ficha Técnica <span>*</span></label>
            <Form.Item rules={[REQUIRED_FIELD_RULE]} name="description">
              <TextArea className="input" placeholder="Ficha Técnica"/>
            </Form.Item>
          </div>
          <div className="text-area-container">
            <label className="input-label">Estado <span>*</span></label>
            <Form.Item rules={[REQUIRED_FIELD_RULE]} name="idState">
              <Select placeholder="Estado">
                {states.map((state, index) => (
                  <Option key={index} value={state.id}>
                    {state.description}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="empty-div" />
          <div className="elegir-archivo-container">
            <Form.Item rules={[REQUIRED_FIELD_RULE]} name="file">
              <ImageLabel
                label={<label className="input-label">Documento <span>*</span></label>}
                setFile={setFile}
                formats={['.pdf']}
                description="El formato aceptado es .pdf"
                fileList={fileList}
                beforeUpload={(file) => {
                  const isPdf = file.type === 'application/pdf';
                  const isLt5M = file.size / 1024 / 1024 < 5;
                  
                  if (!isLt5M)
                    message.error('El documento cargado no respeta el limite de 5MB');

                  if (!isPdf)
                    message.error("El archivo cargado no respeta las condiciones de formato");
                  return !isPdf || !isLt5M;
                }}
              />
            </Form.Item>
          </div>
        </div>
        <label className="fields-required">
          Campos requeridos <span>*</span>{' '}
        </label>
      </div>
      <div className="buttons-container">
        <ButtonSecondary
          text="CANCELAR"
          theme="btn-cancelar"
          onClick={() => setUrlToRedirect(ADMIN_PROMOTIONS_URL)}
        />
        <ButtonPrimary text="GUARDAR" theme="btn-guardar" type="submit" />
      </div>
    </Form>
  );
};

PromotionForm.propTypes = {
  promotion: PropTypes.object
};

export default PromotionForm;
