import React, { useState } from 'react';
import './_style.scss';
import { Input, message, Space } from 'antd';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import PaginatedTable from '../../molecules/PaginatedTable/paginated-table.jsx';
import { columns } from '../../../utils/tables/table-users-list-admin';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import apiCalls from '../../../services/api-calls/all';

import { ADMIN_USER_LIST_URL, ADMIN_USER_CREATE, PAGE_SIZE } from '../../../utils/constants';
import { useRedirect } from '../../Router/redirect';
import { UserHelpers } from '../../../utils/user-helpers';
import Spinner from '../../atoms/Spinner/Spinner';

const { getUsersFiltered, enableUser, disableUser } = apiCalls();
const { Search } = Input;

const AdminUserList = () => {
  const [search, setSearch] = useState('');
  const [reset, setReset] = useState(false);
  const filters = [search, reset];
  const { canCreate, canUpdate } = UserHelpers();
  const { redirect, setUrlToRedirect } = useRedirect();
  const [loading, setLoading] = useState(false);

  const formatTableData = data =>
    data.content.map((user, i) => ({ key: (i + 1).toString(), ...user }));

  const handleOnEdit = id => {
    setUrlToRedirect(`${ADMIN_USER_LIST_URL}/${id}`);
  };

  const onChangeStatus = async (id, active) => {
    try {
      setLoading(true);
      active ? await enableUser(id) : await disableUser(id);
      setReset(!reset);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };
  
  const usersFiltered = async (page, pageSize, onResult) => {
    try {
      setLoading(true);
      const filter = {
        page,
        size: pageSize,
        search
      };
      const response = await getUsersFiltered(filter);
      setLoading(false);
      onResult(response);
    } catch (error) {
      setLoading(false);
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  return (
    <Spinner loading={loading}>
      {redirect()}
      <div className="inputs_container">
        <div className="search_btn">
          <Search
            className="search_btn"
            placeholder="Buscar"
            onSearch={value => setSearch(value)}
            enterButton
          />
        </div>
        <Space className="admin-promotions">
          {canCreate() && (
            <div className="btn_crear">
              <ButtonPrimary
                theme="btn_blue"
                text="CREAR NUEVO USUARIO"
                onClick={() => setUrlToRedirect(ADMIN_USER_CREATE)}
              />
            </div>
          )}
        </Space>
      </div>
      <div className="page-content">
        <PaginatedTable
          className="table-users"
          columns={columns(canUpdate() ? handleOnEdit : null, canUpdate() ? onChangeStatus : null)}
          rowClassName={record => !record.active && 'disabled-row'}
          onFetch={usersFiltered}
          onFormat={formatTableData}
          pageSize={PAGE_SIZE}
          filters={filters}
          scroll={{ x: 800, y:300 }}
        />
      </div>
    </Spinner>
  );
};

export default AdminUserList;
