import React from 'react';
import './_style.scss';

import TitlePage from '../../atoms/TitlePage/TitlePage';

const Home = () => (
  <div className="main-home-container">
    <TitlePage text="HOME" />
  </div>
);

export default Home;
