import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import './_style.scss';
import UploadImage from '../../molecules/UploadImage/UploadImage';
import TextArea from 'antd/lib/input/TextArea';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import { Form, Select, message, Input, DatePicker } from 'antd';

import { REQUIRED_FIELD_RULE } from '../../../utils/validation-rules';
import { useRedirect } from '../../Router/redirect';
import apiCall from '../../../services/api-calls/all';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import { ADMIN_NEWS_URL } from '../../../utils/constants';
import moment from 'moment';
import Spinner from '../../atoms/Spinner/Spinner';

const { getNewOrders, editNew } = apiCall();

const { Option } = Select;

const NewForm = forwardRef((properties, reference) => {
  const [form] = Form.useForm();
  const [orders, setOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [image, setImage] = useState(
    properties.newItem.hasOwnProperty('image') ? properties.newItem.image : null
  );
  const [fileList, setFileList] = useState([]);
  const [newItem, setNewItem] = useState(properties.newItem);
  const [loading, setLoading] = useState(false);
  const [sendForm, setSendForm] = useState(false);
  const { redirect, setUrlToRedirect } = useRedirect();

  properties.newItem.date = properties.newItem.date ? moment(properties.newItem.date) : null;

  const searchOrders = async () => {
    try {
      setOrdersLoading(true);
      const response = await getNewOrders();
      setOrders(response);
      setOrdersLoading(false);
    } catch (error) {
      setOrdersLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const handleSubmit = async values => {
    if (loading) return;
    setLoading(true);
    try {
      const formData = new FormData();
      Object.keys(values).map(key => {
        if (key !== 'image' && values[key]) formData.set(key, values[key]);
      });
      if (values.hasOwnProperty('date') && values.date)
        formData.set('date', moment(values.date).format('YYYY-MM-DD'));
      if (image.name) formData.set('image', image);
      await editNew(properties.newItem.id, formData);
      message.success('Acción realizada con exito');
      setLoading(false);
      setUrlToRedirect(ADMIN_NEWS_URL);
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
    setNewItem(null);
    setImage(null);
    validateSendForm({}, form.getFieldsValue());
  };

  const validateSendForm = (changedValues, allValues) => {
    const { title, image, date, description } = allValues;
    if (title && image && date && description) {
      setSendForm(true);
    } else {
      setSendForm(false);
    }
  };

  useEffect(() => {
    searchOrders();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      image,
      ...newItem
    });
    if (image) {
      setFileList([
        {
          uid: '1',
          name: image.name ? image.name : properties.newItem.title
        }
      ]);
      validateSendForm({}, form.getFieldsValue());
    } else {
      setFileList([]);
      setSendForm(false);
    }
  }, [form, image, properties.newItem.title]);

  return (
    <Spinner loading={loading}>
      <Form form={form} onFinish={handleSubmit} onValuesChange={validateSendForm}>
        {redirect()}
        <div className="page-content">
          <div className="load-image">
            <Form.Item rules={[REQUIRED_FIELD_RULE]} name="image">
              <UploadImage
                defaultImage={image ? { fileUrl: image } : {}}
                setFile={setImage}
                formats={['.png', '.jpg']}
                description="Los formatos aceptados son .jpg y .png. El tamaño maximo permitido para los archivos es 5MB."
                fileList={fileList}
                required={true}
              />
            </Form.Item>
          </div>
          <div className="inputs-container">
            <div className="input date">
              <label className="input-label">
                Fecha <span>*</span>{' '}
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="date">
                <DatePicker placeholder="Fecha" format="YYYY-MM-DD" />
              </Form.Item>
            </div>
            <div className="input date">
              <label className="input-label">
                Titulo <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="title">
                <Input className="input" placeholder="Titulo" />
              </Form.Item>
            </div>
            <div className="text-area-container">
              <label className="text-area-label">
                Descripción <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="description">
                <TextArea />
              </Form.Item>
            </div>
            <div className="input date">
              <label className="input-label">
                Cambiar Orden <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="order">
                <Select loading={ordersLoading} placeholder=" Cambiar Orden">
                  {orders.map((order, index) => (
                    <Option key={index} value={order}>
                      {order}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <label className="fields-required">
            Campos requeridos <span>*</span>{' '}
          </label>
        </div>
        <div className="buttons-container">
          <ButtonSecondary
            text="CANCELAR"
            theme="btn-cancelar"
            onClick={() => setUrlToRedirect(ADMIN_NEWS_URL)}
          />
          <ButtonPrimary text="LIMPIAR" theme="btn-limpiar" onClick={onReset} />
          <ButtonPrimary
            text="GUARDAR"
            theme={!sendForm ? 'ThemeDisable' : 'btn-guardar'}
            type="submit"
            disabled={!sendForm}
          />
        </div>
      </Form>
    </Spinner>
  );
});

NewForm.propTypes = {
  newItem: PropTypes.object
};

export default NewForm;
