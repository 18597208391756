import React, { useEffect } from 'react';
import moment from 'moment';
import './_style.scss';
import PropTypes from 'prop-types';
import { DatePicker, Form, Input } from 'antd';
import ButtonSecondary from '../../atoms/ButtonSecondary/button-secondary';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import { SURVEYS } from '../../../utils/constants';
import { useRedirect } from '../../Router/redirect';
import TextArea from 'antd/es/input/TextArea';
import { REQUIRED_FIELD_RULE } from '../../../utils/validation-rules';
import { isEmpty } from '../../../utils/func-helpers';
import { UserHelpers } from '../../../utils/user-helpers';

const SurveyProductForm = ({ survey, submitText, handleSubmit }) => {
  const [form] = Form.useForm();
  const { redirect, setUrlToRedirect } = useRedirect();
  const { isViewer } = UserHelpers();

  useEffect(() => {
    if (survey && !isEmpty(survey)) {
      const { surveyDate, ...rest } = survey;
      form.setFieldsValue({
        surveyDate: moment(surveyDate),
        ...rest
      });
    }
  }, [survey]);

  return (
    <Form form={form} onFinish={handleSubmit}>
      {redirect()}
      <div className="page-content survey-product">
        <p className="required-fields">* Campos requeridos</p>
        <div className="inputs-survey-container">
          <div className="inputs-survey">
            <div className="_input">
              <label className="input-label">
                Fecha de relevamiento <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name={'surveyDate'}>
                <DatePicker format="YYYY-MM-DD" className="datepicker" />
              </Form.Item>
            </div>
            <div className="_input">
              <label className="input-label">
                Cantidad estimada mensual <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="estimatedMonthlyAmount">
                <Input placeholder="Cantidad estimada mensual" />
              </Form.Item>
            </div>
            <div className="_input">
              <label className="input-label">
                Producto <span>*</span>
              </label>
              <Form.Item rules={[REQUIRED_FIELD_RULE]} name="product">
                <Input maxLength={100} placeholder="Producto" />
              </Form.Item>
            </div>
          </div>

          <div className="_input">
            <label className="input-label">
              Medida <span>*</span>
            </label>
            <Form.Item rules={[REQUIRED_FIELD_RULE]} name="measure">
              <Input maxLength={100} placeholder="Medida" />
            </Form.Item>
          </div>
          <div className="text-area-survey">
            <label className="input-label">Diseño recomendado</label>
            <Form.Item rules={[]} name="recommendedDesign">
              <TextArea maxLength={100} rows={3} placeholder="Diseño" />
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="buttons-container">
        <ButtonSecondary
          text="CANCELAR"
          theme="btn-cancel"
          onClick={() => setUrlToRedirect(SURVEYS)}
        />
        <ButtonPrimary
          disabled={isViewer()}
          theme={isViewer() ? 'disabled' : 'btn-save'}
          text={submitText}
          type="submit"
        />
      </div>
    </Form>
  );
};

SurveyProductForm.propTypes = {
  survey: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string
};

SurveyProductForm.defaultProps = {
  survey: {},
  submitText: 'GUARDAR'
};

export default SurveyProductForm;
