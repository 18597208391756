import React, { useState, useEffect, useContext } from 'react';
import './_style.scss';
import { message, Table } from 'antd';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import { compare } from '../../../utils/func-helpers';
import { columns } from '../../../utils/tables/table-cart';
import { CartContext } from '../../../services/providers/cart-provider';

const CartList = () => {
  const { cart, removeFromCart, addItemToCart, loadCart } = useContext(CartContext);
  const [products, setProducts] = useState([]);

  const onChange = async (product, qty) => {
    try {
      await addItemToCart({ idProduct: product.id, quantity: qty });
      loadCart();
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  const onRemoveItem = product => {
    removeFromCart(product)
      .then(response => {
        message.success(`Producto: "${product.title}" ha sido removido del carrito.`);
      })
      .catch(error => {
        message.error(processedErrorMessage(error));
      });
  };

  useEffect(() => {
    console.log(cart);
    const { items } = cart;
    if (items) {
      setProducts(
        items
          .sort(({ product }, { productB = product }) => compare(product, productB, 'id'))
          .map((item, index) => ({ key: index, ...item }))
      );
    }
  }, [cart]);

  return (
    <Table
      locale={{
        emptyText: 'No se han agregado productos al Carrito de compras'
      }}
      className="table-users"
      columns={columns(onChange, onRemoveItem)}
      dataSource={products}
    />
  );
};

export default CartList;
