import React, { useState, useEffect } from 'react';
import './_style.scss';
import TitlePage from '../../atoms/TitlePage/TitlePage';
import CatalogueCards from '../../molecules/CatalogueCards/catalogue-cards';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import { message, Pagination } from 'antd';
import apiCalls from '../../../services/api-calls/all';
import { currencyFormat } from '../../../utils/func-helpers';
import Spinner from '../../atoms/Spinner/Spinner';

const { getProductsCatalog, getProductsCategories } = apiCalls();

const CatalogueList = () => {
  const [categories, setCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState([-1]);
  const [products, setProducts] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [size, setSize] = useState(12);
  const [loading, setLoading] = useState(false);

  const getProducts = async (page, pageSize = size) => {
    try {
      const filter = {
        page,
        size: pageSize,
        productCategories:
          categorySelected && !categorySelected.includes(-1) ? categorySelected.join(',') : null
      };
      setLoading(true);
      const response = await getProductsCatalog(filter);
      setProducts(response.content);
      setLoading(false);
      setTotalProducts(response.totalElements);
      setSize(response.size);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const getCategories = async () => {
    try {
      setLoading(true);
      const response = await getProductsCategories();
      setCategories(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(processedErrorMessage(error));
    }
  };

  const prepareProducts = () =>
    products.map(product => (
      <CatalogueCards
        key={product.id}
        id={product.id}
        code={product.code}
        img={product.image}
        title={product.title}
        description={product.description}
        puntos={product.points}
        stock={product.availableStock}
        precio={currencyFormat(product.price)}
        enable={product.enable}
      />
    ));

  const handleCategory = categoryId => {
    // reset other pills
    if (categoryId === -1) setCategorySelected([-1]);
    else {
      const selected = categorySelected.includes(-1) ? [] : categorySelected;
      const exist = selected.indexOf(categoryId);
      exist > -1 ? selected.splice(exist, 1) : selected.push(categoryId);
      setCategorySelected([...new Set(selected)]);
    }
  };

  const onChange = value => {
    getProducts(value - 1, size);
    setPageNumber(value);
  };

  useEffect(() => {
    if (categorySelected.length === 0) setCategorySelected([-1]);
    onChange(1);
  }, [categorySelected]);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Spinner loading={loading}>
      <div className="catalogue-title-filter">
        <TitlePage text="CATÁLOGO" />
        <div className="filters-container">
          <ButtonPrimary
            text="TODOS"
            onClick={() => handleCategory(-1)}
            theme={categorySelected.includes(-1) ? 'filters-style active' : 'filters-style'}
          />
          {categories.map(category => (
            <ButtonPrimary
              key={category.id}
              text={category.description}
              onClick={() => handleCategory(category.id)}
              theme={
                categorySelected.includes(category.id) ? 'filters-style active' : 'filters-style'
              }
            />
          ))}
        </div>
      </div>
      <div className="cards-container">
        {products.length > 0 ? prepareProducts() : 'No hay productos disponibles'}
      </div>
      {totalProducts > size ? (
        <Pagination
          total={totalProducts}
          current={pageNumber}
          pageSize={size}
          onChange={onChange}
        />
      ) : (
        ''
      )}
    </Spinner>
  );
};

export default CatalogueList;
