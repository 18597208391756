import React from 'react';
import './style.scss';
import { Tabs } from 'antd';
import TitlePage from '../../atoms/TitlePage/TitlePage';
import { SurveyPriceList } from '../../organism/SurveyPriceList/SurveyPriceList';
import { SurveyProductList } from '../../organism/SurveyProductList/SurveyProductList';

const { TabPane } = Tabs;

const SurveyClient = ({ match }) => {
  const { params } = match;
  return (
    <div className="main-survey-container">
      <TitlePage text="RELEVAMIENTOS" />
      <Tabs defaultActiveKey={params.step ? params.step : 'price'}>
        <TabPane tab="INFORMAR PRECIO" key="price">
          <SurveyPriceList />
        </TabPane>
        <TabPane tab="INFORMAR PRODUCTO" key="product">
          <SurveyProductList />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default SurveyClient;
