import React from 'react';
import { Popconfirm, Space } from 'antd';
import ButtonPrimary from '../../components/atoms/ButtonPrimary/button-primary';
import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined
} from '@ant-design/icons';
import { EXPENSE_APPROVED, EXPENSE_DECLINED, EXPENSE_DRAFT, EXPENSE_PENDING } from '../constants';
import { getMonthDescriptionByNumber } from '../func-helpers';

export const columns = () => [
  {
    title: 'AÑO',
    dataIndex: 'year',
    width: 80
  },
  {
    title: 'MES',
    dataIndex: 'month',
    render(month) {
      return getMonthDescriptionByNumber(month);
    },
    width: 100
  },
  {
    title: 'CLIENTE',
    dataIndex: 'registeredName',
    width: 150
  },
  {
    title: 'ESTADO',
    dataIndex: 'state',
    width: 250,
    ellipsis: true
  },
  {
    title: 'PASAJERO RADIAL 16',
    dataIndex: 'unitSales',
    render({ radialPassenger16 }) {
      return radialPassenger16;
    },
    width: 200
  },
  {
    title: 'PASAJERO RADIAL 17',
    dataIndex: 'unitSales',
    render({ radialPassenger17 }) {
      return radialPassenger17;
    },
    width: 200
  },
  {
    title: 'PASAJERO RADIAL 18',
    dataIndex: 'unitSales',
    render({ radialPassenger18 }) {
      return radialPassenger18;
    },
    width: 200
  },
  {
    title: 'CAMIONETA RADIAL 16',
    dataIndex: 'unitSales',
    render({ radialPickup16 }) {
      return radialPickup16;
    },
    width: 200
  },
  {
    title: 'CAMIONETA RADIAL 17',
    dataIndex: 'unitSales',
    render({ radialPickup17 }) {
      return radialPickup17;
    },
    width: 200
  },
  {
    title: 'CAMIONETA RADIAL 18',
    dataIndex: 'unitSales',
    render({ radialPickup18 }) {
      return radialPickup18;
    },
    width: 200
  },
  {
    title: 'VEHÍCULOS ATENDIDOS',
    dataIndex: 'unitSales',
    render({ servicedVehicles }) {
      return servicedVehicles;
    },
    width: 200
  },
  {
    title: 'Q ALINEACIONES',
    dataIndex: 'unitSales',
    render({ alignments }) {
      return alignments;
    },
    width: 200
  },
  {
    title: 'Q BOXES DE SERVICIOS',
    dataIndex: 'unitSales',
    render({ serviceBoxes }) {
      return serviceBoxes;
    },
    width: 200
  },
  {
    title: 'Q BALANCEO',
    dataIndex: 'unitSales',
    render({ balances }) {
      return balances;
    },
    width: 150
  },
  {
    title: 'DÍAS TRABAJADOS',
    dataIndex: 'unitSales',
    render({ workedDays }) {
      return workedDays;
    },
    width: 150
  },
  {
    title: 'NEUMÁTICOS MOSTRADOR',
    dataIndex: 'billing',
    render({ counterTires }) {
      return counterTires;
    },
    width: 200
  },
  {
    title: 'NEUMÁTICOS REVENTA',
    dataIndex: 'billing',
    render({ resaleTires }) {
      return resaleTires;
    },
    width: 200
  },
  {
    title: 'REPUESTOS & ACCESORIOS',
    dataIndex: 'billing',
    render({ spareParts }) {
      return spareParts;
    },
    width: 250
  },
  {
    title: 'RECONSTRUCCIÓN',
    dataIndex: 'billing',
    render({ rebuilds }) {
      return rebuilds;
    },
    width: 200
  },
  {
    title: 'SERVICIOS GOMERÍA',
    dataIndex: 'billing',
    render({ tireRepairServices }) {
      return tireRepairServices;
    },
    width: 200
  },
  {
    title: 'SERVICIOS MECÁNICO',
    dataIndex: 'billing',
    render({ autoRepairServices }) {
      return autoRepairServices;
    },
    width: 200
  },
  {
    title: 'VARIOS',
    dataIndex: 'billing',
    render({ various }) {
      return various;
    },
    width: 100
  },
  {
    title: 'GOODYEAR',
    dataIndex: 'inventory',
    render({ goodyear }) {
      return goodyear;
    },
    width: 100
  },
  {
    title: 'KELLY',
    dataIndex: 'inventory',
    render({ kelly }) {
      return kelly;
    },
    width: 100
  },
  {
    title: 'OTROS',
    dataIndex: 'inventory',
    render({ others }) {
      return others;
    },
    width: 100
  }
];
