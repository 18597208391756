import React from 'react';
import './style.scss';
import TitlePage from '../../atoms/TitlePage/TitlePage';
import PreApprovalList from '../../organism/PreApprovalLIst/PreApprovalList';


const PreApproval = () => (
  <div className="main-rendiciones-container">
    <TitlePage text="PRE-APROBACIÓN" />
    <PreApprovalList />
  </div>
);

export default PreApproval;
