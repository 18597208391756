import React, { useContext, useState, useEffect } from 'react';
import './style.scss';
import Icon from '../../atoms/Icon/Icon';
import { BellOutlined, SmileOutlined } from '@ant-design/icons';
import ButtonPrimary from '../../atoms/ButtonPrimary/button-primary';
import { processedErrorMessage } from '../../../services/api-calls/helpers';
import {
  Dropdown,
  Menu,
  Badge,
  message,
  Popover,
  Button,
  notification,
  Row,
  Col,
  Tooltip
} from 'antd';
import history from '../../Router/history';
import {
  LOGIN_URL,
  NOTIFICATION_APPROVED,
  NOTIFICATION_CREATED,
  NOTIFICATION_DECLINED,
  NOTIFICATION_PENDING,
  PENDING
} from '../../../utils/constants';
import apiCalls from '../../../services/api-calls/all';
import { UserContext } from '../../../services/providers/user-context';
import PropTypes from 'prop-types';
import { compare } from '../../../utils/func-helpers';
import { useRedirect } from '../../Router/redirect';
import { CartContext } from '../../../services/providers/cart-provider';
import Notification from '../Notifications/notfication';
import moment from 'moment';
import check from '../../../img/check.svg';
import close from '../../../img/close.svg';
import absent from '../../../img/absent.svg';
import { ClockCircleFilled } from '@ant-design/icons';

const {
  logoutRequest,
  unreadNotifications,
  readNotificationIdentifiedBy,
  readAllNotifications
} = apiCalls();

const spanishTopBarTooltips = {
  Profile: 'Perfil',
  Notifications: 'Notificaciones',
  Cart: 'Carrito',
  Logout: 'Cerrar Sesión'
};

const TopBar = ({ name, img, topbarEntries }) => {
  const { setUser } = useContext(UserContext);
  const { cartStatus } = useContext(CartContext);
  const { redirect, setUrlToRedirect } = useRedirect();
  const [notifications, setNotifications] = useState([]);

  const itemClick = path => {
    if (path === LOGIN_URL) {
      signOut();
    } else if (path !== null) {
      setUrlToRedirect(path);
    } else {
      setUser(null);
      history.push(LOGIN_URL);
    }
  };

  const _unreadNotifications = async () => {
    try {
      const response = await unreadNotifications();
      setNotifications(response);
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  const _markNotificationAsRead = async id => {
    try {
      await readNotificationIdentifiedBy(id);
      _unreadNotifications();
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  const _markAllNotificationsAsRead = async () => {
    try {
      await readAllNotifications();
      _unreadNotifications();
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
  };

  const _getNotificationIcon = notification => {
    if (notification.type.description === NOTIFICATION_PENDING)
      return (
        <ClockCircleFilled
          style={{ fontSize: '22px', color: '#FED102' }}
          className="pending-notification"
        />
      );
    if (notification.type.description === NOTIFICATION_APPROVED) return <img src={check} alt="" />;
    if (notification.type.description === NOTIFICATION_CREATED)
      return <img src={absent} alt="" style={{ height: '20px' }} />;
    if (notification.type.description === NOTIFICATION_DECLINED) return <img src={close} alt="" />;
  };

  const _notifications = () => {
    if (notifications.length === 0) {
      return <div className="no-notifications-label">¡Sin notificaciones!</div>;
    } else {
      return (
        <>
          {notifications.map(notification => {
            return (
              <Notification
                icon={_getNotificationIcon(notification)}
                title={notification.title}
                description={notification.description}
                date={moment(notification.created).format('YYYY-MM-DD')}
                onRead={() => _markNotificationAsRead(notification.id)}
              />
            );
          })}
          <Row justify="center">
            <Col>
              <Button
                type="link"
                onClick={_markAllNotificationsAsRead}
                className="mark-all-as-read"
              >
                Limpiar todas las notificaciones
              </Button>
            </Col>
          </Row>
        </>
      );
    }
  };

  useEffect(() => {
    _unreadNotifications();
  }, []);

  const signOut = async () => {
    try {
      await logoutRequest();
    } catch (error) {
      const errorMessage = processedErrorMessage(error);
      message.error(errorMessage);
    }
    setUser(null);
    history.push(LOGIN_URL);
  };

  const sortMenu = sortMenu => sortMenu.sort((a, b) => compare(a, b, 'order'));

  const _notificationsPopoverTitle = () => {
    return notifications.length > 0 ? (
      <Row justify="space-between">
        <Col span={14}>NOTIFICACIONES</Col>
        <Col span={2} offset={6}>
          {notifications.length}
        </Col>
      </Row>
    ) : (
      'NOTIFICACIONES'
    );
  };

  const prepareTopNavbar = sortedMenu => replaceIcons =>
    sortedMenu.map(item => {
      const icon = replaceIcons(item);
      if (item.items.length > 0) {
        return prepareDropdown(item, icon);
      }

      if (item.description === 'Cart') {
        return (
          <Badge key={item.order} count={cartStatus.amountProducts}>
            <ButtonPrimary
              onClick={() => itemClick(item.url)}
              iconLeft={icon}
              text={!icon ? item.description : ''}
              theme="buttons"
            />
          </Badge>
        );
      }

      if (item.description === 'Notifications') {
        return (
          <Badge count={notifications.length}>
            <Popover
              placement="bottom"
              title={_notificationsPopoverTitle}
              content={_notifications}
              trigger="click"
            >
              <ButtonPrimary
                className="button-notifications"
                theme="buttons"
                text={!icon ? item.description : ''}
                iconLeft={icon}
              />
            </Popover>
          </Badge>
        );
      }

      return (
        <ButtonPrimary
          key={item.order}
          onClick={() => itemClick(item.url)}
          iconLeft={icon}
          text={!icon ? item.description : ''}
          theme="buttons"
        />
      );
    });

  const prepareDropdown = (item, icon) => {
    const menuItems = sortedMenu => (
      <Menu>
        {sortedMenu.map(item => (
          <Menu.Item key={item.order}>
            {item.url}
            <div onClick={() => itemClick(item.url)}>{item.description}</div>
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Dropdown key={item.order} overlay={menuItems(sortMenu(item.items))} trigger={['click']}>
        <ButtonPrimary
          key={item.order}
          onClick={e => e.preventDefault()}
          iconLeft={icon}
          text={!icon ? item.description : ''}
          theme="buttons"
        />
      </Dropdown>
    );
  };

  const replaceIcons = prepareTopNavbar(sortMenu(topbarEntries));
  const topNavbar = replaceIcons(item =>
    item.icon ? (
      <Tooltip title={spanishTopBarTooltips[item.description]}>
        <Icon description={item.description} type={item.icon} />
      </Tooltip>
    ) : null
  );

  return (
    <div className="topbar-container">
      {redirect()}
      <div className="name-container">
        <span>Hola {name}!</span>
      </div>
      <div className="icons-container">
        <div className="vertical-line" />
        {topNavbar}
      </div>
    </div>
  );
};

TopBar.propTypes = {
  topbarEntries: PropTypes.any
};

export default TopBar;
