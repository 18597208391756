export const HOME_URL = '/';
export const LOGIN_URL = '/login';
export const CART_URL = '/cart';
export const NEWS_URL = '/novedades';
export const PROMOTIONS_URL = '/promociones';
export const PRICE_LIST = '/lista-precios';
export const CATALOGUE_LIST = '/catalogo';
export const ADMIN_NEWS_URL = '/parametria-novedades';
export const ADMIN_PROMOTIONS_URL = '/parametria-promociones';
export const ADMIN_PROMOTIONS_CREATE_URL = '/parametria-promociones/create';
export const ADMIN_PRICE_LIST_URL = '/parametria-lista-precios';
export const ADMIN_PRICE_LIST_CREATE_URL = '/parametria-lista-precios/create';
export const ADMIN_CLIENTS_URL = '/parametria-clients';
export const ADMIN_CLIENTS_CREATE_URL = '/parametria-clients/create';
export const ADMIN_USER_LIST_URL = '/parametria-usuarios';
export const ADMIN_USER_CREATE = '/parametria-usuarios/create';
export const ADMIN_USER_EDIT = '/parametria-usuarios/:id';
export const ADMIN_CATALOGUE_LIST_URL = '/parametria-catalogo';
export const ADMIN_CATALOGUE_CREATE = '/parametria-catalogo/create';
export const ADMIN_CATALOGUE_EDIT = '/parametria-catalogo/:id';
export const ADMIN_ORDER_EDIT = '/parametria-pedidos/:id';
export const ADMIN_ORDER_LIST = '/parametria-pedidos';
export const ADMIN_PROFILE = '/profile/';
export const PRODUCTIVITY = '/productividad';
export const ADMIN_PRODUCTIVITY_LIST = '/parametria-productividad';
export const ADMIN_PRODUCTIVITY_CREATE = '/parametria-productividad/create';
export const ADMIN_PRODUCTIVITY_EDIT = '/parametria-productividad/:id';
export const ADMIN_SURVEY_IMPORT = '/parametria-relevamientos/import';
export const ADMIN_SURVEY = '/parametria-relevamientos';
export const SURVEYS = '/relevamientos';
export const PRICE_SURVEYS_CREATE = '/relevamientos/price/create';
export const PRICE_SURVEYS_EDIT = '/relevamientos/price/:id';
export const PRODUCT_SURVEYS_CREATE = '/relevamientos/product/create';
export const PRODUCT_SURVEYS_EDIT = '/relevamientos/product/:id';
export const ADMIN_EXPENSE_REPORT = '/parametria-rendiciones';
export const ADD_CREDENTIALS = '/credentials/add';
export const EXPENSE = '/rendiciones';
export const RENDITIONS_CREATE = '/rendiciones/create';
export const RENDITIONS = '/rendiciones';
export const DASHBOARD = '/dashboard';
export const PREAPPROVAL = '/pre-aprobacion';
export const PREAPPROVAL_CREATE = '/pre-aprobacion/create';
export const PREAPPROVAL_EDIT = '/pre-aprobacion/:id';
export const REFUND = '/reembolsos';
export const REFUND_EDIT = '/reembolsos/:id';
export const REFUND_CREATE = '/reembolsos/create/:id';

export const DOCUMENT_NAME = 'goodyear-intranet-frontend';
export const USER = 'user';
export const ACCESS_TOKEN = 'accessToken';
export const EXPIRED_SESSION = 'expired_session';
export const MENU = 'menu';
export const PAGE_SIZE = 20;
export const CREDENTIALS_URL = '/novedades';
export const REQUESTS_URL = '/requests';
export const ACTIVITIES_URL = '/activities';
export const PROFILE_URL = '/profile';
export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export const CREDENTIAL_ACTIVE = 'Vigente';
export const CREDENTIAL_REVOKE = 'Revocada';

export const CREDENTIAL_PENDING_DIDI = 'Pendiente-didi';
export const CREDENTIAL_PENDING_BONDAREA = 'Pendiente-bondarea';

export const PERMISSION_NEWS = 'NEWS';
export const PERMISSION_PRICE_LIST = 'PRICE_LIST';
export const PERMISSION_PROMOTIONS = 'PROMOTIONS';
export const PERMISSION_ADMIN_NEWS = 'ADMIN_NEWS';
export const PERMISSION_ADMIN_NEWS_EDIT = 'ADMIN_NEWS_EDIT';
export const PERMISSION_ADMIN_PROMOTIONS = 'ADMIN_PROMOTIONS';
export const PERMISSION_ADMIN_PROMOTIONS_CREATE = 'ADMIN_PROMOTIONS_CREATE';
export const PERMISSION_ADMIN_PROMOTIONS_EDIT = 'ADMIN_PROMOTIONS_EDIT';
export const PERMISSION_ADMIN_PRICE_LIST = 'ADMIN_PRICE_LIST';
export const PERMISSION_ADMIN_PRICE_LIST_CREATE = 'ADMIN_PRICE_LIST_CREATE';
export const PERMISSION_ADMIN_PRICE_LIST_EDIT = 'ADMIN_PRICE_LIST_EDIT';
export const PERMISSION_ADMIN_CLIENTS = 'ADMIN_CUSTOMERS';
export const PERMISSION_ADMIN_CLIENTS_CREATE = 'ADMIN_CUSTOMERS_CREATE';
export const PERMISSION_ADMIN_CLIENTS_EDIT = 'ADMIN_CUSTOMERS_EDIT';
export const PERMISSION_ADMIN_CATALOG_CREATE = 'ADMIN_CATALOG_CREATE';
export const PERMISSION_ADMIN_CATALOG = 'ADMIN_CATALOG';
export const PERMISSION_ADMIN_CATALOG_EDIT = 'ADMIN_CATALOG_EDIT';
export const PERMISSION_ADMIN_ORDERS = 'ADMIN_ORDERS';
export const PERMISSION_ADMIN_EDIT_ORDERS = 'ADMIN_ORDERS_EDIT';
export const PERMISSION_PRODUCTIVITY = 'PRODUCTIVITY';
export const PERMISSION_ADMIN_PRODUCTIVITY_CREATE = 'PRODUCTIVITY_CREATE';
export const PERMISSION_ADMIN_PRODUCTIVITY_EDIT = 'PRODUCTIVITY_UPDATE';
export const PERMISSION_SURVEYS = 'SURVEYS';
export const PERMISSION_PRICE_SURVEYS_CREATE = 'PRICE_SURVEYS_CREATE';
export const PERMISSION_PRICE_SURVEYS_EDIT = 'PRICE_SURVEYS_EDIT';
export const PERMISSION_PRODUCT_SURVEYS_CREATE = 'PRODUCT_SURVEYS_CREATE';
export const PERMISSION_PRODUCT_SURVEYS_EDIT = 'PRODUCT_SURVEYS_EDIT';
export const PERMISSION_ADMIN_SURVEYS = 'ADMIN_SURVEYS';
export const PERMISSION_EXPENSE_REPORT = 'EXPENSE_REPORT';
export const PERMISSION_EXPENSE_REPORT_CREATE = 'EXPENSE_REPORT_CREATE';
export const PERMISSION_ADMIN_EXPENSE_REPORT = 'ADMIN_EXPENSE_REPORT';
export const PERMISSION_DASHBOARD = 'DASHBOARD';
export const PERMISSION_PRE_APPROVAL = 'PRE_APPROVAL';
export const PERMISSION_PRE_APPROVAL_CREATE = 'PRE_APPROVAL_CREATE';
export const PERMISSION_PRE_APPROVAL_EDIT = 'PRE_APPROVAL_EDIT';
export const PERMISSION_REFUND = 'REFUND';
export const PERMISSION_REFUND_EDIT = 'REFUND_EDIT';
export const PERMISSION_REFUND_CREATE = 'REFUND_CREATE';
export const PERMISSION_ADMIN_EXPENSE_IMPORT = 'ADMIN_EXPENSE_IMPORT';

export const ACTION_ADD_TO_CART = 'ADD_TO_CART';

// states for order
export const PENDING = 'Pendiente';
export const APPROVED = 'Confirmado';
export const DECLINED = 'Rechazado';

// states for renditions
export const EXPENSE_PENDING = 'Pendiente';
export const EXPENSE_APPROVED = 'Confirmada';
export const EXPENSE_DECLINED = 'Rechazada';
export const EXPENSE_DRAFT = 'Borrador';

// payment methods
export const PAYMENT_METHOD_POINTS = 'Puntos';
export const PAYMENT_METHOD_MONEY = 'PAYMENT_METHOD_MONEY';

// states for preapproval
export const PREAPPROVAL_PENDING = 'Pendiente';
export const PREAPPROVAL_APPROVED = 'Confirmada';
export const PREAPPROVAL_DECLINED = 'Rechazada';

// notification types
export const NOTIFICATION_PENDING = 'Pendiente';
export const NOTIFICATION_APPROVED = 'Confirmación';
export const NOTIFICATION_CREATED = 'Creación';
export const NOTIFICATION_DECLINED = 'Rechazo';